/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react'
import {
  AccountCardMediaFileLink,
  PortfolioMediaFileUri,
} from 'src/graphql/models/PortfolioDetail'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ConvertDateToUTC, formatDateAndTimeCountry } from 'src/utils/date'
import { useLazyQuery } from '@apollo/client'
import { GET_PORTFOLIO_MEDIA_FILE_URI } from 'src/graphql/operations/queries/portfolio'
import { cellCreateBy } from 'src/utils/formatKendoColumns'
import { useDeletePortfolioMediaFile } from 'src/graphql/operations/mutations/postSale'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import Loader from 'src/components/Loader/Loader'
import { AzureContainers } from 'src/utils/common'
import { DataTable, Icon } from 'everchain-uilibrary'
import { ZipIcon } from 'src/components/Icons'

interface AccountDetailMediaFilesTableProps {
  accountCardMediaFiles?: AccountCardMediaFileLink[] | undefined
  uploadInProgress: boolean
}

const AccountDetailMediaFilesTable: React.FC<
  AccountDetailMediaFilesTableProps
> = ({
  accountCardMediaFiles,
  uploadInProgress,
}: AccountDetailMediaFilesTableProps) => {
  const { profileClient } = useContext<any>(AuthContext)
  const [mediaFileToBeDeleted, setMediaFileToBeDeleted] = useState<any>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')
  const notifySuccess = notistackOptions('success')

  const [getUri, { loading: loadingAccountCardMediaFileUri }] =
    useLazyQuery<PortfolioMediaFileUri>(GET_PORTFOLIO_MEDIA_FILE_URI, {
      onCompleted: (responseData) => {
        const uri = responseData.getPortfolioMediaFileUri ?? ''
        if (uri === '') return
        downloadFile(uri)
      },
    })

  const {
    deleteAccountCardMediaFile,
    loading: loadingDeleteAccountCardMediaFile,
  } = useDeletePortfolioMediaFile({
    onCompleted: () => {
      enqueueSnackbar('File deleted successfully', notifySuccess)
      handleDeleteDialogToggle()
    },
    onError: (e) => {
      enqueueSnackbar('Error deleting file', notifyError)
    },
  })

  const onDownload = (mediaFileId: any) => {
    getUri({
      variables: {
        mediaFileId,
        containerId: AzureContainers.AccountCard,
      },
      fetchPolicy: 'network-only',
    })
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const handleDeleteDialogToggle = () => {
    setDeleteDialogOpen(!deleteDialogOpen)
  }

  const handleDeleteMediaFile = (value: any) => {
    setMediaFileToBeDeleted(value)
    handleDeleteDialogToggle()
  }

  const onDelete = () => {
    deleteAccountCardMediaFile({
      variables: {
        portfolioMediaFileId: mediaFileToBeDeleted,
      },
      refetchQueries: ['GetAccoundCardMediaFilesLink'],
    })
  }

  const fileLinkColumns: any[] = [
    {
      title: 'File Name',
      field: 'displayName',
      width: 150,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Box width={300}>
              <Button
                data-cy="file-name-button"
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                startIcon={
                  <Box width={20} height={20}>
                    <ZipIcon width="130%" height="130%" />
                  </Box>
                }
                onClick={() => {
                  onDownload(props.dataItem.id)
                }}
              >
                {props.dataItem[props.field]}
              </Button>
            </Box>
          </td>
        )
      },
    },
    {
      title: 'Uploaded By',
      field: 'createdByUserName',
      width: 150,
      show: true,
      render: (props: any) => cellCreateBy(props),
    },
    {
      title: 'Uploaded Date',
      field: 'createdUtc',
      width: 100,
      show: true,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td data-cy="uploaded-date-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? formatDateAndTimeCountry(
                    new Date(ConvertDateToUTC(props.dataItem[props.field])),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="uploaded-date-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Is Deleted',
      field: 'isDeleted',
      width: 50,
      show: true,
      render: (props: any) => {
        return (
          <td data-cy="is-deleted">
            <span>{props.dataItem[props.field] === true ? 'Yes' : 'No'}</span>
          </td>
        )
      },
    },
    {
      title: 'Portfolio Row Id',
      field: 'portfolioRowGuid',
      width: 150,
      show: true,
    },
    {
      title: 'Lender Loan Id',
      field: 'lenderLoanId',
      width: 100,
      show: true,
    },
    {
      title: 'Actions',
      field: '',
      show: true,
      width: 40,
      alignCenter: true,
      render: (props: any) => {
        return (
          <td>
            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleDeleteMediaFile(props.dataItem.id)
                }}
              >
                <Icon name="Delete" fontSize="small" />
              </IconButton>
            </Box>
          </td>
        )
      },
    },
  ]

  return (
    <>
      {(loadingAccountCardMediaFileUri || uploadInProgress) && (
        <Loader specificHeight="1px" />
      )}
      <Box p={2}>
        <DataTable
          scrollable="scrollable"
          data={accountCardMediaFiles}
          gridColumns={fileLinkColumns}
        />
      </Box>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogToggle}>
        <DialogTitle>Confirm delete media file</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container direction="column">
              <Grid item>
                <Typography>
                  Are you sure you want to delete this media file?
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDeleteDialogToggle}
            color="secondary"
            disabled={loadingDeleteAccountCardMediaFile}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onDelete}
            disabled={loadingDeleteAccountCardMediaFile}
            startIcon={
              loadingDeleteAccountCardMediaFile && (
                <CircularProgress size={16} color="primary" />
              )
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

AccountDetailMediaFilesTable.defaultProps = {
  accountCardMediaFiles: [],
}

export default AccountDetailMediaFilesTable
