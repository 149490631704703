import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  TextField,
  MenuItem,
  Grid,
  Box,
  Switch,
  Typography,
  InputAdornment,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import { format, parse } from 'date-fns'

import {
  FORWARD_FLOW_DETAIL,
  FORWARD_FLOW_INFO_SELLER_TEMPLATE,
  PORTFOLIO_TEMPLATE,
} from 'src/routes'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { maskerMoneyLocale, unmaskMoney } from 'src/utils/masker'
import { Icon, textSecondary } from 'everchain-uilibrary'
import dayjs from 'dayjs'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { savePortfolioTemplate } from 'src/data/features/post/portfolio/portfolio'
import { getNewPortfolioTemplateOptions } from 'src/data/features/get/portfolio/portfolio'
import { PortfolioTemplateOption } from 'src/data/features/get/portfolio/types'
import { getStandardUri } from 'src/utils/common'

const numberTopercent = (
  value?: number | string,
  returnType: 'number' | 'string' = 'number'
) => {
  if (value) {
    const result = Number(value) / 100
    if (returnType === 'string') {
      return result.toString()
    }
    return result
  }
  return null
}

const formInfoValidation = yup.object().shape({
  name: yup.string().required('Required'),
  fileMapId: yup.number().required('Required'),
  clientBankAccountId: yup.number().required('Required'),
  portfolioTypeId: yup.number().required('Required'),
  auctionDays: yup
    .number()
    .required('Required')
    .typeError('Only number')
    .min(0, 'Auction Days must be more than or equal to 0'),
  forwardFlowAgreementId: yup.number().nullable(true),
  maxResalesAllowed: yup
    .number()
    .required('Required')
    .typeError('Only number')
    .min(0, 'Max Resales must be more than or equal to 0'),
  status: yup.string().required('Required'),
  portfolioCloseTime: yup.string().required('Required'),
  buyerFeePercent: yup
    .number()
    .typeError('Only number')
    .min(0, 'Sales Commission must be more than or equal to 0')
    .max(100, 'Sales Commission must be less than or equal to 100'),
  reservePrice: yup
    .number()
    .typeError('Only number')
    .min(0, 'Reserve Price Percent must be more than or equal to 0')
    .max(100, 'Reserve Price Percent must be less than or equal to 100'),
  maxApr: yup
    .number()
    .typeError('Only number')
    .min(0, 'Max Apr must be more than or equal to 0'),
  minApr: yup
    .number()
    .typeError('Only number')
    .min(0, 'Min Apr must be more than or equal to 0')
    .when(['maxApr'], (maxApr: any, schema: any) => {
      return maxApr > 0
        ? schema.max(
            maxApr,
            'Min Apr value must be less than or equal to Max Apr'
          )
        : schema.max(Number.MAX_VALUE)
    }),
})

interface InfoFormValues {
  name: string | any
  auctionDays: number | any
  canEditFileMap?: boolean | any
  fileMapId: number | any
  portfolioTypeId: number | any
  maxResalesAllowed: number | any
  reservePrice: number | any
  frequency: string | any
  status: string | any
  clientBankAccountId: number | any
  estimatedFaceValue: number | any
  forwardFlowAgreementId: number | any
  buyerFeePercent: number | any
  maxApr: string | any
  minApr: string | any
  allowForwardFlowBids: Boolean | any
  licenseType?: string | any
  activeBOSWorkflow: Boolean | any
  activePSAWorkflow: Boolean | any
  pSABuyerSignsFirst: Boolean | any
  portfolioCloseTime: string | any
  disclosure: string | any
  militaryScrub: boolean | any
  skipBalanceValidation: Boolean | any
}
interface InfoStepperProps {
  infoFormValues?: InfoFormValues | null
  loadingInfo: boolean
  sellerInfo: any | null
  canPermissionEdit?: boolean | null
  portfolioCount: number
}

const InfoStepper: React.FC<InfoStepperProps> = ({
  infoFormValues,
  loadingInfo,
  sellerInfo,
  canPermissionEdit,
  portfolioCount,
}: InfoStepperProps) => {
  const [initialValues, setInitialValues] = useState<any>({
    auctionDays: '4',
    name: '',
    fileMapId: '',
    portfolioTypeId: '',
    maxResalesAllowed: '0',
    reservePrice: '',
    frequency: '',
    status: '',
    clientBankAccountId: '',
    estimatedFaceValue: '',
    forwardFlowAgreementId: '',
    buyerFeePercent: '',
    maxApr: '',
    minApr: '',
    allowForwardFlowBids: true,
    activeBOSWorkflow: true,
    activePSAWorkflow: true,
    pSABuyerSignsFirst: true,
    skipBalanceValidation: false,
    portfolioCloseTime: new Date(new Date().setHours(17, 0)),
    licenseType: '',
    disclosure: infoFormValues == null ? sellerInfo.disclosure : '',
  })

  const { sellerId, templateId } = useParams<any>()
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(window.location.search)

  const { userPermissions, profileClient } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const [createPortfolioTemplateRequest, setCreatePortfolioTemplateRequest] =
    useState<any>()

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }

  const addDisclosure =
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioDisclosure,
      'any'
    )

  const { data: portfolioTemplates, isFetching: loading } =
    useCustomQuery<PortfolioTemplateOption>(
      [
        'getNewPortfolioTemplateOptions',
        sellerId,
        infoFormValues?.forwardFlowAgreementId,
      ],
      async () =>
        getNewPortfolioTemplateOptions(
          sellerId,
          infoFormValues?.forwardFlowAgreementId
            ? infoFormValues?.forwardFlowAgreementId.toString()
            : ''
        ),
      { enabled: !!sellerId, cacheTime: 0 }
    )

  const { enqueueSnackbar } = useSnackbar()

  const handleMutationCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar(
        'Portfolio upload template created successfully',
        notistackOptions('success')
      )
      const pathToReplace = `${PORTFOLIO_TEMPLATE}/${sellerId}/${data.id}`
      history.push(getStandardUri(pathToReplace))
    }
  }

  const handleUpdateMutationCompleted = (data: any) => {
    const { updatePortfolioTemplate } = data || {}
    if (updatePortfolioTemplate) {
      enqueueSnackbar(
        'Portfolio upload template updated successfully',
        notistackOptions('success')
      )
    }
  }

  const { useUpdatePortfolioTemplate } = portfolioMutation

  const { isFetching: loadingSaveTemplate } = useCustomQuery<any>(
    ['savePortfolioTemplate', createPortfolioTemplateRequest],
    async () => {
      savePortfolioTemplate(createPortfolioTemplateRequest).then(
        (data: any) => {
          handleMutationCompleted(data)
        }
      )
    },
    { enabled: !!createPortfolioTemplateRequest }
  )

  const { updatePortfolioTemplate, loading: loadingUpdateTemplate } =
    useUpdatePortfolioTemplate({
      onCompleted: handleUpdateMutationCompleted,
    })

  const newPortfolioTemplatesOptions = portfolioTemplates
  const validate = (values: any) => {
    const errors: any = {}

    if (!values.forwardFlowAgreementId && !values.frequency)
      errors.frequency = 'Required'

    return errors
  }
  const formInfo = useFormik({
    enableReinitialize: true,
    validationSchema: formInfoValidation,
    initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const {
        auctionDays,
        name,
        fileMapId,
        portfolioTypeId,
        maxResalesAllowed,
        reservePrice,
        frequency,
        status,
        clientBankAccountId,
        estimatedFaceValue,
        forwardFlowAgreementId,
        buyerFeePercent,
        maxApr,
        minApr,
        allowForwardFlowBids,
        licenseType,
        activeBOSWorkflow,
        activePSAWorkflow,
        pSABuyerSignsFirst,
        portfolioCloseTime,
        disclosure,
        skipBalanceValidation,
      } = values
      const formatValues = {
        name,
        fileMapId: Number(fileMapId),
        portfolioTypeId,
        maxResalesAllowed: Number(maxResalesAllowed),
        status,
        auctionDays: auctionDays ? Number(auctionDays) : null,
        estimatedFaceValue: estimatedFaceValue
          ? Number(unmaskMoney(estimatedFaceValue))
          : null,
        frequency: frequency || null,
        clientBankAccountId: clientBankAccountId || null,
        buyerFeePercent: buyerFeePercent
          ? Number(numberTopercent(buyerFeePercent))
          : null,
        minApr: minApr ? Number(minApr) : null,
        maxApr: maxApr ? Number(maxApr) : null,
        reservePrice: reservePrice
          ? Number(numberTopercent(reservePrice))
          : null,
        sellerId,
        forwardFlowAgreementId: forwardFlowAgreementId
          ? Number(forwardFlowAgreementId)
          : null,
        allowForwardFlowBids: Boolean(allowForwardFlowBids),
        licenseType,
        activeBOSWorkflow: Boolean(activeBOSWorkflow),
        activePSAWorkflow: Boolean(activePSAWorkflow),
        skipBalanceValidation: Boolean(skipBalanceValidation),
        pSABuyerSignsFirst: Boolean(pSABuyerSignsFirst),
        portfolioCloseTime: format(new Date(portfolioCloseTime), 'hh:mm a'),
        disclosure,
      }
      if (!templateId) {
        setCreatePortfolioTemplateRequest(formatValues)
      } else {
        updatePortfolioTemplate({
          variables: {
            updatePortfolioTemplateRequest: {
              id: Number(templateId),
              ...formatValues,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })

  const canEditFileMap = infoFormValues?.canEditFileMap

  const handleForwardFlowClick = (forwardFlowId: any): void => {
    if (forwardFlowId !== undefined) {
      history.push(getStandardUri(`${FORWARD_FLOW_DETAIL}/${forwardFlowId}`))
    }
  }

  const handleAddForwardFlowClick = (seller: any): void => {
    if (seller !== undefined) {
      history.push(
        getStandardUri(
          `${FORWARD_FLOW_INFO_SELLER_TEMPLATE}/${seller.sellerId}?from=${window.location}&template=${templateId}`
        )
      )
    }
  }

  useEffect(() => {
    if (!loadingInfo && infoFormValues && loading) {
      const {
        auctionDays,
        name,
        fileMapId,
        portfolioTypeId,
        maxResalesAllowed,
        reservePrice,
        frequency,
        status,
        clientBankAccountId,
        estimatedFaceValue,
        forwardFlowAgreementId,
        buyerFeePercent,
        maxApr,
        minApr,
        allowForwardFlowBids,
        licenseType,
        activeBOSWorkflow,
        activePSAWorkflow,
        pSABuyerSignsFirst,
        disclosure,
        skipBalanceValidation,
      } = infoFormValues

      const portfolioTemplateNormalize = {
        auctionDays: auctionDays || '4',
        name: name || '',
        fileMapId: fileMapId || '',
        portfolioTypeId: portfolioTypeId || '',
        maxResalesAllowed: maxResalesAllowed || '0',
        reservePrice: reservePrice ? reservePrice * 100 : '',
        frequency: frequency || '',
        status: status || 'Active',
        clientBankAccountId: clientBankAccountId || '',
        estimatedFaceValue: maskerMoneyLocale(
          estimatedFaceValue?.toFixed(2) || '',
          userCountry()
        ),
        forwardFlowAgreementId:
          forwardFlowAgreementId ||
          (urlSearchParams.has('ff') ? urlSearchParams.get('ff') : ''),
        buyerFeePercent: buyerFeePercent
          ? (buyerFeePercent * 100).toFixed(2)
          : '',
        minApr: minApr ? Number(minApr) : null,
        maxApr: maxApr ? Number(maxApr) : null,
        allowForwardFlowBids: Boolean(allowForwardFlowBids),
        licenseType: licenseType || '',
        skipBalanceValidation: Boolean(skipBalanceValidation),
        activeBOSWorkflow: Boolean(activeBOSWorkflow),
        activePSAWorkflow: Boolean(activePSAWorkflow),
        pSABuyerSignsFirst: Boolean(pSABuyerSignsFirst),
        portfolioCloseTime: parse(
          infoFormValues?.portfolioCloseTime,
          'h:mm a',
          new Date()
        ),
        disclosure,
      }
      setInitialValues(portfolioTemplateNormalize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingInfo, infoFormValues, loading])

  return (
    <form onSubmit={formInfo.handleSubmit}>
      <Box pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-name"
              label="Name"
              name="name"
              onChange={formInfo.handleChange}
              value={formInfo.values.name}
              error={!!formInfo.errors.name}
              helperText={<>{formInfo.errors.name}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-status"
              select
              label="Status"
              name="status"
              onChange={formInfo.handleChange}
              value={formInfo.values.status}
              error={!!formInfo.errors.status}
              helperText={<>{formInfo.errors.status}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
            >
              <MenuItem selected disabled value="">
                Select Status
              </MenuItem>
              {newPortfolioTemplatesOptions?.statuses &&
                newPortfolioTemplatesOptions.statuses.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-file-map"
              select
              label="File Map"
              name="fileMapId"
              onChange={formInfo.handleChange}
              value={formInfo.values.fileMapId}
              error={!!formInfo.errors.fileMapId}
              helperText={<>{formInfo.errors.fileMapId}</>}
              fullWidth
              disabled={
                loading || (templateId && !canEditFileMap) || !canPermissionEdit
              }
            >
              <MenuItem selected disabled>
                Select File Map
              </MenuItem>
              {newPortfolioTemplatesOptions?.fileMaps &&
                newPortfolioTemplatesOptions?.fileMaps.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            {formInfo.values.forwardFlowAgreementId && portfolioCount > 0 ? (
              <Box>
                <TextField
                  id="select-forward-flow-aggrement-id"
                  label="Forward Flow Agreement ID"
                  fullWidth
                  name="forwardFlowAgreementId"
                  disabled={true}
                  onChange={formInfo.handleChange}
                  value=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ cursor: 'pointer' }}
                          variant="body2"
                          onClick={(): void => {
                            handleForwardFlowClick(
                              formInfo.values.forwardFlowAgreementId
                            )
                          }}
                        >
                          {`${formInfo.values.forwardFlowAgreementId} - ${formInfo.values.name}`}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  error={!!formInfo.errors.forwardFlowAgreementId}
                  helperText={<>{formInfo.errors.forwardFlowAgreementId}</>}
                />
              </Box>
            ) : portfolioCount > 0 ? (
              <TextField
                id="forwardFlowAgreementId"
                label="Forward Flow Agreement ID"
                fullWidth
                name="forwardFlowAgreementId"
                onChange={formInfo.handleChange}
                value={formInfo.values.forwardFlowAgreementId}
                error={!!formInfo.errors.forwardFlowAgreementId}
                helperText={<>{formInfo.errors.forwardFlowAgreementId}</>}
                disabled={true}
              />
            ) : (
              <TextField
                id="select-forward-flow-aggrement-id"
                select
                label="Forward Flow Agreement ID"
                name="forwardFlowAgreementId"
                onChange={formInfo.handleChange}
                value={formInfo.values.forwardFlowAgreementId}
                error={!!formInfo.errors.forwardFlowAgreementId}
                helperText={<>{formInfo.errors.forwardFlowAgreementId}</>}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(): void => {
                          handleAddForwardFlowClick({ sellerId })
                        }}
                      >
                        <Icon name="Add" fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                disabled={loading || !canPermissionEdit}
              >
                <MenuItem selected disabled>
                  Select Forward Flow Agreement ID
                </MenuItem>
                {newPortfolioTemplatesOptions?.forwardFlowAgreements &&
                  newPortfolioTemplatesOptions?.forwardFlowAgreements.map(
                    (option) => (
                      <MenuItem key={option.id} value={option.id}>
                        ID: {option.id} | Buyer: {option.buyerName} | From{' '}
                        {option.startDate} to {option.endDate} | Price:{' '}
                        {option.purchasePricePercent}
                      </MenuItem>
                    )
                  )}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-max-releases"
              label="Max Resales"
              fullWidth
              name="maxResalesAllowed"
              onChange={formInfo.handleChange}
              value={formInfo.values.maxResalesAllowed}
              error={!!formInfo.errors.maxResalesAllowed}
              helperText={<>{formInfo.errors.maxResalesAllowed}</>}
              disabled={loading || !canPermissionEdit}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-auction-days"
              label="Auction Days"
              name="auctionDays"
              onChange={formInfo.handleChange}
              value={formInfo.values.auctionDays}
              error={!!formInfo.errors.auctionDays}
              helperText={<>{formInfo.errors.auctionDays}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
              InputProps={{
                endAdornment: infoFormValues?.militaryScrub && (
                  <InputAdornment position="start">
                    <Tooltip title="Military Scrub is enabled. This can take 24-48 hours.">
                      <Icon name="ErrorOutline" fontSize="inherit" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-portfolio-type"
              select
              label="Portfolio Types"
              onChange={formInfo.handleChange}
              name="portfolioTypeId"
              value={formInfo.values.portfolioTypeId}
              error={!!formInfo.errors.portfolioTypeId}
              helperText={<>{formInfo.errors.portfolioTypeId}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
            >
              <MenuItem selected disabled>
                Select Portfolio Type
              </MenuItem>
              {newPortfolioTemplatesOptions?.portfolioTypes &&
                newPortfolioTemplatesOptions?.portfolioTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {userCountry() === 'UK' && option.id === 2
                      ? 'Insolvency'
                      : option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-choose-bank-account"
              select
              label="Choose Bank Account"
              name="clientBankAccountId"
              onChange={formInfo.handleChange}
              value={formInfo.values.clientBankAccountId}
              error={!!formInfo.errors.clientBankAccountId}
              helperText={<>{formInfo.errors.clientBankAccountId}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
            >
              <MenuItem selected disabled>
                Select Banck Account
              </MenuItem>
              {newPortfolioTemplatesOptions?.bankAccounts &&
                newPortfolioTemplatesOptions?.bankAccounts.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-buyer-fee"
              label="Sales Commission"
              name="buyerFeePercent"
              onChange={formInfo.handleChange}
              value={formInfo.values.buyerFeePercent}
              error={!!formInfo.errors.buyerFeePercent}
              helperText={<>{formInfo.errors.buyerFeePercent}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-reserve-fee"
              label="Reserve Price Percent"
              fullWidth
              name="reservePrice"
              onChange={formInfo.handleChange}
              value={formInfo.values.reservePrice}
              error={!!formInfo.errors.reservePrice}
              helperText={<>{formInfo.errors.reservePrice}</>}
              disabled={loading || !canPermissionEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="min-apr"
              label="Min APR"
              fullWidth
              name="minApr"
              onChange={formInfo.handleChange}
              value={formInfo.values.minApr}
              error={!!formInfo.errors.minApr}
              helperText={<>{formInfo.errors.minApr}</>}
              disabled={loading || !canPermissionEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="max-apr"
              label="Max APR"
              fullWidth
              name="maxApr"
              onChange={formInfo.handleChange}
              value={formInfo.values.maxApr}
              error={!!formInfo.errors.maxApr}
              helperText={<>{formInfo.errors.maxApr}</>}
              disabled={loading || !canPermissionEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <TextField
              id="select-license-type"
              label="License Model"
              name="licenseType"
              onChange={formInfo.handleChange}
              value={formInfo.values.licenseType}
              error={!!formInfo.errors.licenseType}
              helperText={<>{formInfo.errors.licenseType}</>}
              fullWidth
              disabled={loading || !canPermissionEdit}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
              <Switch
                color="primary"
                style={{ marginLeft: 0 }}
                name="activeBOSWorkflow"
                onChange={formInfo.handleChange}
                value={formInfo.values.activeBOSWorkflow}
                checked={!!formInfo.values.activeBOSWorkflow}
                disabled={loading || !canPermissionEdit}
              />
              <Typography display="block" color={textSecondary.color}>
                Active BOS Workflow
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
              <Switch
                color="primary"
                style={{ marginLeft: 0 }}
                name="activePSAWorkflow"
                onChange={formInfo.handleChange}
                value={formInfo.values.activePSAWorkflow}
                checked={!!formInfo.values.activePSAWorkflow}
                disabled={loading || !canPermissionEdit}
              />
              <Typography display="block" color={textSecondary.color}>
                Active PSA Workflow
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
              <Switch
                color="primary"
                style={{ marginLeft: 0 }}
                name="pSABuyerSignsFirst"
                onChange={formInfo.handleChange}
                value={formInfo.values.pSABuyerSignsFirst}
                checked={!!formInfo.values.pSABuyerSignsFirst}
                disabled={loading || !canPermissionEdit}
              />
              <Typography display="block" color={textSecondary.color}>
                PSA Workflow - Buyer Signs First
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <MobileTimePicker
              label={`Portfolio Close Time ${
                process.env.REACT_APP_COUNTRY === 'UK' ? '(GMT)' : '(PST)'
              }`}
              value={dayjs(formInfo.values.portfolioCloseTime)}
              onChange={(date: any) => {
                formInfo.setFieldValue('portfolioCloseTime', date, false)
              }}
              ampm={true}
              minutesStep={5}
              orientation="landscape"
              disabled={loading || !canPermissionEdit}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
              <Switch
                color="primary"
                style={{ marginLeft: 0 }}
                name="skipBalanceValidation"
                onChange={formInfo.handleChange}
                value={formInfo.values.skipBalanceValidation}
                checked={!!formInfo.values.skipBalanceValidation}
                disabled={loading || !canPermissionEdit}
              />
              <Typography display="block" color={textSecondary.color}>
                Skip Balance Validation
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!formInfo.values.forwardFlowAgreementId && (
        <>
          <Box pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mt={4}
                >
                  <Switch
                    color="primary"
                    style={{ marginLeft: 0 }}
                    name="allowForwardFlowBids"
                    onChange={(e: any) => {
                      formInfo.setFieldValue(
                        'allowForwardFlowBids',
                        e.target.checked,
                        false
                      )
                    }}
                    value={formInfo.values.allowForwardFlowBids}
                    checked={!!formInfo.values.allowForwardFlowBids}
                    disabled={loading || !canPermissionEdit}
                  />
                  <Typography display="block" color={textSecondary.color}>
                    Allow Forward Flow Bids
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <TextField
                  id="select-Frequency"
                  select
                  label="Frequency"
                  name="frequency"
                  onChange={formInfo.handleChange}
                  value={formInfo.values.frequency}
                  error={!!formInfo.errors.frequency}
                  helperText={<>{formInfo.errors.frequency}</>}
                  fullWidth
                  disabled={
                    loading ||
                    !formInfo.values.allowForwardFlowBids ||
                    !canPermissionEdit
                  }
                >
                  <MenuItem selected disabled>
                    Select Frequency
                  </MenuItem>
                  {newPortfolioTemplatesOptions?.forwardFlowFrequencies ? (
                    newPortfolioTemplatesOptions?.forwardFlowFrequencies.map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )
                  ) : (
                    <MenuItem value="">None</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <TextField
                  id="select-estimated-face-value"
                  label="Est. Face value"
                  name="estimatedFaceValue"
                  onChange={(e: any) => {
                    formInfo.setFieldValue(
                      'estimatedFaceValue',
                      maskerMoneyLocale(e.target.value, userCountry()),
                      false
                    )
                  }}
                  value={
                    formInfo.values.estimatedFaceValue
                      ? maskerMoneyLocale(
                          formInfo.values.estimatedFaceValue,
                          userCountry()
                        )
                      : ''
                  }
                  error={!!formInfo.errors.estimatedFaceValue}
                  helperText={<>{formInfo.errors.estimatedFaceValue}</>}
                  fullWidth
                  disabled={
                    loading ||
                    !formInfo.values.allowForwardFlowBids ||
                    !canPermissionEdit
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box p={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={9} md={9} lg={9} />
              <TextField
                id="select-disclosure"
                label="Disclosure"
                name="disclosure"
                multiline
                rows={4}
                onChange={formInfo.handleChange}
                value={formInfo.values.disclosure}
                error={!!formInfo.errors.disclosure}
                helperText={<>{formInfo.errors.disclosure}</>}
                fullWidth
                disabled={loading || !addDisclosure || !canPermissionEdit}
                variant="outlined"
              />
            </Grid>
          </Box>
        </>
      )}
      <Box p={5} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={
            loading ||
            loadingSaveTemplate ||
            loadingInfo ||
            loadingUpdateTemplate ||
            !formInfo.isValid ||
            !formInfo.dirty
          }
          startIcon={
            loadingInfo || loadingUpdateTemplate || loadingSaveTemplate ? (
              <CircularProgress size={18} />
            ) : (
              <Icon name="Save" />
            )
          }
        >
          Save Template
        </Button>
      </Box>
    </form>
  )
}

InfoStepper.defaultProps = {
  infoFormValues: null,
  canPermissionEdit: false,
}

export default InfoStepper
