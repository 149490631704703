import { gql } from '@apollo/client'

export const GET_ACCOUNT_PLACEMENT_ACCOUNTS = gql`
  query GetAccountPlacementAccounts(
    $portfolioNumber: Long
    $accountIds: String
    $sellerId: String
    $buyerId: String
    $kendoPagination: String
  ) {
    accountPlacementAccounts: getAccountPlacementAccounts(
      portfolioNumber: $portfolioNumber
      accountIds: $accountIds
      sellerId: $sellerId
      buyerId: $buyerId
      kendoPagination: $kendoPagination
    ) {
      totalOfDataResult
      totalOfAllAccounts
      accounts {
        rowIndex
        portfolioRowId
        portfolioRowGuid
        lender
        lenderLoanId
        firstName
        lastName
        hasComplianceHold
        portfolioNumber
        portfolioCloseUtc
      }
    }
  }
`

export const GET_ACCOUNT_PLACEMENT_SELECTED_ACCOUNTS = gql`
  query GetAccountPlacementSelectedAccounts(
    $accountPlacementRequest: AccountPlacementRequest!
  ) {
    getAccountPlacementSelectedAccounts(
      accountPlacementRequest: $accountPlacementRequest
    ) {
      rowIndex
      portfolioRowId
      portfolioRowGuid
      lenderLoanId
      lender
      firstName
      lastName
      hasComplianceHold
      errorMessages
      portfolioNumber
      chainOfTitleDetailId
    }
  }
`

export const GET_BULK_ACCOUNT_PLACEMENT_TEMPLATE_FILE = gql`
  query GetBulkAccountPlacementTemplateFile {
    templateData: getBulkAccountPlacementTemplateFile {
      file
      fileMime
      fileName
    }
  }
`
