import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ExportExcelButton from 'src/components/ExportExcel'
import { HeaderInfos } from 'src/components/Header/styles'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useProcessBulkAccountPlacement } from 'src/graphql/operations/mutations/accountPlacement'
import { DataError } from 'src/utils/parseFile'
import { ACCOUNT_DETAIL } from 'src/routes'
import { Content, FileUploadSection, Header } from './styles'
import { DataTable, Icon } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const RequestsFileUploadErrors: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { processBulkAccountPlacement, loading, data } =
    useProcessBulkAccountPlacement({
      onCompleted: () => {
        enqueueSnackbar('Accounts have been processed', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar('Error processing accounts', notifyError)
      },
    })

  const stateData: any = location.state

  if (!stateData) {
    return (
      <FileUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Bulk upload file
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Box ml="20px">
          <Typography>
            There is no file uploaded, please go back and upload a file.
          </Typography>
        </Box>
      </FileUploadSection>
    )
  }

  const { errors, allData, allValidData, agencyId, placementType } = stateData

  const toCamelCase = (str: string) => {
    return str.charAt(0).toLowerCase() + str.substring(1)
  }

  const toLowerCaseProperties = (list: any[]) =>
    list.map((obj: any) => {
      const typedObj = obj as Record<string, any>
      return Object.keys(typedObj).reduce((acc: any, key: any) => {
        acc[toCamelCase(key)] = typedObj[key]
        return acc
      }, {})
    })

  const validData = toLowerCaseProperties(JSON.parse(allValidData))

  const errorsGrouped = errors.reduce((acc: any, e: DataError) => {
    acc[e.rowIndex] = acc[e.rowIndex] || []
    acc[e.rowIndex].push(e)
    return acc
  }, {})

  const invalidAccountsRowIndex = Object.keys(errorsGrouped)
  const subGroup = invalidAccountsRowIndex.map((x: any) => {
    const rowData = allData[x - 1]
    rowData.errorMessages = errorsGrouped[x].map(
      (e: any, index: any) => e.errorMessage
    )
    return rowData
  })

  const subGroupExcel = invalidAccountsRowIndex.map((x: any) => {
    const rowData = allData[x - 1]
    rowData.errorMessages = errorsGrouped[x]
      .map((e: any, index: any) => e.errorMessage)
      .toString()
    return rowData
  })

  const allInvalidAccounts = allData.length === subGroup.length
  const allValidAccounts = subGroup.length === 0
  const qtyAccountsPassedValidation = allData.length - subGroup.length
  const qtyAccountsFailedValidation = subGroup.length
  const processmentDone: boolean = !!data

  const processmentDataError = data?.processBulkAccountPlacement.filter(
    (x: any) => x.errorMessages != null
  )

  const ProcessBulkAccountPlacement = () => {
    const selectedBulkAccountPlacementRequest = validData.map((x) => {
      return {
        eCAID: x.eCAID,
        pID: Number(x.pID),
        loanID: x['loan ID'] ? String(x['loan ID']) : null,
      }
    })

    processBulkAccountPlacement({
      variables: {
        processBulkAccountPlacementRequest: {
          agencyId,
          placementType,
          selectedBulkAccountPlacementRequest,
        },
      },
    })
  }

  const getProcessmentMessage = () => {
    if (processmentDataError.length === qtyAccountsPassedValidation)
      return 'All accounts failed validation. The list of errors can be seen in the grid below.'

    if (processmentDataError.length === 0)
      return `${
        qtyAccountsPassedValidation - processmentDataError.length
      } requests have been created.`

    return `${
      qtyAccountsPassedValidation - processmentDataError.length
    } requests have been created and ${
      processmentDataError.length
    } failed validation.
    The list of errors can be seen in the grid below.`
  }

  const fileErrorGridColumns: any[] = [
    {
      field: 'eCAID',
      title: 'ECAID',
      width: 100,
      show: true,
      editable: false,
    },
    {
      field: 'pID',
      title: 'PID',
      width: 100,
      show: true,
      editable: false,
    },
    {
      field: 'loan ID',
      title: 'Loan ID',
      width: 100,
      show: true,
      editable: false,
    },
    {
      field: 'errorMessages',
      title: 'Errors',
      width: 300,
      show: true,
      editable: true,
      render: (props: any) => {
        const errorsMsg: any = props.dataItem['errorMessages']
        let render
        if (typeof errorsMsg === 'string') {
          render = errorsMsg
        } else {
          render = errorsMsg.map((message: string) => {
            return <div key={message}>{message}</div>
          })
        }
        return <td key={`td2_${props.dataItem['eCAID']}`}>{render}</td>
      },
    },
  ]
  const GridColumns: any[] = [
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      width: 150,
      show: true,
      editable: false,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 120,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'errorMessages',
      title: 'Errors',
      width: 300,
      show: true,
      editable: true,
      render: (props: any) => {
        const errorsMsg: string[] = props.dataItem['errorMessages']
        const render = errorsMsg.map((message) => {
          return <div key={message}>{message}</div>
        })
        return <td key={`td2_${props.dataItem['portfolioRowId']}`}>{render}</td>
      },
    },
    {
      field: 'portfolioRowId',
      title: 'RowId',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioRowGuid',
      title: 'portfolioRowGuid',
      width: 150,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      width: 150,
      show: false,
    },
  ]

  const getFileProcessmentMessage = () => {
    if (allInvalidAccounts)
      return `All ${allData.length} accounts have errors, fix the errors listed below and try uploading again.`

    if (allValidAccounts)
      return `All ${validData.length} accounts are in the correct format to be processed. Would you like to proceed?`

    return `${qtyAccountsPassedValidation} accounts are in the correct format and ${qtyAccountsFailedValidation} are not valid and won't be processed. Would you like to proceed with the valid accounts?`
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI = loading

  if (!processmentDone) {
    return (
      <>
        {disableUI && <Box>{loadingPanel}</Box>}
        <FileUploadSection>
          <Header as={Content}>
            <Grid container direction="row">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <HeaderInfos>
                  <IconButton
                    aria-label="go-back"
                    style={{ color: '#656565' }}
                    className="btn-goback"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    <Icon name="ArrowBack" />
                  </IconButton>

                  <Box ml={2}>
                    <Typography variant="h1" style={{ color: '#656565' }}>
                      Bulk upload file
                    </Typography>
                  </Box>
                </HeaderInfos>
              </Grid>
            </Grid>
          </Header>
          <Grid
            container
            direction="column"
            style={{
              paddingLeft: '20px',
              gap: '24px',
            }}
          >
            <Grid container direction="column" style={{ gap: 10 }}>
              <Grid item>{getFileProcessmentMessage()}</Grid>
              <Grid item>
                <Box display="flex" flexDirection="row">
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={ProcessBulkAccountPlacement}
                      disabled={disableUI}
                    >
                      Yes
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => history.goBack()}
                      disabled={disableUI}
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {!allValidAccounts && subGroup && (
              <Grid
                container
                spacing={2}
                style={{
                  width: '92vw',
                }}
              >
                {disableUI && loadingPanel}
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <ExportExcelButton data={subGroupExcel} fileName="Errors" />
                </Grid>
                <Grid
                  item
                  style={{
                    width: '92vw',
                  }}
                >
                  <DataTable
                    style={{ height: '54vh' }}
                    scrollable="scrollable"
                    data={toLowerCaseProperties(subGroup)}
                    gridColumns={fileErrorGridColumns}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </FileUploadSection>
      </>
    )
  }

  return (
    <FileUploadSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderInfos>
              <IconButton
                aria-label="go-back"
                style={{ color: '#656565' }}
                className="btn-goback"
                onClick={() => {
                  history.goBack()
                }}
              >
                <Icon name="ArrowBack" />
              </IconButton>

              <Box ml={2}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Bulk upload file
                </Typography>
              </Box>
            </HeaderInfos>
          </Grid>
        </Grid>
      </Header>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: '20px',
          gap: '24px',
        }}
      >
        <Grid container direction="column" style={{ gap: 10 }}>
          <Grid item>{getProcessmentMessage()}</Grid>
        </Grid>
        {processmentDataError.length > 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: '92vw',
            }}
          >
            {disableUI && loadingPanel}
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <ExportExcelButton
                data={processmentDataError}
                fileName="Errors"
              />
            </Grid>
            <Grid
              item
              style={{
                width: '92vw',
              }}
            >
              <DataTable
                style={{ height: '54vh' }}
                scrollable="scrollable"
                data={processmentDataError}
                total={processmentDataError?.length || 0}
                gridColumns={GridColumns}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </FileUploadSection>
  )
}

export default RequestsFileUploadErrors
