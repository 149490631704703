import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography,
  Button,
  Grid,
  Tooltip,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioPurchaseInformation } from 'src/graphql/models/Portfolio'
import { useUpdatePortfolioDates } from 'src/graphql/operations/mutations/portfolio'
import DTAccordion from 'src/components/Accordion'

import { DatePicker, Icon, textSecondary } from 'everchain-uilibrary'
import { PermissionCodeAccess, PortfolioStatusType } from 'src/utils/constants'
import { AbilityContext, Can } from 'src/context/Can'
import { ConfirmDialog } from 'src/components/Dialogs'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { TableCellFundingCustom, PoundCircleFilledStyled } from '../styles'
import BillOfSale from './BillOfSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioPurchaseInfo } from 'src/data/features/get/portfolio/portfolio'
import { useMutation } from '@tanstack/react-query'
import { confirmFinalFundingAsync } from 'src/data/features/post/portfolio/portfolio'

interface ConfirmFinalFundingProps {
  portfolioId: string | undefined
  buyerName: string | undefined
  sellerName: string | undefined
  portfolioStatus: string
  portfolioCloseDate: Date | null
  portfolioFundDate: Date | null
  portfolioInitialPsaDate: Date | null
  hasBOS: boolean
  hasFinalBOS: boolean
  portfolioHasBOSReleased: boolean
  portfolioHasBOSConfirmed: boolean
  portfolioNumber: string | any
  loading: boolean
  portfolioCountry: string | undefined
  eSignatureEnabledBOS: boolean
  isBosESignatureSent: boolean
  bosSignerName: string | undefined
  bosSignerTitle: string | undefined
  bosSignerEmail: string | undefined
  sellerUploadTemplateId: number | undefined
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const ConfirmFinalFunding: React.FC<ConfirmFinalFundingProps> = ({
  portfolioId,
  buyerName,
  sellerName,
  portfolioStatus,
  portfolioCloseDate,
  portfolioFundDate,
  portfolioInitialPsaDate,
  hasBOS,
  hasFinalBOS,
  portfolioHasBOSReleased,
  portfolioHasBOSConfirmed,
  portfolioNumber,
  loading,
  portfolioCountry,
  eSignatureEnabledBOS,
  isBosESignatureSent,
  bosSignerName,
  bosSignerTitle,
  bosSignerEmail,
  sellerUploadTemplateId,
}: ConfirmFinalFundingProps) => {
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()
  const [disableConfirmationButton, setDisableConfirmationButton] =
    useState(false)
  const [dateSelected, setDateSelected] = useState('')
  const gradientBackgroundColor = '#F2F2F2'
  const { updatePortfolioDates, loading: loadingDate } =
    useUpdatePortfolioDates({
      onCompleted: (updateResponse: string) => {
        if (updateResponse) {
          enqueueSnackbar(`${dateSelected} updated`, notifySuccess)
        } else {
          enqueueSnackbar('Error', notifyError)
        }
      },
    })

  const confirmationRequest = useMutation({
    mutationFn: (request: string | undefined) =>
      confirmFinalFundingAsync(request),
    onSuccess: (data: any) => {
      if (data) {
        enqueueSnackbar('Funding has been confirmed', notifySuccess)
      } else {
        setDisableConfirmationButton(false)
        enqueueSnackbar('Error', notifyError)
      }
    },
    onError: () => {
      setDisableConfirmationButton(false)
      enqueueSnackbar('Error', notifyError)
    },
  })

  const [openConfirmFinalFunds, setOpenConfirmFinalFunds] = useState(false)
  const handleClose = () => {
    setOpenConfirmFinalFunds(false)
  }

  const handleConfirmFinalFunds = () => {
    setDisableConfirmationButton(true)
    confirmationRequest.mutate(portfolioId)
    setOpenConfirmFinalFunds(false)
  }

  const { data: purchaseData, isFetching: loadingPortfolioPurchaseInfo } =
    useCustomQuery<PortfolioPurchaseInformation>(
      ['getPortfolioPurchaseInfo', portfolioId],
      async () => getPortfolioPurchaseInfo(portfolioId),
      { enabled: portfolioId != null, cacheTime: 0 }
    )

  const handlePortfolioCloseDateChange = (date: any) => {
    setDateSelected('Portfolio Close Date')
    updatePortfolioDates({
      variables: {
        updateRequest: {
          portfolioId,
          closingUtcDate: date,
        },
      },
      refetchQueries: ['GetAllPortfolioSteps'],
    })
  }

  const handlePortfolioFundingDateChange = (date: any) => {
    setDateSelected('Portfolio Fund Date')
    updatePortfolioDates({
      variables: {
        updateRequest: {
          portfolioId,
          fundingUtcDate: date,
        },
      },
      refetchQueries: ['GetAllPortfolioSteps'],
    })
  }

  const handleInitialPsaDateChange = (date: any) => {
    setDateSelected('Initial PSA Date')
    updatePortfolioDates({
      variables: {
        updateRequest: {
          portfolioId,
          initialPsaUtcDate: date,
        },
      },
      refetchQueries: ['GetAllPortfolioSteps'],
    })
  }

  const showBOSComponent =
    (portfolioStatus === PortfolioStatusType.AwaitingSellerFee ||
      portfolioStatus === PortfolioStatusType.Funded ||
      portfolioStatus === PortfolioStatusType.PendingFinalConfirmation) &&
    !portfolioHasBOSConfirmed &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Workflows_CompleteBOSWorkflow,
      'any'
    )

  const canConfirmFinalFunds =
    portfolioStatus === PortfolioStatusType.PendingFinalConfirmation &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_ConfirmPortfolioFunding,
      'any'
    )

  const { userPermissions, profileClient } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <DTAccordion
      id="funding-accordion"
      title="Funding"
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
      expanded={true}
    >
      <Grid container spacing={4}>
        <Can
          do={PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioDates}
          on="any"
        >
          <Grid item xs={12} md={4}>
            <DatePicker
              country={profileClient?.Country}
              margin="normal"
              id="portfolio-psa-date"
              label="Initial PSA Date"
              value={portfolioInitialPsaDate}
              disabled={loadingDate || loading}
              onChange={handleInitialPsaDateChange}
              style={{ marginTop: '0px' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              country={profileClient?.Country}
              margin="normal"
              id="portfolio-close-date"
              label="Portfolio Close Date"
              value={portfolioCloseDate}
              disabled={loadingDate || loading}
              onChange={handlePortfolioCloseDateChange}
              style={{ marginTop: '0px' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              country={profileClient?.Country}
              margin="normal"
              id="portfolio-funding-date"
              label="Portfolio Funding Date"
              value={portfolioFundDate}
              disabled={loadingDate || loading}
              onChange={handlePortfolioFundingDateChange}
              style={{ marginTop: '0px' }}
            />
          </Grid>
        </Can>
        {showBOSComponent && (
          <Grid item xs={12}>
            <BillOfSale
              portfolioId={portfolioId}
              portfolioHasBOS={hasBOS}
              portfolioHasFinalBOS={hasFinalBOS}
              portfolioHasBOSReleased={portfolioHasBOSReleased}
              portfolioHasBOSConfirmed={portfolioHasBOSConfirmed}
              isPortfolioSeller={false}
              eSignatureEnabledBOS={Boolean(eSignatureEnabledBOS)}
              isBosESignatureSent={Boolean(isBosESignatureSent)}
              bosSignerName={bosSignerName}
              bosSignerTitle={bosSignerTitle}
              bosSignerEmail={bosSignerEmail}
              sellerUploadTemplateId={sellerUploadTemplateId}
            />
          </Grid>
        )}
        {!!purchaseData && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" id="tableTitle" component="div">
                Purchase Info
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom
                        style={{ color: '#002e3a' }}
                        component="th"
                        scope="row"
                      >
                        Face Value
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ color: '#002e3a' }}
                        align="right"
                      >
                        {purchaseData.faceValue}
                      </TableCellFundingCustom>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" id="tableTitle" component="div">
                Buyer Funding
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Buyer Name
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {buyerName}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Purchase Price ({purchaseData.purchasePricePercent})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {purchaseData.purchasePriceAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                    {purchaseData.isBk && (
                      <>
                        <TableRow>
                          <TableCellFundingCustom component="th" scope="row">
                            Total TOC ({purchaseData.totalPocAccounts} x{' '}
                            {purchaseData.pocValue})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ color: 'red' }}
                            align="right"
                          >
                            - {purchaseData.totalTocAmount}
                          </TableCellFundingCustom>
                        </TableRow>
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Final Purchase Price (
                            {purchaseData.finalPurchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseData.finalPurchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Sales Commission ({purchaseData.buyerFeeDisplay})
                        <Tooltip
                          title="
                  As compensation for the services
                  performed by EverChain, Buyer shall pay
                  EverChain a sales commission. This
                  sales commission is a percentage of the
                  total sale price for each executed sale
                  of a debt portfolio made with a Seller
                  via EverChain as displayed on the
                  portfolio summary page within the
                  EverChain platform"
                        >
                          <Icon
                            name="HelpOutline"
                            fontSize="inherit"
                            style={{ paddingLeft: '2px' }}
                          />
                        </Tooltip>
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        + {purchaseData.buyerFeeAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                    {isPortfolioUk && purchaseData.buyerVATFeeDisplay && (
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom component="th" scope="row">
                          VAT Fee ({purchaseData.buyerVATFeePercent})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          + {purchaseData.buyerVATFeeDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.buyerPurchaseAmountDisplay && (
                      <TableRow>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          component="th"
                          scope="row"
                        >
                          <strong> Total Purchase Amount</strong>
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          align="right"
                        >
                          <strong>
                            {purchaseData.buyerPurchaseAmountDisplay}
                          </strong>
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {purchaseData.sellerFeeDisplay && (
              <Grid item xs={12}>
                <Typography variant="h6" id="tableTitle" component="div">
                  Seller Funding
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Seller Name
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {sellerName}
                        </TableCellFundingCustom>
                      </TableRow>
                      {!purchaseData.isBk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Purchase Price ({purchaseData.purchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseData.purchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      {purchaseData.isBk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Final Purchase Price
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseData.finalPurchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Service Fee To EverChain (
                          {purchaseData.sellerFeeDisplay})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: 'red' }}
                          align="right"
                        >
                          - {purchaseData.sellerFeeAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                      {isPortfolioUk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            VAT Fee ({purchaseData.sellerVATFeePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ color: 'red' }}
                            align="right"
                          >
                            - {purchaseData.sellerVATFeeDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          component="th"
                          scope="row"
                        >
                          <strong>
                            {isSeller ? 'Net Proceeds To You' : 'Net To Seller'}{' '}
                            ({purchaseData.netToSellerPercentDisplay})
                          </strong>
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          align="right"
                        >
                          <strong> {purchaseData.netToSellerDisplay}</strong>
                        </TableCellFundingCustom>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </>
        )}
        {purchaseData &&
        !loadingPortfolioPurchaseInfo &&
        canConfirmFinalFunds ? (
          <>
            <Grid item xs={12} md={12}>
              <Button
                data-cy="confirm-final-funding-release-file-button"
                variant="contained"
                color="primary"
                size="small"
                disabled={disableConfirmationButton}
                onClick={(): void => setOpenConfirmFinalFunds(true)}
              >
                Confirm Final Funding and Release File
              </Button>
            </Grid>
            <ConfirmDialog
              open={openConfirmFinalFunds}
              title="Confirm Sales Commission"
              description={
                <>
                  <Typography variant="body1" color={textSecondary.color}>
                    {` Do you want to confirm sales commission for portfolio ${portfolioNumber} on the amount of ${purchaseData.buyerFeeAmountDisplay}`}
                  </Typography>
                </>
              }
              closeName="No"
              confirmName="Yes"
              onClose={handleClose}
              onConfirm={handleConfirmFinalFunds}
            />
          </>
        ) : (
          <></>
        )}
      </Grid>
    </DTAccordion>
  )
}

export default ConfirmFinalFunding
