/* eslint-disable react/require-default-props */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PORTFOLIO } from 'src/routes'
import { BuyerBids } from 'src/graphql/models/PortfolioDetail'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { ConfirmDialog } from 'src/components/Dialogs'
import { numberCurrency, numberToPercentage } from 'src/utils/numbers'
import {
  Box,
  BottomNavigationAction,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
  Skeleton,
  TextField,
  MenuItem,
} from '@mui/material'
import { INTERNAL, PermissionCodeAccess } from 'src/utils/constants'
import { Can } from 'src/context/Can'
import { formatDate } from 'src/utils/date'
import DTAccordion from 'src/components/Accordion'
import BCOPreview from 'src/pages/Compliance/components/BCOPreview'
import { Content } from 'src/styles/layout'
import {
  cellCurrencyRegular,
  cellDefaultDataCy,
} from 'src/components/DynamicTable/DynamicTableUltis'
import { getStandardUri, isUkCountry } from 'src/utils/common'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PortfolioBids } from 'src/infra/api/models/portfolio/portfolio-details'
import {
  getPortfolioBidFileUriApi,
  getPortfolioBidsAward,
} from 'src/data/features/get/portfolio/portfolio'
import { getAcknowledgementList } from 'src/data/features/get/buyer/buyer'
import BidContingencyButton from './BidContingencyButton'
import BidReviewTable from './BidReviewTable'
import { CardTypography, ModalHeader, PoundCircleFilledStyled } from '../styles'
import BkAccountSelection from './BkAccountSelection'
import { Colors, Icon, textSecondary } from 'everchain-uilibrary'
import { getDueDiligence } from 'src/data/features/get/compliance/compliance'
import {
  getAgreementTemplates,
  getFeatureFlagAgreementTemplate,
} from 'src/data/features/get/agreementTemplate/agreementTemplate'
import { acceptBid } from 'src/data/features/post/portfolio/portfolio'
import { AcceptBidRequest } from 'src/data/features/post/portfolio/types'
import { useQueryClient } from '@tanstack/react-query'
import { AuthContext } from 'src/context/AuthenticationContext'

const oneTimeHeader = () => (
  <Box textAlign="right" pr={12}>
    One-Time
  </Box>
)
const forwardFlowHeader = () => (
  <Box textAlign="right" pr={12}>
    Forward Flow
  </Box>
)

interface BidReviewProps {
  portfolioId: string | undefined
  bidAccepted?: () => void
  isPortfolioBkType: boolean | undefined
  portfolioCountry: string | undefined
}

const PAGE_SIZE = 25
const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const BidReview: React.FC<BidReviewProps> = ({
  portfolioId,
  bidAccepted,
  isPortfolioBkType,
  portfolioCountry,
}) => {
  const [openBkAccountSelection, setOpenBkAccountSelection] =
    useState<boolean>(false)
  const [page, setPage] = useState(0)
  const [openAcceptBidDialog, setOpenAcceptBidDialog] = useState(false)
  const [agreementTemplateSelected, setAgreementTemplateSelected] =
    useState<any>(null)
  const [openAcceptAckowledgement, setOpenAcceptAckowledgement] =
    useState(false)
  const [buyerIdList, setBuyerIdList] = useState<any>([])
  const [bidSelected, setBidSelected] = useState({} as BuyerBids)
  const [bidIdSelected, setBidIdSelected] = useState(0)
  const [buyerIdSelected, setBuyerIdSelected] = useState('')
  const [acceptBidRequest, setAcceptBidRequest] = useState<AcceptBidRequest>()

  const { userPermissions } = useContext(AuthContext)

  const isInternal = userPermissions.type === INTERNAL
  const history = useHistory()
  const featureDueDiligence =
    process.env.REACT_APP_FEATURE_DOWNLOAD_DUE_DILIGENCE
  const featureDocumentRepository = process.env.REACT_APP_HIDE_DOC_REPO
  const columns = [
    {
      Header: <Box pl={10}>Buyer</Box>,
      accessor: 'buyerName',
      Cell: (props: any) => cellDefaultDataCy(props, 'buyer-seller-name'),
    },
    {
      Header: oneTimeHeader,
      accessor: 'oneTimeBidPercent',
      clickable: false,
      Cell: (props: any) => cellDefaultDataCy(props, 'one-time-bid'),
    },
    {
      Header: forwardFlowHeader,
      accessor: 'forwardFlowBidPercent',
      clickable: false,
      Cell: (props: any) => cellDefaultDataCy(props, 'forward-flow'),
    },
    {
      Header: 'Closing Date',
      Cell: (props: any) => cellDefaultDataCy(props, 'closing-date'),
      accessor: (originalRow: any, rowIndex: any) => {
        if (originalRow.oneTimeBidPortfolioCloseDate != null) {
          return originalRow.oneTimeBidPortfolioCloseDate
            ? formatDate(
                new Date(originalRow.oneTimeBidPortfolioCloseDate),
                '',
                portfolioCountry ?? ''
              )
            : 'N/A'
        }
        return originalRow.forwardFlowBidPortfolioCloseDate
          ? formatDate(
              new Date(originalRow.forwardFlowBidPortfolioCloseDate),
              '',
              portfolioCountry ?? ''
            )
          : 'N/A'
      },
    },
    {
      Header: 'Funding Date',
      Cell: (props: any) => cellDefaultDataCy(props, 'funding-date'),
      accessor: (originalRow: any, rowIndex: any) => {
        if (originalRow.oneTimeBidFundingDate != null) {
          return originalRow.oneTimeBidFundingDate
            ? formatDate(
                new Date(originalRow.oneTimeBidFundingDate),
                '',
                portfolioCountry ?? ''
              )
            : 'N/A'
        }
        return originalRow.forwardFlowBidFundingDate
          ? formatDate(
              new Date(originalRow.forwardFlowBidFundingDate),
              '',
              portfolioCountry ?? ''
            )
          : 'N/A'
      },
    },
  ]
  const columnsBK = [
    {
      Header: <Box pl={10}>Buyer</Box>,
      accessor: 'buyerName',
      Cell: (props: any) => cellDefaultDataCy(props, 'buyer-seller-name'),
    },
    {
      Header: <Box>Estimated Proceeds</Box>,
      accessor: 'totalPurchasePriceIncludedBKAccounts',
      clickable: false,
      Cell: (props: any) => cellCurrencyRegular(props, portfolioCountry),
    },
    {
      Header: oneTimeHeader,
      accessor: 'oneTimeBidPercent',
      clickable: false,
      Cell: (props: any) => cellDefaultDataCy(props, 'one-time-bid'),
    },
    {
      Header: forwardFlowHeader,
      accessor: 'forwardFlowBidPercent',
      clickable: false,
      Cell: (props: any) => cellDefaultDataCy(props, 'forward-flow'),
    },
    {
      Header: 'Closing Date',
      accessor: (originalRow: any, rowIndex: any) => {
        if (originalRow.oneTimeBidPortfolioCloseDate != null) {
          return originalRow.oneTimeBidPortfolioCloseDate
            ? formatDate(
                new Date(originalRow.oneTimeBidPortfolioCloseDate),
                '',
                portfolioCountry ?? ''
              )
            : 'N/A'
        }
        return originalRow.forwardFlowBidPortfolioCloseDate
          ? formatDate(
              new Date(originalRow.forwardFlowBidPortfolioCloseDate),
              '',
              portfolioCountry ?? ''
            )
          : 'N/A'
      },
    },
    {
      Header: 'Funding Date',
      accessor: (originalRow: any, rowIndex: any) => {
        if (originalRow.oneTimeBidFundingDate != null) {
          return originalRow.oneTimeBidFundingDate
            ? formatDate(
                new Date(originalRow.oneTimeBidFundingDate),
                '',
                portfolioCountry ?? ''
              )
            : 'N/A'
        }
        return originalRow.forwardFlowBidFundingDate
          ? formatDate(
              new Date(originalRow.forwardFlowBidFundingDate),
              '',
              portfolioCountry ?? ''
            )
          : 'N/A'
      },
    },
    {
      Header: <Box>Accounts Included</Box>,
      accessor: 'totalIncludedBKAccts',
      clickable: false,
    },
    {
      Header: <Box>Excluded Accounts</Box>,
      accessor: 'totalExcludedBKAccts',
      clickable: false,
    },
    {
      clickable: false,
      Header: 'View Excluded Accounts',
      accessor: 'viewExcludedAccounts',
      Cell: (props: any): React.ReactElement => {
        return (
          <IconButton
            size="small"
            onClick={(event) => {
              setBuyerIdSelected(props.row.original?.buyerId)
              setOpenBkAccountSelection(true)
              event.stopPropagation()
            }}
          >
            <Icon name="Visibility" fontSize="small" />
          </IconButton>
        )
      },
    },
    {
      Header: <Box>Face Value</Box>,
      accessor: 'totalFaceValueIncludedBKAccounts',
      clickable: false,
      Cell: (props: any) => cellCurrencyRegular(props, portfolioCountry),
    },
  ]

  const clickBuyerModal = (item: any) => {
    setBuyerIdSelected(item.buyerId)
    setBidSelected(item)
    setBidIdSelected(item.oneTimeBidId || item.forwardFlowBidId)
    setShowModal(!showModal)
  }

  const { enqueueSnackbar } = useSnackbar()
  const [showModal, setShowModal] = useState(false)
  const handleCloseConfirmation = useCallback(() => {
    setOpenAcceptBidDialog(false)
    setAgreementTemplateSelected(null)
  }, [setOpenAcceptBidDialog])

  const handleCloseAckowledgement = useCallback(() => {
    setOpenAcceptAckowledgement(false)
  }, [setOpenAcceptAckowledgement])

  const reactQueryClient = useQueryClient()

  const { isFetching: loadingAcceptBid } = useCustomQuery<any>(
    ['acceptBid', acceptBidRequest],
    async () => {
      if (acceptBidRequest) {
        await acceptBid(acceptBidRequest)
          .then(() => {
            enqueueSnackbar(
              `Bid from ${bidSelected.buyerName} accepted`,
              notifySuccess
            )

            if (bidAccepted) {
              bidAccepted()
            }
            handleCloseConfirmation()
            history.push(getStandardUri(`${PORTFOLIO}/auction-closed`))

            reactQueryClient.refetchQueries({
              queryKey: ['GetAllPortfolioSteps'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Bid not accepted', notifyError)
          })
      }
    },
    { enabled: !!acceptBidRequest }
  )

  const { data, isFetching: bidsAwardLoading } = useCustomQuery<PortfolioBids>(
    ['getPortfolioBidsAward', portfolioId],
    async () => {
      return getPortfolioBidsAward(portfolioId)
    },
    {
      cacheTime: 0,
    }
  )

  const portfolioBids = data

  const buyerBids = portfolioBids?.bids

  const handleAcceptBid = (bid: BuyerBids, bidId: number) => {
    if ((bid.oneTimeBidId || bid.forwardFlowBidId) && portfolioId) {
      return setAcceptBidRequest({
        portfolioId: portfolioId.toString(),
        bidId,
        bidAckowledgmentTracking: acknowledgementData,
        agreementId: agreementTemplateSelected,
      })
    }
    throw new Error('Bid type not identified.')
  }

  const {
    data: getPortfolioBidFileUri,
    isFetching: bidFileLoading,
    refetch: getUri,
  } = useCustomQuery<string>(
    ['GetPortfolioBidFileUri'],
    async () => getPortfolioBidFileUriApi(portfolioId),
    { cacheTime: 0, enabled: false }
  )

  const onDownload = () => {
    getUri()
  }

  const { data: getDueDiligenceFileUri, isFetching: dueDiligenceFileLoading } =
    useCustomQuery<any>(
      ['GetDueDiligence', buyerIdSelected],
      async () => getDueDiligence(buyerIdSelected),
      {
        cacheTime: 0,
        enabled: !!buyerIdSelected,
      }
    )

  const { data: featureFlagAgreementTemplate } = useCustomQuery<boolean>(
    ['GetFeatureFlagAgreementTemplate', portfolioId],
    async () => getFeatureFlagAgreementTemplate(portfolioId),
    {
      cacheTime: 0,
      enabled: true,
    }
  )
  const dueDiligenceUri = getDueDiligenceFileUri ?? ''

  const { data: acknowledgementData } = useCustomQuery<any>(
    ['GetAcknowledgementList', buyerIdSelected],
    async () => getAcknowledgementList(buyerIdSelected),
    { enabled: !!buyerIdSelected, cacheTime: 0 }
  )

  const downloadDueDiligence = () => {
    downloadFile(dueDiligenceUri)
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  useEffect(() => {
    const uri = getPortfolioBidFileUri ?? ''
    downloadFile(uri)
  }, [getPortfolioBidFileUri])

  const setPreviousBuyerId = () => {
    const indexBuyerSelected = buyerIdList.indexOf(buyerIdSelected)
    if (indexBuyerSelected < buyerIdList.length) {
      const previousIndex = indexBuyerSelected - 1
      setBuyerIdSelected(buyerIdList[previousIndex])
      const bid = buyerBids?.find(
        (item: any) => item.buyerId === buyerIdList[previousIndex]
      )

      if (bid) {
        setBidSelected(bid)
        setBidIdSelected(bid.oneTimeBidId || bid.forwardFlowBidId)
      }
    }
  }
  const setNextBuyerId = () => {
    const indexBuyerSelected = buyerIdList.indexOf(buyerIdSelected)
    if (indexBuyerSelected < buyerIdList.length) {
      const nextIndex = indexBuyerSelected + 1
      setBuyerIdSelected(buyerIdList[nextIndex])
      const bid = buyerBids?.find(
        (item: any) => item.buyerId === buyerIdList[nextIndex]
      )

      if (bid) {
        setBidSelected(bid)
        setBidIdSelected(bid.oneTimeBidId || bid.forwardFlowBidId)
      }
    }
  }
  const buttonPreviousDisabled = () => {
    if (buyerIdList) {
      const indexBuyerSelected = buyerIdList.indexOf(buyerIdSelected)
      return buyerIdList.length <= 1 || indexBuyerSelected === 0
    }
    return true
  }
  const buttonNextDisabled = () => {
    if (buyerIdList) {
      const indexBuyerSelected = buyerIdList.indexOf(buyerIdSelected)
      return (
        buyerIdList.length <= 1 || indexBuyerSelected === buyerIdList.length - 1
      )
    }
    return true
  }
  const buttonIconSize = {
    width: '40px',
    height: '40px',
  }

  const renderNextButton = () => {
    if (buttonNextDisabled()) {
      return (
        <Icon name="ArrowForwardIos" color="disabled" style={buttonIconSize} />
      )
    }
    return (
      <Icon name="ArrowForwardIos" color="primary" style={buttonIconSize} />
    )
  }
  const renderPreviousButton = () => {
    if (buttonPreviousDisabled()) {
      return (
        <Icon name="ArrowBackIos" color="disabled" style={buttonIconSize} />
      )
    }
    return <Icon name="ArrowBackIos" color="primary" style={buttonIconSize} />
  }
  const minBids = portfolioBids?.minBidPercent
    ? `${numberToPercentage(portfolioBids?.minBidPercent, '', 3)}`
    : '0%'
  const maxBids = portfolioBids?.maxBidPercent
    ? `${numberToPercentage(portfolioBids?.maxBidPercent, '', 3)}`
    : '0%'

  useEffect(() => {
    setBuyerIdList(buyerBids?.map((buyerBid: any) => buyerBid.buyerId))
  }, [buyerBids])

  const bidButtons = (item: BuyerBids, bidId: number, bidType: string) => {
    return (
      <Box display="flex" alignItems="right">
        <BidContingencyButton
          contingencyText={
            bidType === 'one-time'
              ? item.oneTimeBidContingency
              : item.forwardFlowBidContingency
          }
        />
      </Box>
    )
  }

  const winningBidIcon = (status: string) => {
    return status === 'won' ? (
      <Box style={{ paddingRight: '3px' }}>
        <Tooltip title="Winning Bid" aria-label="bidWinner">
          <Icon name="EmojiEventsOutlined" />
        </Tooltip>
      </Box>
    ) : (
      <></>
    )
  }
  const { data: agreementTemplates } = useCustomQuery<any[]>(
    ['getAgreementTemplates', portfolioId, buyerIdSelected],
    async () => getAgreementTemplates(portfolioId, 'psa', buyerIdSelected),
    {
      enabled: openAcceptBidDialog && featureFlagAgreementTemplate,
      cacheTime: 0,
    }
  )

  const buyerBidsEntry = (portfolioBids?.bids ?? []).map((item: any) => {
    return {
      buyerId: item.buyerId,
      buyerName: item.buyerName,
      status: item.status,
      oneTimeBidId: item.oneTimeBidId,
      forwardFlowBidId: item.forwardFlowBidId,
      forwardFlowBidMonths: item.forwardFlowBidMonths,
      oneTimeBidContingency: item.oneTimeBidContingency,
      forwardFlowBidContingency: item.forwardFlowBidContingency,
      forwardFlowMaxFaceValue: item.forwardFlowMaxFaceValue,
      oneTimeBidPercentValue: item.oneTimeBidPercent,
      oneTimeBidPercent: (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={item.oneTimeBidPercent ? 'flex-end' : 'center'}
          minWidth={110}
        >
          {winningBidIcon(item.oneTimeStatus)}
          <span>{numberToPercentage(item.oneTimeBidPercent, '-', 3)}</span>
          {item.oneTimeBidId
            ? bidButtons(item, item.oneTimeBidId, 'one-time')
            : null}
        </Box>
      ),
      forwardFlowBidPercent: (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={item.forwardFlowBidPercent ? 'flex-end' : 'center'}
          minWidth={110}
        >
          {winningBidIcon(item.forwardFlowStatus)}
          <span>{numberToPercentage(item.forwardFlowBidPercent, '-', 3)}</span>
          {item.forwardFlowBidId
            ? bidButtons(item, item.forwardFlowBidId, 'forward-flow')
            : null}
        </Box>
      ),
      forwardFlowBidPercentValue: item.forwardFlowBidPercent,
      forwardFlowBidPortfolioCloseDate: item.forwardFlowBidPortfolioCloseDate,
      forwardFlowBidFundingDate: item.forwardFlowBidFundingDate,
      oneTimeBidPortfolioCloseDate: item.oneTimeBidPortfolioCloseDate,
      oneTimeBidFundingDate: item.oneTimeBidFundingDate,
      totalBkAccts: item.totalBkAccts,
      totalExcludedBKAccts: item.totalExcludedBKAccts,
      totalIncludedBKAccts: item.totalIncludedBKAccts,
      totalFaceValueIncludedBKAccounts: item.totalFaceValueIncludedBKAccounts,
      totalPocAccounts: item.totalPocAccounts,
      bidPercent: (
        <Box display="flex" alignItems="center" minWidth={110}>
          {winningBidIcon(item.status)}
          <span>{numberToPercentage(item.bidPercent)}</span>
        </Box>
      ),
      totalPurchasePriceIncludedBKAccounts:
        item.totalPurchasePriceIncludedBKAccounts,
      oneTimeStatus: item.oneTimeStatus,
      forwardFlowStatus: item.forwardFlowStatus,
      businessStatus: item.businessStatus,
    }
  })

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  const renderOneTimeData = () => {
    if (bidSelected.oneTimeBidId) {
      return (
        <>
          <Paper style={{ boxShadow: 'none' }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item>
                  <Typography
                    variant="h1"
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    One-Time Bid{' '}
                  </Typography>
                </Grid>

                <Grid item>
                  <BidContingencyButton
                    contingencyText={bidSelected.oneTimeBidContingency}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <CardTypography primary={true}>Bid: </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography primary={false}>
                  {numberToPercentage(
                    bidSelected.oneTimeBidPercentValue ??
                      bidSelected.oneTimeBidPercent,
                    '-',
                    3
                  )}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>Closing Date:</CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {bidSelected.oneTimeBidPortfolioCloseDate
                    ? formatDate(
                        new Date(bidSelected.oneTimeBidPortfolioCloseDate),
                        '',
                        portfolioCountry ?? ''
                      )
                    : 'N/A'}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>Funding Date: </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {bidSelected.oneTimeBidPortfolioCloseDate
                    ? formatDate(
                        new Date(bidSelected.oneTimeBidFundingDate),
                        '',
                        portfolioCountry ?? ''
                      )
                    : 'N/A'}
                </CardTypography>
              </Grid>
            </Grid>
            {bidSelected.status === 'pending' && (
              <Can
                do={PermissionCodeAccess.MarketPlace_Basic_AcceptBid}
                on="any"
              >
                <Grid container spacing={2} style={{ marginTop: '5px' }}>
                  <Grid item display={'flex'} flexDirection={'row'} gap={5}>
                    <Button
                      data-cy="accept-bid-button"
                      color="primary"
                      variant="contained"
                      startIcon={
                        isPortfolioUk ? (
                          <PoundCircleFilledStyled />
                        ) : (
                          <Icon name="MonetizationOn" />
                        )
                      }
                      onClick={() => {
                        setBidSelected(bidSelected)
                        setBidIdSelected(bidSelected.oneTimeBidId)
                        if (acknowledgementData?.length > 0) {
                          setOpenAcceptAckowledgement(true)
                        } else setOpenAcceptBidDialog(true)
                      }}
                    >
                      Accept Bid
                    </Button>
                    {bidSelected.businessStatus === 'pre-approved' && (
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        gap={1}
                        alignSelf={'center'}
                      >
                        <Icon name="Warning" style={{ color: 'orange' }} />
                        <Typography style={{ alignSelf: 'center' }}>
                          This bid was made by a pre-approved buyer
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  {acknowledgementData?.length > 0 && (
                    <Grid>
                      <Tooltip
                        title={acknowledgementData.map(
                          (acknowledgement: any) => (
                            // eslint-disable-next-line react/jsx-key
                            <p>{acknowledgement.description}</p>
                          )
                        )}
                      >
                        <Box ml={2} mt={3}>
                          <Icon
                            name="WarningOutlined"
                            color="orange"
                            fontSize="small"
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Can>
            )}
          </Paper>
        </>
      )
    }

    return null
  }
  const renderForwardFlowData = () => {
    if (bidSelected.forwardFlowBidId) {
      return (
        <>
          <Paper style={{ boxShadow: 'none' }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item>
                  <Typography
                    variant="h1"
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    Forward Flow Bid{' '}
                  </Typography>
                </Grid>

                <Grid item>
                  <BidContingencyButton
                    contingencyText={bidSelected.forwardFlowBidContingency}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <CardTypography primary={true}>Bid: </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {numberToPercentage(
                    bidSelected.forwardFlowBidPercentValue ??
                      bidSelected.forwardFlowBidPercent,
                    '-',
                    3
                  )}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>
                  Closing Date:{'   '}
                </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {formatDate(
                    new Date(bidSelected.forwardFlowBidPortfolioCloseDate),
                    '',
                    portfolioCountry ?? ''
                  )}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>Funding Date: </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {formatDate(
                    new Date(bidSelected.forwardFlowBidFundingDate),
                    '',
                    portfolioCountry ?? ''
                  )}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>FF Months: </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {bidSelected.forwardFlowBidMonths}
                </CardTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item>
                <CardTypography primary={true}>
                  FF Max Face Value:{' '}
                </CardTypography>
              </Grid>
              <Grid item>
                <CardTypography>
                  {numberCurrency(
                    bidSelected.forwardFlowMaxFaceValue,
                    portfolioCountry ?? ''
                  )}
                </CardTypography>
              </Grid>
            </Grid>
            {bidSelected.status === 'pending' && (
              <Can
                do={PermissionCodeAccess.MarketPlace_Basic_AcceptBid}
                on="any"
              >
                <Grid container spacing={2} style={{ marginTop: '5px' }}>
                  <Grid item display={'flex'} flexDirection={'row'} gap={5}>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={
                        isPortfolioUk ? (
                          <PoundCircleFilledStyled />
                        ) : (
                          <Icon name="MonetizationOn" />
                        )
                      }
                      onClick={() => {
                        setBidSelected(bidSelected)
                        setBidIdSelected(bidSelected.forwardFlowBidId)
                        if (acknowledgementData?.length > 0) {
                          setOpenAcceptAckowledgement(true)
                        } else setOpenAcceptBidDialog(true)
                      }}
                    >
                      Accept Bid
                    </Button>
                    {bidSelected.businessStatus === 'pre-approved' && (
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        gap={1}
                        alignSelf={'center'}
                      >
                        <Icon name="Warning" style={{ color: 'orange' }} />
                        <Typography style={{ alignSelf: 'center' }}>
                          This bid was made by a pre-approved buyer
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  {acknowledgementData?.length > 0 && (
                    <Grid>
                      <Tooltip
                        title={acknowledgementData.map(
                          (acknowledgement: any) => (
                            // eslint-disable-next-line react/jsx-key
                            <p>{acknowledgement.description}</p>
                          )
                        )}
                      >
                        <Box ml={2} mt={3}>
                          <Icon
                            name="WarningOutlined"
                            color="orange"
                            fontSize="small"
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Can>
            )}
          </Paper>
        </>
      )
    }

    return null
  }

  return (
    <>
      <DTAccordion
        id="bid-review-accordion"
        title="Bid Review"
        icon={
          isPortfolioUk ? (
            <PoundCircleFilledStyled />
          ) : (
            <Icon name="MonetizationOn" />
          )
        }
        expanded={true}
      >
        <BidReviewTable
          onClickCell={(tableCell: any) => {
            clickBuyerModal(tableCell.row.original)
          }}
          columns={isPortfolioBkType ? columnsBK : columns}
          data={buyerBidsEntry}
          loading={bidsAwardLoading}
          minBidPercent={minBids}
          maxBidPercent={maxBids}
          totalItems={portfolioBids?.totalNumberOfBids ?? 0}
          pageSize={PAGE_SIZE}
          currentPage={page}
          onChangePagination={(event, newPage) => setPage(newPage)}
        />
        <BkAccountSelection
          buyerId={buyerIdSelected || ''}
          portfolioId={portfolioId || ''}
          portfolioNumber={undefined}
          bidPercentApplied={0}
          disableBidPercentInput={true}
          openDialog={openBkAccountSelection}
          onCloseDialog={() => {
            setOpenBkAccountSelection(false)
          }}
          onlyExcluded={true}
          onApplyAndClose={(bidPercent: number) => {}}
        />
      </DTAccordion>
      {showModal && (
        <Content>
          <Dialog fullWidth maxWidth={false} open={showModal}>
            <ModalHeader
              py={3}
              px={5}
              m={0}
              bgcolor="green"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="white"
            >
              <Typography variant="h5" style={{ color: 'white' }}>
                BCO Card
              </Typography>
              <IconButton size="small" onClick={() => setShowModal(!showModal)}>
                <Icon name="Close" color="white" />
              </IconButton>
            </ModalHeader>
            <DialogContent id="bidContent">
              <Box style={{ display: 'flex' }}>
                <BottomNavigationAction
                  label="Previous"
                  icon={renderPreviousButton()}
                  title="Previous"
                  disabled={buttonPreviousDisabled()}
                  onClick={() => setPreviousBuyerId()}
                />

                <Grid container spacing={2}>
                  <Grid item xs={8} id="gridPreview">
                    <BCOPreview
                      buyerIdGuid={buyerIdSelected}
                      isSeller={false}
                      sellerIdGuid=""
                      showExportPDF={false}
                      showDueDiligence={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {loadingAcceptBid || dueDiligenceFileLoading ? (
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          style={{ minHeight: 430 }}
                        />
                      </Box>
                    ) : (
                      <Paper style={{ minHeight: 430 }}>
                        <Box display="flex" justifyContent="space-between">
                          {!featureDocumentRepository && (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                const url = isInternal
                                  ? 'document-repository'
                                  : 'third-party-repository'
                                window.open(
                                  `${process.env.REACT_APP_CMS_SITE}/${url}?businessId=${buyerIdSelected}`,
                                  '_blank'
                                )
                              }}
                            >
                              Due Diligence
                            </Button>
                          )}
                          <Button
                            startIcon={
                              bidFileLoading ? (
                                <CircularProgress size={15} color="primary" />
                              ) : (
                                <Icon name="GetApp" />
                              )
                            }
                            color="secondary"
                            onClick={onDownload}
                            disabled={bidFileLoading}
                          >
                            Export bid information to PDF
                          </Button>
                        </Box>
                        <Box
                          py={3}
                          px={5}
                          bgcolor="white"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          color="black"
                        >
                          <Typography variant="h1" style={{ fontWeight: 400 }}>
                            Bid Review
                          </Typography>
                        </Box>
                        {dueDiligenceUri !== '' && featureDueDiligence && (
                          <Box py={3} px={5} mb={2}>
                            <Button
                              data-cy="due-diligence-button"
                              color="primary"
                              variant="contained"
                              startIcon={
                                <Icon name="GetApp" fontSize="medium" />
                              }
                              onClick={downloadDueDiligence}
                            >
                              Download Due Diligence
                            </Button>
                          </Box>
                        )}
                        <DialogContent style={{ minHeight: 430 }}>
                          <Box
                            style={{ minHeight: 430 }}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                          >
                            {renderOneTimeData()}

                            {renderForwardFlowData()}
                          </Box>
                        </DialogContent>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
                <BottomNavigationAction
                  onClick={() => setNextBuyerId()}
                  label="Next"
                  title="Next"
                  icon={renderNextButton()}
                  disabled={buttonNextDisabled()}
                />
              </Box>
            </DialogContent>
          </Dialog>
        </Content>
      )}
      <ConfirmDialog
        open={openAcceptBidDialog}
        title="Accept Bid"
        description={
          bidSelected.buyerId ? (
            <>
              {bidSelected.businessStatus === 'pre-approved' && (
                <Box display={'flex'} flexDirection={'row'} gap={2}>
                  <Icon
                    name="Warning"
                    style={{ color: 'orange', alignSelf: 'center' }}
                  />
                  <Typography variant="subtitle1" color={Colors.warning}>
                    This bid was made by a pre-approved buyer
                  </Typography>
                </Box>
              )}
              <Typography
                data-cy="bid-acceptance-verbiage"
                variant="body1"
                color={textSecondary.color}
              >
                Are you sure you want to accept the
                <b data-cy="one-time-or-forward-flow">
                  {' '}
                  {bidSelected?.oneTimeBidId === bidIdSelected
                    ? 'one-time'
                    : 'forward-flow'}{' '}
                </b>
                bid of
                <b data-cy="one-time-or-forward-flow-value">
                  {' '}
                  {bidSelected?.oneTimeBidId === bidIdSelected
                    ? numberToPercentage(
                        bidSelected?.oneTimeBidPercentValue ??
                          bidSelected?.oneTimeBidPercent,
                        '',
                        3
                      )
                    : numberToPercentage(
                        bidSelected?.forwardFlowBidPercentValue ??
                          bidSelected?.forwardFlowBidPercent,
                        '',
                        3
                      )}{' '}
                </b>
                from <b data-cy="buyer-name"> {bidSelected.buyerName} </b>
                with a <b>close date</b> of{' '}
                <b data-cy="close-data-value">
                  {' '}
                  {bidSelected.oneTimeBidPortfolioCloseDate
                    ? formatDate(
                        bidSelected.oneTimeBidPortfolioCloseDate,
                        '',
                        portfolioCountry ?? ''
                      )
                    : bidSelected.forwardFlowBidPortfolioCloseDate
                    ? formatDate(
                        bidSelected.forwardFlowBidPortfolioCloseDate,
                        '',
                        portfolioCountry ?? ''
                      )
                    : ' - '}
                </b>{' '}
                and a <b>fund date</b> of{' '}
                <b data-cy="fund-data-value">
                  {bidSelected.oneTimeBidFundingDate
                    ? formatDate(
                        bidSelected.oneTimeBidFundingDate,
                        '',
                        portfolioCountry ?? ''
                      )
                    : bidSelected.forwardFlowBidFundingDate
                    ? formatDate(
                        bidSelected.forwardFlowBidFundingDate,
                        '',
                        portfolioCountry ?? ''
                      )
                    : ' - '}
                </b>
                ?
              </Typography>
              {featureFlagAgreementTemplate && (
                <Grid container>
                  <Grid item marginTop={5}>
                    <Typography>Select the Agreement Template</Typography>
                    <TextField
                      select
                      id="select-agreement"
                      fullWidth
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        onClick={() => {
                          setAgreementTemplateSelected(null)
                        }}
                        value={-1}
                      >
                        <em>Select agreement template</em>
                      </MenuItem>
                      {agreementTemplates?.map((business) => {
                        return (
                          <MenuItem
                            key={business.id}
                            value={business.name}
                            onClick={(e) => {
                              setAgreementTemplateSelected(business.id)
                            }}
                          >
                            {business.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>

                    {agreementTemplateSelected && (
                      <Button
                        style={{ marginTop: '5px' }}
                        onClick={() => {
                          const fileUri = agreementTemplates?.find(
                            (x) => x.id === agreementTemplateSelected
                          ).fileUri
                          if (fileUri !== '') {
                            window.location.href = fileUri
                          }
                        }}
                        color="secondary"
                        variant="contained"
                      >
                        Preview PSA template
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Box />
          )
        }
        loading={loadingAcceptBid}
        closeName="Cancel"
        confirmName="Continue"
        disableButtons={
          featureFlagAgreementTemplate && !agreementTemplateSelected
        }
        onClose={handleCloseConfirmation}
        onConfirm={() => handleAcceptBid(bidSelected, bidIdSelected)}
      />
      <ConfirmDialog
        open={openAcceptAckowledgement}
        title="Ackowledgement"
        description={
          acknowledgementData && acknowledgementData.length > 0 ? (
            <Typography
              data-cy="bid-acceptance-verbiage"
              variant="body1"
              color={textSecondary.color}
            >
              {acknowledgementData.map((acknowledgement: any) => (
                // eslint-disable-next-line react/jsx-key
                <p>{acknowledgement.description}</p>
              ))}
              <p>
                Do you acknowledge this information and would like to proceed?
              </p>
            </Typography>
          ) : (
            <Box />
          )
        }
        loading={loadingAcceptBid}
        closeName="No"
        confirmName="Yes"
        onClose={handleCloseAckowledgement}
        onConfirm={() => {
          setOpenAcceptAckowledgement(false)
          setOpenAcceptBidDialog(true)
        }}
      />
    </>
  )
}

export default BidReview
