/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useContext } from 'react'
import * as yup from 'yup'
import { useLazyQuery } from '@apollo/client'
import { notistackOptions } from 'src/configs/notistackOptions'

import { FORWARD_FLOW_AGREEMENT } from 'src/graphql/operations/queries/portfolio'
import { PortfolioTemplateInfo } from 'src/graphql/models/PortfolioTemplates'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Skeleton,
  ToggleButtonGroup,
  InputAdornment,
} from '@mui/material'
import { formatDate, IsBusinessDay } from 'src/utils/date'
import {
  numberToPercentage,
  numberCurrencyDollar,
  NumberFormatOptionsCurrency,
  calcNumberToPercent,
} from 'src/utils/numbers'
import { useFormik } from 'formik'
import {
  DatePicker,
  Group,
  Icon,
  ModalDialog,
  textPrimary,
} from 'everchain-uilibrary'

import {
  usePlaceBid,
  useAcceptPortfolioDisclosureOnBid,
  useAcceptPortfolioStatuteOfLimitation,
} from 'src/graphql/operations/mutations/portfolio'

import { ConfirmDialog } from 'src/components/Dialogs'
import { BusinessSelector } from 'src/components/BusinessSelector'
import { ForwardFlowAgreementResponse } from 'src/graphql/models/ForwardFlows'

import DTAccordion from 'src/components/Accordion'
import { isUkCountry } from 'src/utils/common'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import {
  BidDialogText,
  ToggleButtonCustom,
  PoundCircleFilledStyled,
} from '../styles'
import BkAccountSelection from './BkAccountSelection'
import BuyerPlaceBidDisplay from './BuyerPlaceBidDisplay'
import BuyerPlaceBidSummary from './BuyerPlaceBidSummary'
import BuyerPlaceBidBkSummary from './BuyerPlaceBidBkSummary'
import Loader from '../../../components/Loader/Loader'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPortfolioDisclosure,
  getBKPortfolioBalance,
  getPortfolioTemplate,
  getBuyersAllowedAccessPortfolio,
} from 'src/data/features/get/portfolio/portfolio'
import {
  BkAccountsBalanceResponse,
  PortfolioBid,
} from 'src/data/features/get/portfolio/types'
import {
  getBkAccountsExcludedSummary,
  includeAllBkAccount,
} from 'src/data/features/get/account/account'
import {
  BkExcludedAccountsSummaryData,
  IncludeBkAccountRequest,
} from 'src/data/features/get/account/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BuyerInfo } from 'src/graphql/models/Portfolio'
import { buyerConfirmForwardFlow } from 'src/data/features/post/forwardFlow/forwardFlow'
import {
  BuyerBidSimulateValuesItemResponse,
  BuyerBidSimulateValuesResponse,
} from 'src/data/features/get/financial/types'
import { getBuyerBidSimulateValues } from 'src/data/features/get/financial/financial'

interface BuyerPlaceBidProps {
  portfolioCountry: string | undefined
  portfolioId: string | undefined
  portfolioNumber: string | undefined
  portfolioTypeId: number | undefined
  portfolioTemplateId: number | undefined
  portfolioFaceValue: number | undefined
  forwardFlowAgreementId: number | undefined
  canCompleteFFReview: boolean
  buyerBidsData: PortfolioBid[] | undefined
  loadingPlacedBids: boolean
  showDisclosure: boolean
  onOpenDisclosureModal: (open: boolean) => void
}

interface BuyerBidSimulateValuesRequest {
  oneTimeBidPercent?: number
  calculateOneTime: boolean
  forwardFlowBidPercent?: number
  calculateForwardFlow: boolean
  totalBidAmount?: number
}

const TAB_INDEX_OT = '0'
const TAB_INDEX_FF = '1'
const TAB_INDEX_OTFF = '2'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const BuyerPlaceBid: React.FC<BuyerPlaceBidProps> = ({
  portfolioCountry,
  portfolioId,
  portfolioNumber,
  portfolioTypeId,
  portfolioTemplateId,
  forwardFlowAgreementId,
  canCompleteFFReview,
  buyerBidsData,
  loadingPlacedBids,
  showDisclosure,
  onOpenDisclosureModal,
}: BuyerPlaceBidProps) => {
  const queryClient = useQueryClient()
  const isBkPortfolio = portfolioTypeId === 2
  const isBkFFPortfolio = !!(isBkPortfolio && forwardFlowAgreementId)
  const [buttonIndex, setButtonIndex] = useState('')
  const [ffAllowed, setFfAllowed] = useState(false)
  const [otBidSelected, setOtBidSelected] = useState(false)
  const [ffBidSelected, setFfBidSelected] = useState(isBkFFPortfolio)
  const [calculatorOpen, setCalculatorOpen] = useState(false)
  const [calculatorBid, setCalculatorBid] = useState('')
  const [totalBidAmount, setTotalBidAmount] = useState('')
  const [placeBidOpen, setPlaceBidOpen] = useState(false)
  const [disableUI, setDisableUI] = useState(true)
  const [openDisclosure, setOpenDisclosure] = useState<boolean>(false)
  const [hasBid, setHasBid] = useState<boolean>(false)
  const [openLoader, setOpenLoader] = useState<boolean>(false)
  const ability = useContext(AbilityContext)
  const hideBidCalculator = portfolioTypeId === 2
  const [openBkAccountSelection, setOpenBkAccountSelection] =
    useState<boolean>(false)
  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined)
  const [bkBidPercentSelected, setBkBidPercentSelected] = useState<
    number | undefined
  >(undefined)
  const [statuteOfLimitationOpen, setStatuteOfLimitationOpen] =
    useState<boolean>(false)
  const { user, profileClient, profileBusiness } = useContext(AuthContext)
  const userName = user.profile['http://debttrader.com/claims/display_name']
  const isChargeOffPortfolio = portfolioTypeId !== 2
  const [openPreApproved, setOpenPreApproved] = useState<boolean>(false)
  const [ffBuyerBidSimulateValues, setFFbuyerBidSimulateValues] =
    useState<BuyerBidSimulateValuesItemResponse>()
  const [oTBuyerBidSimulateValues, setOTbuyerBidSimulateValues] =
    useState<BuyerBidSimulateValuesItemResponse>()
  const [buyerBidSimulateValuesRequest, setBuyerBidSimulateValuesRequest] =
    useState<BuyerBidSimulateValuesRequest>({
      calculateOneTime: false,
      calculateForwardFlow: false,
    })
  const [displayOneTimeBidPercent, setDisplayOneTimeBidPercent] = useState<
    number | string | undefined
  >(undefined)
  const [displayFFBidPercent, setDisplayFFBidPercent] = useState<
    number | string | undefined
  >(undefined)
  const [openExcludedAccountsDialog, setOpenExcludedAccountsDialog] =
    useState(false)

  const countryCode = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const { enqueueSnackbar } = useSnackbar()

  const oneTimeBid = buyerBidsData?.find((x) => x.bidType === 'one-time')
  const forwardFlowBid = buyerBidsData?.find(
    (x) => x.bidType === 'forward-flow'
  )

  const [initialValues, setInitialValues] = useState<any>({
    oneTimeBidPercent: undefined,
    oneTimeBidCloseDate: null, // suggestedCloseFundingDate,
    oneTimeBidFundingDate: null, // suggestedCloseFundingDate,
    oneTimeBidContingency: undefined,

    forwardFlowBidPercent: undefined,
    forwardFlowBidCloseDate: null, // suggestedCloseFundingDate,
    forwardFlowBidFundingDate: null, // suggestedCloseFundingDate,
    forwardFlowBidContingency: undefined,
    forwardFlowBidMonths: undefined,
    forwardFlowMaxFaceValue: undefined,
  })

  const formInfoOneTimeBidValidation = yup.object().shape({
    oneTimeBidPercent: yup
      .number()
      .required('Required')
      .min(0.001, 'Bid Percent cannot be lower than 0.001'),
    oneTimeBidCloseDate: yup.date().required('Required'),
    oneTimeBidFundingDate: yup.date().required('Required'),
  })

  const formInfoForwardFlowBidValidation = yup.object().shape({
    forwardFlowBidPercent: yup
      .number()
      .required('Required')
      .min(0.001, 'Bid Percent cannot be lower than 0.001'),
    forwardFlowBidCloseDate: yup.date().required('Required'),
    forwardFlowBidFundingDate: yup.date().required('Required'),
    forwardFlowBidMonths: yup
      .number()
      .required('Required')
      .min(1, 'Term cannot be lower than 1'),
    forwardFlowMaxFaceValue: yup
      .number()
      .required('Required')
      .min(0.01, 'Max Face Value can`t be lower than $0.01'),
  })

  const formInfoForwardValidation = yup.object().shape({
    forwardFlowBidCloseDate: yup.date().required('Required'),
    forwardFlowBidFundingDate: yup.date().required('Required'),
  })

  const getValidationSchema = () => {
    if (forwardFlowAgreementId) return formInfoForwardValidation

    if (otBidSelected && !ffBidSelected) return formInfoOneTimeBidValidation

    if (!otBidSelected && ffBidSelected) return formInfoForwardFlowBidValidation

    if (otBidSelected && ffBidSelected)
      return formInfoOneTimeBidValidation.concat(
        formInfoForwardFlowBidValidation
      )

    return null
  }

  const validate = (values: any) => {
    const errors: any = {}

    if (otBidSelected && !values.oneTimeBidCloseDate) {
      errors.oneTimeBidCloseDate = 'Required'
    }

    if (otBidSelected && !values.oneTimeBidFundingDate) {
      errors.oneTimeBidFundingDate = 'Required'
    }

    if (ffBidSelected && !values.forwardFlowBidCloseDate) {
      errors.forwardFlowBidCloseDate = 'Required'
    }

    if (ffBidSelected && !values.forwardFlowBidFundingDate) {
      errors.forwardFlowBidFundingDate = 'Required'
    }

    return errors
  }

  const formInfo = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    validationSchema: getValidationSchema(),
    onSubmit: () => {},
  })

  const { data: disclosureData, isFetching: loadingDisclosure } =
    useCustomQuery<any>(
      ['getPortfolioDisclosure', portfolioId],
      async () => getPortfolioDisclosure(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  const { data: buyerQueryData, isFetching: loadingBuyerData } = useCustomQuery<
    BuyerInfo[]
  >(
    ['getBuyersAllowedAccessPortfolio', portfolioId],
    async () => getBuyersAllowedAccessPortfolio(portfolioId),
    { enabled: true, cacheTime: 0 }
  )

  const { data: templateData, isFetching: portfolioTemplateLoading } =
    useCustomQuery<PortfolioTemplateInfo>(
      ['getPortfolioTemplate', portfolioTemplateId],
      async () => getPortfolioTemplate(portfolioTemplateId),
      {
        enabled: portfolioTemplateId != null,
      }
    )

  const { isFetching: loadingBuyerBidSimulateValues } =
    useCustomQuery<BuyerBidSimulateValuesResponse>(
      ['getBuyerBidSimulateValues', buyerBidSimulateValuesRequest],
      async () =>
        getBuyerBidSimulateValues(
          portfolioId,
          selectedBusiness?.id,
          Number(
            calcNumberToPercent(buyerBidSimulateValuesRequest.oneTimeBidPercent)
          ),
          Number(
            calcNumberToPercent(
              buyerBidSimulateValuesRequest.forwardFlowBidPercent
            )
          ),
          buyerBidSimulateValuesRequest.calculateOneTime,
          buyerBidSimulateValuesRequest.calculateForwardFlow,
          buyerBidSimulateValuesRequest.totalBidAmount
        )
          .then((data) => {
            if (buyerBidSimulateValuesRequest.calculateOneTime) {
              setOTbuyerBidSimulateValues(data.oneTimeBidValues)

              if (
                data.oneTimeBidValues?.purchaseProceedsPercent &&
                buyerBidSimulateValuesRequest.totalBidAmount
              ) {
                formInfo.setFieldValue(
                  'oneTimeBidPercent',
                  data.oneTimeBidValues.purchaseProceedsPercent * 100
                )
                setDisplayOneTimeBidPercent(
                  (data.oneTimeBidValues.purchaseProceedsPercent * 100).toFixed(
                    3
                  )
                )
              }
            }

            if (buyerBidSimulateValuesRequest.calculateForwardFlow) {
              setFFbuyerBidSimulateValues(data.forwardFlowBidValues)

              if (
                data.forwardFlowBidValues?.purchaseProceedsPercent &&
                buyerBidSimulateValuesRequest.totalBidAmount
              ) {
                formInfo.setFieldValue(
                  'forwardFlowBidPercent',
                  data.forwardFlowBidValues.purchaseProceedsPercent * 100
                )
                setDisplayFFBidPercent(
                  (
                    data.forwardFlowBidValues.purchaseProceedsPercent * 100
                  ).toFixed(3)
                )
              }
            }

            return data
          })
          .finally(() => {
            setBuyerBidSimulateValuesRequest({
              calculateOneTime: false,
              calculateForwardFlow: false,
            })
          }),
      {
        enabled:
          !openExcludedAccountsDialog &&
          !disableUI &&
          !portfolioTemplateLoading &&
          (buyerBidSimulateValuesRequest.calculateOneTime ||
            buyerBidSimulateValuesRequest.calculateForwardFlow),
        cacheTime: 0,
      }
    )

  // if has bid
  useEffect(() => {
    if (buyerBidsData && buyerBidsData.length > 0) {
      //   if (isBkPortfolio) getBkPortfolioBalance()

      setHasBid(true)
      setDisableUI(true)

      if (oneTimeBid) {
        setSelectedBusiness(
          buyerQueryData?.find((element) => element.id === oneTimeBid?.buyerId)
        )
        setInitialValues((prevState: any) => ({
          ...prevState,
          oneTimeBidPercent: oneTimeBid.bidPercent / 100,
          oneTimeBidCloseDate: oneTimeBid.bidPortfolioCloseDate,
          oneTimeBidFundingDate: oneTimeBid.bidFundingDate,
          oneTimeBidContingency: oneTimeBid.bidContingency,
        }))

        if (!forwardFlowBid) {
          setBuyerBidSimulateValuesRequest({
            calculateOneTime: true,
            calculateForwardFlow: false,
            oneTimeBidPercent: oneTimeBid.bidPercent / 100,
          })
        }
      } else setOtBidSelected(false)

      if (forwardFlowBid) {
        if (!selectedBusiness)
          setSelectedBusiness(
            buyerQueryData?.find(
              (element) => element.id === forwardFlowBid?.buyerId
            )
          )
        setFfBidSelected(true)
        setInitialValues((prevState: any) => ({
          ...prevState,
          forwardFlowBidPercent: forwardFlowBid.bidPercent / 100,
          forwardFlowBidCloseDate: forwardFlowBid.bidPortfolioCloseDate,
          forwardFlowBidFundingDate: forwardFlowBid.bidFundingDate,
          forwardFlowBidContingency: forwardFlowBid.bidContingency,
          forwardFlowBidMonths: forwardFlowBid.forwardFlowBidMonths,
          forwardFlowMaxFaceValue: forwardFlowBid.forwardFlowMaxFaceValue,
        }))

        if (!oneTimeBid) {
          setBuyerBidSimulateValuesRequest({
            calculateOneTime: false,
            calculateForwardFlow: true,
            forwardFlowBidPercent: forwardFlowBid.bidPercent / 100,
          })
        }
      }

      if (oneTimeBid && forwardFlowBid) {
        setBuyerBidSimulateValuesRequest({
          calculateOneTime: true,
          oneTimeBidPercent: oneTimeBid.bidPercent / 100,
          calculateForwardFlow: true,
          forwardFlowBidPercent: forwardFlowBid.bidPercent / 100,
        })
      }
    }
  }, [buyerBidsData, buyerQueryData])

  const { acceptPortfolioStatuteOfLimitation } =
    useAcceptPortfolioStatuteOfLimitation({
      onCompleted: () => {
        setOpenLoader(false)
        setStatuteOfLimitationOpen(false)
        setPlaceBidOpen(true)
      },
      onError: () => {
        setOpenLoader(false)
        enqueueSnackbar('Error accepting disclosure', notifyError)
        setDisableUI(false)
      },
    })

  const { acceptPortfolioDisclosureOnBid } = useAcceptPortfolioDisclosureOnBid({
    onCompleted: () => {
      setOpenDisclosure(false)
      setDisableUI(false)
      setStatuteOfLimitationOpen(true)
    },
    onError: () => {
      enqueueSnackbar('Error accepting disclosure', notifyError)
      setDisableUI(false)
    },
  })

  const handleAcceptDisclosure = () => {
    setDisableUI(true)
    acceptPortfolioDisclosureOnBid({
      variables: {
        portfolioNumber: disclosureData?.portfolioNumber,
      },
      refetchQueries: ['GetPortfolioDisclosure'],
    })
  }

  const handleCloseDisclosure = () => {
    setOpenDisclosure(false)
  }

  const { data: bkPortfolioBalance, refetch: refetchGetBkPortfolioBalance } =
    useCustomQuery<BkAccountsBalanceResponse>(
      ['getBkPortfolioBalance', selectedBusiness?.id, portfolioId],
      async () => getBKPortfolioBalance(selectedBusiness?.id, portfolioId),
      {
        enabled: isBkFFPortfolio && selectedBusiness?.id != null,
        cacheTime: 0,
      }
    )

  const { data: bkAccountsSummaryDataResponse } = useCustomQuery<
    BkExcludedAccountsSummaryData[]
  >(
    ['getBkAccountsExcludedSummary', portfolioId, selectedBusiness?.id],
    async () => getBkAccountsExcludedSummary(portfolioId),
    {
      enabled: portfolioTypeId === 2,
      cacheTime: 0,
    }
  )

  const bkAccountsSummaryData = bkAccountsSummaryDataResponse

  const includeAllAccountBkBid = useMutation({
    mutationFn: (request: IncludeBkAccountRequest) =>
      includeAllBkAccount(request),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['getBkAccountsExcludedSummary'],
      })

      queryClient.invalidateQueries({ queryKey: ['getBKAccounts'] })

      enqueueSnackbar('Accounts selection reset', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Error reseting accounts', notifyError)
      setDisableUI(false)
    },
  })

  const handleYes = () => {
    setDisableUI(true)
    includeAllAccountBkBid.mutate({
      buyerId:
        bkAccountsSummaryData === undefined
          ? ''
          : bkAccountsSummaryData[0].buyerId,
      portfolioId: portfolioId || '',
      portfolioRowIds: [],
    })

    setOpenExcludedAccountsDialog(false)
  }

  const handleNo = () => {
    if (bkAccountsSummaryData) {
      const buyer = buyerQueryData?.find(
        (element) => element.id === bkAccountsSummaryData[0]?.buyerId
      )
      setSelectedBusiness(buyer)
      setOpenExcludedAccountsDialog(false)
    }
  }

  useEffect(() => {
    if (bkAccountsSummaryData && bkAccountsSummaryData.length > 0) {
      if (selectedBusiness?.id !== bkAccountsSummaryData[0].buyerId) {
        setOpenExcludedAccountsDialog(true)
      }
    }
  }, [bkAccountsSummaryData, selectedBusiness?.id])

  useEffect(() => {
    if (bkBidPercentSelected) {
      if (buyerBidsData || portfolioTypeId === 2) {
        handleSetOTAndFFBidPercent(bkBidPercentSelected)
      }
    }
  }, [bkBidPercentSelected])

  const handleSetOTAndFFBidPercent = (bidPercent: number) => {
    formInfo.setFieldValue('oneTimeBidPercent', bidPercent)
    formInfo.setFieldValue('forwardFlowBidPercent', bidPercent)
    setDisplayOneTimeBidPercent(bidPercent)
    setDisplayFFBidPercent(bidPercent)
  }

  useEffect(() => {
    formInfo.validateForm()
  }, [formInfo.values, buttonIndex])

  const { placeBidRequest } = usePlaceBid({
    onCompleted: () => {
      setPlaceBidOpen(false)
      enqueueSnackbar('Bid has been placed', notifySuccess)
      setDisableUI(true)
    },
    onError: () => {
      setPlaceBidOpen(false)
      enqueueSnackbar('Error placing bid', notifyError)
      setDisableUI(false)
    },
  })

  const buyerConfirmForwardFlowEvent = useMutation({
    mutationFn: (request: any) => buyerConfirmForwardFlow(request),
    onSuccess: () => {
      setOpenLoader(false)
      setPlaceBidOpen(false)
      enqueueSnackbar('Forward flow has been confirmed', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      setOpenLoader(false)
      setPlaceBidOpen(false)
      enqueueSnackbar('Error confirming forward flow', notifyError)
      setDisableUI(false)
    },
  })

  const onStatuteOfLimitationClose = () => {
    setDisableUI(true)
    setOpenLoader(true)
    acceptPortfolioStatuteOfLimitation({
      variables: {
        portfolioId,
      },
    })
  }

  const onPlaceBid = () => {
    let oneTimeBidValues = null
    let ffBidValues = null

    if (otBidSelected)
      oneTimeBidValues = {
        oneTimeBidPercent: formInfo.values.oneTimeBidPercent / 100,
        oneTimeBidPortfolioCloseDate: formInfo.values.oneTimeBidCloseDate,
        oneTimeBidFundingDate: formInfo.values.oneTimeBidFundingDate,
        oneTimeBidContingency: formInfo.values.oneTimeBidContingency,
      }

    if (ffBidSelected)
      ffBidValues = {
        forwardFlowBidPercent: formInfo.values.forwardFlowBidPercent / 100,
        forwardFlowBidPortfolioCloseDate:
          formInfo.values.forwardFlowBidCloseDate,
        forwardFlowBidFundingDate: formInfo.values.forwardFlowBidFundingDate,
        forwardFlowBidContingency: formInfo.values.forwardFlowBidContingency,
        forwardFlowBidMonths: formInfo.values.forwardFlowBidMonths,
        forwardFlowMaxFaceValue: Number(
          formInfo.values.forwardFlowMaxFaceValue
        ),
      }

    setDisableUI(true)

    placeBidRequest({
      variables: {
        placeBidRequest: {
          portfolioId,
          buyerId: selectedBusiness?.id,
          oneTimeBid: oneTimeBidValues,
          forwardFlowBid: ffBidValues,
        },
      },
    })
  }

  const onConfirmFlorwardFlow = () => {
    setDisableUI(true)
    setOpenLoader(true)

    buyerConfirmForwardFlowEvent.mutate({
      portfolioId,
      closeDate: formInfo.values.forwardFlowBidCloseDate,
      fundingDate: formInfo.values.forwardFlowBidFundingDate,
    })
  }

  const handleCloseDateChange = (propName: string, date: any) => {
    formInfo.setFieldValue(propName, date)

    if (propName.indexOf('oneTime') !== -1) {
      if (formInfo.values.oneTimeBidFundingDate < date)
        formInfo.setFieldValue('oneTimeBidFundingDate', date)

      return
    }

    if (propName.indexOf('forwardFlow') !== -1)
      if (formInfo.values.forwardFlowBidFundingDate < date)
        formInfo.setFieldValue('forwardFlowBidFundingDate', date)
  }

  const handlePlaceBid = () => {
    if (isBkFFPortfolio) {
      onConfirmFlorwardFlow()
    } else if (
      disclosureData?.isDisclosureEnabled &&
      !disclosureData.isDisclosureOnBidAccepted
    ) {
      setOpenDisclosure(true)
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } else if (
      profileClient?.Type === 'Buyer' &&
      portfolioCountry === 'US' &&
      isChargeOffPortfolio
    ) {
      setStatuteOfLimitationOpen(true)
    } else {
      setPlaceBidOpen(true)
    }
  }

  const handleOneTimeBidCalculatorOpen = () => {
    setTotalBidAmount('')
    setCalculatorBid('one-time')
    setCalculatorOpen(true)
  }

  const handleForwardFlowBidCalculatorOpen = () => {
    setTotalBidAmount('')
    setCalculatorBid('forward-flow')
    setCalculatorOpen(true)
  }

  const disableDates = (date: Date, propName: string): boolean => {
    if (date) {
      const d = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      )

      if (propName === 'oneTimeBidFundingDate') {
        const closeDate = new Date(
          formInfo.values.oneTimeBidCloseDate?.getFullYear() ||
            date.getFullYear(),
          formInfo.values.oneTimeBidCloseDate?.getMonth() || date.getMonth(),
          formInfo.values.oneTimeBidCloseDate?.getDate() || date.getDate(),
          0,
          0,
          0,
          0
        )

        const maxCloseDate = new Date(
          formInfo.values.oneTimeBidCloseDate?.getFullYear() ||
            date.getFullYear(),
          formInfo.values.oneTimeBidCloseDate?.getMonth() || date.getMonth(),
          formInfo.values.oneTimeBidCloseDate?.getDate() + 20 ||
            date.getDate() + 20,
          0,
          0,
          0,
          0
        )

        if (d < closeDate || d > maxCloseDate) return true
      }

      if (propName === 'forwardFlowBidFundingDate') {
        const closeDate = new Date(
          formInfo.values.forwardFlowBidCloseDate?.getFullYear() ||
            date.getFullYear(),
          formInfo.values.forwardFlowBidCloseDate?.getMonth() ||
            date.getMonth(),
          formInfo.values.forwardFlowBidCloseDate?.getDate() || date.getDate(),
          0,
          0,
          0,
          0
        )

        const maxCloseDate = new Date(
          formInfo.values.forwardFlowBidCloseDate?.getFullYear() ||
            date.getFullYear(),
          formInfo.values.forwardFlowBidCloseDate?.getMonth() ||
            date.getMonth(),
          formInfo.values.forwardFlowBidCloseDate?.getDate() + 20 ||
            date.getDate() + 20,
          0,
          0,
          0,
          0
        )
        if (d < closeDate || d > maxCloseDate) return true
      }

      return !IsBusinessDay(date, countryCode) || date <= new Date()
    }

    return true
  }

  useEffect(() => {
    if (forwardFlowAgreementId || loadingPlacedBids || hasBid) {
      return
    }

    if (templateData?.allowForwardFlowBids) {
      // process Allow FF Bid logic
      setButtonIndex(TAB_INDEX_OTFF)
      setFfAllowed(true)
      setOtBidSelected(true)
      setFfBidSelected(true)
    } else {
      setButtonIndex(TAB_INDEX_OT)
      setFfAllowed(false)
      setOtBidSelected(true)
      setFfBidSelected(false)
    }
  }, [templateData, hasBid, loadingPlacedBids])

  const [getForwardFlowAgreement] = useLazyQuery<ForwardFlowAgreementResponse>(
    FORWARD_FLOW_AGREEMENT,
    {
      variables: {
        forwardFlowId: forwardFlowAgreementId,
      },
      onCompleted: (data) => {
        // suggestedCloseFundingDate = new Date()
        // suggestedCloseFundingDate.setDate(
        //   new Date().getDate() + data.forwardFlowData.buyerFundingTerms
        // )

        // while (!IsBusinessDay(suggestedCloseFundingDate)) {
        //   suggestedCloseFundingDate.setDate(
        //     suggestedCloseFundingDate.getDate() + 1
        //   )
        // }

        // setInitialValues((prevState: any) => ({
        //   ...prevState,
        //   oneTimeBidCloseDate: suggestedCloseFundingDate,
        //   oneTimeBidFundingDate: suggestedCloseFundingDate,
        //   forwardFlowBidCloseDate: suggestedCloseFundingDate,
        //   forwardFlowBidFundingDate: suggestedCloseFundingDate,
        // }))

        const value = Number(
          (data.forwardFlowData.purchasePricePercent * 100).toFixed(3)
        )
        setBkBidPercentSelected(value)
        setBuyerBidSimulateValuesRequest({
          calculateOneTime: false,
          forwardFlowBidPercent: value,
          calculateForwardFlow: true,
        })
      },
    }
  )

  // Forward flow portfolio configuration
  useEffect(() => {
    if (forwardFlowAgreementId) {
      getForwardFlowAgreement()
    }
  }, [getForwardFlowAgreement, forwardFlowAgreementId])

  useEffect(() => {
    if (buyerQueryData?.length === 1) {
      setSelectedBusiness(buyerQueryData[0])
      setDisableUI(false)
    }
  }, [buyerQueryData])

  const handleBusinessSelected = (business: any) => {
    setSelectedBusiness(business)
    setBuyerBidSimulateValuesRequest({
      oneTimeBidPercent: formInfo.values.oneTimeBidPercent,
      calculateOneTime: true,
      forwardFlowBidPercent: formInfo.values.forwardFlowBidPercent,
      calculateForwardFlow: ffAllowed,
    })
    setDisableUI(false)
  }

  const handleAccountExclusion = () => {
    setOpenBkAccountSelection(true)
  }

  const termLabel = templateData?.frequency
    ? `Term (${templateData?.frequency})`
    : 'Term (Monthly)'

  const maxFaceValue = templateData?.estimatedFaceValue
    ? `Max Face Value (est: ${numberCurrencyDollar(
        templateData?.estimatedFaceValue,
        portfolioCountry
      )})`
    : 'Max Face Value'

  if (
    loadingDisclosure ||
    loadingBuyerData ||
    portfolioTemplateLoading ||
    loadingPlacedBids
  )
    return <Skeleton variant="rectangular" width="100%" height={50} />

  const submitButtonLabel = () => {
    if (isBkFFPortfolio) return 'Confirm Forward Flow'

    if (ffBidSelected && otBidSelected) return 'Place Bids'

    return 'Place Bid'
  }

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )
  const getBkName = () => {
    return isPortfolioUk ? 'Insolvency' : 'Bankruptcy'
  }

  const getCurrencySymbol = () => {
    const currency = NumberFormatOptionsCurrency(portfolioCountry)

    const locale = navigator.language
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    })
    const parts = formatter.formatToParts(0)
    const symbolPart = parts.find((part) => part.type === 'currency')
    return symbolPart ? symbolPart.value : ''
  }

  const handlePreApprovedPlaceBid = () => {
    if (
      profileBusiness.find((x) => x.Id === selectedBusiness?.id)?.Status ===
      'pre-approved'
    ) {
      setOpenPreApproved(true)
    } else {
      handlePlaceBid()
    }
  }

  const handleConfirmPreApprovedPlaceBid = () => {
    setOpenPreApproved(false)
    handlePlaceBid()
  }

  const handleSetBidSimulateValues = (bidPercent: number) => {
    if (forwardFlowAgreementId) {
      setBuyerBidSimulateValuesRequest({
        calculateOneTime: false,
        forwardFlowBidPercent: bidPercent,
        calculateForwardFlow: true,
      })
    } else {
      setBuyerBidSimulateValuesRequest({
        oneTimeBidPercent: bidPercent,
        calculateOneTime: true,
        forwardFlowBidPercent: bidPercent,
        calculateForwardFlow: ffAllowed,
      })
    }
  }

  if (canCompleteFFReview)
    return (
      <DTAccordion
        id="place-bid-accordion"
        title={isBkFFPortfolio ? 'Confirm Forward-Flow' : 'Place Bid'}
        icon={
          isPortfolioUk ? (
            <PoundCircleFilledStyled />
          ) : (
            <Icon name="MonetizationOn" />
          )
        }
        expanded={true}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body2" color={textPrimary.color}>
              <strong>Next Step: </strong>Awaiting seller to complete the
              portfolio review.
            </Typography>
          </Grid>
        </Grid>
      </DTAccordion>
    )

  if (portfolioTypeId === 2 && !bkBidPercentSelected && !hasBid)
    return (
      <>
        <DTAccordion
          id="place-bid-accordion"
          title="Place Bid"
          icon={
            isPortfolioUk ? (
              <PoundCircleFilledStyled />
            ) : (
              <Icon name="MonetizationOn" />
            )
          }
          expanded={true}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">
                {getBkName()} portfolios require accounts to be selected prior
                to placing a bid.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {buyerQueryData && buyerQueryData?.length > 1 && (
                <Box p={1}>
                  <BusinessSelector
                    data={buyerQueryData}
                    disabled={selectedBusiness && disableUI}
                    label="Buyer"
                    noDataText="This user doesn't belong to any buyer"
                    selectionText="Select a buyer"
                    value={selectedBusiness || undefined}
                    onSelectedBusiness={(business) => {
                      handleBusinessSelected(business)
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!selectedBusiness}
                variant="contained"
                color="primary"
                onClick={handleAccountExclusion}
              >
                Account Exclusion
              </Button>
            </Grid>
          </Grid>

          {selectedBusiness && selectedBusiness.id && portfolioTypeId === 2 && (
            <BkAccountSelection
              buyerId={selectedBusiness?.id || ''}
              portfolioId={portfolioId || ''}
              portfolioNumber={portfolioNumber}
              bidPercentApplied={bkBidPercentSelected || 0}
              disableBidPercentInput={isBkFFPortfolio}
              openDialog={openBkAccountSelection}
              onCloseDialog={() => {
                refetchGetBkPortfolioBalance()
                setOpenBkAccountSelection(false)
                if (bkBidPercentSelected) {
                  handleSetBidSimulateValues(bkBidPercentSelected)
                }
              }}
              onApplyAndClose={(bidPercent: number) => {
                if (bidPercent === bkBidPercentSelected) {
                  handleSetOTAndFFBidPercent(bidPercent)
                }
                refetchGetBkPortfolioBalance()
                setBkBidPercentSelected(bidPercent)
                setOpenBkAccountSelection(false)
                // setOpenBidDialog(true)
                handleSetBidSimulateValues(bidPercent)
              }}
            />
          )}
        </DTAccordion>
        <Dialog
          open={openExcludedAccountsDialog}
          onClose={handleNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There is an existing selection in progress under the business "
              {bkAccountsSummaryData &&
                bkAccountsSummaryData.length > 0 &&
                bkAccountsSummaryData[0].buyerName}
              ",
              {bkAccountsSummaryData &&
              bkAccountsSummaryData.length > 0 &&
              bkAccountsSummaryData[0].total === 1
                ? ' account'
                : ' accounts'}
              {bkAccountsSummaryData &&
              bkAccountsSummaryData.length > 0 &&
              bkAccountsSummaryData[0].total === 1
                ? ' were '
                : ' was '}
              excluded. If you switch businesses, the selection will be lost. Do
              you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleYes} color="primary">
              Yes
            </Button>
            <Button
              onClick={handleNo}
              color="primary"
              variant="contained"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )

  return (
    <DTAccordion
      id="place-bid-accordion"
      title={isBkFFPortfolio ? 'Confirm Forward Flow' : 'Place Bid'}
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
      expanded={true}
    >
      <form onSubmit={formInfo.handleSubmit}>
        <Grid container>
          {hasBid &&
            ability.can(
              PermissionCodeAccess.MarketPlace_Basic_PlaceBid,
              'any'
            ) && (
              <Grid item xs={12}>
                <BuyerPlaceBidDisplay
                  portfolioCountry={portfolioCountry}
                  hasOneTimeBid={!!initialValues.oneTimeBidPercent}
                  oneTimeBidPercent={initialValues.oneTimeBidPercent}
                  oneTimeFinalBidPercent={
                    oTBuyerBidSimulateValues?.finalPurchaseProceedsPercent?.toLocaleString() ??
                    ''
                  }
                  oneTimeBidCloseDate={initialValues.oneTimeBidCloseDate}
                  oneTimeBidFundingDate={initialValues.oneTimeBidFundingDate}
                  oneTimeBidSalesComission={
                    oTBuyerBidSimulateValues?.salesCommissionAmount ?? 0
                  }
                  oneTimePurchaseProceeds={
                    oTBuyerBidSimulateValues?.purchaseProceedsAmount ?? 0
                  }
                  oneTimeBidTotalPrice={
                    oTBuyerBidSimulateValues?.totalPriceAmount ?? 0
                  }
                  oneTimeBidContingency={initialValues.oneTimeBidContingency}
                  hasForwardFlowBid={!!initialValues.forwardFlowBidPercent}
                  forwardFlowBidPercent={initialValues.forwardFlowBidPercent}
                  // eslint-disable-next-line max-len
                  forwardFlowFinalBidPercent={
                    ffBuyerBidSimulateValues?.finalPurchaseProceedsPercent?.toLocaleString() ??
                    ''
                  }
                  forwardFlowBidCloseDate={
                    initialValues.forwardFlowBidCloseDate
                  }
                  forwardFlowBidFundingDate={
                    initialValues.forwardFlowBidFundingDate
                  }
                  forwardFlowBidSalesComission={
                    ffBuyerBidSimulateValues?.salesCommissionAmount ?? 0
                  }
                  forwardFlowPurchaseProceeds={
                    ffBuyerBidSimulateValues?.purchaseProceedsAmount ?? 0
                  }
                  forwardFlowBidTotalPrice={
                    ffBuyerBidSimulateValues?.totalPriceAmount ?? 0
                  }
                  forwardFlowBidContingency={
                    initialValues.forwardFlowBidContingency
                  }
                  forwardFlowTerms={initialValues.forwardFlowBidMonths}
                  forwardFlowTermLabel={termLabel}
                  forwardFlowMaxFaceValue={
                    initialValues.forwardFlowMaxFaceValue
                  }
                  forwardFlowMaxFaceLabel={maxFaceValue}
                  bkBidNumberOfAccounts={bkPortfolioBalance?.totalAccounts}
                  bkTocNumberOfAccounts={bkPortfolioBalance?.totalPocAccounts}
                  bkTocTotalAmount={bkPortfolioBalance?.totalTocBalance}
                />
              </Grid>
            )}
          {!hasBid && (
            <Grid item xs={12}>
              <Box>
                {buyerQueryData && buyerQueryData?.length > 1 && (
                  <Box pb={2}>
                    <BusinessSelector
                      data={buyerQueryData}
                      disabled={selectedBusiness && disableUI}
                      label="Buyer"
                      noDataText="This user doesn't belong to any buyer"
                      selectionText="Select a buyer"
                      onSelectedBusiness={(business) => {
                        handleBusinessSelected(business)
                      }}
                      value={selectedBusiness || null}
                    />
                  </Box>
                )}
                <Dialog
                  open={openExcludedAccountsDialog}
                  onClose={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      There is an existing selection in progress under the
                      business "
                      {bkAccountsSummaryData &&
                        bkAccountsSummaryData.length > 0 &&
                        bkAccountsSummaryData[0].buyerName}
                      ",
                      {bkAccountsSummaryData &&
                      bkAccountsSummaryData.length > 0 &&
                      bkAccountsSummaryData[0].total === 1
                        ? ' account'
                        : ' accounts'}
                      {bkAccountsSummaryData &&
                      bkAccountsSummaryData.length > 0 &&
                      bkAccountsSummaryData[0].total === 1
                        ? ' were '
                        : ' was '}
                      excluded. If you switch businesses, the selection will be
                      lost. Do you want to proceed?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleYes} color="primary">
                      Yes
                    </Button>
                    <Button
                      onClick={handleNo}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
                {portfolioTypeId === 2 && !hasBid && (
                  <Box mb={4}>
                    <Button
                      // disabled={!selectedBusiness}
                      variant="contained"
                      color="primary"
                      onClick={handleAccountExclusion}
                    >
                      Account Exclusion
                    </Button>
                  </Box>
                )}
                <Box
                  display={ffAllowed ? 'flex' : 'none'}
                  flexDirection="row"
                  justifyContent="center"
                >
                  <ToggleButtonGroup
                    value={buttonIndex}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setButtonIndex(newValue)
                        setOtBidSelected(
                          newValue === TAB_INDEX_OT ||
                            newValue === TAB_INDEX_OTFF
                        )
                        setFfBidSelected(
                          newValue === TAB_INDEX_FF ||
                            newValue === TAB_INDEX_OTFF
                        )
                      }
                    }}
                    exclusive
                  >
                    <ToggleButtonCustom
                      value={TAB_INDEX_OT}
                      disabled={disableUI}
                    >
                      <Typography variant="caption">
                        One-Time Bid Only
                      </Typography>
                    </ToggleButtonCustom>
                    <ToggleButtonCustom
                      value={TAB_INDEX_FF}
                      disabled={disableUI}
                    >
                      <Typography variant="caption">
                        Forward Flow Bid Only
                      </Typography>
                    </ToggleButtonCustom>
                    <ToggleButtonCustom
                      value={TAB_INDEX_OTFF}
                      disabled={disableUI}
                    >
                      <Typography variant="caption">
                        Both One-Time and Forward Flow Bids
                      </Typography>
                    </ToggleButtonCustom>
                  </ToggleButtonGroup>
                </Box>
                {portfolioTypeId === 2 && (
                  <Box>
                    <BkAccountSelection
                      buyerId={selectedBusiness?.id || ''}
                      portfolioId={portfolioId || ''}
                      portfolioNumber={portfolioNumber}
                      bidPercentApplied={bkBidPercentSelected}
                      openDialog={openBkAccountSelection}
                      disableBidPercentInput={isBkFFPortfolio}
                      onCloseDialog={() => {
                        setOpenBkAccountSelection(false)
                        if (bkBidPercentSelected) {
                          handleSetBidSimulateValues(bkBidPercentSelected)
                        }
                      }}
                      onApplyAndClose={(bidPercent: number) => {
                        if (bidPercent === bkBidPercentSelected) {
                          handleSetOTAndFFBidPercent(bidPercent)
                        }
                        refetchGetBkPortfolioBalance()
                        setBkBidPercentSelected(bidPercent)
                        setOpenBkAccountSelection(false)
                        setDisableUI(false)
                        handleSetBidSimulateValues(bidPercent)
                      }}
                    />
                  </Box>
                )}

                <Dialog
                  open={calculatorOpen}
                  onClose={() => setCalculatorOpen(false)}
                >
                  <DialogTitle>Bid Percent Calculator</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Enter the total dollar amount for this bid:{' '}
                    </DialogContentText>
                    <Box pt={2}>
                      <TextField
                        fullWidth
                        id="totalDollarAmount"
                        label="Total Dollar Amount"
                        onChange={(e: any) => setTotalBidAmount(e.target.value)}
                        onBlur={(e) => {
                          const value = e.target.value
                            ? parseFloat(e.target.value ?? 0).toFixed(2)
                            : ''

                          setTotalBidAmount(value)
                        }}
                        value={totalBidAmount}
                        inputProps={{
                          min: 0,
                          step: '.01',
                          type: 'number',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {getCurrencySymbol()}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <DialogActions>
                      <Button
                        onClick={() => setCalculatorOpen(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setCalculatorOpen(false)
                          setBuyerBidSimulateValuesRequest({
                            calculateOneTime: calculatorBid === 'one-time',
                            calculateForwardFlow:
                              calculatorBid === 'forward-flow',
                            totalBidAmount: Number(totalBidAmount),
                          })
                        }}
                        color="primary"
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>

                <ConfirmDialog
                  open={statuteOfLimitationOpen}
                  onClose={() => setStatuteOfLimitationOpen(false)}
                  onConfirm={() => onStatuteOfLimitationClose()}
                  closeName="Cancel"
                  confirmName="Ok"
                  title="Statute of Limitation Communication"
                >
                  <>
                    {openLoader && <Loader specificHeight="70%" />}
                    <Typography variant="body1" component="div">
                      <Box ml={8} mb={4}>
                        {isPortfolioUk
                          ? `The statements provided regarding any applicable statute
                        of limitation is being provided for your information
                        only, and should not be relied on in making evaluation
                        of the offered portfolio. EverChain is not operating as
                        an attourney and has based the Statute of
                        Limitations calculations on information for written
                        contracts published by industry representatives.
                        EverChain does not represent or warrant that the
                        calculations are correct or that the interpretation of
                        the laws surrounding Statute of Limitations are
                        accurate. A myriad of factors under state law should be
                        considered when determining the applicable limitation
                        period. You should consult with your counsel for making
                        any determination on the applicable statute of
                        limitations.`
                          : `The statements provided regarding any applicable statute
                          of limitation is being provided for your information
                          only, and should not be relied on in making evaluation
                          of the offered portfolio. EverChain is not operating as
                          an attorney and has based the Statute of
                          Limitations calculations on information for written
                          contracts published by industry representatives.
                          EverChain does not represent or warrant that the
                          calculations are correct or that the interpretation of
                          the laws surrounding Statute of Limitations are
                          accurate. A myriad of factors under state law should be
                          considered when determining the applicable limitation
                          period. You should consult with your counsel for making
                          any determination on the applicable statute of
                          limitations.`}
                      </Box>
                    </Typography>
                  </>
                </ConfirmDialog>

                <ConfirmDialog
                  open={placeBidOpen}
                  onClose={() => {
                    setDisableUI(false)
                    setPlaceBidOpen(false)
                  }}
                  onConfirm={() => {
                    setOpenLoader(true)
                    if (isBkFFPortfolio) onConfirmFlorwardFlow()
                    else onPlaceBid()
                  }}
                  confirmName="Accept"
                  closeName="Cancel"
                  title="Confirm Bid"
                >
                  <>
                    {openLoader && <Loader specificHeight="70%" />}
                    <Typography variant="h1" component="div">
                      <Box ml={8} mb={4}>
                        You are placing{' '}
                        {otBidSelected
                          ? `a one-time bid of ${numberToPercentage(
                              formInfo.values.oneTimeBidPercent / 100,
                              '',
                              3
                            )} (${numberCurrencyDollar(
                              oTBuyerBidSimulateValues?.purchaseProceedsAmount ??
                                0,
                              portfolioCountry
                            )})`
                          : ''}{' '}
                        {otBidSelected && ffBidSelected ? 'and ' : ''}
                        {ffBidSelected
                          ? `a forward-flow bid of ${numberToPercentage(
                              formInfo.values.forwardFlowBidPercent / 100,
                              '',
                              3
                            )}(${numberCurrencyDollar(
                              ffBuyerBidSimulateValues?.purchaseProceedsAmount ??
                                0,
                              portfolioCountry
                            )})`
                          : ''}{' '}
                      </Box>
                      <Box ml={8} mb={4}>
                        Please review and accept the terms below before
                        submitting your bid
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="div">
                      <Box ml={8} mb={4}>
                        I, {userName}, am submitting
                        {otBidSelected
                          ? ` a one-time bid of ${numberToPercentage(
                              formInfo.values.oneTimeBidPercent / 100,
                              '',
                              3
                            )}`
                          : ''}{' '}
                        {otBidSelected && ffBidSelected ? 'and ' : ''}
                        {ffBidSelected
                          ? `a forward-flow bid of ${numberToPercentage(
                              formInfo.values.forwardFlowBidPercent / 100,
                              '',
                              3
                            )}`
                          : ''}{' '}
                        on behalf of {selectedBusiness?.name} on Portfolio{' '}
                        {portfolioNumber} (hereinafter “I,”, “my”, “Buyer”). In
                        submitting this bid, I understand and agree:
                      </Box>
                      <BidDialogText ml={15} mb={4}>
                        1. The submitted bid does not include EverChain’s sales
                        commission. However, as compensation for the services
                        performed by EverChain, {selectedBusiness?.name} shall
                        pay EverChain a sales commission of{' '}
                        {numberToPercentage(
                          otBidSelected
                            ? oTBuyerBidSimulateValues?.salesCommissionPercent
                            : oTBuyerBidSimulateValues?.salesCommissionPercent
                        )}
                        .
                      </BidDialogText>
                      <BidDialogText ml={15} mb={4}>
                        2. Buyer understands that all other terms and conditions
                        within the Buyer Services Agreement executed by and
                        between Buyer and EverChain apply to this submitted bid
                        and any subsequent transaction arising from this
                        submitted bid.
                      </BidDialogText>
                      <BidDialogText ml={15} mb={4}>
                        3. I acknowledge that all contingencies to this bid have
                        been entered in the bid contingencies section.
                      </BidDialogText>
                      <BidDialogText ml={15} mb={4}>
                        4. I intend to close this portfolio purchase on{' '}
                        {otBidSelected
                          ? `${formatDate(
                              formInfo.values.oneTimeBidCloseDate
                            )} (One-Time Bid)`
                          : ''}{' '}
                        {otBidSelected && ffBidSelected ? 'or ' : ''}
                        {ffBidSelected
                          ? `${formatDate(
                              formInfo.values.forwardFlowBidCloseDate
                            )} (Forward-Flow Bid)`
                          : ''}{' '}
                        and fund this portfolio on
                        {otBidSelected
                          ? ` ${formatDate(
                              formInfo.values.oneTimeBidFundingDate
                            )} (One-Time Bid)`
                          : ''}
                        {otBidSelected && ffBidSelected ? ' or ' : ''}
                        {ffBidSelected
                          ? ` ${formatDate(
                              formInfo.values.forwardFlowBidFundingDate
                            )} (Forward-Flow Bid)`
                          : ''}
                        . I understand that these dates will be the date
                        memorialized in the Purchase and Sale Agreement.
                      </BidDialogText>
                    </Typography>
                  </>
                </ConfirmDialog>

                <Box p={4} hidden={portfolioTemplateLoading || !otBidSelected}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    One-Time Bid
                  </Typography>
                  <Grid container spacing={4} alignItems="end">
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <Box display="flex" flexDirection="row">
                        <TextField
                          fullWidth
                          id="oneTimeBidPercent"
                          placeholder="Bid Percent"
                          name="oneTimeBidPercent"
                          value={displayOneTimeBidPercent}
                          error={!!formInfo.errors.oneTimeBidPercent}
                          helperText={<>{formInfo.errors.oneTimeBidPercent}</>}
                          disabled={disableUI || isBkFFPortfolio}
                          onChange={(event) => {
                            formInfo.handleChange(event)
                            setDisplayOneTimeBidPercent(event.target?.value)
                          }}
                          onBlur={() =>
                            setBuyerBidSimulateValuesRequest({
                              calculateOneTime: true,
                              oneTimeBidPercent:
                                formInfo.values.oneTimeBidPercent,
                              calculateForwardFlow: false,
                            })
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {!hideBidCalculator && (
                          <Box mt={1}>
                            <Tooltip title="Bid Percent Calculator">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={handleOneTimeBidCalculatorOpen}
                                disabled={disableUI}
                              >
                                <Icon name="Dialpad" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <DatePicker
                        id="oneTimeBidCloseDate"
                        label={
                          <Typography>
                            Close Date{' '}
                            <Tooltip title="This is the date by which both parties (Buyer and Seller) will have executed the Purchase and Sale Agreement.">
                              <Icon name="HelpOutline" fontSize="inherit" />
                            </Tooltip>
                          </Typography>
                        }
                        name="oneTimeBidCloseDate"
                        shouldDisableDate={(day: Date) => {
                          return disableDates(
                            new Date(day),
                            'oneTimeBidCloseDate'
                          )
                        }}
                        country={profileClient?.Country}
                        onChange={
                          (date: Date | null) => {
                            handleCloseDateChange('oneTimeBidCloseDate', date)
                            if (buttonIndex === TAB_INDEX_OTFF) {
                              handleCloseDateChange(
                                'forwardFlowBidCloseDate',
                                date
                              )
                            }
                          }
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        value={formInfo.values.oneTimeBidCloseDate}
                        errorMessage={formInfo.errors.oneTimeBidCloseDate?.toString()}
                        disabled={disableUI}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <DatePicker
                        id="oneTimeBidFundingDate"
                        label={
                          <Typography>
                            Funding Date{' '}
                            <Tooltip title="This is the date by which the Seller will have received purchase proceeds from the Buyer.">
                              <Icon name="HelpOutline" fontSize="inherit" />
                            </Tooltip>
                          </Typography>
                        }
                        name="oneTimeBidFundingDate"
                        shouldDisableDate={(day: Date) => {
                          return disableDates(
                            new Date(day),
                            'oneTimeBidFundingDate'
                          )
                        }}
                        country={profileClient?.Country}
                        onChange={(val: Date | null) => {
                          formInfo.setFieldValue('oneTimeBidFundingDate', val)
                          if (buttonIndex === TAB_INDEX_OTFF) {
                            formInfo.setFieldValue(
                              'forwardFlowBidFundingDate',
                              val
                            )
                          }
                          // eslint-disable-next-line react/jsx-curly-newline
                        }}
                        value={formInfo.values.oneTimeBidFundingDate}
                        errorMessage={formInfo.errors.oneTimeBidFundingDate?.toString()}
                        disabled={disableUI}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="oneTimeBidContingency"
                        label="Bid Contingency"
                        name="oneTimeBidContingency"
                        onChange={formInfo.handleChange}
                        value={formInfo.values.oneTimeBidContingency || ''}
                        error={!!formInfo.errors.oneTimeBidContingency}
                        helperText={
                          <>{formInfo.errors.oneTimeBidContingency}</>
                        }
                        fullWidth
                        disabled={disableUI}
                      />
                    </Grid>
                  </Grid>
                  {portfolioTypeId !== 2 && (
                    <BuyerPlaceBidSummary
                      buyerBidSimulateValues={oTBuyerBidSimulateValues}
                      portfolioCountry={portfolioCountry}
                      isLoading={
                        buyerBidSimulateValuesRequest.calculateOneTime &&
                        loadingBuyerBidSimulateValues
                      }
                    />
                  )}

                  {portfolioTypeId === 2 && (
                    <BuyerPlaceBidBkSummary
                      buyerBidSimulateValues={oTBuyerBidSimulateValues}
                      portfolioCountry={portfolioCountry}
                      isLoading={
                        buyerBidSimulateValuesRequest.calculateOneTime &&
                        loadingBuyerBidSimulateValues
                      }
                    />
                  )}
                </Box>

                <Box p={4} hidden={portfolioTemplateLoading || !ffBidSelected}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    {isBkFFPortfolio
                      ? 'Forward Flow Summary'
                      : 'Forward Flow Bid'}
                  </Typography>
                  <Grid container spacing={4} alignItems="end">
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <Box display="flex" flexDirection="row">
                        <TextField
                          fullWidth
                          id="forwardFlowBidPercent"
                          placeholder="Bid Percent"
                          name="forwardFlowBidPercent"
                          value={displayFFBidPercent}
                          error={!!formInfo.errors.forwardFlowBidPercent}
                          helperText={
                            <>{formInfo.errors.forwardFlowBidPercent}</>
                          }
                          disabled={disableUI || isBkFFPortfolio}
                          onChange={(event) => {
                            formInfo.handleChange(event)
                            setDisplayFFBidPercent(event.target?.value)
                          }}
                          onBlur={() =>
                            setBuyerBidSimulateValuesRequest({
                              calculateOneTime: false,
                              forwardFlowBidPercent:
                                formInfo.values.forwardFlowBidPercent,
                              calculateForwardFlow: true,
                            })
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {!hideBidCalculator && (
                          <Box mt={1}>
                            <Tooltip title="Bid Percent Calculator">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={handleForwardFlowBidCalculatorOpen}
                                disabled={disableUI || hideBidCalculator}
                              >
                                <Icon name="Dialpad" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <DatePicker
                        id="forwardFlowBidCloseDate"
                        label={
                          <Typography>
                            Close Date{' '}
                            <Tooltip title="This is the date by which both parties (Buyer and Seller) will have executed the Purchase and Sale Agreement.">
                              <Icon name="HelpOutline" fontSize="inherit" />
                            </Tooltip>
                          </Typography>
                        }
                        name="forwardFlowBidCloseDate"
                        shouldDisableDate={(day: Date) => {
                          return disableDates(
                            new Date(day),
                            'forwardFlowBidCloseDate'
                          )
                        }}
                        country={profileClient?.Country}
                        onChange={
                          (date: Date | null) => {
                            handleCloseDateChange(
                              'forwardFlowBidCloseDate',
                              date
                            )
                            if (buttonIndex === TAB_INDEX_OTFF) {
                              handleCloseDateChange('oneTimeBidCloseDate', date)
                            }
                          }
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        value={formInfo.values.forwardFlowBidCloseDate}
                        errorMessage={formInfo.errors.forwardFlowBidCloseDate?.toString()}
                        disabled={disableUI}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <DatePicker
                        id="forwardFlowBidFundingDate"
                        label={
                          <Typography>
                            Funding Date{' '}
                            <Tooltip title="This is the date by which the Seller will have received purchase proceeds from the Buyer.">
                              <Icon name="HelpOutline" fontSize="inherit" />
                            </Tooltip>
                          </Typography>
                        }
                        name="forwardFlowBidFundingDate"
                        shouldDisableDate={(day: Date) => {
                          return disableDates(
                            new Date(day),
                            'forwardFlowBidFundingDate'
                          )
                        }}
                        country={profileClient?.Country}
                        onChange={(val: Date | null) => {
                          formInfo.setFieldValue(
                            'forwardFlowBidFundingDate',
                            val
                          )
                          if (buttonIndex === TAB_INDEX_OTFF) {
                            formInfo.setFieldValue('oneTimeBidFundingDate', val)
                          }
                          // eslint-disable-next-line react/jsx-curly-newline
                        }}
                        value={formInfo.values.forwardFlowBidFundingDate}
                        errorMessage={formInfo.errors.forwardFlowBidFundingDate?.toString()}
                        disabled={disableUI}
                      />
                    </Grid>
                    {!forwardFlowAgreementId && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="forwardFlowBidContingency"
                            label="Bid Contingency"
                            name="forwardFlowBidContingency"
                            onChange={formInfo.handleChange}
                            value={
                              formInfo.values.forwardFlowBidContingency || ''
                            }
                            error={!!formInfo.errors.forwardFlowBidContingency}
                            helperText={
                              <>{formInfo.errors.forwardFlowBidContingency}</>
                            }
                            fullWidth
                            disabled={disableUI}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4}>
                          <TextField
                            fullWidth
                            id="forwardFlowBidMonths"
                            label={termLabel}
                            name="forwardFlowBidMonths"
                            type="number"
                            onChange={formInfo.handleChange}
                            value={formInfo.values.forwardFlowBidMonths || null}
                            error={!!formInfo.errors.forwardFlowBidMonths}
                            helperText={
                              <>{formInfo.errors.forwardFlowBidMonths}</>
                            }
                            disabled={disableUI}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4}>
                          <TextField
                            fullWidth
                            id="forwardFlowMaxFaceValue"
                            label={maxFaceValue}
                            name="forwardFlowMaxFaceValue"
                            onChange={formInfo.handleChange}
                            onBlur={(e) =>
                              formInfo.setFieldValue(
                                'forwardFlowMaxFaceValue',
                                e.target.value
                                  ? parseFloat(e.target.value ?? 0).toFixed(2)
                                  : undefined
                              )
                            }
                            value={formInfo.values.forwardFlowMaxFaceValue}
                            error={!!formInfo.errors.forwardFlowMaxFaceValue}
                            helperText={
                              <>{formInfo.errors.forwardFlowMaxFaceValue}</>
                            }
                            disabled={disableUI}
                            inputProps={{
                              min: 0,
                              step: '.01',
                              type: 'number',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {getCurrencySymbol()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {portfolioTypeId !== 2 && (
                    <BuyerPlaceBidSummary
                      buyerBidSimulateValues={ffBuyerBidSimulateValues}
                      portfolioCountry={portfolioCountry}
                      isLoading={
                        buyerBidSimulateValuesRequest.calculateForwardFlow &&
                        loadingBuyerBidSimulateValues
                      }
                    />
                  )}

                  {portfolioTypeId === 2 && (
                    <BuyerPlaceBidBkSummary
                      buyerBidSimulateValues={ffBuyerBidSimulateValues}
                      portfolioCountry={portfolioCountry}
                      isLoading={
                        buyerBidSimulateValuesRequest.calculateForwardFlow &&
                        loadingBuyerBidSimulateValues
                      }
                    />
                  )}
                </Box>

                <Group>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formInfo.isValid || disableUI}
                    onClick={handlePreApprovedPlaceBid}
                    style={{ display: hasBid ? 'none' : undefined }}
                  >
                    {submitButtonLabel()}
                  </Button>
                  {showDisclosure && (
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={() => onOpenDisclosureModal(true)}
                    >
                      Disclosure
                    </Button>
                  )}
                </Group>
              </Box>
              {!loadingDisclosure &&
                disclosureData &&
                disclosureData.isDisclosureEnabled &&
                !disclosureData.isDisclosureOnBidAccepted && (
                  <ConfirmDialog
                    open={openDisclosure}
                    title="Disclosure"
                    description={
                      <Box style={{ fontSize: '1rem' }}>
                        {disclosureData.disclosureText}
                      </Box>
                    }
                    loading={loadingDisclosure}
                    closeName="Cancel"
                    confirmName="Accept"
                    onClose={handleCloseDisclosure}
                    onConfirm={handleAcceptDisclosure}
                    disableButtons={disableUI}
                  />
                )}
              <ModalDialog
                header="Confirm Pre-Approved Buyer Bid"
                onClose={() => setOpenPreApproved(false)}
                isOpen={openPreApproved}
                width="25%"
                buttonOkText="Confirm"
                buttonCancelText="Cancel"
                onContinue={handleConfirmPreApprovedPlaceBid}
              >
                <Box style={{ fontSize: '1rem' }}>
                  I acknowledge that by placing this bid, I am committing to
                  completing the EverChain Buyer Certification program and
                  understand that my bid may not be considered by the Seller if
                  the Certification for my Company is not completed at the time
                  the bid window closes.
                </Box>
              </ModalDialog>
            </Grid>
          )}
        </Grid>
      </form>
    </DTAccordion>
  )
}

export default BuyerPlaceBid

BuyerPlaceBid.defaultProps = {
  forwardFlowAgreementId: undefined,
}
