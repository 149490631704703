import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Skeleton,
} from '@mui/material'
import { PortfolioPurchaseInformation } from 'src/graphql/models/Portfolio'
import { useConfirmSellerFee } from 'src/graphql/operations/mutations/portfolio'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { formatDate } from 'src/utils/date'
import { PortfolioStatusType, PermissionCodeAccess } from 'src/utils/constants'
import { Can, AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import {
  DatePicker,
  Icon,
  textPrimary,
  textSecondary,
} from 'everchain-uilibrary'
import moment from 'moment'
import { TableCellFundingCustom, PoundCircleFilledStyled } from '../styles'
import BillOfSale from './BillOfSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioPurchaseInfo } from 'src/data/features/get/portfolio/portfolio'

interface ConfirmSellerFeeProps {
  portfolioId: string | undefined
  buyerName: string | undefined
  sellerName: string | undefined
  hasBOS: boolean
  hasFinalBOS: boolean
  hasSellerFeeConfirmed: boolean
  portfolioHasBOSReleased: boolean
  portfolioHasBOSConfirmed: boolean
  portfolioNumber: string | undefined
  portfolioCountry: string | undefined
  portfolioStatus: string | undefined
  eSignatureEnabledBOS: boolean
  isBosESignatureSent: boolean
  bosSignerName: string | undefined
  bosSignerTitle: string | undefined
  bosSignerEmail: string | undefined
  sellerUploadTemplateId: number | undefined
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const ConfirmSellerFee: React.FC<ConfirmSellerFeeProps> = ({
  portfolioId,
  buyerName,
  sellerName,
  hasBOS,
  hasFinalBOS,
  portfolioHasBOSReleased,
  portfolioHasBOSConfirmed,
  hasSellerFeeConfirmed,
  portfolioNumber,
  portfolioCountry,
  portfolioStatus,
  eSignatureEnabledBOS,
  isBosESignatureSent,
  bosSignerName,
  bosSignerTitle,
  bosSignerEmail,
  sellerUploadTemplateId,
}: ConfirmSellerFeeProps) => {
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()
  const [disableConfirmationButton, setDisableConfirmationButton] = useState(
    hasSellerFeeConfirmed
  )
  const [showSellerFeeMessage, setShowSellerFeeMessage] = useState(
    hasSellerFeeConfirmed
  )
  const [fundingDateConfirmation, setFundingDateConfirmation] =
    useState<Date | null>(null)
  const gradientBackgroundColor = '#F2F2F2'

  const { data: purchaseInfo, isFetching: loadingPortfolioPurchaseInfo } =
    useCustomQuery<PortfolioPurchaseInformation>(
      ['getPortfolioPurchaseInfo', portfolioId],
      async () => getPortfolioPurchaseInfo(portfolioId),
      { enabled: portfolioId != null, cacheTime: 0 }
    )

  const { confirmationRequest, loading: confirmRequestLoading } =
    useConfirmSellerFee({
      onCompleted: (confirmationResponse: string) => {
        if (confirmationResponse) {
          enqueueSnackbar('Service fee has been confirmed', notifySuccess)
          setShowSellerFeeMessage(true)
        } else {
          setDisableConfirmationButton(false)
          setShowSellerFeeMessage(false)
          enqueueSnackbar('Error', notifyError)
        }
      },
    })
  const [openConfirmSellerFee, setOpenConfirmSellerFee] = useState(false)
  const handleClose = () => {
    setOpenConfirmSellerFee(false)
  }

  const handleConfirmSellerFee = () => {
    setDisableConfirmationButton(true)
    if (!moment(fundingDateConfirmation).isValid()) {
    } else {
      confirmationRequest({
        variables: {
          confirmSellerFeeRequest: {
            portfolioId,
            fundingDateConfirmation,
          },
        },
      })
      setOpenConfirmSellerFee(false)
    }
  }

  const getDueDateInfo = (numberOfDays: number | null) => {
    if (numberOfDays === null) return ''
    if (numberOfDays === 0) return '(Due Today)'
    if (numberOfDays < 0) return `(${numberOfDays} days to due date)`
    if (numberOfDays > 0) {
      const message = `(+${numberOfDays} days past due date)`
      return <span style={{ color: '#FF0000' }}>{message}</span>
    }
    return ''
  }

  const { userPermissions, profileClient } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'

  const showBOSComponent =
    (portfolioStatus === PortfolioStatusType.AwaitingSellerFee ||
      portfolioStatus === PortfolioStatusType.Funded ||
      portfolioStatus === PortfolioStatusType.PendingFinalConfirmation) &&
    !portfolioHasBOSConfirmed &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Workflows_CompleteBOSWorkflow,
      'any'
    )

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <>
      {!loadingPortfolioPurchaseInfo ? (
        purchaseInfo && (
          <DTAccordion
            id="seller-fee-accordion"
            title="Service Fee Confirmation"
            icon={
              isPortfolioUk ? (
                <PoundCircleFilledStyled />
              ) : (
                <Icon name="MonetizationOn" />
              )
            }
            expanded={true}
          >
            <Grid container>
              {showBOSComponent && (
                <Grid item xs={12}>
                  <BillOfSale
                    portfolioId={portfolioId}
                    portfolioHasBOS={hasBOS}
                    portfolioHasFinalBOS={hasFinalBOS}
                    portfolioHasBOSReleased={portfolioHasBOSReleased}
                    portfolioHasBOSConfirmed={portfolioHasBOSConfirmed}
                    isPortfolioSeller={false}
                    eSignatureEnabledBOS={Boolean(eSignatureEnabledBOS)}
                    isBosESignatureSent={Boolean(isBosESignatureSent)}
                    bosSignerName={bosSignerName}
                    bosSignerTitle={bosSignerTitle}
                    bosSignerEmail={bosSignerEmail}
                    sellerUploadTemplateId={sellerUploadTemplateId}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" id="tableTitle" component="div">
                  Purchase Info
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom
                          style={{ color: '#002e3a' }}
                          component="th"
                          scope="row"
                        >
                          Face Value
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: '#002e3a' }}
                          align="right"
                        >
                          {purchaseInfo.faceValue}
                        </TableCellFundingCustom>
                      </TableRow>
                      {purchaseInfo.portfolioCloseUtc && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Buyer Close Date
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {formatDate(purchaseInfo.portfolioCloseUtc)}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      {purchaseInfo.sellerDueDate && (
                        <TableRow
                          style={{
                            backgroundColor: purchaseInfo.portfolioCloseUtc
                              ? '#FFF'
                              : gradientBackgroundColor,
                          }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Seller Due Date
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {formatDate(purchaseInfo.sellerDueDate)}{' '}
                            {getDueDateInfo(purchaseInfo.daysToSellerDueDate)}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Typography variant="h6" id="tableTitle" component="div">
                  Buyer Funding
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Buyer
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {buyerName}
                        </TableCellFundingCustom>
                      </TableRow>
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom component="th" scope="row">
                          Purchase Price ({purchaseInfo.purchasePricePercent})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {purchaseInfo.purchasePriceAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                      {purchaseInfo.isBk && (
                        <TableRow>
                          <TableCellFundingCustom component="th" scope="row">
                            Total TOC ({purchaseInfo.totalPocAccounts} x{' '}
                            {purchaseInfo.pocValue})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ color: 'red' }}
                            align="right"
                          >
                            - {purchaseInfo.totalTocAmount}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      {purchaseInfo.isBk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Final Purchase Price (
                            {purchaseInfo.finalPurchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseInfo.finalPurchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Sales Commission ({purchaseInfo.buyerFeeDisplay})
                          <Tooltip
                            title="
                  As compensation for the services
                  performed by EverChain, Buyer shall pay
                  EverChain a sales commission. This
                  sales commission is a percentage of the
                  total sale price for each executed sale
                  of a debt portfolio made with a Seller
                  via EverChain as displayed on the
                  portfolio summary page within the
                  EverChain platform"
                          >
                            <Icon
                              name="HelpOutline"
                              fontSize="inherit"
                              style={{ paddingLeft: '2px' }}
                            />
                          </Tooltip>
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          + {purchaseInfo.buyerFeeAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                      {purchaseInfo.buyerPurchaseAmountDisplay && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            component="th"
                            scope="row"
                          >
                            <strong> Total Purchase Amount</strong>
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            align="right"
                          >
                            <strong>
                              {purchaseInfo.buyerPurchaseAmountDisplay}
                            </strong>
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Typography variant="h6" id="tableTitle" component="div">
                  Seller Funding
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Seller
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {sellerName}
                        </TableCellFundingCustom>
                      </TableRow>
                      {!purchaseInfo.isBk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Purchase Price ({purchaseInfo.purchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseInfo.purchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      {purchaseInfo.isBk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Final Purchase Price (
                            {purchaseInfo.finalPurchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseInfo.finalPurchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Service Fee To EverChain (
                          {purchaseInfo.sellerFeeDisplay})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: 'red' }}
                          align="right"
                        >
                          - {purchaseInfo.sellerFeeAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                      {isPortfolioUk && (
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            VAT Fee ({purchaseInfo.sellerVATFeePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ color: 'red' }}
                            align="right"
                          >
                            - {purchaseInfo.sellerVATFeeDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                      {purchaseInfo.buyerPurchaseAmountDisplay && (
                        <TableRow>
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            component="th"
                            scope="row"
                          >
                            <strong>
                              {isSeller
                                ? 'Net Proceeds To You'
                                : 'Net To Seller'}{' '}
                              ({purchaseInfo.netToSellerPercentDisplay})
                            </strong>
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            align="right"
                          >
                            <strong> {purchaseInfo.netToSellerDisplay}</strong>
                          </TableCellFundingCustom>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />

                <Box display="flex" flexDirection="row" alignItems="center">
                  <Can
                    do={
                      PermissionCodeAccess.MarketPlace_Basic_ConfirmPortfolioFunding
                    }
                    on="any"
                  >
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={disableConfirmationButton}
                        onClick={(): void => setOpenConfirmSellerFee(true)}
                      >
                        Confirm Service Fee Payment
                      </Button>
                    </Box>
                    <Dialog
                      open={openConfirmSellerFee}
                      title="Confirm Service Fee"
                      onClose={handleClose}
                    >
                      <DialogTitle id="form-filter-title">
                        <Typography
                          variant="h1"
                          style={{ textAlign: 'left', color: 'white' }}
                        >
                          Confirm Service Fee
                        </Typography>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                          }}
                        >
                          <Icon name="Close" />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <Box>
                          <Typography
                            variant="body1"
                            color={textSecondary.color}
                          >
                            {` Do you want to confirm service fee for portfolio ${portfolioNumber} on the amount of ${purchaseInfo?.sellerFeeAmountDisplay}?`}
                          </Typography>
                        </Box>
                        <Box pt={2} display="flex" flexDirection="row">
                          <Box pt={2} pr={4}>
                            <Typography
                              variant="body1"
                              color={textSecondary.color}
                            >
                              Funding Date:
                            </Typography>
                          </Box>
                          <Box>
                            <DatePicker
                              id="toDate"
                              name="toDate"
                              country={profileClient?.Country}
                              value={fundingDateConfirmation}
                              onChange={(date: any) => {
                                if (date) {
                                  date.setHours(0, 0, 0, 0)
                                  setFundingDateConfirmation(date)
                                }
                              }}
                              disabled={confirmRequestLoading}
                              disableFuture
                              style={{ padding: '5px 0 5px 0' }}
                            />
                          </Box>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          No
                        </Button>
                        <Button
                          onClick={handleConfirmSellerFee}
                          disabled={!fundingDateConfirmation}
                          color="primary"
                          variant="contained"
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Can>
                  {showSellerFeeMessage && (
                    <Box pl={4}>
                      <Typography variant="body2" color={textPrimary.color}>
                        The service fee has been confirmed. Pending BOS
                        confirmation for moving portfolio to Funded.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DTAccordion>
        )
      ) : (
        <Skeleton variant="rectangular" width="100%" height={50} />
      )}
    </>
  )
}

export default ConfirmSellerFee
