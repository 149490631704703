import { BASE_ROUTE } from 'src/routes'
import { formatDate } from './date'
import { numberCurrencyDollar, numberToPercentage } from './numbers'
import { convertToTrueOrFalse } from './text'
import { Permissions } from 'src/infra/api/models/permissions'
import { authUrl, clientId, rmsClientId } from 'src/configs/authConst'
import { enqueueSnackbar } from 'notistack'
import { FileParseResult } from 'everchain-uilibrary'
import { notistackOptions } from 'src/configs/notistackOptions'

interface Options {
  min: number
  max: number
  opacity: number
}

export interface IPagination {
  pageNumber: number
  pageSize: number
}

const notifyError = notistackOptions('error')

export const dynamicColors = (options: Options): string => {
  const newOptions = {
    ...options,
  }
  const { min, max, opacity } = newOptions
  const r = Math.floor(Math.random() * (max - min + 1)) + min
  const g = Math.floor(Math.random() * (max - min + 1)) + min
  const b = Math.floor(Math.random() * (max - min + 1)) + min
  return `rgba(${r},${g},${b}, ${opacity})`
}

export const isUkCountry = (country: string | undefined) => {
  return country !== undefined ? country.toUpperCase() === 'UK' : false
}

export const filesAccepted =
  'text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip'

export const getCountryCurrency = (country: string | undefined): string => {
  let currency = ''

  switch (country) {
    case 'CA':
      currency = 'CAD'
      break
    case 'US':
      currency = '$'
      break
    case 'UK':
      currency = '£'
      break
    default:
      currency = '$'
  }
  return currency
}

export const getCustomErrorMessage = (message: string) => {
  const customMessage = message.replace('GraphQL.ExecutionError: ', '')
  return customMessage
}

export const formatDataToExport = (
  entryData: any,
  columnArray: any[],
  profileClient: any
) => {
  const dataToExport: any[] = []

  entryData?.forEach((item: any[]) => {
    const row: any = {}
    columnArray.forEach((g: any) => {
      if (g.show) {
        row[g.title] = g.render
          ? g.render.name === 'renderDate'
            ? formatDate(item[g.field])
            : g.render.name === 'renderTrueOrFalse'
            ? convertToTrueOrFalse(item[g.field])
            : g.render.name === 'renderCurrency'
            ? numberCurrencyDollar(
                item[g.field],
                isUkCountry(
                  profileClient?.Country || process.env.REACT_APP_COUNTRY
                )
                  ? 'UK'
                  : 'US'
              )
            : g.render.name === 'renderPercent'
            ? numberToPercentage(item[g.field])
            : item[g.field]
          : item[g.field]
      }
    })

    dataToExport.push(row)
  })

  return dataToExport
}

export interface CommomProps {
  /** An optional prop that allows you to assign a unique identifier to the component. This can be useful for styling or targeting specific elements. */
  id?: string
  /** An optional prop that allows you to add one or more CSS classes to the component. This enables you to apply custom styling or select the component using CSS selectors. */
  className?: string
  /** An optional prop that allows you to pass a ref to the component. Refs provide a way to access the underlying DOM element or React component instance. */
  ref?: any
  /** An optional prop that allows you to apply inline CSS styles to the component. This can be used to customize the appearance or layout of the component. */
  style?: React.CSSProperties
  /** A boolean flag that indicates the loading state of the component. When set to true, it indicates that the component is in a loading state and is currently fetching or processing data. This is commonly used to display a loading indicator or placeholder content while waiting for the actual data to be loaded. */
  isLoading?: boolean
  /** Specifies the width of the skeleton element used for placeholder content. It can be defined as a string (e.g., "100px") or a number representing the width value. */
  skeletonWidth?: string | number
  /** Specifies the height of the skeleton element used for placeholder content. It can be defined as a string (e.g., "50%") or a number representing the height value. */
  skeletonHeight?: string | number
  variant?: string
  color?: string
}

export enum AzureContainers {
  Portfolios = 1,
  AccountCard = 2,
}

export const isRMSEnabled = (): boolean => {
  return process.env.REACT_APP_RMS_ENABLED === 'Y'
}

export const getStandardUri = (uri: string): string => {
  if (uri) {
    const baseRoute = isRMSEnabled() ? BASE_ROUTE : ''
    return `${baseRoute}${uri}`
  }

  return uri
}

export const isMarketplaceRoute = (pathName: string) => {
  return pathName.includes('/marketplace/')
}

interface PermissionReduce {
  [field: string]: boolean
}

export const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce => {
  return permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )
}

export const getLocalStorageUser = (): string | null => {
  const standardClientId = isRMSEnabled() ? rmsClientId : clientId

  return window.localStorage.getItem(
    `dt.user:${authUrl}:${standardClientId}` || ''
  )
}

export const checkAndNotifyIfMissing = (
  parsedFile: FileParseResult,
  property: string
) => {
  if (parsedFile.data.find((x) => !x[property])) {
    enqueueSnackbar(`There are rows without ${property}.`, notifyError)
    return true
  }
  return false
}
