import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { ModalHeader } from '../styles'
import { Icon } from 'everchain-uilibrary'

interface BidContingencyButtonProps {
  contingencyText: string
}

const BidContingencyButton: React.FC<BidContingencyButtonProps> = ({
  contingencyText,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  if (contingencyText) {
    return (
      <>
        <Tooltip title="Bid Contingency">
          <Box ml={2}>
            <IconButton
              size="small"
              onClick={(event) => {
                handleOpen()
                event.stopPropagation()
              }}
            >
              <Icon name="HelpOutline" color="orange" />
            </IconButton>
          </Box>
        </Tooltip>
        <Dialog
          open={showModal}
          title="Buyer Contingency"
          fullWidth
          maxWidth="xs"
        >
          <ModalHeader
            id="modalHeader"
            py={3}
            px={5}
            m={0}
            bgcolor="green"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="white"
          >
            <Typography
              id="modalHeaderTitle"
              style={{ color: 'white' }}
              variant="h5"
            >
              Bid Contingency
            </Typography>
            <IconButton
              id="modalCloseButton"
              size="small"
              onClick={(event) => {
                handleClose()
                event.stopPropagation()
              }}
            >
              <Icon name="Close" color="white" />
            </IconButton>
          </ModalHeader>
          <Box py={3} px={5} m={0}>
            <Typography variant="body2" style={{ paddingTop: '10px' }}>
              <b>This bid has a contingency:</b>
            </Typography>
            <Typography
              variant="body2"
              style={{ paddingTop: '7px', paddingBottom: '10px' }}
            >
              {contingencyText}
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Button
              onClick={(event) => {
                handleClose()
                event.stopPropagation()
              }}
              fullWidth
            >
              OK
            </Button>
          </Box>
        </Dialog>
      </>
    )
  }

  return <Box component="div" display="inline" width="38px" />
}

export default BidContingencyButton
