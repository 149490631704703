import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import { ConfirmDialog } from 'src/components/Dialogs'
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Link as LinkMaterial,
} from '@mui/material'
import { PortfolioStatusType } from 'src/utils/constants'
import DTAccordion from 'src/components/Accordion'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { SubmitScrubPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { useMutation } from '@tanstack/react-query'

interface SubmitScrubProps {
  portfolio: PortfolioInfoDetail | undefined
  openAccountErrorWarning: (type: string) => void
}

const SubmitScrub: React.FC<SubmitScrubProps> = ({
  portfolio,
  openAccountErrorWarning,
}: SubmitScrubProps) => {
  const [requestSubmitted, setRequestSubmitted] = useState(false)
  const [reviewAccountsErrors, setReviewAccountsErrors] = useState(false)
  const [removeAccounts, setRemoveAccounts] = useState(false)
  const [openRequestScrub, setOpenRequestScrub] = useState(false)
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()
  const [loadingRequestScrub, setLoadingRequestScrub] = useState(false)

  const handleClose = () => {
    setOpenRequestScrub(false)
  }

  const requestScrubPortfolio = useMutation({
    mutationFn: (variables: any) => {
      setLoadingRequestScrub(true)
      return SubmitScrubPortfolio(variables)
    },
    onSuccess: (dataScrub: any) => {
      setLoadingRequestScrub(false)
      if (dataScrub) {
        handleClose()
        enqueueSnackbar(
          'Portfolio has been submitted for scrubbing',
          notifySuccess
        )
        setRequestSubmitted(true)
      }
    },
    onError: (error: any) => {
      setLoadingRequestScrub(false)
      enqueueSnackbar(error?.code || 'Unexpected error', notifyError)
    },
  })

  const handleConfirmation = () => {
    setOpenRequestScrub(true)
  }
  const renderRequireComponent = () => {
    if (!reviewAccountsErrors || !removeAccounts) {
      return (
        <Grid item style={{ marginTop: '5px' }}>
          <Typography data-cy="request-checkbox-checked" variant="body2">
            All fields must be checked
          </Typography>
        </Grid>
      )
    }
    return null
  }
  const handleRequestScrub = () => {
    if (portfolio?.id) {
      requestScrubPortfolio.mutate({
        scrubRequest: {
          portfolioId: portfolio.id,
          scrubIndex:
            portfolio?.status.toLowerCase() ===
            PortfolioStatusType.ReadyForReview
              ? 0
              : 1,
        },
        refetchQueries: ['GetAllPortfolioSteps'],
      })
    }
  }

  return (
    <>
      <DTAccordion
        id="submit-scrub-accordion"
        title="Release for Scrub"
        icon={<Icon name="LibraryAddCheck" />}
        expanded={true}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
          >
            <FormControl>
              <Checkbox
                data-cy="checkbox-review-accounts-for-errors-and-warnings"
                checked={reviewAccountsErrors}
                onChange={() => {
                  setReviewAccountsErrors(!reviewAccountsErrors)
                }}
              />
            </FormControl>

            <Typography data-cy="text-review-accounts" variant="body2">
              Review accounts for
            </Typography>
            <LinkMaterial
              data-cy="link-text-review-accounts-for-error"
              style={{ cursor: 'pointer', marginRight: 3, marginLeft: 3 }}
              onClick={() => {
                openAccountErrorWarning('errors')
              }}
            >
              errors
            </LinkMaterial>
            <Typography data-cy="text-review-accounts-for" variant="body2">
              and
            </Typography>
            <LinkMaterial
              data-cy="link-text-review-accounts-for-warning"
              style={{ cursor: 'pointer', marginLeft: 3 }}
              onClick={() => {
                openAccountErrorWarning('warnings')
              }}
            >
              warnings
            </LinkMaterial>
          </Box>
          <Box
            mt={-3}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
          >
            <FormControl>
              <Checkbox
                data-cy="checkbox-remove-accounts-as-needed"
                checked={removeAccounts}
                onChange={() => setRemoveAccounts(!removeAccounts)}
              />
            </FormControl>

            <Typography
              data-cy="text-remove-accounts-as-needed"
              variant="body2"
            >
              Remove accounts as needed
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  data-cy="request-scrub-button"
                  onClick={handleConfirmation}
                  variant="outlined"
                  disabled={
                    loadingRequestScrub ||
                    requestSubmitted ||
                    !reviewAccountsErrors ||
                    !removeAccounts
                  }
                  color="primary"
                >
                  Request Scrub
                </Button>
              </Grid>
              {renderRequireComponent()}
            </Grid>
          </Box>
        </Box>
      </DTAccordion>
      <ConfirmDialog
        open={openRequestScrub}
        title="Request Scrub"
        description={
          <>
            <Box mb={3}>
              <Typography variant="body2" color={textSecondary.color}>
                Your portfolio is being submitted for scrubbing. Please click
                continue to proceed.
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" color={textSecondary.color}>
                Should you choose to withdraw this portfolio, you may incur the
                cost associated with the scrub. A confirmation email will be
                sent once the scrub results have been received and the portfolio
                has been updated.
              </Typography>
            </Box>
            <Typography variant="body2" color={textSecondary.color}>
              Thank you.
            </Typography>
          </>
        }
        loading={loadingRequestScrub}
        closeName="Cancel"
        confirmName="Continue"
        onClose={handleClose}
        onConfirm={handleRequestScrub}
      />
    </>
  )
}

export default SubmitScrub
