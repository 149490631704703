/* eslint-disable max-len */
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography,
  Button,
  Grid,
  Skeleton,
} from '@mui/material'
import DTAccordion from 'src/components/Accordion'
import { useSnackbar } from 'notistack'
import React, { useState, useContext } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Can } from 'src/context/Can'
import {
  PortfolioFundingConfirmation,
  PortfolioPurchaseInformation,
} from 'src/graphql/models/Portfolio'
import { PermissionCodeAccess, PortfolioStatusType } from 'src/utils/constants'
import { ConfirmDialog } from 'src/components/Dialogs'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { TableCellFundingCustom, PoundCircleFilledStyled } from '../styles'
import { Icon, textPrimary, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getFundingInformation,
  getPortfolioFundingConfirmation,
  getPortfolioPurchaseInfo,
} from 'src/data/features/get/portfolio/portfolio'
import RemittanceInvoiceEmail from './RemittanceInvoiceEmail'
import { confirmSellerFunding } from 'src/data/features/post/portfolioFunding/portfoliofunding'
import { PortfolioFundingInfo } from 'src/data/features/get/portfolio/types'

interface FundingInformationProps {
  portfolioId: string | undefined
  portfolioStatus: string
  portfolioNumber: string | any
  portfolioCountry: string | undefined
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const gradientBackgroundColor = '#F2F2F2'
const ConfirmSellerFunding: React.FC<FundingInformationProps> = ({
  portfolioId,
  portfolioStatus,
  portfolioNumber,
  portfolioCountry,
}: FundingInformationProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [disableConfirmationButton, setDisableConfirmationButton] =
    useState(false)
  const [isPendingPSA] = useState(
    portfolioStatus === PortfolioStatusType.PendingPSA
  )

  const [openConfirmFunds, setOpenConfirmFunds] = useState(false)
  const handleClose = () => {
    setOpenConfirmFunds(false)
  }

  const handleConfirmFunds = () => {
    setDisableConfirmationButton(true)
    if (portfolioId)
      confirmSellerFunding(portfolioId)
        .then(() => {
          enqueueSnackbar('Funding has been confirmed', notifySuccess)
        })
        .catch(() => {
          setDisableConfirmationButton(false)
          enqueueSnackbar('Error', notifyError)
        })
    setOpenConfirmFunds(false)
  }

  const {
    data: portfolioFundingToDebtTraderInfo,
    isFetching: loadingPortfolioFundingToDebtTraderInfo,
  } = useCustomQuery<PortfolioFundingInfo>(
    ['getFundingInformation', portfolioId, portfolioStatus],
    async () => getFundingInformation(portfolioId, 'FundingToDebttrader'),
    {
      enabled: !!(
        portfolioId &&
        portfolioStatus === PortfolioStatusType.PendingSellerFunding
      ),
      cacheTime: 0,
    }
  )

  const {
    data: fundingConfirmationQueryData,
    isFetching: loadingPortfolioFundingConfirmation,
  } = useCustomQuery<PortfolioFundingConfirmation>(
    ['getPortfolioFundingConfirmation', portfolioId],
    async () => getPortfolioFundingConfirmation(portfolioId),
    {
      enabled: portfolioStatus === PortfolioStatusType.PendingSellerFunding,
      cacheTime: 0,
    }
  )

  const fundingFromBuyer = fundingConfirmationQueryData

  const { data: purchaseData, isFetching: loadingPortfolioPurchaseInfo } =
    useCustomQuery<PortfolioPurchaseInformation>(
      ['getPortfolioPurchaseInfo', portfolioId],
      async () => getPortfolioPurchaseInfo(portfolioId),
      { enabled: portfolioId != null, cacheTime: 0 }
    )

  const { userPermissions } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  if (
    loadingPortfolioPurchaseInfo ||
    loadingPortfolioFundingToDebtTraderInfo ||
    loadingPortfolioFundingConfirmation ||
    !purchaseData
  ) {
    return <Skeleton variant="rectangular" width="100%" height={50} />
  }

  return (
    <>
      <DTAccordion
        id="seller-funding-accordion"
        title="Seller Funding Information"
        icon={
          isPortfolioUk ? (
            <PoundCircleFilledStyled />
          ) : (
            <Icon name="MonetizationOn" />
          )
        }
        expanded={true}
      >
        <Grid container spacing={4}>
          {!isPendingPSA && (
            <Grid item xs={12}>
              <Typography variant="body2" color={textPrimary.color}>
                <strong>Next Step: </strong>Waiting for buyer funding
                confirmation.
              </Typography>
            </Grid>
          )}
          {!!purchaseData && (
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom
                        style={{ color: '#002e3a' }}
                        component="th"
                        scope="row"
                      >
                        Face Value
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ color: '#002e3a' }}
                        align="right"
                      >
                        {purchaseData.faceValue}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Purchase Price ({purchaseData.purchasePricePercent})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {purchaseData.purchasePriceAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                    {purchaseData.isBk && (
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Total TOC ({purchaseData.totalPocAccounts} x{' '}
                          {purchaseData.pocValue})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: 'red' }}
                          align="right"
                        >
                          - {purchaseData.totalTocAmount}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.isBk && (
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom component="th" scope="row">
                          Final Purchase Price (
                          {purchaseData.finalPurchasePricePercent})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {purchaseData.finalPurchasePriceAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.buyerFeeAmountDisplay && (
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Sales Commission
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          + {purchaseData.buyerFeeAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.sellerFeeDisplay && (
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Service Fee To EverChain (
                          {purchaseData.sellerFeeDisplay})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: 'red' }}
                          align="right"
                        >
                          - {purchaseData.sellerFeeAmountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {isPortfolioUk && (
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          VAT Fee ({purchaseData.sellerVATFeePercent})
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: 'red' }}
                          align="right"
                        >
                          - {purchaseData.sellerVATFeeDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.buyerPurchaseAmountDisplay && (
                      <TableRow>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          component="th"
                          scope="row"
                        >
                          <strong> Total Purchase Amount</strong>
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          align="right"
                        >
                          <strong>
                            {purchaseData.buyerPurchaseAmountDisplay}
                          </strong>
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                    {purchaseData.netToSellerDisplay && (
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          component="th"
                          scope="row"
                        >
                          <strong>
                            {isSeller ? 'Net Proceeds To You' : 'Net To Seller'}{' '}
                            ({purchaseData.netToSellerPercentDisplay})
                          </strong>
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ borderTop: '1px solid' }}
                          align="right"
                        >
                          <strong> {purchaseData.netToSellerDisplay}</strong>
                        </TableCellFundingCustom>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {fundingFromBuyer && (
            <>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Date Funded
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {fundingFromBuyer?.dateFunded}
                        </TableCellFundingCustom>
                      </TableRow>
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom component="th" scope="row">
                          Amount
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {fundingFromBuyer?.amountDisplay}
                        </TableCellFundingCustom>
                      </TableRow>
                      <TableRow>
                        <TableCellFundingCustom component="th" scope="row">
                          Wire Transfer #
                        </TableCellFundingCustom>
                        <TableCellFundingCustom align="right">
                          {fundingFromBuyer?.wireNumber}
                        </TableCellFundingCustom>
                      </TableRow>
                      <TableRow
                        style={{ backgroundColor: gradientBackgroundColor }}
                      >
                        <TableCellFundingCustom colSpan={2} align="right">
                          <Can
                            do={
                              PermissionCodeAccess.MarketPlace_Basic_ConfirmPortfolioFunding
                            }
                            on="any"
                          >
                            <Button
                              data-cy="confirm-funds-received-button"
                              variant="contained"
                              color="primary"
                              size="small"
                              disabled={disableConfirmationButton}
                              onClick={(): void => setOpenConfirmFunds(true)}
                            >
                              Confirm Funds Received
                            </Button>
                          </Can>
                        </TableCellFundingCustom>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <ConfirmDialog
                open={openConfirmFunds}
                title="Confirm Funding"
                description={
                  <>
                    <Typography variant="body1" color={textSecondary.color}>
                      {` Confirm the buyer has funded portfolio ${portfolioNumber} for the amount of ${fundingFromBuyer?.amountDisplay}. This action will release the sold file.`}
                    </Typography>
                  </>
                }
                hideCloseButton={true}
                confirmName="Confirm"
                onClose={handleClose}
                onConfirm={handleConfirmFunds}
              />
            </>
          )}
          {portfolioFundingToDebtTraderInfo && (
            <Grid item xs={12}>
              <Typography variant="h6" id="tableTitle" component="div">
                Funding to EverChain
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Bank
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {portfolioFundingToDebtTraderInfo?.bankName}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Routing Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {portfolioFundingToDebtTraderInfo?.routingNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Account Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {portfolioFundingToDebtTraderInfo?.accountNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Service Fee
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {portfolioFundingToDebtTraderInfo?.amountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow>
                      <TableCellFundingCustom
                        style={{ fontStyle: 'italic' }}
                        component="th"
                        scope="row"
                      >
                        Due within two business days
                      </TableCellFundingCustom>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <RemittanceInvoiceEmail />
            </Grid>
          )}
        </Grid>
      </DTAccordion>
    </>
  )
}

export default ConfirmSellerFunding
