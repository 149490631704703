import React, { useContext, useEffect, useState } from 'react'
import { Content } from 'src/styles/layout'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Skeleton,
} from '@mui/material'
import {
  useConfirmBuyerFundingNotification,
  useSubmitFundingNotification,
  useWithdrawFundingNotification,
} from 'src/graphql/operations/mutations/funding'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { ConvertFundingNotificationStatusToDisplay } from 'src/utils/nameConvertions'
import { useHistory } from 'react-router-dom'
import { FUNDING, SELLER_DETAIL, BUYER_DETAIL } from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { Can, AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { numberCurrency, numberCurrencyDollar } from 'src/utils/numbers'
import { ConfirmDialog } from 'src/components/Dialogs'
import { HeaderInfos } from 'src/components/Header/styles'
import { Header, TypographyHyperlink } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getUriFundingNotificationInvoice } from 'src/data/features/get/postSale/postSale'
import { useQueryClient } from '@tanstack/react-query'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface FundingHeaderProps {
  fundingId: number
  status: string
  loading: boolean
  from: string
  to: string
  amount: number
  dateSent: string | undefined
  checkOrReferenceNumber: string | undefined
  paymentMethod: string | undefined
  canGenerateReport: boolean | undefined
  sellerId: string
  buyerId: string
}

const FundingHeader: React.FC<FundingHeaderProps> = ({
  fundingId,
  status,
  loading,
  from,
  to,
  amount,
  dateSent,
  checkOrReferenceNumber,
  paymentMethod,
  canGenerateReport,
  sellerId,
  buyerId,
}: FundingHeaderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const userIsSeller: boolean = userPermissions.type.toLowerCase() === 'seller'
  const userIsBuyer: boolean = userPermissions.type.toLowerCase() === 'buyer'
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [generateReport, setGenerateReport] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const canSubmit = () => {
    if (
      dateSent &&
      checkOrReferenceNumber &&
      paymentMethod &&
      !disableUI &&
      status.toLowerCase() === 'new'
    )
      return true
    return false
  }
  const hideWithdraw = () => {
    if (status !== 'new') return true
    return false
  }
  const hideSubmit = () => {
    if (status !== 'new') return true
    return false
  }
  const { profileClient } = useContext(AuthContext)

  const displayBuyerFundingComponent =
    status === 'pending buyer funding confirmation' && userIsBuyer

  const [disableConfirmFundingButton, setDisableConfirmFundingButton] =
    useState<boolean>(false)
  const [openConfirmFundingConfirmation, setOpenConfirmFundingConfirmation] =
    useState(false)
  const ability = useContext(AbilityContext)

  const handleClose = () => {
    setOpenConfirmFundingConfirmation(false)
  }

  const { confirmBuyerFundingNotification } =
    useConfirmBuyerFundingNotification({
      onCompleted: () => {
        queryClient.refetchQueries(['GetFundingNotification'])
        enqueueSnackbar('Funding confirmed', notifySuccess)
        setDisableConfirmFundingButton(false)
      },
      onError: () => {
        enqueueSnackbar('Operation failed', notifyError)
      },
    })

  const handleConfirm = () => {
    setDisableConfirmFundingButton(true)
    confirmBuyerFundingNotification({
      variables: {
        fundingNotificationId: Number(fundingId),
      },
    })
    setOpenConfirmFundingConfirmation(false)
  }

  const { withdrawFundingNotification, loading: withdrawing } =
    useWithdrawFundingNotification({
      onCompleted: () => {
        enqueueSnackbar('Funding notification withdrawn', notifySuccess)
        setDisableUI(false)
        history.push(getStandardUri(`${FUNDING}/unpaid-funding`))
      },
      onError: () => {
        enqueueSnackbar('Operation failed', notifyError)
      },
    })

  const { submitFundingNotification, loading: submitting } =
    useSubmitFundingNotification({
      onCompleted: () => {
        queryClient.refetchQueries(['GetFundingNotification'])
        enqueueSnackbar('Funding notification submitted', notifySuccess)
        setDisableUI(false)
      },
      onError: () => {
        enqueueSnackbar('Operation failed', notifyError)
        setDisableUI(false)
      },
    })

  const onSubmit = () => {
    setDisableUI(true)
    submitFundingNotification({
      variables: {
        request: {
          dateMailed: dateSent,
          fundingNotificationId: fundingId,
          referenceOrCheckNumber: checkOrReferenceNumber,
          paymentMethod: Number(paymentMethod),
        },
      },
    })
  }
  const onGenerateReport = () => {
    setGenerateReport(true)
  }

  const { data: getReportUri, isFetching: isGenerateLoading } =
    useCustomQuery<string>(
      ['GetUriFundingNotificationInvoice', fundingId, generateReport],
      async () => getUriFundingNotificationInvoice(fundingId),
      { cacheTime: 0, enabled: !!(fundingId && generateReport) }
    )

  useEffect(() => {
    setGenerateReport(false)
    const uri = getReportUri ?? ''
    if (uri === '') return
    window.location.href = uri
  }, [getReportUri])
  const onWithdraw = () => {
    setDisableUI(true)
    withdrawFundingNotification({
      variables: {
        fundingNotificationId: fundingId,
      },
    })
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const redirectUser = (route: string, id: any) => {
    history.push(getStandardUri(`${route}/${id}`))
  }

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }

  return (
    <>
      <Header as={Content}>
        {(submitting || withdrawing) && loadingPanel}
        <Grid container direction="row" spacing={3}>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderInfos>
              <IconButton
                aria-label="go-back"
                style={{ color: '#656565' }}
                className="btn-goback"
                onClick={() => {
                  history.goBack()
                }}
              >
                <Icon name="ArrowBack" />
              </IconButton>

              <Box ml={2}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Funding Details
                </Typography>
              </Box>
            </HeaderInfos>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row">
                  <Typography>ID:</Typography>
                  <Typography
                    style={{ paddingLeft: '3px' }}
                    color={textSecondary.color}
                  >
                    {loading ? <Skeleton /> : fundingId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row">
                  <Typography>Status:</Typography>
                  <Typography
                    style={{ paddingLeft: '3px' }}
                    color={textSecondary.color}
                  >
                    {loading ? (
                      <Skeleton />
                    ) : (
                      ConvertFundingNotificationStatusToDisplay(status)
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row">
                  <Typography>From:</Typography>
                  <TypographyHyperlink
                    data-cy="from-hyperlink"
                    style={{ paddingLeft: '3px' }}
                    color={textSecondary.color}
                    onClick={() => {
                      redirectUser(SELLER_DETAIL, sellerId)
                    }}
                  >
                    {loading ? <Skeleton /> : from}
                  </TypographyHyperlink>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row">
                  <Typography>To:</Typography>
                  <TypographyHyperlink
                    data-cy="to-hyperlink"
                    style={{ paddingLeft: '3px' }}
                    color={textSecondary.color}
                    onClick={() => {
                      redirectUser(BUYER_DETAIL, buyerId)
                    }}
                  >
                    {loading ? <Skeleton /> : to}
                  </TypographyHyperlink>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row">
                  <Typography>Amount:</Typography>
                  <Typography
                    style={{ paddingLeft: '3px' }}
                    color={textSecondary.color}
                  >
                    {loading ? (
                      <Skeleton />
                    ) : (
                      numberCurrencyDollar(amount, userCountry())
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            {displayBuyerFundingComponent &&
              ability.can(
                PermissionCodeAccess.MarketPlace_PostSale_ConfirmFunding,
                'any'
              ) && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenConfirmFundingConfirmation(true)}
                    disabled={disableConfirmFundingButton}
                  >
                    Confirm Seller Funding
                  </Button>
                  <ConfirmDialog
                    open={openConfirmFundingConfirmation}
                    title="Confirm Seller Funding"
                    description={
                      <>
                        <Typography variant="body1" color={textSecondary.color}>
                          {`Do you want to confirm seller funding for Post-Sale Funding with the ID: ${fundingId} on the amount of ${numberCurrency(
                            amount
                          )}?`}
                        </Typography>
                      </>
                    }
                    closeName="No"
                    confirmName="Yes"
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                  />
                </>
              )}
            {canGenerateReport && (
              <Box style={{ padding: '3px' }}>
                <Button
                  onClick={() => {
                    onGenerateReport()
                  }}
                  startIcon={
                    isGenerateLoading ? (
                      <CircularProgress size={15} color="primary" />
                    ) : (
                      <Icon name="Assignment" fontSize="small" />
                    )
                  }
                  disabled={isGenerateLoading}
                  color="primary"
                  variant="contained"
                >
                  Generate Report
                </Button>
              </Box>
            )}
            {userIsSeller && (
              <>
                <Can
                  do={PermissionCodeAccess.MarketPlace_PostSale_CreateFunding}
                  on="any"
                >
                  <Box style={{ padding: '3px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onWithdraw}
                      style={{ display: hideWithdraw() ? 'none' : '' }}
                      disabled={disableUI}
                    >
                      Withdraw
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      disabled={!canSubmit()}
                      style={{ display: hideSubmit() ? 'none' : '' }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Can>
              </>
            )}
          </Grid>
        </Grid>
      </Header>
    </>
  )
}

export default FundingHeader
