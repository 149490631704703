import {
  ACCOUNT_API_URL,
  BACKEND_URL,
} from 'src/infra/api/axios-wrapper/httpClient'

const postSalePrefix = 'postSale.'

export const PostSaleAddRequestComment = `${BACKEND_URL}/${postSalePrefix}addRequestComment`
export const PostSaleSaveRequestAttachment = `${BACKEND_URL}/${postSalePrefix}saveRequestAttachment`
export const PostSaleAssignRequestToUser = `${BACKEND_URL}/${postSalePrefix}assignRequestToUser`
export const PostSaleSaveBulkRequestFilesAttachment = `${BACKEND_URL}/${postSalePrefix}saveBulkRequestFilesAttachment`
export const PostSaleWithdrawRequest = `${BACKEND_URL}/${postSalePrefix}withdrawRequest`
export const PostSaleCreatePostSaleRequest = `${ACCOUNT_API_URL}/${postSalePrefix}createPostSaleRequest`
export const PostSaleCloseRequest = `${BACKEND_URL}/${postSalePrefix}closeRequest`
export const PostSaleAcceptDataRequest = `${BACKEND_URL}/${postSalePrefix}acceptDataRequest`
export const PostSaleSubmitAccountsRequestedData = `${BACKEND_URL}/${postSalePrefix}submitAccountsRequestedData`
