import React, { useContext, useState } from 'react'

import DTAccordion from 'src/components/Accordion'
import { AuthContext } from 'src/context/AuthenticationContext'
import { Box } from '@mui/material'
import PortfolioMediaFileForm from './PortfolioMediaFileForm'
import PortfolioMediaFileLinksTable from './PortfolioMediaFileLinksTable'
import { Icon } from 'everchain-uilibrary'

interface PortfolioMediaFileLinksProp {
  // eslint-disable-next-line react/require-default-props
  portfolioId: string
  portfolioNumber: string | undefined
  portfolioMediaFiles: any
  isInternal: boolean
  canUserUpload: boolean
  refetchMediaFileLinks: () => {}
}

const PortfolioMediaFileLinks: React.FC<PortfolioMediaFileLinksProp> = ({
  portfolioId,
  portfolioNumber,
  portfolioMediaFiles,
  isInternal,
  canUserUpload,
  refetchMediaFileLinks,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const fileLinks = portfolioMediaFiles ?? []

  return (
    <DTAccordion
      id="media-files-accordion"
      title="Media Files"
      icon={<Icon name="CloudDownload" />}
      expanded={userPermissions.type !== 'internal'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          minWidth: '100%',
        }}
      >
        {canUserUpload && (
          <PortfolioMediaFileForm
            portfolioId={portfolioId}
            portfolioNumber={portfolioNumber}
            portfolioMediaFiles={fileLinks}
            setUploadInProgress={setUploadInProgress}
            refetchMediaFileLinks={refetchMediaFileLinks}
          />
        )}
        <PortfolioMediaFileLinksTable
          portfolioId={portfolioId}
          data={fileLinks}
          uploadInProgress={uploadInProgress}
          isInternal={isInternal}
          canUserUpload={canUserUpload}
          refetchMediaFileLinks={refetchMediaFileLinks}
        />
      </Box>
    </DTAccordion>
  )
}

export default PortfolioMediaFileLinks
