/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { complaintAttachmentMutation } from 'src/graphql/operations/mutations'
import { DropUpload } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import { notistackOptions } from 'src/configs/notistackOptions'
import { UploadStepper } from '../styles'
import { UploadItem } from '../../LoadPorfolio/styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'

const relatedInfoTypes = [
  {
    label: 'All recordings of client interactions.',
    value: '1001',
  },
  {
    label: 'Collection agency notes associated with this account.',
    value: '1002',
  },
]

const initialValuesDefault = {
  id: 0,
  description: '',
  status: 'active',
  infoRequestId: 1001,
  complaintId: 0,
}

interface DefaultValuesProps {
  id: number | any
  description: string | any
  status: string | any
  infoRequestId: number | any
  complaintId: number | any
}

interface AttachmentFormProps {
  defaultValues?: DefaultValuesProps | undefined
  loading?: boolean
}
const AttachmentForm: React.FC<AttachmentFormProps> = ({
  defaultValues,
  loading,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const [attachmentFiles, setAttachmentFiles] = useState<any[]>([])
  const MAX_SIZE_FILE = 104857600 // 100MB
  const onDrop = useCallback((acceptedFiles: any) => {
    setAttachmentFiles(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
  })
  const queryClient = useQueryClient()

  function handleUploadFileRemove(): void {
    setAttachmentFiles([])
  }
  const { useSaveComplaintAttachment } = complaintAttachmentMutation
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      ...defaultValues,
      infoRequestId: defaultValues?.infoRequestId || 1001,
    }))
  }, [defaultValues])

  const notifyError = notistackOptions('error')
  const { saveComplaintAttachment, loading: createAttachmentLoading } =
    useSaveComplaintAttachment({
      onCompleted: () => {
        enqueueSnackbar('File(s) uploaded successfully', { variant: 'success' })
        setAttachmentFiles([])

        formCollection.setFieldValue('description', '')
      },
      onError: () => {
        enqueueSnackbar('Error uploading files', notifyError)
      },
    })

  const handleSubmitForm = () => {
    const file = attachmentFiles[0]
    const { infoRequestId, complaintId, status, description } =
      formCollection.values
    saveComplaintAttachment({
      variables: {
        saveComplaintAttachmentRequest: {
          infoRequestId: Number(infoRequestId),
          complaintId: Number(complaintId),
          status,
          description,
        },
        file,
      },
      onCompleted: () => {
        queryClient.refetchQueries({
          queryKey: ['getComplaintAttachment'],
        })
      },
    })
  }

  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {},
  })

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            id="infoRequestId"
            name="infoRequestId"
            select
            value={formCollection.values.infoRequestId || 1001}
            onChange={formCollection.handleChange}
            label="Related Info Request"
            variant="filled"
            fullWidth
            size="small"
            disabled={loading}
          >
            {relatedInfoTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy="add-attachment-description"
            fullWidth
            rows={4}
            name="description"
            label="Description"
            multiline
            onChange={formCollection.handleChange}
            value={formCollection.values.description}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadStepper>
            {!attachmentFiles.length ? (
              <>
                <DropUpload isDragActive={isDragActive} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-placeholder">
                    <Icon name="CloudUpload" className="upload-icon" />{' '}
                    <strong>Choose File </strong> or drop a file here to upload
                  </div>
                </DropUpload>

                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>100MB</strong>
                </Typography>
              </>
            ) : (
              <UploadItem>
                {attachmentFiles.map((file: any, idx) => (
                  <>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />{' '}
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      onClick={handleUploadFileRemove}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </>
                ))}
              </UploadItem>
            )}
          </UploadStepper>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              data-cy="add-attachment-button"
              color="primary"
              variant="contained"
              type="button"
              onClick={() => handleSubmitForm()}
              disabled={!attachmentFiles.length || createAttachmentLoading}
              startIcon={
                createAttachmentLoading ? (
                  <CircularProgress size={15} color="primary" />
                ) : (
                  <Icon name="Add" fontSize="small" />
                )
              }
            >
              Add Attachment
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

AttachmentForm.defaultProps = {
  defaultValues: initialValuesDefault,
  loading: false,
}
export default AttachmentForm
