import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './url'

export const getAgreementTemplates = async (
  portfolioId: string | undefined,
  type: string | undefined,
  buyerId?: string
): Promise<any[]> => {
  const response = await httpClient.get(
    urls.AgreementTemplateGetAgreementTemplates,
    {
      params: {
        portfolioId,
        type,
        buyerId,
      },
    }
  )
  return response?.data
}
export const getFeatureFlagAgreementTemplate = async (
  portfolioId: string | undefined | null
): Promise<boolean> => {
  const response = await httpClient.get(urls.GetFeatureFlagAgreementTemplate, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}
