import React, { useState, useEffect } from 'react'
import { Box, Grid, Paper, Typography, Skeleton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { PortfoliosBeingProcessed } from 'src/graphql/models/LoadPortfolio'
import { loadPortfolioMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'
import LoadPorfolioCard from 'src/components/LoadPorfolioCard'
import { ConfirmDialog } from 'src/components/Dialogs'
import { LoadListActions } from './styles'
import { textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfoliosBeingProcessed } from 'src/data/features/get/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'

const MainLoadPorfolios: React.FC = () => {
  const [openDeleteCofirm, setOpenDeleteCofirm] = useState(false)
  const [portfolioSeleceted, setPortfolioSeleceted] = useState<
    PortfoliosBeingProcessed | any
  >({})

  const { data, isFetching: loading } = useCustomQuery<
    PortfoliosBeingProcessed[]
  >(['getPortfoliosBeingProcessed'], async () => getPortfoliosBeingProcessed())

  const { usePurgePortfolio } = loadPortfolioMutation
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const reactQueryClient = useQueryClient()

  const handleClearPortfolioSeleceted = () => {
    setPortfolioSeleceted({})
  }

  const handleClose = () => {
    setOpenDeleteCofirm(false)
    handleClearPortfolioSeleceted()
  }

  const { purgePortfolio, loading: loadingPurge } = usePurgePortfolio({
    onCompleted: (loadPortfolioData) => {
      if (loadPortfolioData) {
        reactQueryClient.refetchQueries({
          queryKey: ['getPortfoliosBeingProcessed'],
        })
        enqueueSnackbar(
          'The portfolio purge has been requested and will be completed in a few minutes.',
          notifySuccess
        )
        handleClearPortfolioSeleceted()
        handleClose()
      }
    },
  })

  const handlePurgePortfolio = () => {
    if (portfolioSeleceted && portfolioSeleceted.portfolioId) {
      purgePortfolio({
        variables: {
          purgePortfolioRequest: {
            portfolioId: portfolioSeleceted.portfolioId,
            preserveHeader: false,
            reason: null,
          },
        },
        refetchQueries: ['GetPortfoliosBeingPurged'],
      })
    }
  }

  const handleRemovePortfolio = (portfolio: PortfoliosBeingProcessed) => {
    setOpenDeleteCofirm(true)
    setPortfolioSeleceted(portfolio)
  }

  const portfoliosBeingProcessedData = data || []

  useEffect(() => {
    if (portfolioSeleceted && portfolioSeleceted.portfolioNumber) {
      setOpenDeleteCofirm(true)
    }
  }, [portfolioSeleceted])

  return (
    <>
      <LoadListActions>
        <Typography variant="h1" color="secondary">
          Portfolios
        </Typography>
      </LoadListActions>
      <Grid container spacing={4}>
        {loading && !portfoliosBeingProcessedData?.length ? (
          [0, 1, 2, 3, 4, 5].map((item) => (
            <Grid key={item} item xs={12} sm={6} md={6} lg={4}>
              <Skeleton variant="rectangular" width="100%" height={50} />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                style={{ marginBottom: 4 }}
              />
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))
        ) : (
          <>
            {portfoliosBeingProcessedData?.length ? (
              portfoliosBeingProcessedData.map((portfolio) => (
                <Grid
                  key={portfolio.portfolioNumber}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                >
                  <LoadPorfolioCard
                    portfolio={portfolio}
                    loading={loading}
                    onRemove={handleRemovePortfolio}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Paper>
                  <Box
                    p={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    None
                  </Box>
                </Paper>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <ConfirmDialog
        open={openDeleteCofirm}
        title="Delete Portfolio"
        description={
          <>
            <Typography variant="body1" color={textSecondary.color}>
              {`Are you sure want to delete portfolio ${portfolioSeleceted.portfolioNumber}?`}
            </Typography>
            <Typography variant="body1" color={textSecondary.color}>
              All data related to this portfolio will be deleted from of
              platform.
            </Typography>
          </>
        }
        loading={loadingPurge}
        closeName="Cancel"
        confirmName="Continue"
        onClose={handleClose}
        onConfirm={handlePurgePortfolio}
      />
    </>
  )
}

export default MainLoadPorfolios
