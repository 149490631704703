/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { subDays } from 'date-fns'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { BusinessInfoResponse } from 'src/graphql/models/Business'
import { QueryPostSaleReportData } from 'src/graphql/models/PostSale'
import {
  GET_MY_BUYERS_AND_SELLERS,
  GET_REPORT_DATA,
  GET_REPORT_DATA_BANKRUPT,
  GET_REPORT_DATA_DECEASED,
  GET_REPORT_DATA_DIRECTPAY,
  GET_REPORT_DATA_DISMISSEDDISCHARGED,
  GET_REPORT_DATA_FRAUD,
  GET_REPORT_DATA_INACCURATEDATA,
  GET_REPORT_DATA_INFO,
  GET_REPORT_DATA_LEGAL,
  GET_REPORT_DATA_LIENLOSS,
  GET_REPORT_DATA_OTHER,
  GET_REPORT_DATA_PAIDPRIOR,
  GET_REPORT_DATA_PIFSIF,
  GET_REPORT_DATA_SCRAMILITARY,
} from 'src/graphql/operations/queries/postSale'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  renderClickableNumberLink,
  renderCurrencyByPortfolioCountry,
  renderDate,
} from 'src/utils/formatKendoColumns'
import { ACCOUNT_DETAIL, POSTSALE } from 'src/routes'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import ExportExcelButton from 'src/components/ExportExcel/ExportExcelButton'
import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { Link } from 'react-router-dom'
import { RequestType, RequestTypeEnum } from '../RequestType'
import { Content, Header, ReportSection } from './styles'
import MultipleSelectionDropDown from '../../../components/MultipleSelectionDropDown'

const defaultFromDate = subDays(new Date(), 30)
const defaultToDate = new Date()

interface IInitialValues {
  requestType: any[]
  status: any[]
  fromDate: Date
  toDate: Date
  seller: string
  buyer: string
  infoType: string
  infoRequested: string
}

const initialValues: IInitialValues = {
  requestType: [],
  status: [],
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  seller: '',
  buyer: '',
  infoType: '',
  infoRequested: '',
}

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
  tooltip?: string
  filter?: string
}

const requestStatuses = [
  'Pending Buyer Funding Confirmation',
  'Pending Buyer Response',
  'Pending Buyer Review',
  'Pending Seller Funding',
  'Pending Seller Response',
  'Pending Seller Review',
  'Rejected',
  'Unresolved',
  'Withdrawn',
  'Closed',
]

const buyBackList = [0, 1, 4, 5, 6, 8, 9, 11, 12]

const insolvencyTypes = [
  { name: 'Bankruptcy', value: 1 },
  { name: 'IVA', value: 2 },
  { name: 'DRO', value: 3 },
  { name: 'Sequestration', value: 4 },
  { name: 'Trust Deed', value: 5 },
  { name: 'DAS', value: 6 },
]

const PostSaleReportMain: React.FC = () => {
  const [statusSelected, setStatusSelected] = useState<string[]>([])
  const [requestTypeSelected, setRequestTypeSelected] = useState<string[]>([])
  const [buyersSelected, setBuyersSelected] = useState<string[]>([])
  const [sellersSelected, setSellersSelected] = useState<string[]>([])
  const [gridData, setGridData] = useState<any>()
  const [gridColumns, setGridColumns] = useState<any[]>([])
  let fileName = 'Requests'
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUK = country === 'UK'
  const bkName = isUK ? 'Insolvency' : 'Bankrupt'
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const RequestTypeList = (): RequestType[] => {
    const requestTypes: RequestType[] = [
      { Id: RequestTypeEnum.Bankrupt, Name: bkName },
      { Id: RequestTypeEnum.Deceased, Name: 'Deceased' },
      { Id: RequestTypeEnum.DirectPay, Name: 'Direct Pay' },
      { Id: RequestTypeEnum.Fraud, Name: 'Fraud' },
      { Id: RequestTypeEnum.Info, Name: 'Info' },
      { Id: RequestTypeEnum.InaccurateData, Name: 'Inaccurate Data' },
      { Id: RequestTypeEnum.Other, Name: 'Other' },
      { Id: RequestTypeEnum.PaidPrior, Name: 'Paid Prior' },
      { Id: RequestTypeEnum.PifSif, Name: 'PIF/SIF' },
    ]

    if (!isUK) {
      const notUkTypes = [
        {
          Id: RequestTypeEnum.DismissedDischarged,
          Name: 'Dismissed/Discharged',
        },
        { Id: RequestTypeEnum.Legal, Name: 'Legal' },
        { Id: RequestTypeEnum.LienLoss, Name: 'Lien-Loss' },
        {
          Id: RequestTypeEnum.ScraMilitary,
          Name: 'SCRA',
        },
      ]
      const allRequestTypes = requestTypes.concat(notUkTypes)
      return allRequestTypes.sort((a, b) => a.Name.localeCompare(b.Name))
    }

    return requestTypes.sort((a, b) => a.Name.localeCompare(b.Name))
  }

  const [getReportData, { loading: loadingReportData }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataBankrupt, { loading: loadingReportDataBankrupt }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_BANKRUPT, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataDeceased, { loading: loadingReportDataDeceased }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_DECEASED, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataDirectPay, { loading: loadingReportDataDirectPay }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_DIRECTPAY, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataInfo, { loading: loadingReportDataInfo }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_INFO, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataLegal, { loading: loadingReportDataLegal }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_LEGAL, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataOther, { loading: loadingReportDataOther }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_OTHER, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataPaidPrior, { loading: loadingReportDataPaidPrior }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_PAIDPRIOR, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataPifSif, { loading: loadingReportDataPifSif }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_PIFSIF, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataLienLoss, { loading: loadingReportDataLienLoss }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_LIENLOSS, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [getReportDataFraud, { loading: loadingReportDataFraud }] =
    useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_FRAUD, {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    })

  const [
    getReportDataInaccurateData,
    { loading: loadingReportDataInaccurateData },
  ] = useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_INACCURATEDATA, {
    onCompleted: (data: QueryPostSaleReportData) => {
      setGridData(data.reportData)
    },
    notifyOnNetworkStatusChange: true,
  })

  const [
    getReportDataScraMilitary,
    { loading: loadingReportDataScraMilitary },
  ] = useLazyQuery<QueryPostSaleReportData>(GET_REPORT_DATA_SCRAMILITARY, {
    onCompleted: (data: QueryPostSaleReportData) => {
      setGridData(data.reportData)
    },
    notifyOnNetworkStatusChange: true,
  })

  const [
    getReportDataDismissedDischarged,
    { loading: loadingReportDataDismissedDischarged },
  ] = useLazyQuery<QueryPostSaleReportData>(
    GET_REPORT_DATA_DISMISSEDDISCHARGED,
    {
      onCompleted: (data: QueryPostSaleReportData) => {
        setGridData(data.reportData)
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const handleFileName = () => {
    if (requestTypeSelected.length > 1) {
      fileName = 'Requests'
    } else {
      const requestTypeName = RequestTypeList().find(
        (x) => x.Name === requestTypeSelected[0]
      )?.Name

      fileName = requestTypeName || 'Requests'
    }
  }

  const handleColumns = () => {
    if (requestTypeSelected.length > 1) {
      setGridColumns(GetGridColumns(-1))
    } else {
      const requestTypeId = RequestTypeList().find(
        (x) => x.Name === requestTypeSelected[0]
      )?.Id

      setGridColumns(GetGridColumns(requestTypeId || -1))
    }
  }

  const getReport = (values: any, kendoState: any) => {
    const requestTypeIds = RequestTypeList()
      .filter((obj) => requestTypeSelected.includes(obj.Name))
      .map((x) => x.Id)

    const buyerIds =
      buyersSelected.length > 0
        ? buyersList
            .filter((obj) => buyersSelected.includes(obj.name))
            .map((x) => x.id)
        : buyersList.map((x) => x.id)

    const sellerIds =
      sellersSelected.length > 0
        ? sellerList
            .filter((obj) => sellersSelected.includes(obj.name))
            .map((x) => x.id)
        : sellerList.map((x) => x.id)

    const options = {
      variables: {
        options: {
          requestType: requestTypeIds,
          buyer: buyerIds,
          seller: sellerIds,
          status: statusSelected,
          fromDate: new Date(values.fromDate),
          toDate: new Date(values.toDate),
          infoType: values.infoType,
          infoRequested: values.infoRequested,
        },
        kendoPagination: JSON.stringify(kendoState),
      },
    }

    if (requestTypeIds.length > 1) {
      getReportData(options)
      return
    }

    const requestTypeId = RequestTypeList().find(
      (x) => x.Name === requestTypeSelected[0]
    )?.Id

    switch (requestTypeId) {
      case RequestTypeEnum.Bankrupt:
        getReportDataBankrupt(options)
        break
      case RequestTypeEnum.Deceased:
        getReportDataDeceased(options)
        break
      case RequestTypeEnum.DirectPay:
        getReportDataDirectPay(options)
        break
      case RequestTypeEnum.Info:
        getReportDataInfo(options)
        break
      case RequestTypeEnum.Legal:
        getReportDataLegal(options)
        break
      case RequestTypeEnum.Other:
        getReportDataOther(options)
        break
      case RequestTypeEnum.Fraud:
        getReportDataFraud(options)
        break
      case RequestTypeEnum.PifSif:
        getReportDataPifSif(options)
        break
      case RequestTypeEnum.PaidPrior:
        getReportDataPaidPrior(options)
        break
      case RequestTypeEnum.LienLoss:
        getReportDataLienLoss(options)
        break
      case RequestTypeEnum.InaccurateData:
        getReportDataInaccurateData(options)
        break
      case RequestTypeEnum.ScraMilitary:
        getReportDataScraMilitary(options)
        break
      case RequestTypeEnum.DismissedDischarged:
        getReportDataDismissedDischarged(options)
        break
      default:
        break
    }
  }

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      handleColumns()
      getReport(values, gridState)
    },
  })

  const renderRequestTypeOption = () => {
    const handleChange = (value: any) => {
      setRequestTypeSelected(value)
      reportForm.setFieldValue('requestType', value)
    }
    const data = RequestTypeList().map((x) => x.Name)

    const customSelectionData = RequestTypeList()
      .filter((obj) => buyBackList.includes(obj.Id))
      .map((x) => x.Name)

    return (
      <MultipleSelectionDropDown
        label="Request Type"
        data={data}
        disable={disableUI}
        selectionState={requestTypeSelected || []}
        handleSelectionChange={handleChange}
        hasValidationError={requestTypeSelected.length < 1}
        errorMessage="Required"
        customSelectionItemName="Select Buy/Putbacks"
        customSelectionData={customSelectionData}
        customSelectedMessage="(All Buy/Putbacks selected)"
      />
    )
  }

  const renderInfoTypeRequestedFilters = () => {
    return (
      <>
        <Grid item>
          <TextField
            label="Info Type"
            name="infoType"
            error={!!reportForm.errors.infoType}
            helperText={<>{reportForm.errors?.infoType}</>}
            value={reportForm.values.infoType}
            onChange={reportForm.handleChange}
            fullWidth
            style={{ minWidth: '200px' }}
            disabled={disableUI}
          />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <TextField
            label="Info Requested"
            name="infoRequested"
            error={!!reportForm.errors.infoRequested}
            helperText={<>{reportForm.errors?.infoRequested}</>}
            value={reportForm.values.infoRequested}
            onChange={reportForm.handleChange}
            fullWidth
            style={{ minWidth: '200px' }}
            disabled={disableUI}
          />
        </Grid>
      </>
    )
  }

  const renderRequestStatusOption = () => {
    const handleChange = (value: any) => setStatusSelected(value)
    return (
      <MultipleSelectionDropDown
        label="Status"
        data={requestStatuses}
        disable={disableUI}
        handleSelectionChange={handleChange}
        selectionState={statusSelected || []}
      />
    )
  }

  const renderCreationDateOption = () => {
    return (
      <Box display="flex" flexDirection="row" gap={3}>
        <div>
          <DatePicker
            id="fromDate"
            label="From"
            name="fromDate"
            country={profileClient?.Country}
            value={reportForm.values.fromDate}
            onChange={(date: any) => {
              const from = date || new Date()
              const to = new Date(reportForm.values.toDate)
              if (from > to) reportForm.setFieldValue('toDate', date)
              date
                ? reportForm.setFieldValue('fromDate', date)
                : reportForm.setFieldValue('fromDate', defaultFromDate)
            }}
            disabled={disableUI}
            maxDate={defaultToDate}
            disablePast={false}
          />
        </div>
        <div>
          <DatePicker
            id="toDate"
            label="To"
            name="toDate"
            country={profileClient?.Country}
            value={reportForm.values.toDate}
            onChange={(date: any) => {
              const from = new Date(reportForm.values.fromDate)
              const to = date || new Date()
              if (from > to || !date)
                reportForm.setFieldValue('toDate', reportForm.values.fromDate)
              date
                ? reportForm.setFieldValue('toDate', date)
                : reportForm.setFieldValue('toDate', defaultFromDate)
            }}
            disabled={disableUI}
            minDate={reportForm.values.fromDate}
            maxDate={defaultToDate}
          />
        </div>
      </Box>
    )
  }
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const renderBuyerOption = () => {
    const handleChange = (value: any) => {
      setBuyersSelected(value)
      reportForm.setFieldValue('buyer', value)
    }
    const data = buyersList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Buyer"
        data={data}
        disable={disableUI}
        selectionState={buyersSelected || []}
        handleSelectionChange={handleChange}
      />
    )
  }

  const { data: businessesList, loading: loadingBuyersAndSellers } =
    useQuery<BusinessInfoResponse>(GET_MY_BUYERS_AND_SELLERS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    })

  const buyersList = [
    ...new Set(businessesList?.businesses?.filter((x) => x.type === 'buyer')),
  ].sort((a, b) => a.name.localeCompare(b.name))

  const sellerList = [
    ...new Set(businessesList?.businesses?.filter((x) => x.type === 'seller')),
  ].sort((a, b) => a.name.localeCompare(b.name))

  const disableUI =
    loadingBuyersAndSellers ||
    loadingReportDataBankrupt ||
    loadingReportDataDeceased ||
    loadingReportDataDirectPay ||
    loadingReportDataInfo ||
    loadingReportDataLegal ||
    loadingReportDataOther ||
    loadingReportDataPaidPrior ||
    loadingReportDataPifSif ||
    loadingReportDataLienLoss ||
    loadingReportDataFraud ||
    loadingReportDataInaccurateData ||
    loadingReportDataScraMilitary ||
    loadingReportDataDismissedDischarged ||
    loadingReportData ||
    (sellerList.length === 0 && buyersList.length === 0)

  const renderSellerOption = () => {
    const handleChange = (value: any) => {
      setSellersSelected(value)
      reportForm.setFieldValue('seller', value)
    }
    const data = sellerList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Seller"
        data={data}
        disable={disableUI}
        selectionState={sellersSelected || []}
        handleSelectionChange={handleChange}
      />
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const GetGridColumns = (requestType: number) => {
    const columns: IGridColumn[] = [
      {
        title: 'Request ID',
        field: 'requestId',
        show: true,
        render: (props: any) =>
          renderClickableNumberLink(
            props,
            getStandardUri(
              `${POSTSALE}/request/${props.dataItem[props.field]}`
            ),
            () => {
              localStorage.setItem(
                'postSaleReportFilter',
                JSON.stringify(reportForm.values)
              )
              localStorage.setItem(
                'postSaleReportStatusSelected',
                JSON.stringify(statusSelected)
              )
            }
          ),
        width: '120px',
      },
      {
        title: 'Type',
        field: 'requestType',
        show: requestType === -1,
        width: '100px',
      },
      {
        title: 'Status',
        field: 'status',
        show: true,
        width: '100px',
      },
      {
        title: 'PID',
        field: 'portfolioNumber',
        show: true,
        width: '75px',
        filter: 'numeric',
      },
      {
        title: 'Loan ID',
        field: 'lenderLoanId',
        show: true,
        width: '130px',
        render: (props: any) => {
          return (
            <td>
              {props.dataItem.portfolioRowGuid ? (
                <Button
                  component={Link}
                  to={getStandardUri(
                    `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                  )}
                >
                  {props.dataItem.lenderLoanId}
                </Button>
              ) : (
                <>{props.dataItem.lenderLoanId}</>
              )}
            </td>
          )
        },
      },
      {
        title: 'First Name',
        field: 'firstName',
        show: !isInternal,
        width: '120px',
      },
      {
        title: 'Last Name',
        field: 'lastName',
        show: !isInternal,
        width: '120px',
      },
      {
        title: 'Funding ID',
        field: 'fundingId',
        show: true,
        width: '90px',
        render: (props: any) => {
          if (props.dataItem[props.field] > 0)
            return renderClickableNumberLink(
              props,
              getStandardUri(`/funding/request/${props.dataItem[props.field]}`),
              () => {
                localStorage.setItem(
                  'postSaleReportFilter',
                  JSON.stringify(reportForm.values)
                )
                localStorage.setItem(
                  'postSaleReportStatusSelected',
                  JSON.stringify(statusSelected)
                )
              }
            )

          return <td>N/A</td>
        },
      },
      {
        title: 'Funding Amount',
        field: 'fundingAmount',
        show: buyBackList.indexOf(requestType) !== -1,
        render: renderCurrencyByPortfolioCountry,
        width: '120px',
        filter: 'numeric',
      },
      {
        filter: 'numeric',
        title: 'Purchase Price Amount',
        field: 'purchasePriceAmount',
        show:
          requestTypeSelected.findIndex(
            (d) => d !== 'Info' && d !== 'Inaccurate Data'
          ) !== -1,

        render: renderCurrencyByPortfolioCountry,
        width: '120px',
      },
      {
        title: 'Seller Id',
        field: 'sellerId',
        show: false,
      },
      {
        title: 'Seller',
        field: 'sellerName',
        show: true,
        width: '120px',
      },
      {
        title: 'Buyer Id',
        field: 'buyerId',
        show: false,
      },
      {
        title: 'Buyer',
        field: 'buyerName',
        show: true,
        width: '120px',
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        show: true,
        render: renderDate,
        width: '100px',
        filter: 'date',
      },
      {
        title: 'Rejected Comment',
        field: 'rejectComment',
        show: true,
        width: '100px',
      },
    ]

    if (requestType === -1) return columns

    if (requestType === RequestTypeEnum.Bankrupt)
      return columns.concat(bankruptColumns)
    if (requestType === RequestTypeEnum.Deceased)
      return columns.concat(deceasedColumns)
    if (requestType === RequestTypeEnum.DirectPay)
      return columns.concat(directPayColumns)
    if (requestType === RequestTypeEnum.Info) return columns.concat(infoColumns)
    if (
      requestType === RequestTypeEnum.Legal ||
      requestType === RequestTypeEnum.Other ||
      requestType === RequestTypeEnum.Fraud
    )
      return columns.concat(explanationColumns)
    if (requestType === RequestTypeEnum.PifSif)
      return columns.concat(pifSifColumns)
    if (requestType === RequestTypeEnum.PaidPrior)
      return columns.concat(paidPriorColumns)
    if (requestType === RequestTypeEnum.LienLoss)
      return columns.concat(lienLossColumns)
    if (requestType === RequestTypeEnum.InaccurateData)
      return columns.concat(inaccurateDataColumns)
    if (requestType === RequestTypeEnum.ScraMilitary)
      return columns.concat(scraMilitaryColumns)
    if (requestType === RequestTypeEnum.DismissedDischarged)
      return columns.concat(dismissedDischargedColumns)

    return columns
  }

  const bankruptColumns: IGridColumn[] = [
    {
      title: 'Insolvency Type',
      field: 'insolvencyType',
      show: isUK,
      render: (props: any) => {
        return (
          <td style={{ textAlign: 'left' }}>
            {props.dataItem[props.field]
              ? insolvencyTypes.find(
                  (x) => x.value === props.dataItem[props.field]
                )?.name
              : ''}
          </td>
        )
      },
    },
    {
      title: 'Case #',
      field: 'caseNumber',
      show: true,
      width: '90px',
    },
    {
      title: 'Chapter',
      field: 'chapter',
      show: !isUK,
      width: '90px',
    },
    {
      title: 'District',
      field: 'district',
      show: !isUK,
      width: '90px',
    },
    {
      title: 'File Date',
      field: 'fileDate',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
    {
      title: `${isUK ? 'Attourney' : 'Attorney'} Name`,
      field: 'attorneyName',
      show: true,
      width: '135px',
    },
    {
      title: `${isUK ? 'Attourney' : 'Attorney'} Phone`,
      field: 'attorneyPhone',
      show: true,
      width: '140px',
    },
  ]

  const deceasedColumns: IGridColumn[] = [
    {
      title: 'Date of Death',
      field: 'dateOfDeath',
      show: true,
      render: renderDate,
      width: '90px',
      filter: 'date',
    },
    {
      title: 'Source of Notification',
      field: 'notificationSource',
      show: country.toLowerCase() === 'uk',
      width: '100px',
    },
  ]

  const directPayColumns: IGridColumn[] = [
    {
      field: 'paymentDate',
      title: 'Payment Date',
      show: true,
      render: renderDate,
      width: '90px',
      filter: 'date',
    },
    {
      field: 'paymentAmount',
      title: 'Payment Amount',
      show: true,
      render: renderCurrencyByPortfolioCountry,
      width: '100px',
      filter: 'numeric',
    },
    {
      field: 'consumerCheckOrReferenceNumber',
      title: 'Consumer Check Or Reference Number',
      show: true,
      width: '100px',
    },
  ]

  const infoColumns: IGridColumn[] = [
    {
      field: 'infoType',
      title: 'Info Type',
      show: true,
      render: (props: any) => {
        return (
          <td style={{ textAlign: 'left' }}>
            {props.dataItem[props.field]
              ? props.dataItem[props.field]
              : 'Legacy'}
          </td>
        )
      },
    },
    {
      field: 'infoRequested',
      title: 'Info Requested',
      show: true,
      width: '100px',
    },
  ]

  const explanationColumns: IGridColumn[] = [
    {
      field: 'explanation',
      title: 'Explanation',
      show: true,
      width: '100px',
    },
  ]

  const paidPriorColumns: IGridColumn[] = [
    {
      field: 'paidPriorDate',
      title: 'Paid Prior Date',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
    {
      field: 'paidPriorType',
      title: 'Paid Prior Type',
      show: true,
      width: '100px',
    },
  ]

  const lienLossColumns: IGridColumn[] = [
    {
      field: 'lienLossDate',
      title: 'Lien Loss Date',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
    {
      field: 'explanation',
      title: 'Explanation',
      show: true,
      width: '100px',
    },
  ]

  const pifSifColumns: IGridColumn[] = [
    {
      field: 'pifSifType',
      title: 'Type',
      show: true,
      width: '100px',
    },
    {
      field: 'paymentDate',
      title: 'Payment Date',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
    {
      field: 'paymentAmount',
      title: 'Payment Amount',
      show: true,
      render: renderCurrencyByPortfolioCountry,
      width: '100px',
      filter: 'numeric',
    },
    {
      field: 'sifType',
      title: 'sifType',
      show: false,
    },
    {
      field: 'consumerCheckOrReferenceNumber',
      title: 'Consumer Check Or Reference Number',
      show: true,
      width: '100px',
    },
    {
      field: 'locationOrStore',
      title: 'Location Or Store',
      show: true,
      width: '100px',
    },
    {
      field: 'consumerAddress',
      title: 'Consumer Address',
      show: true,
      width: '100px',
    },
    {
      field: 'consumerCity',
      title: 'Consumer City',
      show: true,
      width: '100px',
    },
    {
      field: 'consumerState',
      title: 'Consumer State',
      show: true,
      width: '100px',
    },
    {
      field: 'consumerPostalCode',
      title: 'Consumer Postal Code',
      show: true,
      width: '100px',
    },
    {
      field: 'consumerPhoneNumber',
      title: 'Consumer Phone Number',
      show: true,
      width: '100px',
    },
  ]

  const inaccurateDataColumns: IGridColumn[] = [
    {
      field: 'inaccurateDataInfo',
      title: 'Inaccurate Data Info',
      show: true,
      width: '100px',
    },
  ]

  const scraMilitaryColumns: IGridColumn[] = [
    {
      field: 'militaryBranch',
      title: 'Military Branch',
      show: true,
      width: '100px',
    },
    {
      field: 'activeDutyStartDate',
      title: 'Active Duty Start Date',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
    {
      field: 'activeDutyEndDate',
      title: 'Active Duty End Date',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
  ]

  const dismissedDischargedColumns: IGridColumn[] = [
    {
      field: 'type',
      title: 'Type (Dismissal/Discharge)',
      show: true,
      width: '100px',
    },
    {
      field: 'dateOfDimissalDischarge',
      title: 'Date Of Dimissal/Discharge',
      show: true,
      render: renderDate,
      width: '100px',
      filter: 'date',
    },
  ]

  useEffect(() => {
    const postSaleFilter = localStorage.getItem('postSaleReportFilter')
    if (postSaleFilter) {
      const filters = JSON.parse(postSaleFilter)
      reportForm.setValues(filters)
      setRequestTypeSelected(filters.requestType)
      setSellersSelected(filters.seller)
      setBuyersSelected(filters.buyer)
    }
    const statusFilter = localStorage.getItem('postSaleReportStatusSelected')
    if (statusFilter) setStatusSelected(JSON.parse(statusFilter))
    setTimeout(() => reportForm.submitForm(), 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  handleFileName()

  return (
    <ReportSection>
      <Header as={Content}>
        <Typography variant="h1" style={{ color: '#656565' }}>
          Post-Sale Report
        </Typography>
      </Header>
      <form onSubmit={reportForm.handleSubmit}>
        <Grid
          container
          direction="column"
          style={{ padding: '15px', gap: '24px' }}
        >
          <Grid container direction="row" style={{ gap: 10 }}>
            <Grid item>{renderRequestTypeOption()}</Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>{renderRequestStatusOption()}</Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>{renderSellerOption()}</Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>{renderBuyerOption()}</Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>{renderCreationDateOption()}</Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            {reportForm.values.requestType.includes('Info') && (
              <>
                {renderInfoTypeRequestedFilters()}
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
              </>
            )}
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color="primary"
                variant="contained"
                disabled={disableUI}
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
          {disableUI && loadingPanel}
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '94vw',
              }}
            >
              <ExportExcelButton
                data={formatDataToExport(
                  gridData?.postSaleReportData,
                  gridColumns,
                  profileClient
                )}
                fileName={fileName}
              />
            </Grid>
            <Grid item>
              <DataTable
                style={{
                  width: '93.5vw',
                }}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
                data={gridData?.postSaleReportData}
                total={gridData?.total}
                sortable
                useFilterMenu
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  getReport(reportForm.values, e.dataState)
                }}
                gridColumns={gridColumns}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ReportSection>
  )
}

export default PostSaleReportMain
