import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@mui/material'

import { ListSavedFilters } from 'src/components/Filters'
import { Icon } from 'everchain-uilibrary'

interface DialogListSavedFiltersProps {
  onSetFilters?: (item: ListSavedFilters) => void
  onDelete?: (item: ListSavedFilters) => void
  onClose: () => void
  open: boolean
  loading?: boolean
  customFilters: ListSavedFilters[]
}

const DialogListSavedFilters: React.FC<DialogListSavedFiltersProps> = ({
  onDelete = (): void => {},
  onSetFilters = (): void => {},
  onClose,
  open,
  loading,
  customFilters,
}: DialogListSavedFiltersProps) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-filter"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-filter-title">List of Saved Filters</DialogTitle>
        <DialogContent>
          {loading && !customFilters.length ? (
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              py={10}
            >
              <CircularProgress size={20} />
            </Box>
          ) : (
            <>
              <DialogContentText>Choose your custom filter</DialogContentText>
              {!!customFilters.length && (
                <List>
                  {customFilters.map((filter) => (
                    <ListItem
                      button
                      onClick={(): void => onSetFilters(filter)}
                      key={`${filter.filterName}-${filter.id}`}
                    >
                      <ListItemText primary={filter.filterName} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={(): void => onDelete(filter)}
                          edge="end"
                          aria-label="delete"
                        >
                          <Icon name="Delete" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogListSavedFilters.defaultProps = {
  onSetFilters: (): void => {},
  onDelete: (): void => {},
  loading: false,
}

export default DialogListSavedFilters
