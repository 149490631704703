export type ManagePortfolioAccountsByRowRequest = {
  portfolioId: string
  portfolioRowIndexes: number[]
  removeComment: string | undefined | null
  command: 'exclude' | 'restore' | string
}

export type ManagePortfolioAccountsByLenderItemRequest = {
  lenderLoanId: string
  lender: string
  reason: string
}

export type ManagePortfolioAccountsByLenderRequest = {
  portfolioId: string
  command: 'exclude' | 'restore'
  itemRequest: ManagePortfolioAccountsByLenderItemRequest[]
}

export type ValidatePortfolioTemplateHeadersRequest = {
  portfolioTemplateId: number
  headers: string[]
}

export type PortfolioTemplateMissingHeadersResponse = {
  header: string
  isRequired: boolean
}

export interface UploadPortfolioRequest {
  portfolioTemplateId: string
  assetTypes: string[]
  file: File
  cutOffDateUtc: Date
}

export type PortfoliosByBuyerAndSellerIdsRequest = {
  sellerIds: string[] | undefined | null
  buyerIds: string[] | undefined | null
}

export interface BuyerInfo {
  id: string
  name: string
}

export enum PortfolioSplitDateRangeEnum {
  ByMonth = 'ByMonth',
  ByQuarter = 'ByQuarter',
  ByYear = 'ByYear',
  Custom = 'Custom',
}

export interface PortfolioSplitParamsRequest {
  templateId: number
  fromDate?: Date | null
  toDate?: Date | null
}

export interface PortfolioSplitRequest {
  strategy: string
  stagingId: string
  sellerId: string
  fileName: string
  assetTypes: string
  splitParams: PortfolioSplitParamsRequest[]
  totalNumberOfAccounts: number
  splitDateField: string
  splitDateRange?: PortfolioSplitDateRangeEnum
  templateId?: number
  cutOffDateUtc: Date
}

export interface AcknowledgementDto {
  type: string
  description: string
}

export interface AcceptBidRequest {
  portfolioId: string
  bidId: number
  bidAckowledgmentTracking: AcknowledgementDto[]
  agreementId: number
}

export interface RespondPortfolioListRequest {
  requestId: number
  accept: boolean
  rejectReason?: string
}

export interface GeneratePortfolioDSSRequest {
  portfolioId?: string
  businessId?: string
  surveyId: number
  file: any
}
