import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_ACCOUNT_CARD_MEDIA_FILE_LINKS } from 'src/graphql/operations/queries/portfolio'
import { AccountCardMediaFileLinksResponse } from 'src/graphql/models/PortfolioDetail'
import AccountDetailMediaFilesForm from './AccountDetailMediaFilesForm'
import AccountDetailMediaFilesTable from './AccountDetailMediaFilesTable'

interface AccountDetailMediaFilesProp {
  // eslint-disable-next-line react/require-default-props
  portfolioId: string | undefined
  portfolioNumber: number | undefined
  portfolioRowGuid: string | undefined
  lenderLoanId: string | undefined
}

const AccountDetailMediaFiles: React.FC<AccountDetailMediaFilesProp> = ({
  portfolioId,
  portfolioNumber,
  portfolioRowGuid,
  lenderLoanId,
}) => {
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  const { data: portfolioMediaFiles } =
    useQuery<AccountCardMediaFileLinksResponse>(
      GET_ACCOUNT_CARD_MEDIA_FILE_LINKS,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          portfolioId,
          portfolioRowGuid,
          lenderLoanId,
        },
      }
    )

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        minWidth: '100%',
      }}
    >
      <AccountDetailMediaFilesForm
        portfolioId={portfolioId}
        portfolioNumber={portfolioNumber}
        portfolioRowGuid={portfolioRowGuid}
        lenderLoanId={lenderLoanId}
        accountCardMediaFiles={portfolioMediaFiles?.files}
        setUploadInProgress={setUploadInProgress}
      />
      <AccountDetailMediaFilesTable
        accountCardMediaFiles={portfolioMediaFiles?.files}
        uploadInProgress={uploadInProgress}
      />
    </Box>
  )
}

export default AccountDetailMediaFiles
