import { gql } from '@apollo/client'

export const FORWARD_FLOW_AGREEMENT = gql`
  query GetForwardFlowAgreement($forwardFlowId: Long) {
    forwardFlowData: getForwardFlowAgreement(forwardFlowId: $forwardFlowId) {
      id
      buyerFundingTerms
      buyerId
      buyerName
      endDate
      startDate
      maxFaceValue
      purchasePricePercent
      sellerId
      sellerName
      sellerUploadDate
      buyerFeePercent
      buyerTerminationNotificationDays
      importFromId
      licenseType
      sellerFeePercent
      sellerTerminationNotificationDays
      renewForwardFlow
    }
  }
`

export const GET_FORWARD_FLOW_FILTER_OPTIONS = gql`
  query GetForwardFlowFilterOptions {
    forwardFlowFilterOptions: getForwardFlowFilterOptions {
      filterName
      values {
        id
        value
      }
    }
  }
`

export const GET_FORWARD_FLOW_AGREMENT_DETAIL = gql`
  query GetForwardFlowAgreement($forwardFlowId: Long) {
    getForwardFlowDetailData: getForwardFlowAgreement(
      forwardFlowId: $forwardFlowId
    ) {
      id
      startDate
      endDate
      maxFaceValue
      purchasePricePercent
      buyerTerminationNotificationDays
      sellerUploadDate
      sellerTerminationNotificationDays
      buyerFundingTerms
      buyerName
      renewForwardFlow
      sellerName
      portfolios {
        id
        status
        purchasePriceAmount
        portfolioNumber
        uploadDateUtc
      }
    }
  }
`

export const GET_APPROVED_SELLERS = gql`
  query GetApprovedSellers {
    getApprovedSellers {
      id
      name
    }
  }
`

export const GET_AGENCY_DETAIL = gql`
  query GetAgencyDetail($agencyId: String!, $buyerId: String) {
    getAgencyDetail(agencyId: $agencyId, buyerId: $buyerId) {
      id
      name
      bCR
      myBCR
      networkBCR
      purchaseAccount
      agencyDetailProps {
        key
        value
        description
      }
      agencyBuyerDetailProps {
        key
        value
        description
      }
      monthlyBCR {
        data {
          description
          key
          value
        }
        title
      }
    }
  }
`

export const GET_BUYER_COMPLAINT = gql`
  query GetBuyerComplaint($buyerId: String!, $pagination: DataPagination) {
    buyerComplaintData: getBuyerComplaint(
      buyerId: $buyerId
      pagination: $pagination
    ) {
      complaints {
        agencyId
        agencyName
        dateSubmitted
        id
        status
        summary
      }
      total
    }
  }
`

export const GET_SWITCH_VIEW_TYPE = gql`
  query GetSwitchViewType {
    switchCardType @client
  }
`

export const GET_USER_TYPE = gql`
  query GetUserType {
    userType @client
  }
`

export const GET_PORTFOLIO_FILTERS = gql`
  query GetPortfolioFilters($userType: UserType!) {
    portfolioFiltersOptions: getPortfolioFilterOptions(userType: $userType) {
      id: filterName
      displayName: filterName
      items: values {
        id
        displayName: value
        value
      }
    }
  }
`
export const GET_ALL_FILTERS = gql`
  query GettAllFilters($filterObjectName: String!) {
    allUserFilters: getAllUserFilters(filterObjectName: $filterObjectName) {
      id
      filterName
    }
  }
`

export const GET_SINGLE_USER_FILTER = gql`
  query GetSingleUserFilter($filterId: ID!) {
    singleUserFilter: getSingleUserFilter(filterId: $filterId) {
      id
      filterName
      options {
        id
        optionName
        optionValue
      }
    }
  }
`

export const GET_INFO_TEMPLATES = gql`
  query GetInfoTemplates {
    getInfoTemplates {
      businessId
      businessName
      templateId
      templateName
      assetId
      assetName
      alertMessages
      fileMapId
      skipBalanceValidation
      portfolioType
    }
  }
`

export const GET_FILE_MAP_FIELDS = gql`
  query GetFileMapFieldDefinitions($fileMapId: Long!) {
    getFileMapFieldDefinitions(fileMapId: $fileMapId) {
      id
      fieldName
      exportFieldType
      exportFieldMaxLength
      isRequired
    }
  }
`

export const GET_SCORING_DATA_INFO = gql`
  query GetScoringInfo($scoringRequest: ScoringRequest!) {
    getScoringInfo(scoringRequest: $scoringRequest) {
      buyerName
      requestedByUserName
      requestedUtc
      submittedUtc
    }
  }
`

export const GET_BUYERS_ALLOWED_TUSCORING = gql`
  query GetBuyersAllowedTuScore($portfolioNumber: Long!) {
    buyersData: getBuyersAllowedTuScore(portfolioNumber: $portfolioNumber) {
      id
      name
    }
  }
`

export const GET_DOCUMENT_NDA_URI = gql`
  query GetNDADocumentUri($workflowId: Int!) {
    getNDADocumentUri(workflowId: $workflowId)
  }
`

export const GET_CAN_SELLER_DOWLOAD_AGENCY_DUE_DILIGENCE = gql`
  query GetCanSellerDownloadAgencyDueDiligence($agencyId: String!) {
    getCanSellerDownloadAgencyDueDiligence(agencyId: $agencyId)
  }
`

export const GET_PORTFOLIO_FILE = gql`
  query GetPortfolioFile($portfolioId: String!, $fileType: String!) {
    getPortfolioFile(portfolioId: $portfolioId, fileType: $fileType)
  }
`

export const GET_PORTFOLIO_BID = gql`
  query GetPortfolioBidParams(
    $portfolioBidParamsRequest: PortfolioBidParamsRequest!
  ) {
    Bid: getPortfolioBidByParams(
      portfolioBidParamsRequest: $portfolioBidParamsRequest
    ) {
      portfolioId
      buyerId
      bidPercent
      forwardFlowBidMonths
      forwardFlowMaxFaceValue
      bidPortfolioCloseDate
      bidFundingDate
    }
  }
`
export const GET_PORTFOLIO_TEMPLATE_ASSET_TYPES = gql`
  query GetPortfolioTemplateAssetType($portfolioTemplateId: ID!) {
    portfolioTemplateAssetTypeData: getPortfolioTemplateAssetType(
      portfolioTemplateId: $portfolioTemplateId
    ) {
      id
      name
      assetTypeId
    }
  }
`

export const GET_PORTFOLIO_TEMPLATE_DEFAULT_VALUES = gql`
  query GetTemplatePortfolioDefaultValues(
    $portfolioTemplateId: ID!
    $pagination: DataPagination
  ) {
    portfolioTemplateDefaultValueData: getPortfolioTemplateDefaultValueSummary(
      portfolioTemplateId: $portfolioTemplateId
      pagination: $pagination
    ) {
      totalOfFields
      fields {
        name
        defaultValue
        sellerUploadTemplateId
        portfolioTemplateDefaultValueId
        exportFileDefinitionId
        exportFieldDefinitionId
        fileMapId
      }
    }
  }
`

export const GET_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITIES = gql`
  query GetPortfolioTemplateCollectionActivities($portfolioTemplateId: ID!) {
    portfolioTemplateCollectionActivitiesData: getPortfolioTemplateCollectionActivities(
      portfolioTemplateId: $portfolioTemplateId
    ) {
      id
      agencyName
      agencyId
      dialerFrequency
      initialDuration
      maxDuration
      order
      sellerUploadTemplateId
      settlementDuration
      settlementPercentageOffered
      settlementDuration
      settlementStatus
      accountsVolume
      collectorVolume
      totalDurationRecoveryEfforts
      dateRecalled
      settlementExpiration
      typeId
    }
  }
`

export const GET_PORTFOLIO_TEMPLATE_FILE = gql`
  query GetPortfolioTemplateFile(
    $sellerUploadTemplateId: String!
    $fileType: String!
  ) {
    portfolioTemplateFileData: getPortfolioTemplateFile(
      sellerUploadTemplateId: $sellerUploadTemplateId
      fileType: $fileType
    ) {
      fileContentBase64
      fileContentType
      fileDownloadName
      fileType
    }
  }
`

export const GET_PORTFOLIOS_BEING_PURGED = gql`
  query GetPortfoliosBeingPurged {
    portfolioDetailData: getPortfoliosBeingPurged {
      portfolioNumber
    }
  }
`
export const GET_ALL_CARDS_DASHBOARD = gql`
  query getAllCardDashboard {
    cards: getDashboardCard {
      displayName
      url
      values {
        value
        type
      }
    }
  }
`
export const GET_FORWARD_FLOW_AGREMENT_HISTORY = gql`
  query GetForwardFlowAgreementHistory(
    $forwardFlowId: Long
    $kendoPagination: String
  ) {
    getForwardFlowAgreementHistoryData: getForwardFlowAgreementHistory(
      forwardFlowId: $forwardFlowId
      kendoPagination: $kendoPagination
    ) {
      totalEvents
      history {
        eventUtc
        eventType
        otherData
        portfolioNumber
        portfolioId
      }
    }
  }
`

export const GET_FORWARD_FLOW_AGREMENT_PORTFOLIOS = gql`
  query GetForwardFlowAgreementPortfolios(
    $forwardFlowId: Long
    $kendoPagination: String
  ) {
    getForwardFlowAgreementPortfoliosData: getForwardFlowAgreementPortfolios(
      forwardFlowId: $forwardFlowId
      kendoPagination: $kendoPagination
    ) {
      totalPortfolios
      portfolios {
        id
        status
        purchasePriceAmount
        portfolioNumber
        uploadDateUtc
        saleDate
        soldUtc
      }
    }
  }
`

export const GET_PORTFOLIO_FIELD_DEFINITIONS = gql`
  query GetExportFieldDefinitionsByTemplate($portfolioTemplateId: Long!) {
    portfolioTemplateFieldDefinitionsData: getExportFieldDefinitionsByTemplate(
      portfolioTemplateId: $portfolioTemplateId
    ) {
      id
      name
    }
  }
`

export const GET_PORTFOLIO_MEDIA_FILE_URI = gql`
  query GetPortfolioMediaFileUri(
    $mediaFileId: String!
    $containerId: Long!
    $snapshot: String
  ) {
    getPortfolioMediaFileUri(
      mediaFileId: $mediaFileId
      containerId: $containerId
      snapshot: $snapshot
    )
  }
`

export const GET_PORTFOLIO_WRITE_BLOB_URL = gql`
  query GetPortfolioMediaFileBlobUrl {
    getPortfolioMediaFileBlobUrl
  }
`

export const GET_ACCOUNT_CARD_MEDIA_FILE_LINKS = gql`
  query GetAccoundCardMediaFilesLink(
    $portfolioId: String!
    $portfolioRowGuid: String!
    $lenderLoanId: String!
  ) {
    files: getAccoundCardMediaFilesLink(
      portfolioId: $portfolioId
      portfolioRowGuid: $portfolioRowGuid
      lenderLoanId: $lenderLoanId
    ) {
      id
      portfolioId
      displayName
      createdByUserId
      createdByUserName
      createdUtc
      isDeleted
      portfolioRowGuid
      lenderLoanId
    }
  }
`
