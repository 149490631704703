/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Content, SectionColumn } from 'src/styles/layout'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Skeleton,
} from '@mui/material'

import { ConvertPostSaleRequestStatusToDisplay } from 'src/utils/nameConvertions'

import {
  useConfirmRequest,
  useRejectRequest,
  useEscalateRequest,
  useRejectDataRequest,
  useRequestedDataSubmitted,
} from 'src/graphql/operations/mutations/postSale'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'

import { AuthContext } from 'src/context/AuthenticationContext'

import {
  PostSaleCardItemsResponse,
  PostSaleUser,
} from 'src/graphql/models/PostSale'
import { Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import PostSaleNavigation from 'src/components/PostSale/PostSaleNavigation'
import { HeaderInfos } from 'src/components/Header/styles'
import { useHistory } from 'react-router-dom'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPostSaleCardItems,
  getPostSaleUsers,
  refechPostSaleRequest,
} from 'src/data/features/get/postSale/postSale'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AssignToUserRequest } from 'src/data/features/post/postSale/types'
import {
  acceptDataRequest,
  assignRequestToUser,
  closeRequest,
  uploadWithdrawRequest,
} from 'src/data/features/post/postSale/postSale'

interface RequestHeaderProps {
  requestId: Number
  requestTypeName: string
  status: string
  buyerId: string
  sellerId: string
  buyerAssignedUser: string
  sellerAssignedUser: string
  initiatedByBuyer: boolean
  escalated: boolean
  loading: boolean
  fundingNotificationId: Number | null
  requestTypeId: number
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const RequestHeader: React.FC<RequestHeaderProps> = ({
  requestId,
  requestTypeName,
  status,
  buyerId,
  sellerId,
  buyerAssignedUser,
  sellerAssignedUser,
  initiatedByBuyer,
  escalated,
  loading,
  fundingNotificationId,
  requestTypeId,
}: RequestHeaderProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<string>('')
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState<boolean>(false)
  const [withdrawReason, setWithdrawReason] = useState<string>('')
  const [openEscalateDialog, setOpenEscalateDialog] = useState<boolean>(false)
  const [escalateReason, setEscalateReason] = useState<string>('')
  const [assignedToBuyerName, setAssignedToBuyerName] = useState<string>('')
  const [assignedToSellerName, setAssignedToSellerName] = useState<string>('')
  const [openAssignToDialog, setOpenAssignToDialog] = useState<boolean>(false)
  const [userSelected, setUserSelected] = useState<PostSaleUser>()
  const [assignToUserRequest, setAssignToUserRequest] =
    useState<AssignToUserRequest[]>()
  const { userPermissions, profileBusiness } = useContext(AuthContext)
  const userIsInternal: boolean = userPermissions.type === 'internal'
  const userIsBuyer: boolean = profileBusiness.some(
    (x: any) => x.Id === buyerId
  )
  const userIsSeller: boolean = profileBusiness.some(
    (x: any) => x.Id === sellerId
  )
  const history = useHistory()
  const queryClient = useQueryClient()
  const [withdrawAccountsRequest, setWithdrawAccountsRequest] = useState<any>()
  const [loadingClose, setLoadingClose] = useState<boolean>(false)
  const variables = JSON.parse(
    localStorage.getItem('postSaleVariables') || '{}'
  )

  const { data: postSaleUsersQueryData, isFetching: loadingPostSaleUsers } =
    useCustomQuery<PostSaleUser[]>(
      ['getPostSaleUsers', requestId],
      async () => getPostSaleUsers(requestId),
      {
        enabled: true,
      }
    )

  const { data: postSaleCardItems, refetch: refetchPostSaleCards } =
    useCustomQuery<PostSaleCardItemsResponse>(
      ['getPostSaleCardItems', variables],
      async () =>
        getPostSaleCardItems(
          variables?.cardType,
          variables?.filter,
          variables?.subFilter,
          variables?.indicator,
          variables?.allCompletedRequests,
          variables?.kendoPagination
            ? JSON.parse(variables?.kendoPagination)
            : {}
        ),
      {
        cacheTime: 0,
        enabled: true,
      }
    )

  useEffect(() => {
    if (postSaleUsersQueryData !== null) {
      const buyerName = postSaleUsersQueryData?.find(
        (x) => x.id === buyerAssignedUser
      )?.name
      setAssignedToBuyerName(buyerName || '')

      const sellerName = postSaleUsersQueryData?.find(
        (x) => x.id === sellerAssignedUser
      )?.name
      setAssignedToSellerName(sellerName || '')
    }
  }, [
    buyerAssignedUser,
    sellerAssignedUser,
    postSaleUsersQueryData,
    userIsBuyer,
    userIsSeller,
  ])

  const AllowSubmitRequested = (): boolean => {
    if (userIsInternal) {
      return false
    }

    if (!['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase()))
      return false

    if (['withdrawn', 'closed', 'new'].includes(status.toLowerCase())) {
      return false
    }

    if (
      userIsBuyer &&
      [
        'pending seller response',
        'pending seller review',
        'pending buyer review',
      ].includes(status.toLocaleLowerCase())
    )
      return false

    if (
      userIsSeller &&
      [
        'pending buyer response',
        'pending buyer review',
        'pending seller review',
      ].includes(status.toLocaleLowerCase())
    )
      return false
    // TODO: Add permission verification
    // if (this.options.PermissionLevel != PermissionLevel.Editable) return false;

    return true
  }

  const AllowConfirmReject = (): boolean => {
    if (userIsInternal) return false
    if (
      ['pif', 'sif', 'account_closed'].includes(requestTypeName.toLowerCase())
    )
      return false

    if (
      [
        'withdrawn',
        'closed',
        'unresolved',
        'rejected',
        'pending seller funding',
        'pending buyer funding',
      ].includes(status.toLowerCase())
    )
      return false

    if (
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase()) &&
      ['pending seller response', 'pending buyer response'].includes(
        status.toLowerCase()
      )
    )
      return false

    if (userIsBuyer && requestTypeName.toLowerCase() === 'direct-pay')
      return false

    if (userIsBuyer && status.toLowerCase() === 'pending seller response')
      return false

    if (userIsSeller && status.toLowerCase() === 'pending buyer response')
      return false

    if (
      ['pending buyer review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsSeller) return false
    }

    if (
      ['pending seller review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsBuyer) return false
    }
    // TODO: Add permission verification
    // if (this.options.PermissionLevel != PermissionLevel.Editable) return false;
    return true
  }

  const AllowWithdraw = (): boolean => {
    if (userIsInternal) {
      return false
    }
    if (['withdrawn', 'closed', 'new'].includes(status.toLowerCase())) {
      return false
    }
    if (
      (userIsBuyer && !initiatedByBuyer) ||
      (userIsSeller && initiatedByBuyer)
    )
      return false

    if (
      userIsBuyer &&
      initiatedByBuyer &&
      fundingNotificationId !== null &&
      ['pending seller funding'].includes(status.toLowerCase())
    ) {
      return false
    }

    if (
      userIsSeller &&
      !initiatedByBuyer &&
      fundingNotificationId !== null &&
      ['pending seller funding'].includes(status.toLowerCase())
    ) {
      return false
    }

    if (
      ['pending seller review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsSeller && !initiatedByBuyer) return false
    }

    if (
      ['pending buyer review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsBuyer && initiatedByBuyer) return false
    }
    // TODO: Add permission verification
    // if (this.options.PermissionLevel != PermissionLevel.Editable) return false;
    return true
  }
  const AllowAccept = (): boolean => {
    if (userIsInternal) {
      return false
    }
    if (
      ['pif', 'sif', 'account_closed'].includes(requestTypeName.toLowerCase())
    )
      return false
    if (
      ['withdrawn', 'closed', 'new', 'pending seller funding'].includes(
        status.toLowerCase()
      )
    ) {
      return false
    }
    if (
      !['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase()) &&
      ((userIsBuyer && initiatedByBuyer) || (userIsSeller && !initiatedByBuyer))
    )
      return false

    if (
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase()) &&
      ['pending seller response', 'pending buyer response'].includes(
        status.toLowerCase()
      )
    )
      return false

    if (
      ['pending buyer review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsSeller) return false
    }

    if (
      ['pending seller review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsBuyer) return false
    }

    return true
  }
  const AllowClose = (): boolean => {
    if (userIsInternal) return false
    if (
      !['pif', 'sif', 'account_closed'].includes(requestTypeName.toLowerCase())
    )
      return false

    if (['closed'].includes(status.toLowerCase())) return false

    return true
  }
  const AllowEscalate = (): boolean => {
    if (userIsInternal) return false

    if (
      ['withdrawn', 'closed', 'unresolved', 'rejected'].includes(
        status.toLowerCase()
      )
    ) {
      return false
    }

    if (
      userIsSeller &&
      ['pending seller funding'].includes(status.toLowerCase())
    ) {
      return false
    }
    if (
      ['pif', 'sif', 'account_closed'].includes(requestTypeName.toLowerCase())
    )
      return false
    if (
      (userIsBuyer && !initiatedByBuyer) ||
      (userIsSeller && initiatedByBuyer)
    )
      return false

    if (
      ['pending seller review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsSeller && !initiatedByBuyer) return false
    }

    if (
      ['pending buyer review'].includes(status.toLowerCase()) &&
      ['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())
    ) {
      if (userIsBuyer && initiatedByBuyer) return false
    }

    if (['legal'].includes(requestTypeName.toLowerCase())) return false

    // TODO: Add permission verification
    // if (this.options.PermissionLevel != PermissionLevel.Editable) return false;
    return true
  }

  const { requestedDataSubmitted } = useRequestedDataSubmitted({
    onCompleted: () => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Data requested submitted', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })
  const closeRequestMutation = useMutation({
    mutationFn: (requestIdParam: number) => {
      setLoadingClose(true)
      return closeRequest(requestIdParam)
    },
    onSuccess: (data: any) => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Data requested submitted', notifySuccess)
      setDisableUI(false)
      setLoadingClose(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
      setLoadingClose(false)
    },
  })

  const acceptDataRequestMutation = useMutation({
    mutationFn: (requestIdParam: number) => {
      setLoadingClose(true)
      return acceptDataRequest(requestIdParam)
    },
    onSuccess: (data: any) => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Request closed', notifySuccess)
      setDisableUI(false)
      setLoadingClose(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
      setLoadingClose(false)
    },
  })

  const { rejectDataRequest } = useRejectDataRequest({
    onCompleted: () => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Request updated', notifySuccess)
      setDisableUI(false)
      handleCloseRejectDialog()
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const { setConfirmRequest } = useConfirmRequest({
    onCompleted: () => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Request accepted', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const { setRejectRequest } = useRejectRequest({
    onCompleted: () => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Request rejected', notifySuccess)
      setDisableUI(false)
      handleCloseRejectDialog()
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const {
    isFetching: loadingSetWithdrawRequest,
    isFetched: fetchedWithdrawRequest,
    isError: errorWithdrawnRequest,
  } = useCustomQuery<any>(
    ['setWithdrawRequest', withdrawAccountsRequest],
    async () => uploadWithdrawRequest(withdrawAccountsRequest),
    { enabled: !!withdrawAccountsRequest, cacheTime: 0 }
  )

  useEffect(() => {
    if (!loadingSetWithdrawRequest && !errorWithdrawnRequest) {
      if (fetchedWithdrawRequest) {
        refechPostSaleRequest(queryClient, Number(requestId))
        enqueueSnackbar('Request withdrawn', notifySuccess)
        setDisableUI(false)
        handleCloseWithdrawDialog()
      }
      if (errorWithdrawnRequest) {
        enqueueSnackbar('Operation failed', notifyError)
        setDisableUI(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSetWithdrawRequest, fetchedWithdrawRequest, errorWithdrawnRequest])

  const { setEscalateRequest } = useEscalateRequest({
    onCompleted: () => {
      refechPostSaleRequest(queryClient, Number(requestId))
      enqueueSnackbar('Request updated', notifySuccess)
      setDisableUI(false)
      handleCloseEscalateDialog()
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const { isFetching: assignRequestToUserLoading } = useCustomQuery<any>(
    ['assignRequestToUser', assignToUserRequest],
    async () => {
      if (assignToUserRequest) {
        assignRequestToUser(assignToUserRequest)
          .then(() => {
            enqueueSnackbar('Request updated', notifySuccess)
            setDisableUI(false)
            handleCloseAssignToDialog()
            refechPostSaleRequest(queryClient, requestId)
            refetchPostSaleCards()
          })
          .catch(() => {
            enqueueSnackbar('Operation failed', notifyError)
            setDisableUI(false)
          })
      }
    },
    { enabled: !!assignToUserRequest }
  )

  const handleCloseRequest = () => {
    setDisableUI(true)
    closeRequestMutation.mutate(Number(requestId))
  }
  const handleSubmitRequestedData = () => {
    setDisableUI(true)
    requestedDataSubmitted({
      variables: {
        requestId: Number(requestId),
      },
    })
  }

  const handleAccept = () => {
    setDisableUI(true)
    if (['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())) {
      acceptDataRequestMutation.mutate(Number(requestId))
    } else {
      setConfirmRequest({
        variables: {
          request: {
            businessId: initiatedByBuyer ? sellerId : buyerId,
            initiatedByBuyer,
            requestIds: [Number(requestId)],
            requestType: requestTypeId,
          },
        },
      })
    }
  }

  const handleReject = () => {
    setDisableUI(true)
    if (['info', 'inaccurate_data'].includes(requestTypeName.toLowerCase())) {
      rejectDataRequest({
        variables: {
          requestId: Number(requestId),
          reason: rejectReason,
        },
      })
    } else {
      setRejectRequest({
        variables: {
          request: {
            businessId: initiatedByBuyer ? sellerId : buyerId,
            initiatedByBuyer,
            requestId: Number(requestId),
            rejectComment: rejectReason,
            requestType: requestTypeId,
          },
        },
      })
    }
  }

  const handleWithdraw = () => {
    setDisableUI(true)
    const request = [
      {
        requestId: Number(requestId),
        reason: withdrawReason,
      },
    ]
    setWithdrawAccountsRequest(request)
  }

  const handleEscalate = () => {
    setDisableUI(true)
    setEscalateRequest({
      variables: {
        request: {
          businessId: initiatedByBuyer ? buyerId : sellerId,
          escalate: !escalated,
          reason: escalateReason,
          requestId: Number(requestId),
        },
      },
    })
  }

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false)
    setRejectReason('')
  }

  const handleCloseWithdrawDialog = () => {
    setOpenWithdrawDialog(false)
    setWithdrawReason('')
  }

  const handleCloseEscalateDialog = () => {
    setOpenEscalateDialog(false)
    setEscalateReason('')
  }

  const handleCloseAssignToDialog = () => {
    setOpenAssignToDialog(false)
  }

  const handleAssignTo = () => {
    setDisableUI(true)
    setAssignToUserRequest([
      {
        requestId: Number(requestId),
        assignToUserId: userSelected?.id,
        assignToUserName: userSelected?.name,
        businessType: userIsBuyer ? 'buyer' : 'seller',
      },
    ])
  }

  let prevPostSale: any | null = null
  let nextPostSale: any | null = null

  if (!loading) {
    if (postSaleCardItems) {
      const current = postSaleCardItems.postSaleCardItemResponse?.findIndex(
        (f: any) => f.id === Number(requestId)
      )

      if (current > 0) {
        prevPostSale =
          postSaleCardItems.postSaleCardItemResponse[current - 1].id
      }
      if (current < postSaleCardItems.postSaleCardItemResponse.length - 1) {
        nextPostSale =
          postSaleCardItems.postSaleCardItemResponse[current + 1].id
      }
    }
  }

  return (
    <SectionColumn>
      <Content>
        <Grid container direction="row">
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '6px',
                }}
              >
                <HeaderInfos>
                  <IconButton
                    aria-label="go-back"
                    color="secondary"
                    className="btn-goback"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    <Icon name="ArrowBack" />
                  </IconButton>
                  <Box ml={2}>
                    <Typography variant="subtitle1">
                      Post-Sale Request
                    </Typography>
                  </Box>
                </HeaderInfos>
                <Typography
                  style={{ paddingLeft: '3px' }}
                  color={textSecondary.color}
                >
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <PostSaleNavigation
                      data-cy="postsale-number"
                      disabled={loading}
                      label={requestId.toString()}
                      next={nextPostSale}
                      prev={prevPostSale}
                    />
                  )}
                </Typography>
                <Typography style={{ paddingLeft: '12px' }}>Status:</Typography>
                <Typography
                  data-cy="post-sale-request-status"
                  style={{ paddingLeft: '3px' }}
                  color={textSecondary.color}
                >
                  {loading ? (
                    <Skeleton />
                  ) : (
                    ConvertPostSaleRequestStatusToDisplay(status)
                  )}
                </Typography>
                {userIsInternal && (
                  <>
                    {assignedToBuyerName && (
                      <>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '12px',
                          }}
                        >
                          <Typography>Buyer - Assigned to: </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '6px',
                          }}
                        >
                          <Typography color={textSecondary.color}>
                            {assignedToBuyerName}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {assignedToSellerName && (
                      <>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '12px',
                          }}
                        >
                          <Typography>Seller - Assigned to: </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '6px',
                          }}
                        >
                          <Typography color={textSecondary.color}>
                            {assignedToSellerName}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {!userIsInternal && (
                  <>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '12px',
                      }}
                    >
                      <Typography>Assigned to: </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '6px',
                      }}
                    >
                      {loadingPostSaleUsers ? (
                        <Skeleton variant="rectangular" width="100px" />
                      ) : (
                        <TextField
                          select
                          id="select-request-type"
                          value={
                            userIsBuyer
                              ? assignedToBuyerName
                              : assignedToSellerName
                          }
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Select an user</em>
                          </MenuItem>
                          {postSaleUsersQueryData?.map((user) => {
                            return (
                              <MenuItem
                                key={user.id}
                                value={user.name}
                                onClick={() => {
                                  setUserSelected(user)
                                  setOpenAssignToDialog(true)
                                }}
                              >
                                {user.name}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              <Box display="flex">
                <Box style={{ paddingLeft: '3px' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={disableUI}
                    style={{
                      display: AllowSubmitRequested() ? undefined : 'none',
                    }}
                    onClick={handleSubmitRequestedData}
                    startIcon={<Icon name="ArrowForward" />}
                  >
                    Submit Requested Data
                  </Button>
                </Box>
                <Box style={{ paddingLeft: '3px' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={disableUI}
                    style={{ display: AllowEscalate() ? undefined : 'none' }}
                    onClick={() => setOpenEscalateDialog(true)}
                    startIcon={
                      disableUI ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        <Icon name="Info" />
                      )
                    }
                  >
                    {escalated ? 'De-escalate' : 'Escalate'}
                  </Button>
                </Box>
                <Box style={{ paddingLeft: '3px' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={disableUI}
                    style={{ display: AllowWithdraw() ? undefined : 'none' }}
                    onClick={() => setOpenWithdrawDialog(true)}
                    startIcon={<Icon name="Delete" />}
                  >
                    Withdraw
                  </Button>
                </Box>

                <Can
                  do={
                    PermissionCodeAccess.MarketPlace_PostSale_AcceptRejectRequest
                  }
                  on="any"
                >
                  <Box style={{ paddingLeft: '3px' }}>
                    <Button
                      data-cy="accept-button"
                      color="primary"
                      variant="outlined"
                      startIcon={<Icon name="CheckCircleOutlineOutlined" />}
                      disabled={disableUI}
                      style={{
                        display: AllowAccept() ? undefined : 'none',
                      }}
                      onClick={handleAccept}
                    >
                      Accept
                    </Button>
                  </Box>

                  <Box style={{ paddingLeft: '3px' }}>
                    <Button
                      data-cy="reject-button"
                      color="primary"
                      variant="outlined"
                      startIcon={<Icon name="HighlightOffOutlined" />}
                      disabled={disableUI}
                      style={{
                        display: AllowConfirmReject() ? undefined : 'none',
                      }}
                      onClick={() => setOpenRejectDialog(true)}
                    >
                      Reject
                    </Button>
                  </Box>
                </Can>

                <Box style={{ paddingLeft: '3px' }}>
                  <Button
                    data-cy="close-post-sale-request-button"
                    onClick={handleCloseRequest}
                    variant="outlined"
                    color="primary"
                    style={{
                      display: AllowClose() ? undefined : 'none',
                    }}
                    disabled={loadingClose || loading}
                    startIcon={
                      loadingClose || loading ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        <Icon name="Check" fontSize="small" />
                      )
                    }
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={openRejectDialog} onClose={handleCloseRejectDialog}>
          <DialogTitle>Reject reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    Please, provide a reason for rejection:
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event: any) => {
                      setRejectReason(event.target.value)
                    }}
                    value={rejectReason}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleReject}
              color="primary"
              disabled={disableUI || !rejectReason}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseRejectDialog}
              color="secondary"
              disabled={disableUI}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openWithdrawDialog} onClose={handleCloseWithdrawDialog}>
          <DialogTitle>Withdraw reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    Please, provide a reason for withdrawn:
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseWithdrawDialog}
                    style={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: 'white',
                    }}
                  >
                    <Icon name="Close" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event: any) => {
                      setWithdrawReason(event.target.value)
                    }}
                    value={withdrawReason}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleWithdraw}
              color="primary"
              disabled={disableUI || !withdrawReason}
            >
              Withdraw
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseWithdrawDialog}
              color="secondary"
              disabled={disableUI}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEscalateDialog} onClose={handleCloseEscalateDialog}>
          <DialogTitle>
            {escalated ? 'De-escalate' : 'Escalating'} reason
            <IconButton
              aria-label="close"
              onClick={handleCloseEscalateDialog}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    Please, provide a reason for{' '}
                    {escalated ? 'de-escalating' : 'escalating'}:
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event: any) => {
                      setEscalateReason(event.target.value)
                    }}
                    value={escalateReason}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleEscalate}
              color="primary"
              disabled={disableUI || !escalateReason}
              startIcon={
                disableUI && <CircularProgress size={15} color="primary" />
              }
            >
              {escalated ? 'De-escalate' : 'Escalate'}
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseEscalateDialog}
              color="secondary"
              disabled={disableUI}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAssignToDialog} onClose={handleCloseAssignToDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAssignToDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <DialogContent>
            <DialogContentText>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    Would you like to assign the this request to{' '}
                    {userSelected?.name}?
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleAssignTo}
              color="primary"
              disabled={disableUI || assignRequestToUserLoading}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseAssignToDialog}
              color="secondary"
              disabled={disableUI}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </SectionColumn>
  )
}

export default RequestHeader
