import { RequestTypeEnum } from 'src/pages/PostSale/RequestType'
import { BulkRespondOperation } from './constants'
import hardcodeData from './hardcodeData'
import { ColumnMetadata } from './parseFile'

export const GetPostSaleDownloadRequestFileColumns = (
  requestTypeId: number,
  country: string
) => {
  const countryLowerCase = country.toLowerCase()
  const headers: ColumnMetadata[] = [
    { name: 'ECAID', type: 'guid', isRequired: false, length: -1 },
    { name: 'PortfolioNumber', type: 'number', isRequired: true, length: -1 },
    { name: 'LenderLoanId', type: 'string', isRequired: true, length: 100 },
    { name: 'LastName', type: 'string', isRequired: true, length: 50 },
    { name: 'FirstName', type: 'string', isRequired: true, length: 50 },
    { name: 'Comments', type: 'string', isRequired: true, length: 4000 },
  ]

  switch (requestTypeId) {
    case RequestTypeEnum.Bankrupt:
      if (countryLowerCase === 'uk')
        return headers.concat([
          {
            name: 'InsolvencyType',
            type: 'number',
            isRequired: false,
            length: -1,
          },
          { name: 'CaseNumber', type: 'string', isRequired: true, length: 40 },
          { name: 'FileDate', type: 'date', isRequired: true, length: -1 },
          {
            name: 'AttourneyName',
            type: 'string',
            isRequired: false,
            length: 100,
          },
          {
            name: 'AttourneyPhone',
            type: 'string',
            isRequired: false,
            length: 30,
          },
        ])
      return headers.concat([
        { name: 'CaseNumber', type: 'string', isRequired: true, length: 40 },
        { name: 'Chapter', type: 'number', isRequired: false, length: -1 },
        { name: 'FileDate', type: 'date', isRequired: true, length: -1 },
        {
          name: 'AttorneyName',
          type: 'string',
          isRequired: false,
          length: 100,
        },
        {
          name: 'AttorneyPhone',
          type: 'string',
          isRequired: false,
          length: 30,
        },
        { name: 'District', type: 'string', isRequired: false, length: 100 },
      ])
    case RequestTypeEnum.Deceased:
      if (countryLowerCase === 'uk')
        return headers.concat([
          { name: 'DateOfDeath', type: 'date', isRequired: true, length: -1 },
          {
            name: 'NotificationSource',
            type: 'string',
            isRequired: true,
            length: 300,
          },
        ])
      return headers.concat([
        { name: 'DateOfDeath', type: 'date', isRequired: false, length: -1 },
      ])
    case RequestTypeEnum.DirectPay:
      return headers.concat([
        { name: 'PaymentDate', type: 'date', isRequired: true, length: -1 },
        {
          name: 'PaymentAmount',
          type: 'number',
          isRequired: true,
          length: -1,
        },
        {
          name: 'ConsumerCheckOrReferenceNumber',
          type: 'string',
          isRequired: true,
          length: 100,
        },
      ])
    case RequestTypeEnum.DismissedDischarged:
      return headers.concat([
        {
          name: 'Type (Dismissal/Discharge)',
          type: 'string',
          isRequired: true,
          length: 10,
          domain: ['dismissal', 'discharge'],
        },
        {
          name: 'DateOfDismissalDischarge',
          type: 'date',
          isRequired: true,
          length: -1,
        },
      ])
    case RequestTypeEnum.Info:
      return headers.concat([
        {
          name: 'InfoType',
          type: 'string',
          isRequired: true,
          length: 50,
          domain: hardcodeData.getInfoTypes(),
        },
        {
          name: 'InfoRequested',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
      ])
    case RequestTypeEnum.Legal:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.Other:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.PaidPrior:
      return headers.concat([
        { name: 'PaidPriorDate', type: 'date', isRequired: true, length: 3 },
        {
          name: 'PaidPriorType',
          type: 'string',
          isRequired: true,
          length: 3,
          domain: hardcodeData.getPifSifTypes(),
        },
      ])
    case RequestTypeEnum.PifSif:
      return headers.concat([
        {
          name: 'PifSifType',
          type: 'string',
          isRequired: true,
          length: 3,
          domain: hardcodeData.getPifSifTypes(),
        },
        { name: 'PaymentDate', type: 'date', isRequired: false, length: -1 },
        {
          name: 'PaymentAmount',
          type: 'number',
          isRequired: false,
          length: -1,
        },
        {
          name: 'CheckOrReferenceNumber',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'LocationOrStore',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerAddress',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerCity',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerState',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerPostalCode',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerPhoneNumber',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
      ])
    case RequestTypeEnum.LienLoss:
      return headers.concat([
        { name: 'LienLossDate', type: 'date', isRequired: true, length: -1 },
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.Fraud:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.InaccurateData:
      return headers.concat([
        {
          name: 'InaccurateDataInfo',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
      ])
    case RequestTypeEnum.ScraMilitary:
      return headers.concat([
        {
          name: 'MilitaryBranch',
          type: 'string',
          isRequired: true,
          length: 45,
        },
        {
          name: 'ActiveDutyStartDate',
          type: 'date',
          isRequired: true,
          length: -1,
        },
        {
          name: 'ActiveDutyEndDate',
          type: 'date',
          isRequired: false,
          length: -1,
        },
      ])
    case RequestTypeEnum.AccountClosed:
      return headers.concat([
        {
          name: 'Reason',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
        {
          name: 'ClosedDate',
          type: 'date',
          isRequired: true,
          length: -1,
        },
      ])
    default:
      throw new Error('Invalid request type')
  }
}

export const GetPostSaleRequestTemplateFileColumns = (
  requestTypeId: number,
  country: string
) => {
  const countryLowerCase = country.toLowerCase()
  const headers: ColumnMetadata[] = [
    { name: 'ECAID', type: 'guid', isRequired: false, length: -1 },
    { name: 'PortfolioNumber', type: 'number', isRequired: true, length: -1 },
    { name: 'LenderLoanId', type: 'string', isRequired: true, length: 100 },
    { name: 'LastName', type: 'string', isRequired: false, length: 50 },
    { name: 'FirstName', type: 'string', isRequired: false, length: 50 },
  ]

  switch (requestTypeId) {
    case RequestTypeEnum.Bankrupt:
      if (countryLowerCase === 'uk')
        return headers.concat([
          {
            name: 'InsolvencyType',
            type: 'number',
            isRequired: false,
            length: -1,
          },
          { name: 'CaseNumber', type: 'string', isRequired: true, length: 40 },
          { name: 'FileDate', type: 'date', isRequired: true, length: -1 },
          {
            name: 'AttourneyName',
            type: 'string',
            isRequired: false,
            length: 100,
          },
          {
            name: 'AttourneyPhone',
            type: 'string',
            isRequired: false,
            length: 30,
          },
        ])
      return headers.concat([
        { name: 'CaseNumber', type: 'string', isRequired: true, length: 40 },
        { name: 'Chapter', type: 'number', isRequired: false, length: -1 },
        { name: 'FileDate', type: 'date', isRequired: true, length: -1 },
        {
          name: 'AttorneyName',
          type: 'string',
          isRequired: false,
          length: 100,
        },
        {
          name: 'AttorneyPhone',
          type: 'string',
          isRequired: false,
          length: 30,
        },
        { name: 'District', type: 'string', isRequired: false, length: 100 },
      ])
    case RequestTypeEnum.Deceased:
      if (countryLowerCase === 'uk')
        return headers.concat([
          { name: 'DateOfDeath', type: 'date', isRequired: true, length: -1 },
          {
            name: 'NotificationSource',
            type: 'string',
            isRequired: true,
            length: 300,
          },
        ])
      return headers.concat([
        { name: 'DateOfDeath', type: 'date', isRequired: false, length: -1 },
      ])
    case RequestTypeEnum.DirectPay:
      return headers.concat([
        { name: 'PaymentDate', type: 'date', isRequired: true, length: -1 },
        {
          name: 'PaymentAmount',
          type: 'number',
          isRequired: true,
          length: -1,
        },
        {
          name: 'ConsumerCheckOrReferenceNumber',
          type: 'string',
          isRequired: true,
          length: 100,
        },
      ])
    case RequestTypeEnum.DismissedDischarged:
      return headers.concat([
        {
          name: 'Type (Dismissal/Discharge)',
          type: 'string',
          isRequired: true,
          length: 10,
          domain: ['dismissal', 'discharge'],
        },
        {
          name: 'DateOfDismissalDischarge',
          type: 'date',
          isRequired: true,
          length: -1,
        },
      ])
    case RequestTypeEnum.Info:
      return headers.concat([
        {
          name: 'InfoType',
          type: 'string',
          isRequired: true,
          length: 50,
          domain: hardcodeData.getInfoTypes(),
        },
        {
          name: 'InfoRequested',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
      ])
    case RequestTypeEnum.Legal:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.Other:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.PaidPrior:
      return headers.concat([
        { name: 'PaidPriorDate', type: 'date', isRequired: true, length: 3 },
        {
          name: 'PaidPriorType',
          type: 'string',
          isRequired: true,
          length: 3,
          domain: hardcodeData.getPifSifTypes(),
        },
      ])
    case RequestTypeEnum.PifSif:
      return headers.concat([
        {
          name: 'PifSifType',
          type: 'string',
          isRequired: true,
          length: 3,
          domain: hardcodeData.getPifSifTypes(),
        },
        { name: 'PaymentDate', type: 'date', isRequired: false, length: -1 },
        {
          name: 'PaymentAmount',
          type: 'number',
          isRequired: false,
          length: -1,
        },
        {
          name: 'CheckOrReferenceNumber',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'LocationOrStore',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerAddress',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerCity',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerState',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerPostalCode',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
        {
          name: 'ConsumerPhoneNumber',
          type: 'string',
          isRequired: false,
          length: 4000,
        },
      ])
    case RequestTypeEnum.LienLoss:
      return headers.concat([
        { name: 'LienLossDate', type: 'date', isRequired: true, length: -1 },
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.Fraud:
      return headers.concat([
        { name: 'Explanation', type: 'string', isRequired: true, length: 4000 },
      ])
    case RequestTypeEnum.InaccurateData:
      return headers.concat([
        {
          name: 'InaccurateDataInfo',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
      ])
    case RequestTypeEnum.ScraMilitary:
      return headers.concat([
        {
          name: 'MilitaryBranch',
          type: 'string',
          isRequired: true,
          length: 45,
        },
        {
          name: 'ActiveDutyStartDate',
          type: 'date',
          isRequired: true,
          length: -1,
        },
        {
          name: 'ActiveDutyEndDate',
          type: 'date',
          isRequired: false,
          length: -1,
        },
      ])
    case RequestTypeEnum.AccountClosed:
      return headers.concat([
        {
          name: 'Reason',
          type: 'string',
          isRequired: true,
          length: 4000,
        },
        {
          name: 'ClosedDate',
          type: 'date',
          isRequired: true,
          length: -1,
        },
      ])
    default:
      throw new Error('Invalid request type')
  }
}

export const GetPostSaleRespondFileHeaders = (
  requestTypeId: number,
  action: string
) => {
  const headers: ColumnMetadata[] = []

  if (
    [RequestTypeEnum.Info, RequestTypeEnum.InaccurateData].includes(
      requestTypeId
    ) &&
    action === BulkRespondOperation.DataSubmission
  )
    headers.push({
      name: 'comments',
      type: 'string',
      isRequired: true,
      length: 4000,
    })
  else if (
    ![RequestTypeEnum.PifSif, RequestTypeEnum.AccountClosed].includes(
      requestTypeId
    )
  ) {
    headers.push({
      name: 'accept (y/n)',
      type: 'string',
      isRequired: true,
      length: 4000,
      domain: ['yes', 'y', 'no', 'n'],
    })
    headers.push({
      name: 'reject reason',
      type: 'string',
      isRequired: false,
      length: 4000,
    })
  }

  headers.push({ name: 'id', type: 'number', isRequired: true, length: -1 })

  return headers
}

export const GetAccountPlacementFileColumns = () => {
  const headers = [
    {
      name: 'ECAID',
      type: 'guid',
      isRequired: true,
      length: -1,
      substituteFields: ['PID', 'Loan ID'],
    },
    {
      name: 'PID',
      type: 'number',
      isRequired: false,
      length: -1,
    },
    {
      name: 'Loan ID',
      type: 'number',
      isRequired: false,
      length: -1,
    },
  ] as ColumnMetadata[]

  return headers
}
