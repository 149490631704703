import React from 'react'
import { MomentDateFormat, formatDate, getLocalDatetimer } from 'src/utils/date'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import { convertToTrueOrFalse } from 'src/utils/text'
import moment from 'moment-timezone'

export const getColumns = (
  userType: string,
  cardType: string,
  country: string,
  subFilter: string = ''
) => {
  const momentFormat = MomentDateFormat(country, false, false, true)

  const renderDateLocalTimeZone = (props: any) => {
    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {props.dataItem[props.field]
          ? moment(new Date(props.dataItem[props.field])).format(momentFormat)
          : props.dataItem[props.field]}
      </td>
    )
  }

  const renderDate = (props: any) => {
    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {props.dataItem[props.field]
          ? formatDate(new Date(props.dataItem[props.field]))
          : props.dataItem[props.field]}
      </td>
    )
  }

  const renderRemaining = (props: any) => {
    const { days, hours } = getLocalDatetimer(props.dataItem[props.field])
    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {days >= 0 ? `(${days} Days, ${hours} Hours)` : 'AUCTION CLOSED'}
      </td>
    )
  }

  const renderCurrency = (props: any) => {
    return (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
        style={{ textAlign: 'right' }}
      >
        {numberCurrencyDollar(
          props.dataItem[props.field],
          props.dataItem.portfolioCountry
        )}
      </td>
    )
  }

  const CheckRender = (props: any) => {
    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {props.dataItem[props.field] || 'N/A'}
      </td>
    )
  }

  const renderNumber = (props: any) => {
    return (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
        style={{ textAlign: 'right' }}
      >
        {props.dataItem[props.field]}
      </td>
    )
  }
  const renderScrubbedColumn = (props: any) => {
    return (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
        style={{ textAlign: 'right' }}
      >
        {props.dataItem.portfolioCountry === 'UK'
          ? 'N/A'
          : props.dataItem[props.field]}
      </td>
    )
  }

  const renderPercent = (props: any) => {
    return (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
        style={{ textAlign: 'right' }}
      >
        {numberToPercentage(props.dataItem[props.field])}
      </td>
    )
  }

  const isCardTypeAvailable = () => {
    return cardType === 'loaded-listed' || cardType === 'auction-closed'
  }
  const getPortfolioTypeMaxWidth = () => {
    if (isCardTypeAvailable()) return 130
    return 145
  }
  const getPortfolioTypeWidth = () => {
    if (isCardTypeAvailable()) return 130
    return 145
  }
  const getPortfolioIdMaxWidth = () => {
    if (isCardTypeAvailable()) return 80
    return 105
  }
  const getPortfolioIdWidth = () => {
    if (isCardTypeAvailable()) return 80
    return 105
  }

  const renderTrueOrFalse = (props: any) => {
    return (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
        style={{ textAlign: 'left' }}
      >
        {convertToTrueOrFalse(props.dataItem[props.field])}
      </td>
    )
  }

  const customLenderCell = (props: any) => {
    let maxLength = 30
    return props.dataItem[props.field].length > maxLength ? (
      <td
        {...props}
        onClick={() => props?.onRowClick?.(props)}
      >{`${props.dataItem[
        props.field
        // eslint-disable-next-line no-plusplus
      ].slice(0, --maxLength)}...`}</td>
    ) : (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {props.dataItem[props.field]}
      </td>
    )
  }

  const customAssetTypeCell = (props: any) => {
    let maxLength = 50
    const value = `${props.dataItem[props.field]} (${
      props.dataItem['portfolioTypeName']
    })`
    return value.length > maxLength ? (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>{`${value.slice(
        0,
        // eslint-disable-next-line no-plusplus
        --maxLength
      )}...`}</td>
    ) : (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {value}
      </td>
    )
  }

  const columnsToRetrieve: any[] = []

  if (userType === 'Seller') {
    columnsToRetrieve.push(
      {
        field: 'portfolioId',
        title: 'id',
        show: false,
        width: 75,
      },
      {
        field: 'portfolioNumber',
        title: 'PID',
        show: true,
        width: getPortfolioIdWidth(),
        maxWidth: getPortfolioIdMaxWidth(),
        filter: 'numeric',
      },
      {
        field: 'sellerName',
        title: 'Seller',
        show: true,
        width: 200,
        maxWidth: 200,
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 200,
        render: customLenderCell,
      }
    )

    if (cardType === 'loaded-listed') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 90,
          filter: 'numeric',
        },
        {
          field: 'numberOfAccounts',
          title: 'Accounts',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'countError',
          title: 'Errors',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'countWarning',
          title: 'Warnings',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'countScrubbed',
          title: 'Scrubbed',
          render: renderScrubbedColumn,
          alignRight: true,
          show: process.env.REACT_APP_COUNTRY !== 'UK',
          width: 80,
          filter: 'numeric',
        }
      )
    }

    if (cardType === 'auction-open') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'auctionCloseDateUtc',
          title: 'Auction Close',
          render: renderDateLocalTimeZone,
          show: true,
          width: 120,
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 90,
          filter: 'numeric',
        },
        {
          field: 'numberOfAccounts',
          title: 'Accounts',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'averageBalance',
          title: 'Avg. Balance',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'avgOrigination',
          title: 'Origination (Avg.)',
          render: renderDate,
          show: true,
          width: 120,
          filter: 'date',
          notFilterable: true,
        },
        {
          field: 'avgChargeOff',
          title: 'Charge Off (Avg.)',
          render: renderDate,
          show: true,
          width: 120,
          filter: 'date',
          notFilterable: true,
        }
      )
    }

    if (cardType === 'auction-closed') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 90,
          filter: 'numeric',
        },
        {
          field: 'highestBidPercent',
          title: 'Top Bid',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
          notFilterable: true,
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 90,
        },
        {
          field: 'auctionCloseDateUtc',
          title: 'Close Date',
          render: renderDate,
          show: true,
          width: 120,
          filter: 'date',
          notFilterable: true,
        }
      )
    }

    if (
      cardType === 'pending-agreement' ||
      cardType === 'pending-funding' ||
      cardType === 'sold'
    ) {
      columnsToRetrieve.push(
        { field: 'buyerName', title: 'Buyer', show: true, width: 75 },
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'psaDetailedStatus',
          title: 'PSA Status',
          show: cardType === 'pending-agreement',
          width: 120,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'saleRate',
          title: 'Sale Rate',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
          notFilterable: true,
        },
        {
          field: 'proceedsDisplayValue',
          title: 'Sale Proceed',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 110,
        },
        {
          field: 'numberOfBids',
          title: 'Bids',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        }
      )
    }

    if (cardType === 'pending-agreement') {
      columnsToRetrieve.push({
        field: 'portfolioCloseUtc',
        title: 'Close Date',
        render: renderDate,
        show: true,
        width: 120,
        filter: 'date',
        notFilterable: true,
      })
    }

    if (cardType === 'pending-funding') {
      columnsToRetrieve.push({
        field: 'fundingDateUtc',
        title: 'Fund Date',
        render: renderDate,
        show: true,
        width: 130,
        filter: 'date',
        notFilterable: true,
      })
      if (subFilter === 'awaiting-seller-fee') {
        columnsToRetrieve.push(
          {
            field: 'bosConfirmed',
            title: 'BOS Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          },
          {
            field: 'serviceFeeConfirmed',
            title: 'Service Fee Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          }
        )
      }
    }

    if (cardType === 'sold') {
      columnsToRetrieve.push(
        {
          field: 'fundedDate',
          title: 'Funded Date',
          render: renderDate,
          show: true,
          width: 130,
          filter: 'date',
          notFilterable: true,
        },
        {
          field: 'averageBalance',
          title: 'Average Balance',
          show: true,
          width: 130,
          render: renderCurrency,
          filter: 'numeric',
        },
        {
          field: 'averageMonthsSinceChargeOff',
          title: 'Average Charge Off',
          show: true,
          width: 130,
          filter: 'date',
          notFilterable: true,
        }
      )
    }
  }

  if (userType === 'Buyer') {
    columnsToRetrieve.push(
      {
        field: 'portfolioId',
        title: 'id',
        show: false,
        width: 75,
      },
      {
        field: 'portfolioNumber',
        title: 'PID',
        show: true,
        width: getPortfolioIdWidth(),
        maxWidth: getPortfolioIdMaxWidth(),
        filter: 'numeric',
      },
      {
        field: 'sellerName',
        title: 'Seller',
        show: true,
        width: 200,
        maxWidth: 200,
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 200,
        render: customLenderCell,
      },
      {
        field: 'assetType',
        title: 'Asset Type',
        show: true,
        width: getPortfolioTypeWidth(),
        maxWidth: getPortfolioTypeMaxWidth(),
        render: customAssetTypeCell,
      },
      {
        field: 'psaDetailedStatus',
        title: 'PSA Status',
        show: cardType === 'pending-agreement',
        width: 120,
      },
      {
        field: 'faceValue',
        title: 'Face Value',
        render: renderCurrency,
        alignRight: true,
        show: true,
        width: 120,
        filter: 'numeric',
      },
      {
        field: 'avgChargeOff',
        title: 'Write Off (Avg.)',
        render: renderDate,
        show: true,
        width: 120,
        filter: 'date',
        notFilterable: true,
      }
    )

    if (cardType === 'auction-open') {
      columnsToRetrieve.splice(5, 0, {
        field: 'forwardFlowAvailable',
        title: 'Forward Flow Enabled',
        render: renderTrueOrFalse,
        show: true,
        width: 160,
        notFilterable: true,
      })

      columnsToRetrieve.splice(7, 0, {
        field: 'auctionCloseDateUtc',
        title: 'Auction Close',
        render: renderDateLocalTimeZone,
        show: true,
        width: 120,
        filter: 'date',
        notFilterable: true,
      })

      columnsToRetrieve.push(
        {
          field: 'numberOfAccounts',
          title: 'Accounts',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 100,
          filter: 'numeric',
        },
        {
          field: 'averageBalance',
          title: 'Avg. Balance',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 90,
          filter: 'numeric',
        },
        {
          field: 'avgOrigination',
          title: 'Origination (Avg.)',
          render: renderDate,
          show: true,
          width: 120,
          filter: 'date',
          notFilterable: true,
        }
      )
    } else if (cardType === 'bid-submitted') {
      columnsToRetrieve.splice(5, 0, {
        field: 'forwardFlowAvailable',
        title: 'Forward Flow Enabled',
        render: renderTrueOrFalse,
        show: true,
        width: 160,
        notFilterable: true,
      })

      columnsToRetrieve.push(
        {
          field: 'myBid',
          title: 'My Bid',
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
          notFilterable: true,
        },
        {
          field: 'proceedsDisplayValue',
          title: 'Sale Proceed',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 130,
          filter: 'numeric',
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 110,
        },
        {
          field: 'auctionCloseDateUtc',
          title: 'Auction closes in',
          render: renderRemaining,
          show: true,
          width: 150,
          notFilterable: true,
        }
      )
    } else if (cardType !== 'lost') {
      columnsToRetrieve.push(
        {
          field: 'saleRate',
          title: 'Sale Rate',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 120,
          notFilterable: true,
        },
        {
          field: 'proceedsDisplayValue',
          title: 'Sale Proceed',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 110,
        }
      )
    }

    if (cardType === 'lost') {
      columnsToRetrieve.push(
        {
          field: 'forwardFlowBidPercentDisplayValue',
          title: 'Forward Flow Bid',
          show: true,
          width: 100,
          render: CheckRender,
          notFilterable: true,
        },
        {
          field: 'oneTimeBidPercentDisplayValue',
          title: 'One Time Bid',
          show: true,
          width: 100,
          render: CheckRender,
          notFilterable: true,
        }
      )
    }

    if (cardType === 'pending-agreement') {
      columnsToRetrieve.push({
        field: 'portfolioCloseUtc',
        title: 'Close Date',
        render: renderDate,
        show: true,
        width: 120,
        filter: 'date',
        notFilterable: true,
      })
    } else if (cardType === 'pending-funding') {
      columnsToRetrieve.push({
        field: 'fundingDateUtc',
        title: 'Fund Date',
        render: renderDate,
        show: true,
        width: 130,
        filter: 'date',
        notFilterable: true,
      })
      if (subFilter === 'awaiting-seller-fee') {
        columnsToRetrieve.push(
          {
            field: 'bosConfirmed',
            title: 'BOS Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          },
          {
            field: 'serviceFeeConfirmed',
            title: 'Service Fee Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          }
        )
      }
    } else if (cardType === 'purchased') {
      columnsToRetrieve.push({
        field: 'purchasedDate',
        title: 'Funded Date',
        render: renderDate,
        show: true,
        width: 130,
        filter: 'date',
        notFilterable: true,
      })
    }
  }

  if (userType === 'internal') {
    columnsToRetrieve.push(
      {
        field: 'portfolioId',
        title: 'id',
        show: false,
        width: 75,
      },
      {
        field: 'portfolioNumber',
        title: 'PID',
        show: true,
        width: getPortfolioIdWidth(),
        maxWidth: getPortfolioIdMaxWidth(),
        filter: 'numeric',
      },
      {
        field: 'sellerName',
        title: 'Seller',
        show: true,
        width: 200,
        maxWidth: 200,
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 200,
        render: customLenderCell,
      }
    )

    if (cardType === 'loaded-listed') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 90,
          filter: 'numeric',
        },
        {
          field: 'numberOfAccounts',
          title: 'Accounts',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'countError',
          title: 'Errors',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },

        {
          field: 'countWarning',
          title: 'Warnings',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'countScrubbed',
          title: 'Scrubbed',
          render: renderScrubbedColumn,
          alignRight: true,
          show: process.env.REACT_APP_COUNTRY !== 'UK',
          width: 80,
          filter: 'numeric',
        }
      )
    }

    if (cardType === 'auction-open') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'auctionCloseDateUtc',
          title: 'Auction Close',
          render: renderDateLocalTimeZone,
          show: true,
          width: 120,
          filter: 'date',
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 150,
          filter: 'numeric',
        },
        {
          field: 'numberOfViews',
          title: 'Views',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'numberOfDownloads',
          title: 'Downloads',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'numberOfBids',
          title: 'Bids',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'reserveMet',
          title: 'Reserve Met',
          show: true,
          width: 80,
        }
      )
    }

    if (cardType === 'auction-closed') {
      columnsToRetrieve.push(
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'forwardFlowAvailable',
          title: 'Forward Flow Enabled',
          render: renderTrueOrFalse,
          show: true,
          width: 160,
          notFilterable: true,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'highestBidPercent',
          title: 'Top Bid',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 120,
          notFilterable: true,
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 110,
        },
        {
          field: 'auctionCloseDateUtc',
          title: 'Close Date',
          render: renderDate,
          show: true,
          width: 150,
          filter: 'date',
          notFilterable: true,
        }
      )
    }

    if (
      cardType === 'pending-agreement' ||
      cardType === 'pending-funding' ||
      cardType === 'sold'
    ) {
      columnsToRetrieve.push(
        { field: 'buyerName', title: 'Buyer', show: true, width: 75 },
        {
          field: 'assetType',
          title: 'Asset Type',
          show: true,
          width: getPortfolioTypeWidth(),
          maxWidth: getPortfolioTypeMaxWidth(),
          render: customAssetTypeCell,
        },
        {
          field: 'psaDetailedStatus',
          title: 'PSA Status',
          show: cardType === 'pending-agreement',
          width: 120,
        },
        {
          field: 'faceValue',
          title: 'Face Value',
          show: true,
          render: renderCurrency,
          alignRight: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'saleRate',
          title: 'Sale Rate',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 120,
          notFilterable: true,
        },
        {
          field: 'proceedsDisplayValue',
          title: 'Sale Proceed',
          render: renderCurrency,
          alignRight: true,
          show: true,
          width: 120,
          filter: 'numeric',
        },
        {
          field: 'flowTerm',
          title: 'Flow Term',
          show: true,
          width: 110,
        },
        {
          field: 'numberOfBids',
          title: 'Bids',
          render: renderNumber,
          alignRight: true,
          show: true,
          width: 80,
          filter: 'numeric',
        },
        {
          field: 'revenueRate',
          title: 'Revenue',
          render: renderPercent,
          alignRight: true,
          show: true,
          width: 120,
          notFilterable: true,
        }
      )
    }

    if (cardType === 'pending-agreement') {
      columnsToRetrieve.push({
        field: 'portfolioCloseUtc',
        title: 'Close Date',
        render: renderDate,
        show: true,
        width: 120,
        filter: 'date',
        notFilterable: true,
      })
    }

    if (cardType === 'pending-funding') {
      columnsToRetrieve.push({
        field: 'fundingDateUtc',
        title: 'Fund Date',
        render: renderDate,
        show: true,
        width: 130,
        filter: 'date',
        notFilterable: true,
      })
      if (subFilter === 'awaiting-seller-fee') {
        columnsToRetrieve.push(
          {
            field: 'bosConfirmed',
            title: 'BOS Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          },
          {
            field: 'serviceFeeConfirmed',
            title: 'Service Fee Confirmed',
            render: renderTrueOrFalse,
            show: true,
            width: 100,
            notFilterable: true,
          }
        )
      }
    }

    if (cardType === 'sold') {
      columnsToRetrieve.push(
        {
          field: 'fundedDate',
          title: 'Funded Date',
          render: renderDate,
          show: true,
          width: 130,
          filter: 'date',
          notFilterable: true,
        },
        {
          field: 'averageBalance',
          title: 'Average Balance',
          show: true,
          width: 130,
          render: renderCurrency,
          filter: 'numeric',
        },
        {
          field: 'averageMonthsSinceChargeOff',
          title: 'Average Charge Off',
          show: true,
          width: 130,
          filter: 'date',
          notFilterable: true,
        }
      )
    }
  }

  return columnsToRetrieve
}
