import React, { useContext } from 'react'
import { Box, Skeleton } from '@mui/material'
import DynamicTable, {
  cellHyperlinkRedirect,
} from 'src/components/DynamicTable'
import { SELLER_DETAIL, BUYER_DETAIL, POST_SALE_DETAIL } from 'src/routes'
import { GlobalContext } from 'src/context/GlobalContext'
import { getStandardUri } from 'src/utils/common'
import { AccountPostSale } from 'src/data/features/get/account/types'

interface PostSaleProps {
  accountPostSaleData?: AccountPostSale[] | undefined
  loading: boolean
  totalItems?: number | undefined
  pageSize?: number | undefined
  showLoandId?: boolean | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const PortfolioPostSale: React.FC<PostSaleProps> = ({
  accountPostSaleData,
  loading,
  totalItems,
  pageSize,
  showLoandId,
  onChangePagination,
}: PostSaleProps) => {
  const { onLoading } = useContext(GlobalContext)
  if (loading || onLoading()) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const dataColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(POST_SALE_DETAIL),
          props.cell.value,
          {}
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Request Type',
      accessor: 'requestType',
      Cell: (props: any) => {
        return (
          <span>{props.cell.value.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
        )
      },
    },
    {
      Header: 'Effort Exhausted',
      accessor: 'effortExhausted',
      Cell: (props: any) => {
        return <span>{props.cell.value === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      Header: 'Escalated',
      accessor: 'escalated',
      Cell: (props: any) => {
        return <span>{props.cell.value === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(SELLER_DETAIL),
          accountPostSaleData?.find((e) => e.id === props.row.values['id'])
            ?.sellerId,
          {}
        ),
    },
    {
      Header: 'Buyer',
      accessor: 'buyer',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(BUYER_DETAIL),
          accountPostSaleData?.find((e) => e.id === props.row.values['id'])
            ?.buyerId,
          {}
        ),
    },
  ]
  const GetColumnsData = () => {
    if (showLoandId)
      dataColumns.push({
        Header: 'Loan ID',
        accessor: 'loanId',
      })
    return dataColumns
  }
  return (
    <DynamicTable
      data={accountPostSaleData}
      pageSize={pageSize ?? 10}
      totalItems={totalItems}
      columns={GetColumnsData()}
      onChangePagination={onChangePagination}
    />
  )
}

PortfolioPostSale.defaultProps = {
  accountPostSaleData: undefined,
  totalItems: undefined,
  onChangePagination: undefined,
  pageSize: 10,
  showLoandId: false,
}

export default PortfolioPostSale
