/* eslint-disable react/require-default-props */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Icon } from 'everchain-uilibrary'

const AccordionSummaryCustom2 = styled(AccordionSummary)`
  justify-content: space-between;
  .MuiAccordionSummary-content.MuiAccordionSummary-content {
    flex-grow: inherit;
  }
  font-size: 1rem;
  margin: 0px;
`

const AccordionTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
`

interface DTAccordionProps {
  id?: string
  title: string
  expanded?: boolean
  icon?: React.ReactElement
  children?: React.ReactNode
}

const DTAccordion: React.FC<DTAccordionProps> = ({
  expanded,
  id,
  title,
  icon,
  children,
}: DTAccordionProps) => {
  const [expandedState, setExpandedState] = useState(expanded || false)

  return (
    <Box mt={5} display="flex-end">
      <Accordion
        expanded={expandedState}
        onChange={() => {
          setExpandedState(!expandedState)
        }}
        id={id}
      >
        <AccordionSummaryCustom2
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Box display="flex" flexDirection="row">
            <Box pr={3}>{icon}</Box>

            <AccordionTitle>{title}</AccordionTitle>
          </Box>
        </AccordionSummaryCustom2>
        <AccordionDetails>{children && <>{children}</>}</AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default DTAccordion
