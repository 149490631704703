import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLazyQuery } from '@apollo/client'

import UploadDragDrop from 'src/components/UploadDragDrop'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  PortfolioTemplateFileData,
  PortfolioTemplateInfo,
} from 'src/graphql/models/PortfolioTemplates'
import { GET_PORTFOLIO_TEMPLATE_FILE } from 'src/graphql/operations/queries/portfolio'
import { useUploadPortfolioTemplateFile } from 'src/graphql/operations/mutations/portfolio'

import {
  Grid,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material'
import moment from 'moment'
import { ButtonGroup } from '../styles'
import { textPrimary, textSecondary } from 'everchain-uilibrary'
import {
  refetchQueriesWrapper,
  useCustomQuery,
} from 'src/infra/react-query-wrapper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getDigitalSellerSurveysAvailable } from 'src/data/features/get/survey/survey'
import { applyDssToPortfolioTemplate } from 'src/data/features/post/survey/survey'

interface DownloadAndUploadProps {
  templateId: number | string
  portfolioInfo?: PortfolioTemplateInfo | any
  canPermissionEdit?: boolean
}

const DownloadAndUpload: React.FC<DownloadAndUploadProps> = ({
  templateId,
  portfolioInfo,
  canPermissionEdit,
}: DownloadAndUploadProps) => {
  const [fileTypeSelected, setfileTypeSelected] = useState('')
  const [dssSelected, setDssSelected] = useState<any>()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleFileUpload = (acceptedFiles: File[], fileType: string) => {
    if (acceptedFiles && acceptedFiles.length) {
      setfileTypeSelected(fileType)
      uploadPortfolioTemplateFile({
        variables: {
          sellerUploadTemplateId: templateId,
          fileType,
          file: acceptedFiles[0],
        },
      })
    } else {
      enqueueSnackbar(
        'Error while trying to upload the file',
        notistackOptions('warning')
      )
    }
  }

  const { uploadPortfolioTemplateFile, loading: fileUploading } =
    useUploadPortfolioTemplateFile({
      onCompleted: (data: any) => {
        if (data || {}) {
          enqueueSnackbar(
            'File uploaded successfully',
            notistackOptions('success')
          )
          refetchQueriesWrapper(
            ['getPortfolioTemplate', templateId],
            queryClient
          )
        } else {
          enqueueSnackbar(
            'Error while trying to upload the file',
            notistackOptions('warning')
          )
        }
      },
    })

  const [getDownloadFile, { loading: loadingDownload }] =
    useLazyQuery<PortfolioTemplateFileData>(GET_PORTFOLIO_TEMPLATE_FILE, {
      onCompleted: (templateDownloadFile) => {
        if (
          templateDownloadFile &&
          templateDownloadFile.portfolioTemplateFileData
        ) {
          const { fileContentBase64, fileContentType, fileDownloadName } =
            templateDownloadFile.portfolioTemplateFileData
          const linkSource = `data:${fileContentType};base64,${fileContentBase64}`
          const downloadLink = document.createElement('a')

          downloadLink.href = linkSource
          downloadLink.download = fileDownloadName
          downloadLink.click()
        }
      },
    })

  const { data: surveysResponse, isFetching: loadingSurveys } =
    useCustomQuery<any>(
      ['getDigitalSellerSurveysAvailable', templateId],
      async () => getDigitalSellerSurveysAvailable(Number(templateId)),
      {
        cacheTime: 0,
      }
    )

  const surveysAvailable = surveysResponse

  const handleDownload = (fileType: string) => {
    setfileTypeSelected(fileType)
    getDownloadFile({
      variables: {
        sellerUploadTemplateId: String(templateId),
        fileType,
      },
    })
  }

  const handleChange = (event: any) => {
    setDssSelected(event.target.value)
  }

  const applySurveyToPortfolioTemplateMutation = useMutation({
    mutationFn: (variable: any) => {
      return applyDssToPortfolioTemplate(
        variable.surveyId,
        variable.sellerUploadTemplateId
      )
    },
    onSuccess: (data: any) => {
      enqueueSnackbar(
        'Digital seller survey applied',
        notistackOptions('success')
      )
      refetchQueriesWrapper(['getPortfolioTemplate', templateId], queryClient)
    },
    onError: () => {
      enqueueSnackbar('Error', notistackOptions('error'))
    },
  })

  const handleApply = () => {
    const request = {
      surveyId: dssSelected?.id,
      sellerUploadTemplateId: Number(templateId),
    }

    applySurveyToPortfolioTemplateMutation.mutate(request)
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={6} className="downloadUploadGrid">
        <Typography variant="subtitle1" color={textSecondary.color}>
          Seller Survey (optional)
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            paddingTop: '5px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              gap: '15px',
            }}
          >
            <div style={{ width: '250px' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="dss-label">Digital Seller Survey</InputLabel>
                <Select
                  labelId="dss-label"
                  id="dss-select"
                  fullWidth
                  value={dssSelected}
                  name="digitalSellerSurvey"
                  onChange={handleChange}
                  disabled={applySurveyToPortfolioTemplateMutation.isLoading}
                >
                  {!loadingSurveys &&
                    surveysAvailable?.map((x: any) => (
                      <MenuItem
                        key={x.id}
                        value={x}
                        disabled={
                          applySurveyToPortfolioTemplateMutation.isLoading
                        }
                      >
                        {x.name} -{' '}
                        {moment(new Date(x.pdfGeneratedAt)).format('MM/DD/YY')}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ gap: '10px', display: 'flex' }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  disabled={
                    applySurveyToPortfolioTemplateMutation.isLoading ||
                    surveysAvailable?.length < 1
                  }
                >
                  Apply
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  disabled={
                    loadingDownload ||
                    !portfolioInfo?.sellerSurveyUri ||
                    !canPermissionEdit
                  }
                  onClick={() => handleDownload('seller-survey')}
                  endIcon={
                    loadingDownload &&
                    fileTypeSelected === 'seller-survey' && (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  Download Seller Survey
                </Button>
              </div>
            </div>
          </Box>
          {portfolioInfo.appliedSurveyName && (
            <Typography color={textPrimary.color}>
              <b>Digital Seller Survey in use:</b>{' '}
              {portfolioInfo.appliedSurveyName}
            </Typography>
          )}
        </Box>
        <Box my={4}>
          <UploadDragDrop
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'seller-survey')
            }}
            uploadDisabled={!canPermissionEdit}
            uploading={fileUploading && fileTypeSelected === 'seller-survey'}
            linkText={`${
              portfolioInfo?.sellerSurveyUri ? 'Replace' : 'Upload'
            } the seller survey`}
            text="or drop a file here to upload"
            size="small"
            accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className="downloadUploadGrid">
        <Typography variant="subtitle1" color={textSecondary.color}>
          PSA Template (optional)
        </Typography>
        <ButtonGroup
          style={{
            marginTop: '15px',
            marginBottom: portfolioInfo.appliedSurveyName ? '50px' : '0px',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => handleDownload('psa-template')}
            disabled={
              loadingDownload ||
              !portfolioInfo?.psaTemplateUri ||
              !canPermissionEdit
            }
            endIcon={
              loadingDownload &&
              fileTypeSelected === 'psa-template' && (
                <CircularProgress size={16} />
              )
            }
          >
            Download PSA Template
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={
              loadingDownload ||
              !portfolioInfo?.psaTemplateUri ||
              !canPermissionEdit
            }
            onClick={() => handleDownload('psa-template-test')}
            endIcon={
              loadingDownload &&
              fileTypeSelected === 'psa-template-test' && (
                <CircularProgress size={16} />
              )
            }
          >
            Test PSA Template Document
          </Button>
        </ButtonGroup>
        <Box my={4}>
          <UploadDragDrop
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'psa-template')
            }}
            uploadDisabled={!canPermissionEdit}
            uploading={fileUploading && fileTypeSelected === 'psa-template'}
            linkText={`${
              portfolioInfo?.psaTemplateUri ? 'Replace' : 'Upload'
            } the PSA Template`}
            text="or drop a file here to upload"
            size="small"
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className="downloadUploadGrid">
        <Typography variant="subtitle1" color={textSecondary.color}>
          Custom PSA (optional)
        </Typography>
        <ButtonGroup
          style={{
            marginTop: '15px',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => handleDownload('psa-custom')}
            disabled={
              loadingDownload ||
              !portfolioInfo?.customPSAUri ||
              !canPermissionEdit
            }
            endIcon={
              loadingDownload &&
              fileTypeSelected === 'psa-custom' && (
                <CircularProgress size={16} />
              )
            }
          >
            Download Custom PSA
          </Button>
        </ButtonGroup>
        <Box my={4}>
          <UploadDragDrop
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'psa-custom')
            }}
            uploadDisabled={!canPermissionEdit}
            uploading={fileUploading && fileTypeSelected === 'psa-custom'}
            linkText={`${
              portfolioInfo?.customPSAUri ? 'Replace' : 'Upload'
            } the custom PSA`}
            text="or drop a file here to upload"
            size="small"
            accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className="downloadUploadGrid">
        <Typography variant="subtitle1" color={textSecondary.color}>
          Bill of Sale (optional)
        </Typography>
        <ButtonGroup
          style={{
            marginTop: '15px',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => handleDownload('bill-of-sale')}
            disabled={
              loadingDownload ||
              !portfolioInfo?.billOfSaleUri ||
              !canPermissionEdit
            }
            endIcon={
              loadingDownload &&
              fileTypeSelected === 'bill-of-sale' && (
                <CircularProgress size={16} />
              )
            }
          >
            Download Bill of Sale
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={
              loadingDownload ||
              !portfolioInfo?.billOfSaleUri ||
              !canPermissionEdit
            }
            onClick={() => handleDownload('bill-of-sale-test')}
            endIcon={
              loadingDownload &&
              fileTypeSelected === 'bill-of-sale-test' && (
                <CircularProgress size={16} />
              )
            }
          >
            Test Bill of Sale Document
          </Button>
        </ButtonGroup>
        <Box my={4}>
          <UploadDragDrop
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'bill-of-sale')
            }}
            uploadDisabled={!canPermissionEdit}
            uploading={fileUploading && fileTypeSelected === 'bill-of-sale'}
            linkText={`${
              portfolioInfo?.billOfSaleUri ? 'Replace' : 'Upload'
            } the Bill of Sale`}
            text="or drop a file here to upload"
            size="small"
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </Box>
      </Grid>
    </Grid>
  )
}

DownloadAndUpload.defaultProps = {
  portfolioInfo: {},
  canPermissionEdit: false,
}
export default DownloadAndUpload
