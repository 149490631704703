import React, { useContext, useEffect, useState } from 'react'
import DynamicTable from 'src/components/DynamicTable'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ConfirmDialog } from 'src/components/Dialogs'
import { useAcceptPortfolioStatuteOfLimitation } from 'src/graphql/operations/mutations/portfolio'
import { ConvertDateToUTC, formatDateAndTimeCountry } from 'src/utils/date'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import Loader from '../../../components/Loader/Loader'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { PortfolioFileLink } from 'src/data/features/get/portfolio/types'
import { getPortfolioFileUri } from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  DocIcon,
  ExcelIcon,
  NoTypeIcon,
  PdfIcon,
  ZipIcon,
} from 'src/components/Icons'
import PortfolioSurveyDialog from './PortfolioSurveyDialog'

interface PortfolioFileLinksTableProps {
  portfolioId: string | undefined
  data?: PortfolioFileLink[] | undefined
  hideUserColumn: boolean
  portfolioTypeId: number | undefined
  portfolioCountry: string | undefined
  businessId: string | undefined
  surveyId: number
  fetchedPortfolioData: boolean
}

const PortfolioFileLinksTable: React.FC<PortfolioFileLinksTableProps> = ({
  portfolioId,
  data,
  hideUserColumn,
  portfolioTypeId,
  portfolioCountry,
  businessId,
  surveyId,
  fetchedPortfolioData,
}: PortfolioFileLinksTableProps) => {
  const { userPermissions } = useContext(AuthContext)
  const [fileType, setFileType] = useState<string>('')
  const [isGettingPortfolioUri, setIsGettingPortfolioUri] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState<string>('')
  const [openLoader, setOpenLoader] = useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const [statuteOfLimitationOpen, setStatuteOfLimitationOpen] =
    useState<boolean>(false)
  const [soldFileDownloaded, setSoldFileDownloaded] = useState<boolean>(false)
  const { profileClient } = useContext<any>(AuthContext)
  const isChargeOffPortfolio = portfolioTypeId !== 2
  const ability = useContext(AbilityContext)
  const [openSurveyDialog, setOpenSurveyDialog] = useState<boolean>(false)
  const [fileLinks, setFileLinks] = useState<any>([])

  const canDownloadPII = ability.can(
    PermissionCodeAccess.MarketPlace_Basic_DownloadPortfolioFilesPII,
    'any'
  )

  const canDownloadNonPII = ability.can(
    PermissionCodeAccess.MarketPlace_Basic_DownloadPortfolioFilesNonPII,
    'any'
  )

  const GetFileIcon = (contentType: string) => {
    switch (contentType) {
      case 'application/pdf':
        return <PdfIcon width="130%" height="130%" />
      case 'application/zip':
        return <ZipIcon width="130%" height="130%" />
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DocIcon width="130%" height="130%" />
      case 'text/csv':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <ExcelIcon width="130%" height="130%" />
      default:
        return <NoTypeIcon width="130%" height="130%" />
    }
  }
  const cellCreateBy = (props: any): React.ReactElement => {
    return props.cell.value ? (
      <div data-cy="created-by-field">
        <span>{props.cell.value}</span>
      </div>
    ) : (
      <div data-cy="created-by-field">
        <span>System</span>
      </div>
    )
  }

  const getFileTooltip = (type: any) => {
    if (
      type === 'data-sold' ||
      type === 'data-masked' ||
      type === 'data-masked-additional-unmask'
    )
      return 'Total valid accounts'

    if (type === 'data-removed' || type === 'data-removed-buyer')
      return 'Total accounts removed'

    if (type === 'errors-warnings') return 'Total errors & warnings'

    return ''
  }

  const fileLinkColumns = [
    {
      Header: 'File Name',
      accessor: 'displayName',
      Cell: (props: any) => {
        return (
          <Box width={300}>
            <Button
              data-cy="file-name-button"
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              startIcon={
                <Box width={20} height={20}>
                  {GetFileIcon(props.row.original.contentType)}
                </Box>
              }
              disabled={
                isFetching ||
                !props.row.original.downloadable ||
                (soldFileDownloaded &&
                  props.row.original.fileType === 'data-sold') ||
                (props.row.original.fileType === 'data-masked' &&
                  !canDownloadNonPII) ||
                (['data-sold', 'data-masked-additional-unmask'].includes(
                  props.row.original.fileType || ''
                ) &&
                  !canDownloadPII)
              }
              onClick={() => {
                if (props.row.original.downloadable) {
                  if (props.row.original.isSurvey) {
                    setOpenSurveyDialog(true)
                  } else {
                    onDownload(props.row.original.fileType)
                  }
                }
              }}
            >
              {props.cell.value}
            </Button>{' '}
            {props?.row?.original?.additionalInfo ? (
              <Tooltip
                placement="top"
                title={
                  <Typography>
                    {props?.row?.original?.additionalInfo}
                  </Typography>
                }
              >
                <Icon
                  name="InfoOutlined"
                  style={{ fontSize: 'medium' }}
                  color="black"
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
        )
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdByUserName',
      Cell: cellCreateBy,
    },
    {
      Header: 'Count',
      accessor: 'count',
      Cell: (props: any) => {
        return props.cell.value ? (
          <div data-cy="count-field" style={{ textAlign: 'right' }}>
            <span>{props.cell.value}</span>{' '}
            <Tooltip title={getFileTooltip(props?.row?.original?.fileType)}>
              <Icon name="HelpOutline" fontSize="inherit" />
            </Tooltip>
          </div>
        ) : (
          <div data-cy="count-field" style={{ textAlign: 'right' }}>
            <span>{props.cell.value}</span>
          </div>
        )
      },
    },
    {
      Header: () => <div>Created Date</div>,
      accessor: 'createdUtc',
      Cell: (props: any): React.ReactElement => {
        return props.cell.value ? (
          <div data-cy="created-date-field" style={{ width: '150px' }}>
            <span>
              {props.cell.value !== ''
                ? formatDateAndTimeCountry(
                    new Date(ConvertDateToUTC(props.cell.value)),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'}
            </span>
          </div>
        ) : (
          <div data-cy="created-date-field">
            <span>N/A</span>
          </div>
        )
      },
    },
  ].filter((item) => {
    return !hideUserColumn || item.Header !== 'User'
  })

  const { isFetching } = useCustomQuery<any>(
    ['getPortfolioFileUri'],
    async () =>
      getPortfolioFileUri(portfolioId, fileType).then((responseData: any) => {
        setIsGettingPortfolioUri(false)
        const uri = responseData ?? ''
        downloadFile(uri)
      }),
    { enabled: isGettingPortfolioUri, cacheTime: 0 }
  )

  const onDownload = (type: any) => {
    const auxFile = type as string
    setFileType(auxFile)

    if (
      (auxFile === 'errors-warnings' || auxFile === 'data-masked') &&
      profileClient?.Type === 'Buyer' &&
      portfolioCountry === 'US' &&
      isChargeOffPortfolio
    ) {
      setStatuteOfLimitationOpen(true)
    } else if (auxFile === 'data-sold') {
      if (!soldFileDownloaded) {
        let fileName = 'sold accounts file'
        if (userPermissions.type === 'Buyer')
          fileName = 'purchased accounts file'

        setConfirmationMessage(
          `The ${fileName} can only be downloaded once. The original sensitive data will be purged from our database after 3 days of the ${fileName} download.` +
            ' Please contact the EverChain Compliance Department if you have any questions or concerns.'
        )

        if (confirmationMessage) {
          setConfirmationTitle(
            confirmationMessage.toLowerCase().indexOf('purchased accounts') ===
              -1
              ? 'Sold Accounts Download'
              : 'Purchase Accounts Download'
          )
          setOpenDialog(true)
        }
      }
    } else {
      setIsGettingPortfolioUri(true)
    }
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const handleDownloadSoldFile = () => {
    setOpenDialog(false)
    setOpenLoader(true)
    setSoldFileDownloaded(true)
    setIsGettingPortfolioUri(true)
    setOpenLoader(false)
  }

  const { acceptPortfolioStatuteOfLimitation } =
    useAcceptPortfolioStatuteOfLimitation({
      onCompleted: () => {
        setStatuteOfLimitationOpen(false)
        setIsGettingPortfolioUri(true)
        setOpenLoader(false)
      },
      onError: () => {
        setOpenLoader(false)
      },
    })

  const onStatuteOfLimitationClose = () => {
    setOpenLoader(true)
    acceptPortfolioStatuteOfLimitation({
      variables: {
        portfolioId,
      },
    })
  }

  useEffect(() => {
    if (data && data?.length > 0 && fetchedPortfolioData) {
      const sellerSurveyRecord: PortfolioFileLink = {
        id: '',
        displayName: 'Seller Survey',
        downloadable: true,
        portfolioId: portfolioId || '',
        fileType: 'survey',
        visible: true,
        createdByUserId: 'System',
        createdByUserName: 'System',
        createdUtc: undefined,
        count: '',
        additionalInfo: 'Generate Seller Survey',
        blobUri: '',
        contentType: 'application/pdf',
        isSurvey: true,
      }

      if (surveyId > 0) {
        data.push(sellerSurveyRecord)
      }
      setFileLinks(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId])

  return (
    <Box width="100%">
      <DynamicTable
        data={fileLinks}
        pageSize={15}
        columns={fileLinkColumns}
        hideScroolBar={true}
      />
      <ConfirmDialog
        open={statuteOfLimitationOpen}
        closeName="Cancel"
        confirmName="Ok"
        onClose={() => setStatuteOfLimitationOpen(false)}
        onConfirm={onStatuteOfLimitationClose}
        title="Statute of Limitation Communication"
      >
        <>
          {openLoader && <Loader specificHeight="70%" />}
          <Typography variant="body2" component="div">
            <Box ml={8} mb={4}>
              {portfolioCountry === 'UK'
                ? `              The statements provided regarding any applicable statute of
              limitation is being provided for your information only, and should
              not be relied on in making evaluation of the offered portfolio.
              EverChain is not operating as an attourney and has based the
              Statute of Limitations calculations on information for written
              contracts published by industry representatives. EverChain does
              not represent or warrant that the calculations are correct or that
              the interpretation of the laws surrounding Statute of Limitations
              are accurate. A myriad of factors under state law should be
              considered when determining the applicable limitation period. You
              should consult with your counsel for making any determination on
              the applicable statute of limitations.`
                : `The statements provided regarding any applicable statute of
              limitation is being provided for your information only, and should
              not be relied on in making evaluation of the offered portfolio.
              EverChain is not operating as an attorney and has based the
              Statute of Limitations calculations on information for written
              contracts published by industry representatives. EverChain does
              not represent or warrant that the calculations are correct or that
              the interpretation of the laws surrounding Statute of Limitations
              are accurate. A myriad of factors under state law should be
              considered when determining the applicable limitation period. You
              should consult with your counsel for making any determination on
              the applicable statute of limitations.`}
            </Box>
          </Typography>
        </>
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialog}
        closeName="Cancel"
        confirmName="Continue"
        onClose={() => setOpenDialog(false)}
        onConfirm={handleDownloadSoldFile}
        title={confirmationTitle}
        description={
          <>
            {openLoader && <Loader specificHeight="70%" />}
            <Box mb={3}>
              <Typography variant="body2" color={textSecondary.color}>
                {confirmationMessage}
              </Typography>
            </Box>
          </>
        }
      />
      {surveyId && (
        <PortfolioSurveyDialog
          businessId={businessId}
          surveyId={surveyId}
          portfolioId={portfolioId}
          openSurveyDialog={openSurveyDialog}
          setOpenSurveyDialog={setOpenSurveyDialog}
        />
      )}
    </Box>
  )
}

PortfolioFileLinksTable.defaultProps = {
  data: [],
}

export default PortfolioFileLinksTable
