import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import DynamicTable from 'src/components/DynamicTable'
import { FormDialog } from 'src/components/Dialogs'

import {
  PortfolioTemplateDefaultValues,
  PortfolioTemplateFieldDefinitionsData,
} from 'src/graphql/models/PortfolioTemplates'
import {
  GET_PORTFOLIO_FIELD_DEFINITIONS,
  GET_PORTFOLIO_TEMPLATE_DEFAULT_VALUES,
} from 'src/graphql/operations/queries/portfolio'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import {
  Box,
  IconButton,
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material'
import { useDeleteDefaultValue } from 'src/graphql/operations/mutations/portfolio'
import { useSnackbar } from 'notistack'
import { Icon } from 'everchain-uilibrary'

interface DefaultValuesProps {
  templateId: number
  canPermissionEdit?: boolean
}
interface Columns {
  onEdit: (originalRow: any) => void
  onDelete: (originalRow: any) => void
}

const columns = ({ onEdit, onDelete }: Columns) => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Default Value',
    accessor: 'defaultValue',
  },
  {
    Header: 'Action',
    accessor: '',
    width: '15px',
    Cell: (props: any): React.ReactElement => {
      return (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              onEdit(props.row.original)
            }}
          >
            <Icon name="Edit" fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              onDelete(props.row.original)
            }}
          >
            <Icon name="Delete" fontSize="small" />
          </IconButton>
        </Box>
      )
    },
  },
]

const DefaultValues: React.FC<DefaultValuesProps> = ({
  templateId,
  canPermissionEdit,
}: DefaultValuesProps) => {
  const [fieldValues, setFieldValues] = useState<{
    [x: string]: any
  }>({
    selectedFieldDefinition: '',
    name: '',
    defaultValue: '',
  })
  const [fieldErrors, setFieldErrors] = useState<{
    [x: string]: any
  }>({
    name: '',
  })
  const [defaultValueSelected, setDefaultValueSelected] = useState<{
    [x: string]: any
  }>({})
  const [open, setOpen] = useState(false)
  const [openDeleteDefaultValue, setOpenDeleteDefaultValue] = useState(false)
  const [defaultValueToExclude, setDefaultValueToExclude] = useState(Number)

  const { enqueueSnackbar } = useSnackbar()

  const {
    useSavePortfolioTemplateDefaultValuesType,
    useUpdatePortfolioTemplateDefaultValue,
  } = portfolioMutation

  const {
    loading: loadingSavedefaultValuesTypes,
    savePortfolioTemplateDefaultValuesType,
  } = useSavePortfolioTemplateDefaultValuesType({
    onCompleted: (defaultValuesTypesData: any) => {
      if (
        defaultValuesTypesData &&
        defaultValuesTypesData.savePortfolioTemplateDefaultValuesType
      ) {
        handleCloseDialog()
      }
    },
  })

  const {
    loading: loadingUpdatedefaultValuesTypes,
    updatePortfolioTemplateDefaultValue,
  } = useUpdatePortfolioTemplateDefaultValue({
    onCompleted: (defaultValuesTypesData: any) => {
      if (
        defaultValuesTypesData &&
        defaultValuesTypesData.updatePortfolioTemplateDefaultValue
      ) {
        handleCloseDialog()
      }
    },
  })

  const { deleteDefaultValue, loading: loadingDeleteDefaultValue } =
    useDeleteDefaultValue({
      onCompleted: (value: any) => {
        enqueueSnackbar('Default Value deleted successfully', {
          variant: 'success',
        })
        setOpenDeleteDefaultValue(false)
      },
      onError: (value: any) => {
        enqueueSnackbar('Error while deleting the Default Value', {
          variant: 'warning',
        })
        setOpenDeleteDefaultValue(false)
      },
    })

  const pageSize = 25

  const {
    data: portfolioTemplateValues,
    loading: loadingPortfoliotemplate,
    fetchMore,
  } = useQuery<PortfolioTemplateDefaultValues>(
    GET_PORTFOLIO_TEMPLATE_DEFAULT_VALUES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        portfolioTemplateId: templateId,
        pagination: { pageNumber: 0, pageSize },
      },
    }
  )

  const {
    data: portfolioTemplateFieldDefinitions,
    loading: loadingPortfolioTemplateFieldDefinitions,
  } = useQuery<PortfolioTemplateFieldDefinitionsData>(
    GET_PORTFOLIO_FIELD_DEFINITIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        portfolioTemplateId: Number(templateId),
      },
    }
  )

  const handleCloseDialog = (): void => {
    setFieldValues((prevState) => ({
      ...prevState,
      name: '',
      defaultValue: '',
      selectedFieldDefinition: '',
    }))
    setFieldErrors({
      defaultValue: '',
      setDefaultValueSelected: '',
    })
    setOpen(false)
    setDefaultValueSelected({})
  }

  const handleEditDefaultValue = (originalRow: any) => {
    if (originalRow) {
      const fieldDefinition =
        portfolioTemplateFieldDefinitions?.portfolioTemplateFieldDefinitionsData.find(
          (x) => x.id === Number(originalRow.exportFieldDefinitionId)
        )
      setFieldValues((prevState) => ({
        ...prevState,
        name: originalRow.name,
        defaultValue: originalRow.defaultValue || '',
        selectedFieldDefinition: fieldDefinition,
      }))
      setDefaultValueSelected(originalRow)
    }
    setOpen(true)
  }

  const handleOpenDeleteDefaultValueModal = (originalRow: any) => {
    if (originalRow) {
      setOpenDeleteDefaultValue(true)
      setDefaultValueToExclude(
        Number(originalRow.portfolioTemplateDefaultValueId)
      )
    }
  }

  const handleDeleteDefaultValue = () => {
    deleteDefaultValue({
      variables: {
        portfolioTemplateDefaultValueId: defaultValueToExclude,
      },
      refetchQueries: ['GetTemplatePortfolioDefaultValues'],
    })
  }

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name) {
      setFieldValues((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    if (name === 'defaultValue' && value) {
      setFieldErrors((prevState) => ({
        ...prevState,
        defaultValue: '',
      }))
    }
  }

  const handleDefaultValueSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!fieldValues.defaultValue && !fieldValues.selectedFieldDefinition) {
      setFieldErrors({
        defaultValue: 'Required',
        selectedFieldDefinition: 'Required',
      })
      return
    }
    if (!fieldValues.defaultValue) {
      setFieldErrors({
        defaultValue: 'Required',
      })
      return
    }
    if (!fieldValues.selectedFieldDefinition) {
      setFieldErrors({
        selectedFieldDefinition: 'Required',
      })
      return
    }
    if (!defaultValueSelected.portfolioTemplateDefaultValueId) {
      savePortfolioTemplateDefaultValuesType({
        variables: {
          createPortfolioTemplateDefaultValuesRequest: {
            defaultValue: fieldValues?.defaultValue || null,
            exportFieldDefinitionId: Number(
              fieldValues?.selectedFieldDefinition?.id
            ),
            sellerUploadTemplateId: Number(templateId),
          },
        },
        refetchQueries: ['GetTemplatePortfolioDefaultValues'],
      })
    } else {
      updatePortfolioTemplateDefaultValue({
        variables: {
          updatePortfolioTemplateDefaultValueRequest: {
            id: Number(defaultValueSelected.portfolioTemplateDefaultValueId),
            defaultValue: fieldValues.defaultValue || null,
            exportFieldDefinitionId: Number(
              fieldValues?.selectedFieldDefinition?.id
            ),
            sellerUploadTemplateId: Number(templateId),
          },
        },
        refetchQueries: ['GetTemplatePortfolioDefaultValues'],
      })
    }
  }

  const handleFieldDefinitionName = (props: any) => {
    const fieldDefinition =
      portfolioTemplateFieldDefinitions?.portfolioTemplateFieldDefinitionsData.find(
        (x) => x.name === props.target.value
      )
    setFieldValues((prevState) => ({
      ...prevState,
      selectedFieldDefinition: fieldDefinition,
    }))
  }

  const portfolioTemplateDefaultValueData =
    portfolioTemplateValues?.portfolioTemplateDefaultValueData

  const totalOfFields = portfolioTemplateDefaultValueData?.totalOfFields
    ? Math.ceil(portfolioTemplateDefaultValueData?.totalOfFields / pageSize)
    : 0
  const fields = portfolioTemplateDefaultValueData?.fields || []

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
        }}
      >
        <Button
          data-cy="add-default-value"
          color="primary"
          variant="contained"
          style={{ margin: '10px' }}
          onClick={() => setOpen(true)}
        >
          Add Default Value
        </Button>
      </Box>
      <DynamicTable
        loading={loadingPortfoliotemplate}
        columns={columns({
          onEdit: handleEditDefaultValue,
          onDelete: handleOpenDeleteDefaultValueModal,
        })}
        data={fields}
        totalItems={totalOfFields}
        pageSize={pageSize}
        onChangePagination={(_, value: number) => {
          const formValue = value - 1
          if (fetchMore) {
            fetchMore({
              variables: {
                pagination: {
                  pageNumber: formValue,
                  pageSize,
                },
              },
              updateQuery: (
                prev,
                { fetchMoreResult }
              ): PortfolioTemplateDefaultValues => {
                if (!fetchMoreResult) return prev
                return fetchMoreResult
              },
            })
          }
        }}
      />
      {open && (
        <FormDialog
          open={open}
          onClose={handleCloseDialog}
          title={defaultValueSelected.name}
          maxWidth="sm"
        >
          <form onSubmit={handleDefaultValueSubmit}>
            <TextField
              id="select-export-field-definition"
              select
              label="Export Field Definition"
              name="selectedFieldDefinition"
              onChange={handleFieldDefinitionName}
              value={fieldValues.selectedFieldDefinition?.name}
              error={!!fieldErrors.selectedFieldDefinition}
              disabled={loadingPortfolioTemplateFieldDefinitions}
              fullWidth
            >
              <MenuItem selected disabled value="">
                Select Field Definition
              </MenuItem>
              {portfolioTemplateFieldDefinitions?.portfolioTemplateFieldDefinitionsData &&
              portfolioTemplateFieldDefinitions
                ?.portfolioTemplateFieldDefinitionsData.length > 0 ? (
                // eslint-disable-next-line max-len
                portfolioTemplateFieldDefinitions?.portfolioTemplateFieldDefinitionsData.map(
                  (option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  )
                )
              ) : (
                <></>
              )}
            </TextField>
            <TextField
              id="defaultValue"
              name="defaultValue"
              label="Default Value"
              margin="dense"
              fullWidth
              onChange={handleFieldChange}
              value={fieldValues.defaultValue}
              error={!!fieldErrors.defaultValue}
              helperText={fieldErrors.defaultValue}
              disabled={!canPermissionEdit}
            />
            <Box
              mt={4}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="10px"
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  (loadingUpdatedefaultValuesTypes ||
                    loadingSavedefaultValuesTypes) && (
                    <CircularProgress size={18} color="primary" />
                  )
                }
                disabled={
                  loadingSavedefaultValuesTypes ||
                  loadingUpdatedefaultValuesTypes ||
                  !canPermissionEdit
                }
              >
                Save
              </Button>
            </Box>
          </form>
        </FormDialog>
      )}
      {openDeleteDefaultValue && (
        <Dialog
          open={openDeleteDefaultValue}
          onClose={() => {
            setOpenDeleteDefaultValue(false)
          }}
        >
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                Would you like to delete this default value?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenDeleteDefaultValue(false)
              }}
              disabled={loadingDeleteDefaultValue}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteDefaultValue}
              disabled={loadingDeleteDefaultValue}
            >
              {loadingDeleteDefaultValue ? (
                <CircularProgress size={24} />
              ) : (
                'Confirm'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}
DefaultValues.defaultProps = {
  canPermissionEdit: false,
}
export default DefaultValues
