import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import { useGlobalModalContext } from 'src/context/ModalContext'
import { DialogTitleStyled } from './style'
import { Icon } from 'everchain-uilibrary'

interface GlobalModalProps {
  children: React.ReactNode
}

const GlobalModal: React.FC<GlobalModalProps> = ({ children }) => {
  const { store, setStore } = useGlobalModalContext()

  const onClose = () => {
    setStore({ ...store, open: false })
  }

  return (
    <>
      <Dialog
        open={store.open}
        onClose={onClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            {store.title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>{store.body}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {store.cancelButtonText}
          </Button>
          <Button
            onClick={() => {}}
            color="primary"
            variant="contained"
            autoFocus
          >
            {store.acceptButtonText}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  )
}

export default GlobalModal
