import React, { useCallback } from 'react'
import { useDropzone, DropzoneProps, FileRejection } from 'react-dropzone'
import { Box, CircularProgress } from '@mui/material'

import { DropUpload } from './styles'
import { Icon } from 'everchain-uilibrary'

interface UploadDragDropProps extends DropzoneProps {
  uploadDisabled?: boolean
  multiple?: boolean
  accept?: string
  onDrop: (acceptedFiles: File[]) => void
  onDropRejected?: (fileRejection: FileRejection[]) => void
  uploading?: boolean
  linkText?: string
  maxSize?: number
  text?: string
  size?: 'small' | 'large'
}

const UploadDragDrop: React.FC<UploadDragDropProps> = ({
  uploadDisabled,
  multiple,
  accept,
  onDrop,
  uploading,
  linkText,
  text,
  size,
  maxSize,
  onDropRejected,
}: UploadDragDropProps) => {
  const handleDrop = useCallback(
    (acceptedFiles: any): void => {
      onDrop(acceptedFiles)
    },
    [onDrop]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept,
    disabled: uploadDisabled,
    multiple,
    maxSize,
    onDropRejected,
  })

  return (
    <div>
      <DropUpload
        size={size}
        isDragActive={isDragActive}
        {...getRootProps()}
        disabled={uploadDisabled}
      >
        <input {...getInputProps()} type="file" />
        <div className="upload-placeholder">
          <Box mr={2} display="flex" alignItems="center">
            {uploading ? (
              <CircularProgress size={16} />
            ) : (
              <Icon name="CloudUpload" className="upload-icon" />
            )}
          </Box>
          <strong>{linkText} </strong> {text}
        </div>
      </DropUpload>
    </div>
  )
}

UploadDragDrop.defaultProps = {
  size: 'large',
  linkText: 'Browse',
  text: 'or Drop files here to upload',
  uploadDisabled: false,
  multiple: false,
  uploading: false,
  maxSize: Infinity,
  onDropRejected: (): void => {},
  accept:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
}

export default UploadDragDrop
