/* eslint-disable no-return-assign */
import { User, UserManager } from 'oidc-client'
import { PORTFOLIO, POSTSALE, COMPLIANCE, ACCESSDENIED } from 'src/routes'

import {
  PermissionCodeAccess,
  MARKETPLACE_PERMISSION_TOKEN,
  AUTH_TIME,
  CAN_IMPERSONATE,
  CMS_PERMISSION_TOKEN,
  CONTROL_PANEL_PERMISSION_TOKEN,
  DISPLAY_NAME,
  EMAIL,
  EMAIL_VERIFIED,
  IS_IMPERSONATING,
  RECOVER_PERMISSION_TOKEN,
  SECURITY_STAMP,
  SERVICER,
} from 'src/utils/constants'
import { Permissions } from 'src/infra/api/models/permissions'
import { CONTROLPANEL_URL } from 'src/infra/api/axios-wrapper/httpClient'
import axios from 'axios'
import { IDENTITY_SETTINGS } from './authConst'
import { getLocalStorageUser } from 'src/utils/common'

const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const USER_ID = 'http://debttrader.com/claims/user_id'
export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'
export const AGENCY = 'Agency'

interface PermissionReduce {
  [field: string]: boolean
}

export default class AuthService {
  userManager: UserManager
  userPermissions: any

  constructor() {
    this.userManager = new UserManager(IDENTITY_SETTINGS)

    // Logger
    // Log.logger = console
    // Log.level = Log.DEBUG

    // If the user logout, he will be disconnect
    // from any tab that the app is open location
    this.userManager.events.addUserSignedOut(() => {
      this.logout().finally(
        () => (window.location.href = window.location.origin)
      )
    })
    // If the token cant be renewed, the session
    // expired and the user needs to log in again
    this.userManager.events.addSilentRenewError(() => {
      this.login()
    })
  }

  // Methods
  // eslint-disable-next-line consistent-return
  getUser = async (): Promise<User | undefined> => {
    const user = await this.userManager.getUser()
    if (user) {
      return user
    }
  }

  login = async () => {
    this.userManager.clearStaleState().then(async () => {
      const user = await this.userManager.getUser()
      if (!user || user?.profile[IS_IMPERSONATING] !== 'True') {
        const url = localStorage.getItem('originationURI')
        window.localStorage.clear()
        if (url) {
          localStorage.setItem('originationURI', url)
        }
        this.userManager.signinRedirect()
      }

      if (user !== null) {
        await this.getPermissions(user)
        this.redirectUser(user, '')
      }
    })
  }

  loginImpersonate = async (userObject: any, token: string) => {
    const internalUser = await this.userManager.getUser()
    this.userManager.clearStaleState().then(async () => {
      const impersonatedUser = {
        id_token: internalUser?.id_token || '',
        session_state: internalUser?.session_state || '',
        access_token: token,
        token_type: 'Bearer',
        scope: userObject['scope'],
        profile: {
          iss: userObject['iss'],
          sub: userObject['sub'],
          aud: userObject['aud'],
          exp: userObject['exp'],
          iat: userObject['nbf'],
          [SECURITY_STAMP]: userObject[SECURITY_STAMP],
          [AUTH_TIME]: userObject[AUTH_TIME],
          email: userObject['email'],
          [EMAIL_VERIFIED]: userObject[EMAIL_VERIFIED],
          [DISPLAY_NAME]: userObject[DISPLAY_NAME],
          [MARKETPLACE_PERMISSION_TOKEN]:
            userObject[MARKETPLACE_PERMISSION_TOKEN],
          [CONTROL_PANEL_PERMISSION_TOKEN]:
            userObject[CONTROL_PANEL_PERMISSION_TOKEN],
          [CMS_PERMISSION_TOKEN]: userObject[CMS_PERMISSION_TOKEN],
          [RECOVER_PERMISSION_TOKEN]: userObject[RECOVER_PERMISSION_TOKEN],
          [CAN_IMPERSONATE]: userObject[CAN_IMPERSONATE],
          [USER_ID]: userObject[USER_ID],
          [EMAIL]: userObject[EMAIL],
          [TENANT_TYPE]: userObject[TENANT_TYPE],
          [CLIENT_INFO]: userObject[CLIENT_INFO],
          [IS_RESELLER]: userObject[IS_RESELLER],
          [IS_IMPERSONATING]: userObject[IS_IMPERSONATING],
        },
        expires_at: userObject['exp'],
        refresh_token: '',
        state: null,
      }
      const user = new User(impersonatedUser)
      window.localStorage.clear()
      const userId = user.profile[USER_ID]
      const tenantType = user.profile[TENANT_TYPE]
      window.localStorage.userId = userId
      this.userManager.storeUser(user)
      if (tenantType === INTERNAL) {
        window.localStorage.setItem('userType', INTERNAL)
      } else {
        const profileClientInfo = user.profile[CLIENT_INFO] || null
        const profileClientInfoParse = Array.isArray(profileClientInfo)
          ? JSON.parse(profileClientInfo[0])
          : JSON.parse(profileClientInfo)

        const type = profileClientInfoParse
          ? profileClientInfoParse.Type
          : tenantType
        window.localStorage.setItem('userType', type)
      }

      const url = localStorage.getItem('originationURI') || undefined
      window.history.replaceState(
        {},
        window.document.title,
        window.location.origin + window.location.pathname
      )
      await this.getPermissions(user)
      this.redirectUser(user, url)
    })
  }

  loginCallback = async (): Promise<void> => {
    return this.userManager
      .signinRedirectCallback()
      .then(async (user) => {
        const url = localStorage.getItem('originationURI') || undefined
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname
        )

        await this.getPermissions(user)
        const userId = user.profile[USER_ID]
        window.localStorage.userId = userId

        this.redirectUser(user, url)
      })
      .catch((error) => {
        window.location.href = '/'
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  isAuth = (): boolean => {
    const user = getLocalStorageUser()

    const userParser = user ? JSON.parse(user) : {}

    if (!!userParser && userParser.access_token) {
      return true
    }
    return false
  }

  logout = (): Promise<void> => {
    return this.userManager.signoutRedirect()
  }

  logoutCallback = (): Promise<void> => {
    return this.userManager.signoutRedirectCallback().then(() => {
      window.location.href = `${window.location.origin}/`
    })
  }

  renewToken = async () => {
    const u = await this.userManager.signinSilent()

    return u
  }

  renewTokenCallback(): Promise<User | undefined> {
    return this.userManager.signinSilentCallback()
  }

  expiringAccessToken = (callback: any) => {
    this.userManager.events.addAccessTokenExpiring(() => {
      callback()
    })
  }

  expiredAccessToken = () => {
    this.userManager.events.addAccessTokenExpired(() => {
      this.logout()
    })
  }

  getPermissions = async (user: User): Promise<any> => {
    const { profile }: any = user || {}
    let permissionCodes: any
    let encodedPermissions: any

    if (!profile[MARKETPLACE_PERMISSION_TOKEN])
      window.location.href = ACCESSDENIED

    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      permissionCodes = permissionCode
      encodedPermissions = profile[MARKETPLACE_PERMISSION_TOKEN]
    }

    await axios({
      method: 'post',
      url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
      data: {
        permissionCodes,
        encodedPermissions,
      },
    }).then((result: any) => {
      const managePermission = result.data.permissions
      this.userPermissions = this.permissionReduce(managePermission)
    })
  }

  permissionReduce = (permissionValues: Permissions[] = []): PermissionReduce =>
    permissionValues.reduce(
      (acc: any, item: Permissions) => ({ ...acc, [item?.code]: item?.value }),
      {}
    )

  redirectUser = (user: User, url: string | undefined) => {
    let urlRedirect = url || `${COMPLIANCE}/action-items`
    const profileTenantType = user.profile[TENANT_TYPE]
    if (profileTenantType === INTERNAL) {
      window.localStorage.setItem('userType', INTERNAL)
      if (this.userPermissions[PermissionCodeAccess.MarketPlace_Basic]) {
        urlRedirect = url || `${PORTFOLIO}/loaded-listed`
      } else if (
        this.userPermissions[PermissionCodeAccess.MarketPlace_PostSale]
      ) {
        urlRedirect = url || `${POSTSALE}/urgent`
      }
      window.location.href = urlRedirect
      return
    }

    const profileClientInfo = user.profile[CLIENT_INFO] || null
    const profileClientInfoParse = Array.isArray(profileClientInfo)
      ? JSON.parse(profileClientInfo[0])
      : JSON.parse(profileClientInfo)

    const type = profileClientInfoParse
      ? profileClientInfoParse.Type
      : profileTenantType

    window.localStorage.setItem('userType', type)
    if (type === SELLER) {
      if (this.userPermissions[PermissionCodeAccess.MarketPlace_Basic]) {
        urlRedirect = url || `${PORTFOLIO}/loaded-listed`
      } else if (
        this.userPermissions[PermissionCodeAccess.MarketPlace_PostSale]
      ) {
        urlRedirect = url || `${POSTSALE}/urgent`
      }
    } else if (type === BUYER) {
      if (this.userPermissions[PermissionCodeAccess.MarketPlace_Basic]) {
        urlRedirect = url || `${PORTFOLIO}/auction-open`
      } else if (
        this.userPermissions[PermissionCodeAccess.MarketPlace_PostSale]
      ) {
        urlRedirect = url || `${POSTSALE}/urgent`
      }
    } else if (type === AGENCY) {
      urlRedirect = ACCESSDENIED
    } else if (type === SERVICER) {
      urlRedirect = ACCESSDENIED
    }

    window.location.href = urlRedirect
  }
}
