import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { ModalHeader } from 'src/pages/PostSale/BulkUpload/styles'
import React, { useMemo, useState } from 'react'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { SurveyData } from 'src/data/features/get/survey/types'
import { getSurveyById } from 'src/data/features/get/survey/survey'
import { Icon } from 'everchain-uilibrary'
import PortfolioSurveyViewer from './PortfolioSurveyViewer'
import Loader from 'src/components/Loader/Loader'

interface PortfolioSurveyDialogProps {
  portfolioId: string | undefined
  surveyId: number
  businessId: string | undefined
  openSurveyDialog: boolean
  setOpenSurveyDialog: (value: boolean) => void
}

const PortfolioSurveyDialog: React.FC<PortfolioSurveyDialogProps> = ({
  portfolioId,
  surveyId,
  businessId,
  openSurveyDialog,
  setOpenSurveyDialog,
}: PortfolioSurveyDialogProps) => {
  const [enableGetSurvey, setEnableGetSurvey] = useState<boolean>(false)

  const { data: surveyResponse, isFetching: loadingSurvey } =
    useCustomQuery<SurveyData>(
      ['getSurveyById', surveyId],
      async () => getSurveyById(Number(surveyId)),
      {
        enabled: !!surveyId && enableGetSurvey,
        cacheTime: 0,
      }
    )

  const surveyData = surveyResponse

  useMemo(() => {
    if (openSurveyDialog) {
      setEnableGetSurvey(true)
    }
  }, [openSurveyDialog])

  return (
    <>
      <Dialog
        open={openSurveyDialog}
        onClose={() => setOpenSurveyDialog(false)}
        fullScreen
        style={{
          marginLeft: 250,
          marginTop: 100,
          marginBottom: 100,
          marginRight: 250,
        }}
      >
        <ModalHeader
          py={3}
          px={5}
          m={0}
          bgcolor="green"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="white"
        >
          <Typography variant="h5" style={{ color: 'white' }}></Typography>
          <IconButton size="small" onClick={() => setOpenSurveyDialog(false)}>
            <Icon name="Close" color="white" />
          </IconButton>
        </ModalHeader>
        <DialogContent>
          {loadingSurvey ? (
            <Loader />
          ) : (
            <PortfolioSurveyViewer
              businessId={businessId}
              surveyId={surveyId}
              surveySchema={surveyData?.surveySchema || ''}
              portfolioId={portfolioId}
            ></PortfolioSurveyViewer>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PortfolioSurveyDialog
