import { gql } from '@apollo/client'

export const COMPLAINT_DATA_BY_ID_PORTIFOLIO = gql`
  query CreateNewComplaint($portfolioRowId: String!) {
    data: createNewComplaint(portfolioRowId: $portfolioRowId) {
      id
      status
      sellerName
      buyerName
      pId
      lender
      originalAmount
      originationDate
      balance
      firstName
      stateCode
      lastName
      city
      loanId
      summary
      agencyName_External
      attorneyContacted
      complainantEmail
      followUpRequired
      accountStatus
      employeesInvolved
      agencyAction
      agencyResponse
      agencyPreventionSteps
      documentsRequired
      agency
      createdByBusinessId
      buyerId
      sellerId
      category
      categoryList
      sourceType
      violationType
      outcome
      complaintResolution
      debtTraderActions
      portfolioRowId
      portfolioCountry
    }
  }
`

export const COMPLAINT_EXTERNAL_AGENCY = gql`
  query getListBuyersAgency($nameFilter: String!) {
    nameList: getListBuyersAgency(nameFilter: $nameFilter)
  }
`
export const COMPLAINT_EXTERNAL_AGENCY_COMPLAINT = gql`
  query getListBuyersAgencyComplaint($nameFilter: String!) {
    nameList: getListBuyersAgencyComplaint(nameFilter: $nameFilter)
  }
`
export const CHAIN_OF_TITLE_DETAIL_BY_PORTFOLIO_ID = gql`
  query GetChainOfTitleDetail($portfolioRowId: String!) {
    data: getChainOfTitleDetail(portfolioRowId: $portfolioRowId) {
      businessId
      businessName
      recordType
    }
  }
`

export const GET_BCO_URI_FILE = gql`
  query GetBcoUriFile($businessId: String!, $fileType: String!, $fileId: Int!) {
    uri: getBcoUriFile(
      businessId: $businessId
      fileType: $fileType
      fileId: $fileId
    )
  }
`
export const GET_BCO_SELLER = gql`
  query GetSellerBco($sortBy: String!) {
    data: getSellerBco(sortBy: $sortBy) {
      bCOEnabled
      buyerBCOMetricId
      lastPurchase
      name
      sellerId
      totalBkAccountsPurchased
      totalBkAccountsPurchased
      totalNonBkAccountsPurchased
      value
      sellerName
      buyerAccounts
    }
  }
`
export const GET_BCO_BUYERS = gql`
  query GetBuyerBco($sortBy: String!) {
    data: getBuyerBco(sortBy: $sortBy) {
      bCOEnabled
      buyerBCOMetricId
      lastPurchase
      name
      sellerId
      totalBkAccountsPurchased
      totalBkAccountsPurchased
      totalNonBkAccountsPurchased
      value
      sellerName
    }
  }
`
export const GET_BCO_INTERNAL = gql`
  query GetInternalBco($sortBy: String!) {
    data: getInternalBco(sortBy: $sortBy) {
      bCOEnabled
      buyerBCOMetricId
      lastPurchase
      name
      sellerId
      totalBkAccountsPurchased
      totalBkAccountsPurchased
      totalNonBkAccountsPurchased
      value
      sellerName
      buyerAccounts
    }
  }
`
export const GET_IS_SELLER_BCO_ENABLED = gql`
  query IsSellerBCOEnabled {
    isSellerBCOEnabled
  }
`
export const GET_HAS_ACKNOWLEDGEMENT = gql`
  query HasAcknowledgement {
    hasAcknowledgement
  }
`
export const COMPLAINT_URI_REPORT = gql`
  query GenerateComplaintReport($complaintId: Long!) {
    uri: generateComplaintReport(complaintId: $complaintId)
  }
`
export const GET_LIST_SELLER_BCO = gql`
  query GetListSellerBco($buyerId: String!) {
    getListSellerBco(buyerId: $buyerId) {
      name
      id
    }
  }
`
export const GET_LIST_BUYER_BCO = gql`
  query GetListBuyerBco($agencyId: String!) {
    getListBuyerBco(agencyId: $agencyId) {
      name
      id
    }
  }
`
