/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useCallback, useContext, useState } from 'react'
import { AdditionalUnmaskedDataRequests } from 'src/graphql/models/Portfolio'
import { ConvertDateToUTC, MomentDateFormat } from 'src/utils/date'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import {
  portfolioDetailMutation,
  unmaskedDataMutation,
} from 'src/graphql/operations/mutations'
import { AuthContext } from 'src/context/AuthenticationContext'
import DTAccordion from 'src/components/Accordion'
import { PortfolioStatusType } from 'src/utils/constants'
import moment from 'moment'

import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import { Colors, DataTable, Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAdditionalUnmaskedDataRequests } from 'src/data/features/get/portfolio/portfolio'

interface ApproveUnmaskedDataRequestProps {
  portfolioId: string
  portfolioStatus: string
}

const ApproveUnmaskedDataRequest: React.FC<ApproveUnmaskedDataRequestProps> = ({
  portfolioId,
  portfolioStatus,
}: ApproveUnmaskedDataRequestProps) => {
  const MAX_SIZE_FILE = 104857600 // 100MB
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { userPermissions } = useContext(AuthContext)
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const { useUploadNDAExecutedFinalFile } = portfolioDetailMutation
  const { uploadNDAExecutedFinalFile, loading: fileUploading } =
    useUploadNDAExecutedFinalFile({})
  const [file, setFile] = useState<any>(null)
  const [currentWorkflowId, setCurrentWorkflowId] = useState<any>('')
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])

  const {
    data: requestsData,
    isFetching: loadingRequests,
    refetch: refetchAdditionalUnmaskedDataRequests,
  } = useCustomQuery<AdditionalUnmaskedDataRequests>(
    ['getAdditionalUnmaskedDataRequests', portfolioId],
    async () => getAdditionalUnmaskedDataRequests(portfolioId),
    { enabled: true, cacheTime: 0 }
  )

  const handleFileUpload = () => {
    if (file) {
      uploadNDAExecutedFinalFile({
        variables: {
          workflowId: currentWorkflowId,
          file,
        },
        onCompleted: (data: any) => {
          if (data) {
            setFile(null)
            setOpenUploadModal(false)
            enqueueSnackbar(
              'File uploaded successfully',
              notistackOptions('success')
            )
            refetchAdditionalUnmaskedDataRequests()
          } else {
            enqueueSnackbar(
              'Error while trying to upload the file',
              notistackOptions('warning')
            )
          }
        },
      })
    }
  }

  const accept = '.pdf, application/pdf'
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
  })

  const {
    useApproveUnmaskDataRequest,
    useDenyUnmaskDataRequest,
    useNDAUnmaskDataRequest,
  } = unmaskedDataMutation
  const { ndaUnmaskDataRequest, loading: ndaUnmaskLoading } =
    useNDAUnmaskDataRequest({
      onCompleted: () => {
        enqueueSnackbar('NDA Requested', notifySuccess)
        setDisableUI(false)
        refetchAdditionalUnmaskedDataRequests()
      },
      onError: () => {
        enqueueSnackbar('NDA Request failed', notifyError)
        setDisableUI(false)
      },
    })
  const { approveUnmaskDataRequest } = useApproveUnmaskDataRequest({
    onCompleted: () => {
      enqueueSnackbar('Request approved', notifySuccess)
      setDisableUI(false)
      refetchAdditionalUnmaskedDataRequests()
    },
    onError: () => {
      enqueueSnackbar('Approval failed', notifyError)
      setDisableUI(false)
    },
  })

  const { denyUnmaskDataRequest } = useDenyUnmaskDataRequest({
    onCompleted: () => {
      enqueueSnackbar('Request denied', notifySuccess)
      setDisableUI(false)
      refetchAdditionalUnmaskedDataRequests()
    },
    onError: () => {
      enqueueSnackbar('Denial failed', notifyError)
      setDisableUI(false)
    },
  })

  const { profileClient } = useContext(AuthContext)
  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const MyCommandCell = (props: any) => {
    if (
      userPermissions.type === 'internal' ||
      props.dataItem.approval ||
      props.dataItem.denial ||
      props.dataItem.executed
    )
      return (
        <td>
          <Grid container direction="row">
            {props.dataItem.blobSellerUri && (
              <Grid item>
                <IconButton
                  component="div"
                  size="small"
                  data-cy="approve-unmask-data-button"
                  onClick={() => {
                    if (props.dataItem.blobSellerUri === '') return
                    window.location.href = props.dataItem.blobSellerUri
                  }}
                >
                  <Tooltip title="Download the final executed NDA">
                    <Icon
                      name="GetApp"
                      fontSize="small"
                      style={{ color: disableUI ? 'silver' : '#000836' }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}
          </Grid>
        </td>
      )

    return (
      <td>
        <Grid container direction="row">
          <Grid item>
            <IconButton
              disabled={
                props.dataItem.nDAUnmaskDataRequested &&
                !props.dataItem.blobSellerUri
              }
              size="small"
              data-cy="approve-unmask-data-button"
              onClick={() => {
                setDisableUI(true)
                approveUnmaskDataRequest({
                  variables: {
                    workflowId: props.dataItem.workflowId,
                  },
                })
              }}
            >
              <Tooltip title="Approve">
                <Icon
                  name="Check"
                  fontSize="small"
                  style={{
                    color:
                      disableUI ||
                      (props.dataItem.nDAUnmaskDataRequested &&
                        props.dataItem.blobBuyerUri &&
                        !props.dataItem.blobSellerUri)
                        ? 'silver'
                        : '#388e3c',
                  }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              disabled={disableUI}
              size="small"
              data-cy="reject-unmask-data-button"
              onClick={() => {
                setDisableUI(true)
                denyUnmaskDataRequest({
                  variables: {
                    workflowId: props.dataItem.workflowId,
                  },
                })
              }}
            >
              <Tooltip title="Reject">
                <Icon
                  name="Close"
                  fontSize="small"
                  style={{ color: disableUI ? 'silver' : 'red' }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
          {requestsData?.canShowNDARequest && (
            <>
              <Grid item>
                <IconButton
                  component="div"
                  size="small"
                  data-cy="approve-unmask-data-button"
                  onClick={() => {
                    if (!props.dataItem.nDAUnmaskDataRequested) {
                      setDisableUI(true)
                      ndaUnmaskDataRequest({
                        variables: {
                          workflowId: props.dataItem.workflowId,
                        },
                      })
                    }
                  }}
                >
                  <Tooltip
                    title={
                      props.dataItem.nDAUnmaskDataRequested
                        ? 'NDA has been requested'
                        : 'Request NDA'
                    }
                  >
                    <Icon
                      name="AssignmentTurnedIn"
                      fontSize="small"
                      color={
                        disableUI ||
                        props.dataItem.nDAUnmaskDataRequested ||
                        ndaUnmaskLoading
                          ? 'silver'
                          : 'orange'
                      }
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
              {props.dataItem.blobBuyerUri && (
                <Grid item>
                  <IconButton
                    component="div"
                    size="small"
                    data-cy="approve-unmask-data-button"
                    onClick={() => {
                      if (props.dataItem.blobBuyerUri === '') return

                      if (props.dataItem.blobSellerUri)
                        window.location.href = props.dataItem.blobSellerUri
                      else window.location.href = props.dataItem.blobBuyerUri
                    }}
                  >
                    <Tooltip
                      title={
                        props.dataItem.blobSellerUri
                          ? 'Download the final executed NDA'
                          : 'Download the buyer executed NDA'
                      }
                    >
                      <Icon
                        name="GetApp"
                        fontSize="small"
                        style={{ color: disableUI ? 'silver' : '#000836' }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              {props.dataItem.blobBuyerUri && (
                <Grid item>
                  <IconButton
                    component="div"
                    size="small"
                    data-cy="approve-unmask-data-button"
                    onClick={() => {
                      setOpenUploadModal(true)
                      setCurrentWorkflowId(props.dataItem.workflowId)
                    }}
                  >
                    <Tooltip title="Upload executed NDA">
                      <Icon
                        name="Publish"
                        fontSize="small"
                        style={{ color: disableUI ? 'silver' : '#000836' }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </td>
    )
  }

  const gridColumns: any[] = [
    {
      title: 'Business Name',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>{props.dataItem.request.performedByBusinessName}</td>
        )
      },
    },
    {
      field: 'currentState',
      title: 'Status',
      show: true,
    },
    {
      title: 'Request Date',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            {props.dataItem.request.performedOn
              ? moment(
                  new Date(ConvertDateToUTC(props.dataItem.request.performedOn))
                )?.format(momentFormat)
              : 'N/A'}
          </td>
        )
      },
    },
    {
      field: '',
      title: '',
      width: '100px',
      show: true,
      render: MyCommandCell,
    },
  ]

  if (loadingRequests) {
    return (
      <Box mt={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <DTAccordion
      id="unmasked-data-accordion"
      title="Unmasked Data"
      icon={<Icon name="VisibilityOff" />}
      expanded={portfolioStatus === PortfolioStatusType.Listed}
    >
      {!requestsData?.templateHasUnmaskedColumns && (
        <Box pb={2}>
          <Typography variant="body2" color={Colors.error}>
            There are no available unmasked fields set up.
          </Typography>
        </Box>
      )}
      <Box style={{ height: '100%', width: '100%' }}>
        <DataTable
          style={{ height: '100%', width: '99%' }}
          data={requestsData?.unmaskDataActivities}
          gridColumns={gridColumns}
        />
      </Box>
      <Dialog open={openUploadModal} maxWidth="sm" fullWidth>
        <Box
          style={{
            backgroundColor: '#002E3A',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenUploadModal(false)
              setCurrentWorkflowId('')
            }}
            style={{
              position: 'absolute',
              right: 5,
              top: 5,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <DialogTitle>
            <Typography variant="h1" style={{ color: 'white' }}>
              Upload executed NDA
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent style={{ margin: '4px 0px 5px 0px' }}>
          <Box display="flex" flexDirection="column">
            <Box>
              {!file ? (
                <DropUpload
                  isDragActive={isDragActive}
                  {...getRootProps()}
                  disabled={disableUI}
                >
                  <input {...getInputProps()} />
                  <div className="upload-placeholder">
                    <Icon name="CloudUpload" className="upload-icon" />{' '}
                    <strong style={{ cursor: 'pointer' }}>Browse </strong> or
                    Drop file here to upload
                  </div>
                </DropUpload>
              ) : (
                <UploadItem>
                  <div className="upload-item-info">
                    <Icon
                      name="Description"
                      fontSize="small"
                      color="primary"
                      className="upload-item-icon"
                    />
                    <Typography
                      variant="body2"
                      color={textSecondary.color}
                      component="span"
                      key={file.name}
                    >
                      {file.name}
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="Clear file selection"
                    onClick={() => setFile(null)}
                    disabled={disableUI}
                  >
                    <Icon name="Delete" fontSize="small" />
                  </IconButton>
                </UploadItem>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenUploadModal(false)
              setCurrentWorkflowId('')
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            startIcon={
              fileUploading && <CircularProgress size={15} color="primary" />
            }
            disabled={disableUI || !file || fileUploading}
          >
            Upload File
          </Button>
        </DialogActions>
      </Dialog>
    </DTAccordion>
  )
}

export default ApproveUnmaskedDataRequest
