import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import AuthService from 'src/configs/AuthService'
import { DialogTitleStyled } from './style'
import { Icon } from 'everchain-uilibrary'

interface SessionExpiringModalProps {
  children: React.ReactNode
}

const SessionExpiringModal: React.FC<SessionExpiringModalProps> = ({
  children,
}) => {
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [isUserNotified, setIsUserNotified] = useState(false)
  const [seconds, setSeconds] = useState(
    Number(process.env.REACT_APP_NOTIFICATION_TIMEOUT_SECONDS)
  )

  useEffect(() => {
    let interval: any = null
    if (openContactDialog) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds(seconds - 1)
        }, 1000)
      }
    }

    return (): void => {
      return clearInterval(interval)
    }
  }, [seconds, openContactDialog])

  useEffect(() => {
    const auth = new AuthService()
    auth.expiringAccessToken(() => {
      if (!isUserNotified) {
        setOpenContactDialog(true)
      } else setOpenContactDialog(false)
    })
    auth.expiredAccessToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {children}
      <Dialog
        open={openContactDialog}
        onClose={() => {
          setIsUserNotified(true)
          setOpenContactDialog(false)
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        style={{ display: isUserNotified ? 'none' : 'block' }}
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Session Expiring
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsUserNotified(true)
              setOpenContactDialog(false)
            }}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography component="div">
            You have been logged in for almost 24 hours. For security reasons,
            you will be automatically signed out in{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              {seconds}
            </Box>{' '}
            seconds.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsUserNotified(true)
              const auth = new AuthService()
              auth.logout()
            }}
            variant="contained"
          >
            Sign me out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SessionExpiringModal
