/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DatePicker, Icon } from 'everchain-uilibrary'
import moment from 'moment'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useQueryClient } from '@tanstack/react-query'
import { splitPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  PortfolioSplitDateRangeEnum,
  PortfolioSplitRequest,
} from 'src/data/features/post/portfolio/types'

interface SplitOption {
  templateId: number
  fromDate?: Date | null
  toDate?: Date | null
}

enum SplitTypesEnum {
  Random = 'Random',
  Date = 'Date',
}

const splitTypes = [
  { id: SplitTypesEnum.Random, name: 'Randomize accounts' },
  { id: SplitTypesEnum.Date, name: 'By Date' },
]

const splitDataRangeTypes = [
  { id: PortfolioSplitDateRangeEnum.Custom, name: 'By Date Range' },
  { id: PortfolioSplitDateRangeEnum.ByMonth, name: 'By Month' },
  { id: PortfolioSplitDateRangeEnum.ByQuarter, name: 'By Quarter' },
  { id: PortfolioSplitDateRangeEnum.ByYear, name: 'By Year' },
]

const dateSplitTypes = [
  { id: 'DefaultDate', name: 'Default Date' },
  { id: 'WriteOffDate', name: 'Write Off Date' },
  { id: 'OriginationDate', name: 'Origination Date' },
]

interface SplitPortfolioProps {
  assetTypes: string
  fileName: string
  totalNumberOfAccounts: number
  selectedTemplateId: number
  stagingId: string
  sellerId: string
  templates: any
  cutOffDate: Date | null
  setOpenSplitDialog?: (open: boolean) => void
}

const SplitPortfolio: React.FC<SplitPortfolioProps> = ({
  assetTypes,
  fileName,
  totalNumberOfAccounts,
  selectedTemplateId,
  stagingId,
  sellerId,
  templates,
  cutOffDate,
  setOpenSplitDialog = () => {},
}: SplitPortfolioProps) => {
  const [numberOfSplits, setNumberOfSplits] = useState(2)
  const [splitOptions, setSplitOptions] = useState<SplitOption[]>([
    { templateId: selectedTemplateId },
    { templateId: selectedTemplateId },
  ])
  const [splitType, setSplitType] = useState<SplitTypesEnum>(
    SplitTypesEnum.Random
  )
  const [splitDateRange, setSplitDateRange] =
    useState<PortfolioSplitDateRangeEnum>(PortfolioSplitDateRangeEnum.Custom)
  const [templateId, setTemplateId] = useState<number>(selectedTemplateId)
  const [splitDateType, setSplitDateType] = useState<string>(
    dateSplitTypes[0].id
  )
  const [portfolioSplitRequest, setPortfolioSplitRequest] =
    useState<PortfolioSplitRequest>()
  const { profileClient } = useContext(AuthContext)

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const reactQueryClient = useQueryClient()

  const { useTeardownStagePortfolioSplit } = portfolioMutation

  const {
    teardownStagePortfolioSplit,
    loading: loadingTeardownStagePortfolioSplit,
  } = useTeardownStagePortfolioSplit({
    onCompleted: () => {
      setOpenSplitDialog(false)
      enqueueSnackbar('Portfolio Split cancelled successfully', notifySuccess)
    },
    onError: () => {
      setOpenSplitDialog(false)
      enqueueSnackbar('Failed to cancel the portfolio split', notifyError)
    },
  })

  const handleCancel = () => {
    teardownStagePortfolioSplit({
      variables: {
        stagingId,
        sellerId,
        fileName,
      },
    })
  }

  const { isFetching: loadingSplitPortfolio } = useCustomQuery<any>(
    ['splitPortfolio', portfolioSplitRequest],
    async () => {
      if (portfolioSplitRequest) {
        await splitPortfolio(portfolioSplitRequest)
          .then(() => {
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfoliosBeingProcessed'],
            })
            enqueueSnackbar(
              'Portfolio has been split successfully',
              notifySuccess
            )
          })
          .catch(() => {
            enqueueSnackbar('Failed to split the portfolio', notifyError)
          })
          .finally(() => {
            setOpenSplitDialog(false)
            setPortfolioSplitRequest(undefined)
          })
      }
    },
    { enabled: !!portfolioSplitRequest }
  )

  const handleSplit = () => {
    const validationMessage = validateSubmitMessage()

    if (validationMessage) {
      enqueueSnackbar(validationMessage, notifyError)
    } else {
      setPortfolioSplitRequest({
        stagingId,
        sellerId,
        fileName,
        assetTypes,
        totalNumberOfAccounts,
        strategy: splitType.toString(),
        splitDateField: splitDateType,
        splitParams:
          splitDateRange === PortfolioSplitDateRangeEnum.Custom
            ? splitOptions
            : [],
        splitDateRange,
        templateId,
        cutOffDateUtc: cutOffDate!,
      })
    }
  }

  const getSplitText = () => {
    return splitType === SplitTypesEnum.Random
      ? 'Number of files'
      : 'Number of date segments'
  }

  const getTitleSize = () => {
    return splitType === SplitTypesEnum.Random ? 9 : 8
  }

  const getValueSize = () => {
    return splitType === SplitTypesEnum.Random ? 3 : 4
  }

  const titleText = (title: string) => {
    return (
      <Box fontWeight={600} paddingTop={4}>
        {title}:{' '}
      </Box>
    )
  }

  const updateSplitOptionsAtIndex = (
    index: number,
    updates: Partial<SplitOption>
  ) => {
    const updatedSplitOptions = [...splitOptions]
    const selectedSplitOptions = updatedSplitOptions[index]

    for (const key in updates) {
      if (key) {
        const castedKey = key as keyof SplitOption
        selectedSplitOptions[castedKey] = updates[castedKey] as any
      }
    }

    setSplitOptions(updatedSplitOptions)
  }

  const standardDates = (index: number, isFromDate: boolean) => {
    const indexFromDate = splitOptions[index].fromDate
    const indexToDate = splitOptions[index].toDate

    if (isFromDate) {
      if (indexToDate && !moment(indexToDate).isAfter(indexFromDate)) {
        updateSplitOptionsAtIndex(index, {
          toDate: moment(indexFromDate).add(1, 'days').toDate(),
        })
      }
    } else if (indexFromDate && !moment(indexToDate).isAfter(indexFromDate)) {
      updateSplitOptionsAtIndex(index, {
        fromDate: moment(indexToDate).subtract(1, 'days').toDate(),
      })
    }

    for (let i = index; i > 0; i--) {
      const previousIndex = i - 1
      const previousFromDate = splitOptions[previousIndex].fromDate
      const previousToDate = splitOptions[previousIndex].toDate
      const currentFromDate = splitOptions[i].fromDate

      if (
        previousToDate &&
        moment(previousToDate).diff(currentFromDate) !== 1
      ) {
        const newToDate = moment(currentFromDate).subtract(1, 'days')
        const newFromDate =
          previousFromDate && !newToDate.isAfter(previousFromDate)
            ? newToDate.clone().subtract(1, 'days')
            : undefined

        const params: any = {
          toDate: newToDate.toDate(),
        }

        if (newFromDate) {
          params.fromDate = newFromDate.toDate()
        }

        updateSplitOptionsAtIndex(previousIndex, { ...params })
      }
    }

    for (let i = index; i < splitOptions.length - 1; i++) {
      const nextIndex = i + 1
      const nextFromDate = splitOptions[nextIndex].fromDate
      const nextToDate = splitOptions[nextIndex].toDate
      const currentToDate = splitOptions[i].toDate

      if (nextFromDate && moment(currentToDate).diff(nextFromDate) !== 1) {
        const newFromDate = moment(currentToDate).add(1, 'days')
        const newToDate =
          nextToDate && newFromDate.isSameOrAfter(nextToDate)
            ? newFromDate.clone().add(1, 'days')
            : undefined

        const params: any = {
          fromDate: newFromDate.toDate(),
        }

        if (newToDate) {
          params.toDate = newToDate?.toDate()
        }

        updateSplitOptionsAtIndex(nextIndex, { ...params })
      }
    }
  }

  const validateSubmitMessage = (): string | undefined => {
    if (
      splitType === SplitTypesEnum.Date &&
      splitDateRange === PortfolioSplitDateRangeEnum.Custom
    ) {
      const hasMissingFromDate = splitOptions
        .slice(1)
        .some((option) => !option.fromDate)
      const hasMissingToDate = splitOptions
        .slice(0, -1)
        .some((option) => !option.toDate)

      if (hasMissingFromDate || hasMissingToDate) {
        return 'When split by date is selected, all dates are required.'
      }
    }

    return undefined
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        {titleText('Method')}
      </Grid>
      <Grid item xs={9}>
        <Box data-cy="portfolio-split-method" pt="2px">
          <TextField select fullWidth value={splitType}>
            {splitTypes?.map((item: any) => (
              <MenuItem
                data-cy={`portfolio-split-method-${item.id}`}
                key={item.id}
                value={item.id ?? ''}
                selected={item.id === splitType}
                onClick={() => setSplitType(item.id)}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Grid>
      {splitType === SplitTypesEnum.Date && (
        <>
          <Grid item xs={3}>
            {titleText('Date option')}
          </Grid>
          <Grid item xs={9}>
            <Box data-cy="portfolio-split-date-option" pt="2px">
              <TextField select fullWidth value={splitDateRange}>
                {splitDataRangeTypes?.map((item: any) => (
                  <MenuItem
                    data-cy={`portfolio-split-date-option-${item.id}`}
                    key={item.id}
                    value={item.id ?? ''}
                    selected={item.id === splitDateRange}
                    onClick={() => setSplitDateRange(item.id)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        {titleText('File name')}
      </Grid>
      <Grid item xs={9}>
        <Box data-cy="portfolio-split-file-name" paddingTop={4}>
          {fileName}
        </Box>
      </Grid>
      <Grid item xs={3}>
        {titleText('Asset type')}
      </Grid>
      <Grid item xs={9}>
        <Box data-cy="portfolio-split-asset-type" paddingTop={4}>
          {assetTypes}
        </Box>
      </Grid>
      <Grid item xs={3}>
        {titleText('Total accounts')}
      </Grid>
      <Grid item xs={9}>
        <Box data-cy="portfolio-total-number-of-accounts" paddingTop={4}>
          {totalNumberOfAccounts}
        </Box>
      </Grid>
      {splitType === SplitTypesEnum.Date && (
        <>
          <Grid item xs={3}>
            {titleText('Date Field')}
          </Grid>
          <Grid item xs={9}>
            <Box data-cy="portfolio-split-date-field" pt="2px">
              <TextField select fullWidth value={splitDateType}>
                {dateSplitTypes?.map((item: any) => (
                  <MenuItem
                    data-cy={`portfolio-split-date-field-${item.id}`}
                    key={item.id}
                    value={item.id ?? ''}
                    selected={item.id === splitDateType}
                    onClick={() => setSplitDateType(item.id)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </>
      )}
      {splitType === SplitTypesEnum.Date &&
      splitDateRange !== PortfolioSplitDateRangeEnum.Custom ? (
        <>
          <Grid item xs={3}>
            {titleText('Template Id')}
          </Grid>
          <Grid item xs={9} pt="2px">
            <TextField
              select
              fullWidth
              onChange={(e: any) => setTemplateId(e.target.value)}
              value={templateId}
            >
              {templates?.map((item: any) => (
                <MenuItem
                  data-cy={`portfolio-split-template-${item.id}`}
                  key={item.id}
                  value={Number(item.id) || ''}
                  selected={item.id && Number(item.id) === selectedTemplateId}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={getValueSize()}>
            {titleText(getSplitText())}
          </Grid>
          <Grid item xs={getTitleSize()}>
            <Box data-cy="portfolio-split-number" paddingTop={2}>
              <TextField
                value={numberOfSplits}
                type="number"
                inputProps={{ min: 2, max: 10 }}
                onChange={(e) => {
                  let num = Number(e.target.value)
                  if (num > 10) num = 10
                  if (num < 2) num = 2
                  while (splitOptions.length < num) {
                    splitOptions.push({ templateId: selectedTemplateId })
                  }
                  while (splitOptions.length > num) {
                    splitOptions.pop()
                  }
                  setNumberOfSplits(num)
                }}
              />
            </Box>
          </Grid>
          {[...Array(numberOfSplits)].map((x: number, i: number) => (
            <Grid item xs={12} key={i}>
              <Box borderTop="1px solid #ccc" mt="20px">
                {splitType === SplitTypesEnum.Date && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="end"
                    pt="4px"
                  >
                    <Grid item xs={2}>
                      {titleText('From')}
                    </Grid>
                    <Grid item xs={4}>
                      {i === 0 ? (
                        <Box display="flex" alignItems="end">
                          <Icon name="FirstPage" />
                        </Box>
                      ) : (
                        <DatePicker
                          value={splitOptions[i].fromDate ?? null}
                          onChange={(date: Date | null) => {
                            updateSplitOptionsAtIndex(i, { fromDate: date })

                            if (date) {
                              standardDates(i, true)
                            }
                          }}
                          country={profileClient?.Country}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {titleText('To')}
                    </Grid>
                    <Grid item xs={4}>
                      {i === numberOfSplits - 1 ? (
                        <Box
                          display="flex"
                          alignItems="end"
                          justifyContent="start"
                          pl={14}
                        >
                          <Icon name="LastPage" />
                        </Box>
                      ) : (
                        <DatePicker
                          value={splitOptions[i].toDate ?? null}
                          onChange={(date: Date | null) => {
                            updateSplitOptionsAtIndex(i, { toDate: date })

                            if (date) {
                              standardDates(i, false)
                            }
                          }}
                          country={profileClient?.Country}
                        />
                      )}
                    </Grid>
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="end"
                  pt="4px"
                >
                  <Grid item xs={2}>
                    {titleText(`Set #${i + 1}`)}
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      select
                      fullWidth
                      onChange={(e: any) =>
                        updateSplitOptionsAtIndex(i, {
                          templateId: e.target.value,
                        })
                      }
                      value={splitOptions[i].templateId}
                    >
                      {templates?.map((item: any) => (
                        <MenuItem
                          data-cy={`portfolio-split-template-${item.id}`}
                          key={item.id}
                          value={Number(item.id) || ''}
                          selected={
                            item.id && Number(item.id) === selectedTemplateId
                          }
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          ))}
        </>
      )}

      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Box display="flex" flexDirection="row" paddingTop={4}>
              <Box marginRight={2} marginLeft={2}>
                <Button
                  data-cy="portfolio-split-cancel"
                  onClick={handleCancel}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    loadingTeardownStagePortfolioSplit && (
                      <CircularProgress size={18} color="primary" />
                    )
                  }
                  disabled={loadingSplitPortfolio}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  data-cy="portfolio-split-submit"
                  onClick={handleSplit}
                  variant="contained"
                  color="primary"
                  startIcon={
                    loadingSplitPortfolio && (
                      <CircularProgress size={18} color="secondary" />
                    )
                  }
                  disabled={numberOfSplits < 2 || loadingSplitPortfolio}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SplitPortfolio
