/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { PurchaseSaleAgreementStatus } from 'src/graphql/models/PortfolioDetail'
import {
  INTERNAL,
  BUYER,
  SELLER,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { portfolioDetailMutation } from 'src/graphql/operations/mutations'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  Box,
  Grid,
  Typography,
  Link as LinkMaterial,
  Button,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Skeleton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link, useHistory } from 'react-router-dom'
import DTAccordion from 'src/components/Accordion'
import { PORTFOLIO_TEMPLATE } from 'src/routes'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { formatDateAndTime, formatDateAndTimeCountry } from 'src/utils/date'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { Icon, textPrimary, textSecondary } from 'everchain-uilibrary'
import {
  useCustomQuery,
  useCustomLazyQuery,
} from 'src/infra/react-query-wrapper'
import {
  getPortfolioFileUri,
  getPsaStatus,
} from 'src/data/features/get/portfolio/portfolio'
import {
  execPsaCommand,
  rewindPsaFinalVersion,
} from 'src/data/features/post/portfolio/portfolio'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getStandardUri } from 'src/utils/common'

interface PurchaseSaleAgreementProps {
  portfolioId: string | undefined
  sellerId: string
  sellerUploadTemplateId: number
  forwardFlowAgreementId: number | undefined
  userBusinessType: string
  portfolioCountry: string | undefined
  eSignatureEnabledPSASeller: boolean
  eSignatureEnabledPSABuyer: boolean
  isESignatureSetBuyerPSA: boolean
  isPSAESignatureSent: boolean
  hasFinalPSA: boolean
  loadingPortfolioDetails: boolean
}

const MAX_SIZE_FILE = 104857600 // 100MB

const useStylesTableCell = makeStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: 2,
  },
}))

const StyledTableCellHead = ({ children }: { children: React.ReactNode }) => {
  const classes = useStylesTableCell()

  return <TableCell className={classes.head}>{children}</TableCell>
}

const StyledTableCellBody = ({ children }: { children: React.ReactNode }) => {
  const classes = useStylesTableCell()

  return <TableCell className={classes.body}>{children}</TableCell>
}

const useStylesTableRow = makeStyles((theme: Theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

const StyledTableRow = ({ children }: { children: React.ReactNode }) => {
  const classes = useStylesTableRow()

  return <TableRow className={classes.root}>{children}</TableRow>
}

enum Action {
  Default,
  ReleasePsaDraft,
  GeneratePsaDraft,
  ApproveDraft,
  GenerateFinalPsa,
  RequestBuyerSignature,
  RequestSellerSignature,
  ConfirmFinalPsa,
  AddendumUpload,
}

const ESIGNATURE_STATUS = {
  notStarted: 'Not Started',
  pending: 'Pending Signature',
  completed: 'Completed',
}

const PurchaseSaleAgreement: React.FC<PurchaseSaleAgreementProps> = ({
  portfolioId,
  sellerId,
  sellerUploadTemplateId,
  forwardFlowAgreementId,
  userBusinessType,
  portfolioCountry,
  eSignatureEnabledPSASeller,
  eSignatureEnabledPSABuyer,
  isESignatureSetBuyerPSA,
  isPSAESignatureSent,
  hasFinalPSA,
  loadingPortfolioDetails,
}) => {
  const [fileTypeSelected, setFileTypeSelected] = useState('')
  const [draftUploadText, setDraftUploadtext] = useState('Upload PSA Draft')
  const [finalUploadText, setFinalUploadtext] = useState('Upload Final PSA')
  const [draftMessageText, setDraftMessageText] = useState('')
  const [finalMessageText, setFinalMessageText] = useState('')
  const [generateDraftMessageText, setGenerateDraftMessageText] = useState('')
  const [canReleasePsaDraft, setCanReleasePsaDraft] = useState(false)
  const [hasActivePSAWorkflow, setHasActivePSAWorkflow] = useState(false)
  const [canGeneratePSADraft, setCanGeneratePSADraft] = useState(false)
  const [canDownloadDraft, setCanDownloadDraft] = useState(false)
  const [hasDraft, setHasDraft] = useState(false)
  const [isDraftApproved, setIsDraftApproved] = useState(false)
  const [canGenerateFinalPSA, setCanGenerateFinalPSA] = useState(false)
  const [canManageFinalPSA, setCanManageFinalPSA] = useState(false)
  const [canRequestBuyerSignature, setCanRequestBuyerSignature] =
    useState(false)
  const [canRequestSellerSignature, setCanRequestSellerSignature] =
    useState(false)
  const [hasFinal, setHasFinal] = useState(false)
  const [pSACanHaveAddendum, setPSACanHaveAddendum] = useState(false)
  const [showPsaDraftFunction, setShowPsaDraftFunction] = useState(false)
  const [showPsaDraftReleaseFunctions, setShowPsaDraftReleaseFunctions] =
    useState(false)
  const [pSAHasFfCopy, setPSAHasFfCopy] = useState(false)
  const [fFPSASignatureRequired, setFFPSASignatureRequired] = useState(false)
  const [hasBuyerSignature, setHasBuyerSignature] = useState(false)
  const [hasSellerSignature, setHasSellerSignature] = useState(false)
  const [canConfirmPSA, setCanConfirmPSA] = useState(false)
  const [releaseDraftRedlineTo, setReleaseDraftRedlineTo] = useState('')
  const [confirmPSAButtonText, setConfirmPSAButtonText] = useState('')
  const [showDraftGrid, setShowDraftGrid] = useState(false)
  const [showFinalGrid, setShowFinalGrid] = useState(false)
  const [showAddendumGrid, setShowAddendumGrid] = useState(false)
  const [expandPsaDraftUpload, setExpandPsaDraftUpload] = useState(false)
  const [expandPsaFinalUpload, setExpandPsaFinalUpload] = useState(false)
  const [expandPsaAddendumUpload, setExpandPsaAddendumUpload] = useState(false)
  const [expandESignaturePsaFinalUpload, setExpandESignaturePsaFinalUpload] =
    useState(false)

  const [fileType, setFileType] = useState<string>('')

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogMessage, setDialogMessage] = useState('')
  const [dialogAction, setDialogAction] = useState(Action.Default)
  const [newSellerUploadTemplateId, setNewSellerUploadTemplateId] = useState<
    number | undefined
  >(undefined)
  const [nextStep, setNextStep] = useState('')
  const [canUploadDraft, setCanUploadDraft] = useState(false)
  const [canUploadFinal, setCanUploadFinal] = useState(false)
  const [canApproveDraft, setCanApproveDraft] = useState(
    userBusinessType === BUYER || userBusinessType === SELLER
  )
  const [docStatusLabel, setDocStatusLabel] = useState(
    ESIGNATURE_STATUS.notStarted
  )

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const history = useHistory()
  const ability = useContext(AbilityContext)
  const reactQueryClient = useQueryClient()

  const isBuyer = userBusinessType === BUYER
  const isSeller = userBusinessType === SELLER
  const isInternal = userBusinessType === INTERNAL

  const showESignatureComponent =
    eSignatureEnabledPSASeller && eSignatureEnabledPSABuyer && isInternal

  const showESignatureBuyerAlert =
    showESignatureComponent && !isESignatureSetBuyerPSA

  useMemo(() => {
    if (isPSAESignatureSent) {
      setDocStatusLabel(ESIGNATURE_STATUS.pending)
    }
  }, [isPSAESignatureSent])

  const { profileClient } = useContext<any>(AuthContext)

  const {
    useUploadPurchaseSaleAgreement,
    useUploadPSAESignature,
    usePSARequestESignature,
    useRegeneratePSAESignature,
  } = portfolioDetailMutation

  const { isFetching, executeQuery } = useCustomLazyQuery<any>(
    ['getPortfolioFileUri'],
    async (params: any) => {
      const { fileTypeDownload, portfolio, snap } = params

      return getPortfolioFileUri(portfolio, fileTypeDownload, snap).then(
        (responseData: any) => {
          const uri = responseData ?? ''
          downloadFile(uri)
        }
      )
    },
    { enabled: false, cacheTime: 10 }
  )

  const rewindPsa = useMutation({
    mutationFn: (snapshotAddress?: string) =>
      rewindPsaFinalVersion(portfolioId, snapshotAddress),
    onSuccess: (data: any) => {
      enqueueSnackbar('PSA rewinded', notistackOptions('success'))
      psaStatusRefetch()
    },
    onError: () => {
      enqueueSnackbar('Error on rewinding PSA', notistackOptions('error'))
    },
  })

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }
  const hasPSAPermissions = ability.can(
    PermissionCodeAccess.MarketPlace_Workflows_CompletePSAWorkflow,
    'any'
  )

  const {
    data: psaStatusData,
    isFetching: psaStatusDataLoading,
    refetch: psaStatusRefetch,
  } = useCustomQuery<PurchaseSaleAgreementStatus>(
    ['getPsaStatus', portfolioId],
    async () => getPsaStatus(portfolioId, isInternal, isInternal),
    { enabled: true, cacheTime: 0 }
  )

  useEffect(() => {
    if (psaStatusDataLoading) return

    const psaProps = psaStatusData

    // internal
    const activePSAWorkflow = psaProps?.hasActivePSAWorkflow || false
    setHasActivePSAWorkflow(activePSAWorkflow)
    setCanGeneratePSADraft(
      (psaProps?.canGenerateDraft &&
        !psaProps?.isDraftReleased &&
        !psaProps?.psaCanHaveAddendum &&
        hasPSAPermissions &&
        activePSAWorkflow) ||
        false
    )
    setCanDownloadDraft(
      (psaProps?.hasDraft &&
        hasPSAPermissions &&
        activePSAWorkflow &&
        !psaProps?.psaCanHaveAddendum) ||
        false
    )
    const hasDraftValue =
      (psaProps?.hasDraft && !psaProps?.psaCanHaveAddendum) || false
    setHasDraft(hasDraftValue)

    setIsDraftApproved(
      (psaProps?.isDraftApproved && !psaProps?.psaCanHaveAddendum) || false
    )
    setCanGenerateFinalPSA(
      (psaProps?.isDraftApproved &&
        !psaProps?.hasFinal &&
        hasPSAPermissions &&
        activePSAWorkflow &&
        !psaProps?.psaCanHaveAddendum) ||
        false
    )

    const ffNeedSignature =
      psaProps?.hasFinal &&
      psaProps?.fF_PSA_SignatureRequired &&
      activePSAWorkflow &&
      (!psaProps?.hasBuyerSignature || !psaProps?.hasSellerSignature)

    setCanManageFinalPSA(
      (psaProps?.isDraftApproved &&
        hasPSAPermissions &&
        !psaProps?.psaCanHaveAddendum) ||
        ffNeedSignature ||
        false
    )

    setCanRequestBuyerSignature(
      (psaProps?.canRequestBuyerSignature &&
        psaProps?.isDraftApproved &&
        hasPSAPermissions &&
        activePSAWorkflow &&
        !psaProps?.psaCanHaveAddendum) ||
        (ffNeedSignature &&
          !psaProps?.hasBuyerSignature &&
          !psaProps?.waitingForBuyerSignature) ||
        false
    )
    setCanRequestSellerSignature(
      (psaProps?.canRequestSellerSignature &&
        psaProps?.isDraftApproved &&
        hasPSAPermissions &&
        activePSAWorkflow &&
        !psaProps?.psaCanHaveAddendum) ||
        (ffNeedSignature &&
          !psaProps?.hasSellerSignature &&
          !psaProps?.waitingForSellerSignature) ||
        false
    )
    setHasFinal(psaProps?.hasFinal || psaProps?.psaHasFfCopy || false)
    setPSACanHaveAddendum(psaProps?.psaCanHaveAddendum || false)

    const psaDraftFunctionValue =
      (activePSAWorkflow &&
        !psaProps?.isDraftApproved &&
        !psaProps?.psaCanHaveAddendum &&
        (psaProps?.psaAddendumHistory == null ||
          psaProps?.psaAddendumHistory.length === 0)) ||
      false
    setShowPsaDraftFunction(psaDraftFunctionValue)

    setPSAHasFfCopy(psaProps?.psaHasFfCopy || false)
    setFFPSASignatureRequired(psaProps?.fF_PSA_SignatureRequired || false)
    setHasBuyerSignature(psaProps?.hasBuyerSignature || false)
    setHasSellerSignature(psaProps?.hasSellerSignature || false)
    setCanConfirmPSA(
      ((psaProps?.hasFinal || psaProps?.psaHasFfCopy) &&
        hasPSAPermissions &&
        !psaProps?.isFinalApproved) ||
        false
    )

    const releaseDraftRedlineToValue =
      psaProps?.releaseDraftRedlineTo ?? 'buyer'
    setReleaseDraftRedlineTo(releaseDraftRedlineToValue)

    setShowDraftGrid(
      (activePSAWorkflow &&
        psaProps?.psaDraftSnapshot &&
        psaProps?.psaDraftSnapshot.length > 0) ||
        false
    )

    setShowFinalGrid(
      (psaProps?.psaFinalSnapshot && psaProps?.psaFinalSnapshot.length > 0) ||
        false
    )

    setShowAddendumGrid(
      (psaProps?.psaAddendumHistory &&
        psaProps?.psaAddendumHistory.length > 0) ||
        false
    )

    if (!activePSAWorkflow && isInternal) {
      setExpandPsaFinalUpload(true)
    }

    const canReleasePsaDraftValue =
      (activePSAWorkflow && !psaProps?.isDraftReleased && psaProps?.hasDraft) ||
      (psaProps?.releaseDraftRedlineTo != null &&
        !psaProps?.isDraftReleased &&
        hasPSAPermissions &&
        !psaProps?.psaCanHaveAddendum)
    setCanReleasePsaDraft(canReleasePsaDraftValue)

    if (psaProps?.hasFinal || psaProps?.psaHasFfCopy) {
      setFinalUploadtext('Replace the Final PSA')
    }

    if (
      psaProps?.waitingForBuyerSignature &&
      !psaProps?.waitingForSellerSignature
    ) {
      setFinalMessageText('Waiting for the buyer signature in the Final PSA.')
    } else if (
      psaProps?.waitingForSellerSignature &&
      !psaProps?.waitingForBuyerSignature
    ) {
      setFinalMessageText('Waiting for the seller signature in the Final PSA.')
    } else if (
      psaProps?.waitingForSellerSignature &&
      psaProps?.waitingForBuyerSignature
    ) {
      setFinalMessageText(
        'Waiting for the seller and the buyer signatures in the Final PSA.'
      )
    } else if (
      psaProps?.hasBuyerSignature &&
      psaProps?.hasSellerSignature &&
      !psaProps?.isFinalApproved
    ) {
      setFinalMessageText(
        'Both buyer and seller have signed the PSA. Please review and confirm it.'
      )
    } else if (
      psaProps?.hasBuyerSignature &&
      psaProps?.hasSellerSignature &&
      psaProps?.isFinalApproved
    ) {
      setFinalMessageText('The Final PSA has been confirmed and released.')
    } else if (
      !psaProps?.waitingForBuyerSignature &&
      !psaProps?.waitingForSellerSignature &&
      !psaProps?.hasBuyerSignature &&
      !psaProps?.hasSellerSignature &&
      psaProps?.isDraftApproved
    ) {
      if (psaProps?.buyerSignsFirst)
        setFinalMessageText(
          'The Seller Upload Template indicates that the buyer signs first.'
        )
      else
        setFinalMessageText(
          "The Seller Upload Template doesn't have indication of first signature."
        )
    }

    // buyer and seller
    const uploadDraft =
      (((psaProps?.releaseDraftRedlineTo === null && isBuyer) || // first draft release
        (psaProps?.releaseDraftRedlineTo === BUYER.toLowerCase() && isBuyer) || // released to the buyer
        (psaProps?.releaseDraftRedlineTo === SELLER.toLowerCase() &&
          isSeller)) &&
        activePSAWorkflow &&
        !psaProps?.isDraftApproved &&
        psaProps?.isDraftReleased) ||
      false
    setCanUploadDraft(uploadDraft)

    if (
      (activePSAWorkflow && isInternal && !hasDraftValue) ||
      (psaDraftFunctionValue &&
        !isInternal &&
        uploadDraft &&
        psaProps?.isDraftReleased &&
        ((psaProps?.releaseDraftRedlineTo === BUYER.toLowerCase() && isBuyer) ||
          (psaProps?.releaseDraftRedlineTo === SELLER.toLowerCase() &&
            isSeller)))
    )
      setExpandPsaDraftUpload(true)

    const uploadFinal =
      (isBuyer && psaProps?.waitingForBuyerSignature) ||
      psaProps?.waitingForSellerSignature ||
      (isSeller &&
        (psaProps?.waitingForSellerSignature ||
          psaProps?.waitingForBuyerSignature)) ||
      false
    setCanUploadFinal(uploadFinal)

    if (
      (psaProps?.hasBuyerSignature && psaProps?.hasSellerSignature) ||
      !activePSAWorkflow
    ) {
      setNextStep('Waiting for the Final PSA confirmation.')
    } else if (
      (psaProps?.releaseDraftRedlineTo === SELLER.toLowerCase() && isBuyer) ||
      (psaProps?.releaseDraftRedlineTo === BUYER.toLowerCase() && isSeller)
    ) {
      setNextStep('The PSA Draft is under review.')
    } else if (
      psaProps?.waitingForBuyerSignature &&
      !psaProps?.waitingForSellerSignature &&
      isSeller
    ) {
      setNextStep('Waiting for the buyer to sign the Final PSA.')
    } else if (
      psaProps?.waitingForSellerSignature &&
      !psaProps?.waitingForBuyerSignature &&
      isBuyer
    ) {
      setNextStep('Waiting for the seller to sign the Final PSA.')
    } else if (
      uploadFinal &&
      ffNeedSignature &&
      ((isSeller && psaProps?.waitingForSellerSignature) ||
        (isBuyer && psaProps?.waitingForBuyerSignature))
    ) {
      setNextStep('Download the Final PSA, sign it, and re-upload it.')
    } else if (
      ffNeedSignature &&
      !(psaProps?.hasBuyerSignature && psaProps?.hasSellerSignature)
    ) {
      setNextStep(
        'This forward flow Final PSA requires signature and will be released shortly.'
      )
    } else if (
      !psaProps?.hasDraft ||
      (psaProps?.hasDraft && !psaProps?.isDraftReleased && !ffNeedSignature)
    ) {
      setNextStep('The PSA Draft has not been released yet.')
    } else if (
      psaProps?.isDraftReleased &&
      isSeller &&
      psaProps?.releaseDraftRedlineTo !== SELLER.toLowerCase() &&
      !psaProps?.isDraftApproved
    ) {
      setNextStep('The buyer is reviewing the PSA.')
    } else if (uploadDraft) {
      setNextStep(
        'Download and review the PSA. Once reviewed, either approve the PSA or upload your redlines.'
      )
    } else if (
      psaProps?.hasBuyerSignature &&
      isBuyer &&
      !psaProps?.hasSellerSignature
    ) {
      setNextStep("Waiting for the seller's signature.")
    } else if (
      psaProps?.hasSellerSignature &&
      isSeller &&
      !psaProps?.hasBuyerSignature
    ) {
      setNextStep("Waiting for the buyer's signature.")
    } else if (psaProps?.isDraftApproved && !uploadFinal) {
      setNextStep(
        'The PSA Draft has been approved and signatures will be requested shortly.'
      )
    } else if (uploadFinal) {
      setNextStep('Download the Final PSA, sign it, and re-upload it.')
    }

    setConfirmPSAButtonText(
      forwardFlowAgreementId &&
        psaStatusData?.psaHasFfCopy &&
        !psaStatusData?.fF_PSA_SignatureRequired
        ? 'Release For Buyer Funding'
        : 'Confirm & Release Final PSA'
    )

    setShowPsaDraftReleaseFunctions(
      (psaDraftFunctionValue && psaStatusData?.hasActivePSAWorkflow) || false
    )

    if (
      psaStatusData?.hasDraft &&
      !psaStatusData?.psaCanHaveAddendum &&
      psaStatusData?.isDraftReleased
    ) {
      setDraftUploadtext(`Upload PSA Redline (${releaseDraftRedlineToValue})`)
    } else if (psaStatusData?.hasDraft && !psaStatusData?.psaCanHaveAddendum) {
      setDraftUploadtext('Replace PSA Draft')
    }

    if (canReleasePsaDraftValue && !psaStatusData?.isDraftReleased)
      setDraftMessageText(
        `Release the PSA Draft to the ${releaseDraftRedlineToValue}.`
      )
    else if (psaStatusData?.isDraftReleased)
      setDraftMessageText(
        'The PSA Draft has been released. Waiting for redlines.'
      )

    if (
      !psaProps?.canGenerateDraft &&
      !psaProps?.isDraftReleased &&
      !hasDraftValue
    ) {
      setGenerateDraftMessageText(
        'The PSA template file does not exist in the seller upload template.'
      )
    }
  }, [
    psaStatusData,
    hasPSAPermissions,
    isBuyer,
    isSeller,
    isInternal,
    forwardFlowAgreementId,
    psaStatusDataLoading,
  ])

  const { uploadPurchaseSaleAgreement, loading: fileUploading } =
    useUploadPurchaseSaleAgreement({
      onCompleted: (data: any) => {
        const returnedId = Number(data.uploadPSA)
        if (expandPsaDraftUpload) setExpandPsaDraftUpload(false)
        if (expandPsaFinalUpload) setExpandPsaFinalUpload(false)
        if (canUploadDraft) setCanUploadDraft(false)
        psaStatusRefetch() // refetch data
        switch (fileTypeSelected) {
          case 'psa-draft':
            enqueueSnackbar(
              'The PSA Draft has been uploaded successfully',
              notifySuccess
            )
            break
          case 'psa-final':
            enqueueSnackbar(
              'The Final PSA has been uploaded successfully.',
              notifySuccess
            )
            break
          case 'psa-addendum':
            enqueueSnackbar(
              'The PSA Addendum has been uploaded successfully.',
              notifySuccess
            )
            if (returnedId) {
              setNewSellerUploadTemplateId(returnedId)
              setDialogAction(Action.AddendumUpload)
              setDialogTitle('Open Template Page')
              setDialogMessage(
                'Do you want to navigate to the new template page?s'
              )
              setDialogOpen(true)
            } else setNewSellerUploadTemplateId(undefined)
            break
        }
      },
      onError: () => {
        enqueueSnackbar(
          'Error while trying to upload the file.',
          notistackOptions('warning')
        )
      },
    })

  const { uploadPSAESignature, loading: fileUploadingESignature } =
    useUploadPSAESignature({
      onCompleted: (data: any) => {
        if (expandPsaDraftUpload) setExpandPsaDraftUpload(false)
        if (expandPsaFinalUpload) setExpandPsaFinalUpload(false)
        if (canUploadDraft) setCanUploadDraft(false)
        if (expandESignaturePsaFinalUpload)
          setExpandESignaturePsaFinalUpload(false)
        psaStatusRefetch() // refetch data
        enqueueSnackbar(
          'The PSA has been uploaded successfully and sent to E-Signature',
          notifySuccess
        )
      },
      onError: () => {
        enqueueSnackbar(
          'Error while trying to upload the file.',
          notistackOptions('warning')
        )
      },
    })

  const { psaRequestESignature, loading: requestingESignature } =
    usePSARequestESignature({
      onCompleted: (data: any) => {
        if (expandPsaDraftUpload) setExpandPsaDraftUpload(false)
        if (expandPsaFinalUpload) setExpandPsaFinalUpload(false)
        if (canUploadDraft) setCanUploadDraft(false)
        if (expandESignaturePsaFinalUpload)
          setExpandESignaturePsaFinalUpload(false)
        psaStatusRefetch() // refetch data
        if (data?.pSARequestESignature) {
          setDocStatusLabel(ESIGNATURE_STATUS.pending)
        }
        enqueueSnackbar('The PSA has been sent to E-Signature', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar(
          'Error while trying to upload the file.',
          notistackOptions('warning')
        )
      },
    })

  const { regeneratePSAESignature, loading: regeneratingESignature } =
    useRegeneratePSAESignature({
      onCompleted: (data: any) => {
        if (expandPsaDraftUpload) setExpandPsaDraftUpload(false)
        if (expandPsaFinalUpload) setExpandPsaFinalUpload(false)
        if (canUploadDraft) setCanUploadDraft(false)
        if (expandESignaturePsaFinalUpload)
          setExpandESignaturePsaFinalUpload(false)
        psaStatusRefetch() // refetch data
        enqueueSnackbar(
          'The PSA has been regenerated successfully and sent to E-Signature',
          notifySuccess
        )
      },
      onError: () => {
        enqueueSnackbar(
          'Error while trying to upload the file.',
          notistackOptions('warning')
        )
      },
    })

  const handleFileRejected = () => {
    enqueueSnackbar(
      'The file has been rejected. Please verify the file type.',
      notifyError
    )
  }

  const handleFileUpload = (acceptedFiles: File[], f: string) => {
    if (acceptedFiles && acceptedFiles.length) {
      setFileTypeSelected(f)
      uploadPurchaseSaleAgreement({
        variables: {
          portfolioId,
          psaFileType: f,
          businessType: userBusinessType,
          file: acceptedFiles[0],
        },
      })
    }
  }

  const handleFileUploadESignature = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length) {
      uploadPSAESignature({
        variables: {
          portfolioId,
          file: acceptedFiles[0],
        },
      })
    }
  }

  const handlePSARequestESignature = () => {
    psaRequestESignature({
      variables: {
        portfolioId,
      },
    })
  }

  const handleRegeneratePSAESignature = () => {
    regeneratePSAESignature({
      variables: {
        portfolioId,
      },
    })
  }

  const handleGeneratePSADraft = () => {
    setDialogAction(Action.GeneratePsaDraft)
    setDialogTitle('Re-generate the PSA Draft')
    setDialogMessage('Do you want to continue and run the PSA tokenization?')
    setDialogOpen(true)
  }

  const handleApprovePSADraft = () => {
    setDialogAction(Action.ApproveDraft)
    setDialogTitle('Approve PSA Draft')
    setDialogMessage('Are you sure you want to approve the PSA Draft?')
    setDialogOpen(true)
  }

  const handleReleasePSADraft = () => {
    setDialogAction(Action.ReleasePsaDraft)
    setDialogTitle('Release PSA Draft')
    setDialogMessage(
      `Do you want to release the PSA Draft to the ${releaseDraftRedlineTo}?`
    )
    setDialogOpen(true)
  }

  const handleGenerateFinalPSA = () => {
    setDialogAction(Action.GenerateFinalPsa)
    setDialogTitle('Generate Final PSA')
    setDialogMessage('Do you want to generate the Final PSA File?')
    setDialogOpen(true)
  }

  const handleRequestBuyerSignature = () => {
    setDialogAction(Action.RequestBuyerSignature)
    setDialogTitle('Request Buyer Signature')
    setDialogMessage(
      'Do you want to continue and request the buyer signature in the Final PSA file?'
    )
    setDialogOpen(true)
  }

  const handleRequestSellerSignature = () => {
    setDialogAction(Action.RequestSellerSignature)
    setDialogTitle('Request Seller Signature')
    setDialogMessage(
      'Do you want to continue and request the seller signature in the Final PSA file?'
    )
    setDialogOpen(true)
  }

  const handleConfirmFinalPSA = () => {
    setDialogAction(Action.ConfirmFinalPsa)
    setDialogTitle('Confirm & Release Final PSA')
    let message = 'Do you want to confirm and release the Final PSA?'
    if (forwardFlowAgreementId && pSAHasFfCopy)
      message =
        'Do you want to confirm the Forward Flow PSA and move to portfolio to Pending Buyer Funding?'
    else if (!hasBuyerSignature && !hasSellerSignature && hasActivePSAWorkflow)
      message = `Neither the buyer nor the seller signature events have been found in the history. ${message}`
    else if (!hasBuyerSignature && hasActivePSAWorkflow)
      message = `The buyer signature event hasn't been found in the history. ${message}`
    else if (!hasSellerSignature && hasActivePSAWorkflow)
      message = `The seller signature event hasn't been found in the history. ${message}`
    setDialogMessage(message)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDialogContinue = () => {
    let command = ''
    switch (dialogAction) {
      case Action.AddendumUpload:
        history.push(
          getStandardUri(
            `${PORTFOLIO_TEMPLATE}/${sellerId}/${newSellerUploadTemplateId}`
          )
        )
        return
      case Action.GeneratePsaDraft:
        setCanGeneratePSADraft(false)
        command = 'generatePSADraft'
        break
      case Action.ReleasePsaDraft:
        setCanReleasePsaDraft(false)
        command = 'releasePSADraft'
        break
      case Action.GenerateFinalPsa:
        setCanGenerateFinalPSA(false)
        command = 'generateFinalPSA'
        break
      case Action.RequestBuyerSignature:
        setCanRequestBuyerSignature(false)
        command = 'requestBuyerSignature'
        break
      case Action.RequestSellerSignature:
        setCanRequestSellerSignature(false)
        command = 'requestSellerSignature'
        break
      case Action.ConfirmFinalPsa:
        setCanConfirmPSA(false)
        command = 'confirmPSA'
        break
      case Action.ApproveDraft:
        setCanApproveDraft(false)
        command = 'approvePSADraft'
        break
      default:
        enqueueSnackbar('Action not defined.', notifyError)
        setDialogOpen(false)
        return
    }

    execPsaCommand(portfolioId, command, userBusinessType, undefined)
      .then(() => {
        psaStatusRefetch()
        switch (dialogAction) {
          case Action.GeneratePsaDraft:
            enqueueSnackbar(
              'The PSA Draft generation is in progress.',
              notifySuccess
            )
            break
          case Action.ReleasePsaDraft:
            enqueueSnackbar('The PSA Draft has been released.', notifySuccess)
            break
          case Action.ApproveDraft:
            enqueueSnackbar('The PSA Draft has been approved.', notifySuccess)
            break
          case Action.GenerateFinalPsa:
            enqueueSnackbar(
              'Final PSA generation is in progress and the PDF file will be available in a few minutes.',
              notifySuccess
            )
            break
          case Action.RequestBuyerSignature:
            enqueueSnackbar(
              'Buyer has been notified for signing the Final PSA.',
              notifySuccess
            )
            break
          case Action.RequestSellerSignature:
            enqueueSnackbar(
              'Seller has been notified for signing the Final PSA.',
              notifySuccess
            )
            break
          case Action.ConfirmFinalPsa:
            enqueueSnackbar('The Final PSA has been confirmed.', notifySuccess)
            break
        }
      })
      .catch(() => {
        switch (dialogAction) {
          case Action.GeneratePsaDraft:
            setCanGeneratePSADraft(true)
            enqueueSnackbar('Generate PSA Draft failed.', notifyError)
            break
          case Action.ReleasePsaDraft:
            setCanReleasePsaDraft(true)
            enqueueSnackbar('Release PSA Draft failed.', notifyError)
            break
          case Action.ApproveDraft:
            setCanApproveDraft(true)
            enqueueSnackbar('The PSA Draft approval failed.', notifyError)
            break
          case Action.GenerateFinalPsa:
            setCanGenerateFinalPSA(true)
            enqueueSnackbar('Release Final PSA failed.', notifyError)
            break
          case Action.RequestBuyerSignature:
            setCanRequestBuyerSignature(true)
            enqueueSnackbar(
              'The buyer notification for signing the Final PSA has failed.',
              notifyError
            )
            break
          case Action.RequestSellerSignature:
            setCanRequestSellerSignature(true)
            enqueueSnackbar(
              'The seller notification for signing the Final PSA has failed.',
              notifyError
            )
            break
          case Action.ConfirmFinalPsa:
            setCanConfirmPSA(true)
            enqueueSnackbar('PSA confirmation failed.', notifySuccess)
            break
        }
      })
    setDialogOpen(false)
  }

  const disableESRequestButton =
    docStatusLabel === ESIGNATURE_STATUS.pending ||
    !hasFinalPSA ||
    requestingESignature ||
    loadingPortfolioDetails

  return (
    <>
      <DTAccordion
        id="psa-accordion"
        title="Purchase-Sale Agreement"
        icon={<Icon name="Assignment" />}
        expanded={true}
      >
        {psaStatusDataLoading || loadingPortfolioDetails ? (
          <Skeleton variant="rectangular" width="100%" height={50} />
        ) : (
          <Grid container spacing={4}>
            {/* template disabled message */}
            {!hasActivePSAWorkflow && isInternal && (
              <Grid item xs={12}>
                <Typography>
                  The Seller Upload Template (
                  <LinkMaterial
                    color="primary"
                    variant="body2"
                    component={Link}
                    to={getStandardUri(
                      `${PORTFOLIO_TEMPLATE}/${sellerId}/${sellerUploadTemplateId}`
                    )}
                  >
                    ID: {sellerUploadTemplateId}
                  </LinkMaterial>
                  ) associated to this portfolio has PSA Workflow disabled.
                </Typography>
              </Grid>
            )}
            {/* buyer and seller message */}
            {nextStep && !isInternal && (
              <Grid item xs={12}>
                <Typography variant="body2" color={textPrimary.color}>
                  <strong>Next Step: </strong>
                  {nextStep}
                </Typography>
              </Grid>
            )}

            {/* generate PSA draft */}
            {hasActivePSAWorkflow && showPsaDraftFunction && isInternal && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box>
                    <Button
                      data-cy="re-generate-psa-draft"
                      disableRipple={true}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!canGeneratePSADraft}
                      onClick={handleGeneratePSADraft}
                    >
                      Re-generate PSA Draft
                    </Button>
                  </Box>
                  <Box pl={4}>
                    {canGeneratePSADraft && (
                      <Typography variant="body2" color={textPrimary.color}>
                        Re-run the PSA tokenization. The PSA Draft will be
                        replaced.
                      </Typography>
                    )}
                  </Box>
                  {generateDraftMessageText && (
                    <Box pl={4}>
                      <Typography variant="body2" color={textPrimary.color}>
                        {generateDraftMessageText}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
            {/* upload PSA draft */}
            {showPsaDraftFunction &&
              isInternal &&
              hasActivePSAWorkflow &&
              !hasDraft && (
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="body2"
                      color={textPrimary.color}
                      display="inline"
                    >
                      Upload the PSA Draft
                    </Typography>
                  </Box>
                </Grid>
              )}
            {showPsaDraftFunction &&
              isInternal &&
              hasActivePSAWorkflow &&
              hasDraft && (
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="body2"
                      color={textPrimary.color}
                      display="inline"
                    >
                      Click
                    </Typography>
                    <Typography
                      variant="body2"
                      color={textSecondary.color}
                      display="inline"
                      onClick={() => {
                        setExpandPsaDraftUpload(!expandPsaDraftUpload)
                      }}
                    >
                      <Tooltip
                        title={`${
                          hasDraft ? 'Replace' : ' Upload'
                        } the PSA Draft`}
                        style={{ cursor: 'pointer' }}
                      >
                        <strong> here </strong>
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="body2"
                      color={textPrimary.color}
                      display="inline"
                    >
                      to {hasDraft ? ' replace ' : ' upload '} the PSA Draft.
                    </Typography>
                  </Box>
                </Grid>
              )}
            {canDownloadDraft && canUploadDraft && !isInternal && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row">
                  <Box pr={4}>
                    <Button
                      disabled={isFetching}
                      disableRipple={true}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        executeQuery({
                          fileTypeDownload: 'psa-draft',
                          portfolio: portfolioId,
                          snap: undefined,
                        })
                      }}
                    >
                      Download the Most Recent PSA Draft
                    </Button>
                  </Box>
                  <Box pr={4}>
                    <Button
                      disableRipple={true}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!canApproveDraft}
                      onClick={handleApprovePSADraft}
                    >
                      Approve PSA Draft
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
            {showPsaDraftFunction && !isInternal && canUploadDraft && (
              <Grid item xs={12}>
                <Box pt={2}>
                  <Typography
                    variant="body2"
                    color={textPrimary.color}
                    display="inline"
                  >
                    Replace the PSA Draft with your redlines
                  </Typography>
                </Box>
              </Grid>
            )}
            {showPsaDraftFunction && expandPsaDraftUpload && (
              <Grid item xs={12}>
                <UploadDragDrop
                  size="small"
                  linkText={hasDraft && isInternal ? 'Replace' : 'Upload'}
                  text={
                    isInternal
                      ? ' the PSA Draft  (*.docx)'
                      : ' PSA Draft redlines  (*.docx)'
                  }
                  disabled={hasPSAPermissions}
                  uploading={fileUploading && fileTypeSelected === 'psa-draft'}
                  uploadDisabled={psaStatusDataLoading}
                  onDrop={(acceptedFiles) => {
                    handleFileUpload(acceptedFiles, 'psa-draft')
                  }}
                  maxSize={MAX_SIZE_FILE}
                  onDropRejected={handleFileRejected}
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>100MB</strong>
                </Typography>
              </Grid>
            )}
            {/* PSA draft grid */}
            {showDraftGrid && isInternal && (
              <>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="body2" color={textPrimary.color}>
                      <strong>PSA Draft Versions</strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead>Created</StyledTableCellHead>
                        <StyledTableCellHead>User</StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {psaStatusData?.psaDraftSnapshot.map((item, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCellBody>
                            <Button
                              disableRipple={true}
                              style={{
                                backgroundColor: 'transparent',
                                textDecoration: 'underline',
                              }}
                              onClick={() => {
                                executeQuery({
                                  fileTypeDownload: 'psa-draft',
                                  portfolio: portfolioId,
                                  snap: item.snapshotAddress,
                                })
                              }}
                            >
                              {formatDateAndTime(item.createdOn)}
                            </Button>
                          </StyledTableCellBody>
                          <StyledTableCellBody>
                            {item.userName}
                          </StyledTableCellBody>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={4} />
              </>
            )}
            {/* release draft to buyer/seller */}
            {showPsaDraftReleaseFunctions && isInternal && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box>
                    <Button
                      disableRipple={true}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!canReleasePsaDraft}
                      onClick={handleReleasePSADraft}
                    >
                      Release PSA Draft for Review
                    </Button>
                  </Box>
                  <Box pl={4}>
                    <Typography variant="body2" color={textPrimary.color}>
                      {draftMessageText}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
            {showESignatureComponent && (
              <Grid item xs={12}>
                {showESignatureBuyerAlert && (
                  <Box pl={4}>
                    <Typography>
                      Buyer doesn't have PSA signer information defined for
                      ESignature. Please setup it on the Control Panel.
                    </Typography>
                  </Box>
                )}
                {!showESignatureBuyerAlert && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="body2" color={textPrimary.color}>
                        Purchase Sale Agreement
                      </Typography>
                      <Typography variant="body2" color={textPrimary.color}>
                        Status: {docStatusLabel}
                      </Typography>
                      <br />
                      <Box display="flex" flexDirection="row">
                        <Box mr={4}>
                          <Button
                            disableRipple={true}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={disableESRequestButton}
                            onClick={handlePSARequestESignature}
                          >
                            Request Signature
                          </Button>
                        </Box>
                        <Box mr={4}>
                          <Button
                            disableRipple={true}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={
                              () =>
                                setExpandESignaturePsaFinalUpload(
                                  !expandESignaturePsaFinalUpload
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            Update PSA
                          </Button>
                        </Box>
                        <Box mr={4}>
                          <Button
                            disableRipple={true}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleRegeneratePSAESignature}
                            disabled={regeneratingESignature}
                          >
                            Regenerate PSA
                          </Button>
                        </Box>
                      </Box>
                      {expandESignaturePsaFinalUpload && (
                        <Grid item xs={12}>
                          <br />
                          <UploadDragDrop
                            size="small"
                            linkText="Update"
                            text=" the Final PSA  (*.docx or *.pdf)"
                            disabled={hasPSAPermissions}
                            uploading={fileUploadingESignature}
                            uploadDisabled={psaStatusDataLoading}
                            onDrop={(acceptedFiles) => {
                              handleFileUploadESignature(acceptedFiles)
                            }}
                            maxSize={MAX_SIZE_FILE}
                            onDropRejected={handleFileRejected}
                            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            Maximum allowed file size is <strong>100MB</strong>
                          </Typography>
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}

            {!showESignatureComponent && (
              <Grid item xs={12}>
                {canManageFinalPSA && isInternal && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box>
                        <Button
                          disableRipple={true}
                          variant="contained"
                          color="primary"
                          size="small"
                          hidden={
                            psaStatusData?.psaHasFfCopy &&
                            psaStatusData?.fF_PSA_SignatureRequired
                          }
                          disabled={!canGenerateFinalPSA}
                          onClick={handleGenerateFinalPSA}
                        >
                          Generate Final PSA
                        </Button>
                      </Box>
                      {canGenerateFinalPSA && (
                        <Box pl={4}>
                          <Typography>
                            Convert the DOCX draft into PDF. Make sure all the
                            draft changes have been "accepted".
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
                {!hasActivePSAWorkflow && isInternal && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        Upload the Final PSA before confirming and releasing it.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {hasActivePSAWorkflow && isInternal && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        Click
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        display="inline"
                        onClick={() => {
                          setExpandPsaFinalUpload(!expandPsaFinalUpload)
                        }}
                      >
                        <Tooltip
                          title={`${
                            hasFinal ? 'Replace' : ' Upload'
                          } the Final PSA`}
                          style={{ cursor: 'pointer' }}
                        >
                          <strong> here </strong>
                        </Tooltip>
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        to {hasFinal ? ' replace ' : ' upload '} the Final PSA.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {canUploadFinal && !isInternal && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        Click
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        display="inline"
                        onClick={() => {
                          setExpandPsaFinalUpload(!expandPsaFinalUpload)
                        }}
                      >
                        <Tooltip
                          title="Upload Executed PSA"
                          style={{ cursor: 'pointer' }}
                        >
                          <strong> here </strong>
                        </Tooltip>
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        to upload the Executed PSA.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {canUploadFinal && !isInternal && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row">
                      <Box pr={4}>
                        <Button
                          disableRipple={true}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            executeQuery({
                              fileTypeDownload: 'psa-final',
                              portfolio: portfolioId,
                              snap: undefined,
                            })
                          }}
                        >
                          Download the Final PSA
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {expandPsaFinalUpload && (
                  <Grid item xs={12}>
                    <UploadDragDrop
                      size="small"
                      linkText={hasFinal ? 'Replace' : 'Upload'}
                      text=" the Final PSA  (*.pdf)"
                      disabled={hasPSAPermissions}
                      uploading={
                        fileUploading && fileTypeSelected === 'psa-final'
                      }
                      uploadDisabled={psaStatusDataLoading}
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(acceptedFiles, 'psa-final')
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                      Maximum allowed file size is <strong>100MB</strong>
                    </Typography>
                  </Grid>
                )}

                {/* PSA draft grid */}
                {showFinalGrid && isInternal && (
                  <>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2" color={textPrimary.color}>
                          <strong>Final PSA Versions</strong>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead>Created</StyledTableCellHead>
                            <StyledTableCellHead>User</StyledTableCellHead>
                            <StyledTableCellHead>#</StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {psaStatusData?.psaFinalSnapshot.map(
                            (item, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCellBody>
                                  <Button
                                    disableRipple={true}
                                    style={{
                                      backgroundColor: 'transparent',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                      executeQuery({
                                        fileTypeDownload: 'psa-final',
                                        portfolio: portfolioId,
                                        snap: item.snapshotAddress,
                                      })
                                    }}
                                  >
                                    {formatDateAndTime(item.createdOn)}
                                  </Button>
                                </StyledTableCellBody>
                                <StyledTableCellBody>
                                  {item.userName}
                                </StyledTableCellBody>
                                <StyledTableCellBody>
                                  {item.snapshotAddress != null && (
                                    <Button
                                      disableRipple={true}
                                      disabled={
                                        !hasPSAPermissions ||
                                        item.snapshotAddress == null
                                      }
                                      style={{
                                        backgroundColor: 'transparent',
                                        textDecoration: 'underline',
                                      }}
                                      onClick={() => {
                                        rewindPsa.mutate(item.snapshotAddress)
                                      }}
                                    >
                                      Rewind
                                    </Button>
                                  )}
                                </StyledTableCellBody>
                              </StyledTableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={4} />
                  </>
                )}
                {canManageFinalPSA && isInternal && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row">
                      <Box mr={4}>
                        <Button
                          disableRipple={true}
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={!canRequestBuyerSignature}
                          onClick={handleRequestBuyerSignature}
                        >
                          Request Buyer Signature
                        </Button>
                      </Box>
                      <Box mr={4}>
                        <Button
                          disableRipple={true}
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={!canRequestSellerSignature}
                          onClick={handleRequestSellerSignature}
                        >
                          Request Seller Signature
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {finalMessageText && isInternal && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color={textPrimary.color}>
                      {finalMessageText}
                    </Typography>
                  </Grid>
                )}
                {pSACanHaveAddendum && isInternal && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        Click
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        display="inline"
                        onClick={() => {
                          setExpandPsaAddendumUpload(!expandPsaAddendumUpload)
                        }}
                      >
                        <Tooltip
                          title="Upload PSA Addenum"
                          style={{ cursor: 'pointer' }}
                        >
                          <strong> here </strong>
                        </Tooltip>
                      </Typography>
                      <Typography
                        variant="body2"
                        color={textPrimary.color}
                        display="inline"
                      >
                        to upload a PSA Addendum.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {pSACanHaveAddendum &&
                  expandPsaAddendumUpload &&
                  isInternal && (
                    <Grid item xs={12}>
                      <UploadDragDrop
                        size="small"
                        linkText="Upload"
                        text=" PSA Addendum"
                        disabled={hasPSAPermissions}
                        uploading={
                          fileUploading && fileTypeSelected === 'psa-addendum'
                        }
                        uploadDisabled={psaStatusDataLoading}
                        onDrop={(acceptedFiles) => {
                          handleFileUpload(acceptedFiles, 'psa-addendum')
                        }}
                        maxSize={MAX_SIZE_FILE}
                        onDropRejected={handleFileRejected}
                        accept="application/pdf"
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Maximum allowed file size is <strong>100MB</strong>
                      </Typography>
                    </Grid>
                  )}
                {showAddendumGrid && (
                  <>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2" color={textPrimary.color}>
                          <strong>PSA Addendum Versions</strong>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead>Created</StyledTableCellHead>
                            <StyledTableCellHead>User</StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {psaStatusData?.psaAddendumHistory.map(
                            (item, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCellBody>
                                  <Button
                                    disableRipple={true}
                                    style={{
                                      backgroundColor: 'transparent',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                      executeQuery({
                                        fileTypeDownload: 'psa-addendum',
                                        portfolio: portfolioId,
                                        snap: null,
                                      })
                                    }}
                                  >
                                    {formatDateAndTimeCountry(
                                      item.createdOn,
                                      portfolioCountry ||
                                        profileClient?.Country ||
                                        process.env.REACT_APP_COUNTRY
                                    )}
                                  </Button>
                                </StyledTableCellBody>
                                <StyledTableCellBody>
                                  {item.userName}
                                </StyledTableCellBody>
                              </StyledTableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={4} />
                  </>
                )}
                {isInternal && (
                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                    <Button
                      disableRipple={true}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!canConfirmPSA}
                      onClick={handleConfirmFinalPSA}
                    >
                      {confirmPSAButtonText}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </DTAccordion>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle style={{ paddingLeft: '7px' }}>{dialogTitle}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: 'white',
          }}
        >
          <Icon name="Close" />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <Grid container direction="column">
              <Grid item>
                <Typography>{dialogMessage}</Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogContinue}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PurchaseSaleAgreement
