/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  MenuItem,
  Autocomplete,
  Skeleton,
} from '@mui/material'

import { FormikErrors } from 'formik'
import { useLazyQuery, useQuery } from '@apollo/client'
import { COMPLAINT_EXTERNAL_AGENCY } from 'src/graphql/operations/queries/complaint'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { UserType } from 'src/graphql/models/User'
import { INTERNAL, SELLER } from 'src/configs/AuthService'
import { DefaultValuesProps } from 'src/graphql/models/Complaint'

interface BuyerAgencyResponseProps {
  loading?: boolean
  initialValues: DefaultValuesProps
  handleChange: (name: any, value: any) => void
  errors: FormikErrors<DefaultValuesProps>
  canEdit: boolean
  portfolioCountry: any
}

const BuyerAgencyResponse: React.FC<BuyerAgencyResponseProps> = ({
  loading,
  initialValues,
  handleChange,
  errors,
  canEdit,
  portfolioCountry,
}) => {
  const accountStatusTypes =
    portfolioCountry !== 'UK'
      ? [
          {
            label: 'Recalled from agency',
            value: 'Recalled from agency',
          },
          {
            label: 'Recalled from agency and closed by buyer',
            value: 'Recalled from agency and closed by buyer',
          },
          {
            label: 'Remains open with agency',
            value: 'Remains open with agency',
          },
        ]
      : [
          {
            label: 'Remains open for collection',
            value: 'Remains open for collection',
          },
          {
            label: 'No further action',
            value: 'No further action',
          },
        ]

  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const [loadBuyerAgency, { data: nameList, loading: loadingNameList }] =
    useLazyQuery<any>(COMPLAINT_EXTERNAL_AGENCY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (nameList?.nameList) {
      setOptions(nameList?.nameList || [])
    }
  }, [nameList])

  const isInternal = userType?.userType === INTERNAL
  const isSeller = userType?.userType === SELLER

  const [options, setOptions] = useState<string[]>([])
  const canEditBuyerResponseData = () => {
    if (isInternal) return true
    if (isSeller) return false

    return initialValues.status === 'investigating' && canEdit
  }

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" height={150} />
      </>
    )
  }

  return (
    <Box>
      <Grid justifyContent="flex-start" container item>
        <Grid container spacing={4}>
          <Grid item xs={6} md={6}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <TextField
                id="accountStatus"
                name="accountStatus"
                select
                label="Acct. Status"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors?.accountStatus}
                helperText={<>{errors?.accountStatus}</>}
                value={initialValues.accountStatus}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value)
                }}
                disabled={!canEditBuyerResponseData()}
              >
                {accountStatusTypes.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Autocomplete
              options={options}
              clearOnEscape
              freeSolo
              disabled={!canEditBuyerResponseData()}
              onSelect={(e: any) => {
                if (e?.target?.value) initialValues.agency = e.target.value
                else initialValues.agency = ''
              }}
              value={initialValues.agency || ''}
              onInputChange={(event, value) => {
                if (value || value === '') initialValues.agency = value
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="agency"
                  label="Agency"
                  fullWidth
                  error={!!errors?.agency}
                  helperText={<>{errors?.agency}</>}
                  value={initialValues.agency}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value)
                    loadBuyerAgency({
                      variables: {
                        nameFilter: e.target.value,
                      },
                    })
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingNameList ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="employeesInvolved"
              value={initialValues.employeesInvolved}
              label="Employees Involved"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="agencyAction"
              label="Agency Action"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={initialValues.agencyAction}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="agencyResponse"
              label="Agency Response"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={initialValues.agencyResponse}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="agencyPreventionSteps"
              label="Agency Prevention Steps"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={initialValues.agencyPreventionSteps}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              disabled={!canEditBuyerResponseData()}
              name="followUpRequired"
              checked={initialValues.followUpRequired}
              value={initialValues.followUpRequired}
              onChange={() => {
                handleChange(
                  'followUpRequired',
                  !initialValues.followUpRequired
                )
              }}
              control={<Switch color="primary" name="followUpRequired" />}
              label="Follow-up required"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              disabled={!canEditBuyerResponseData()}
              control={<Switch name="documentsRequired" color="primary" />}
              onChange={() => {
                handleChange(
                  'documentsRequired',
                  !initialValues.documentsRequired
                )
              }}
              label="Documents required"
              checked={initialValues.documentsRequired}
              value={initialValues.documentsRequired}
              name="documentsRequired"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

BuyerAgencyResponse.defaultProps = {
  loading: false,
}
export default BuyerAgencyResponse
