import React, { useContext, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSaveBid } from 'src/graphql/operations/mutations/portfolio'
import { GET_PORTFOLIO_BID } from 'src/graphql/operations/queries/portfolio'

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Skeleton,
  InputLabel,
  InputAdornment,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioBidQuery } from 'src/graphql/models/PortfolioDetail'
import DTAccordion from 'src/components/Accordion'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ApprovedBuyer } from 'src/data/features/get/buyer/types'
import { getApprovedBuyers } from 'src/data/features/get/buyer/buyer'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { BidType } from 'src/data/features/get/portfolio/types'
import { getBidTypesList } from 'src/data/features/get/portfolio/portfolio'

interface SaveBidProps {
  portfolioId: string | undefined
}

const SaveBid: React.FC<SaveBidProps> = ({ portfolioId }: SaveBidProps) => {
  const [buyer, setBuyer] = useState<string>('')
  const [bidType, setBidType] = useState<string>('')
  const [bidPercent, setBidPercent] = useState<any>(0)
  const [forwardFlowBidMonth, setForwardFlowBidMonth] = useState<number>(0)
  const [bidCloseDate, setBidCloseDate] = useState<Date | null>(null)
  const [bidFundingDate, setBidFundingDate] = useState<Date | null>(null)
  const [forwardFlowBidMaxFaceValue, setForwardFlowBidMaxFaceValue] =
    useState<number>(0)
  const [boxFwVisible, setBoxFwVisible] = useState<Boolean>(false)
  const { profileClient } = useContext(AuthContext)

  const { data: buyers, isFetching: loadingBuyers } = useCustomQuery<
    ApprovedBuyer[]
  >(['getApprovedBuyers'], async () => getApprovedBuyers(), { cacheTime: 0 })

  const { data: bidTypes, isFetching: loadingBids } = useCustomQuery<BidType[]>(
    ['GetBidTypesList', portfolioId],
    async () => getBidTypesList(portfolioId),
    { cacheTime: 0, enabled: !!portfolioId }
  )

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const buyerList = buyers
  const bidList = bidTypes

  const { saveBidRequest, loading: loadingSaveBid } = useSaveBid({
    onCompleted: (regenerateResponse: string) => {
      if (regenerateResponse) {
        enqueueSnackbar('The bid has been saved', notifySuccess)
      } else {
        enqueueSnackbar('Error', notifyError)
      }
    },
  })

  const handleBidChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBidPercent(event.target.value as number)
  }
  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForwardFlowBidMonth(event.target.value as number)
  }
  const handleMaxFaceChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setForwardFlowBidMaxFaceValue(event.target.value as number)
  }

  const [getBid, { data: bidData }] = useLazyQuery<PortfolioBidQuery>(
    GET_PORTFOLIO_BID,
    {
      variables: {
        portfolioBidParamsRequest: {
          portfolioId,
          buyerId: buyer,
          bidType,
        },
      },
    }
  )

  const handleBuyerChange = (event: any) => {
    setBuyer(event.target.value as string)
    if (bidType !== '') {
      getBid()
      if (bidData !== null) {
        setBidPercent(bidData?.Bid?.bidPercent ?? 0)
        setBidFundingDate(bidData?.Bid?.bidFundingDate ?? null)
        setBidCloseDate(bidData?.Bid?.bidPortfolioCloseDate ?? null)
      } else {
        setBidPercent(0)
        setBidFundingDate(null)
        setBidCloseDate(null)
      }
    }
  }

  const handleBidTypeChange = (event: any) => {
    const type = event.target.value as string
    setBidType(type)

    if (type === 'forward-flow') {
      setBoxFwVisible(true)
    } else {
      setBoxFwVisible(false)
    }

    if (buyer !== '') {
      getBid()
      if (bidData !== null) {
        setBidPercent(bidData?.Bid?.bidPercent ?? 0)
        setBidFundingDate(bidData?.Bid?.bidFundingDate ?? null)
        setBidCloseDate(bidData?.Bid?.bidPortfolioCloseDate ?? null)
      } else {
        setBidPercent(0)
        setBidFundingDate(null)
        setBidCloseDate(null)
      }
    }
  }

  const onSaveBid = () => {
    if (bidType === 'one-time') {
      saveBidRequest({
        variables: {
          saveBidRequest: {
            portfolioId,
            buyerId: buyer,
            bidType,
            oneTimeBidPercent: bidPercent / 100,
            bidFundingDate,
            bidPortfolioCloseDate: bidCloseDate,
          },
        },
      })
    } else {
      saveBidRequest({
        variables: {
          saveBidRequest: {
            portfolioId,
            buyerId: buyer,
            bidType,
            forwardFlowBidPercent: bidPercent / 100,
            forwardFlowBidMonths: Number(forwardFlowBidMonth),
            forwardFlowMaxFaceValue: Number(forwardFlowBidMaxFaceValue),
            bidFundingDate,
            bidPortfolioCloseDate: bidCloseDate,
          },
        },
      })
    }
  }

  if (loadingBuyers || loadingBids) {
    return (
      <DTAccordion
        id="bid-update-accordion"
        title="Add/Update Bids"
        icon={<Icon name="Update" />}
      >
        <Box width="100%">
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </DTAccordion>
    )
  }
  if (buyerList && bidList) {
    return (
      <DTAccordion
        id="bid-update-accordion"
        title="Add/Update Bids"
        icon={<Icon name="Update" />}
      >
        <Box display="flex" flexDirection="column" p={4}>
          <Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Buyer
                    <br />
                    <br />
                    <InputLabel id="lblTitle">
                      Select the buyer you want to add/update the bid for:
                    </InputLabel>
                    <Select
                      fullWidth
                      value={buyer}
                      onChange={handleBuyerChange}
                    >
                      {buyerList?.map((option: any) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Bid Type
                    <br />
                    <Select
                      fullWidth
                      value={bidType}
                      onChange={handleBidTypeChange}
                    >
                      {bidList?.map((option: any) => (
                        <MenuItem key={option.value} value={option.description}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Bid percent <br />
                    <TextField
                      style={{ fontSize: '6px' }}
                      id="bid"
                      value={bidPercent}
                      onChange={handleBidChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box id="boxFw" display={boxFwVisible ? 'flex' : 'none'}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Forward flow term (months) <br />
                    <TextField
                      style={{ fontSize: '6px' }}
                      id="months"
                      value={forwardFlowBidMonth}
                      onChange={handleMonthChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Forward flow max. face value <br />
                    <TextField
                      style={{ fontSize: '6px' }}
                      id="maxValue"
                      value={forwardFlowBidMaxFaceValue}
                      onChange={handleMaxFaceChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box id="boxDates" display="flex">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Close Date <br />
                    <DatePicker
                      country={profileClient?.Country}
                      margin="normal"
                      id="bidCloseDate"
                      label="Close date"
                      value={bidCloseDate}
                      onChange={(date: any) => setBidCloseDate(date)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    Funding Date <br />
                    <DatePicker
                      country={profileClient?.Country}
                      margin="normal"
                      id="bidFundingDate"
                      label="Funding date"
                      value={bidFundingDate}
                      onChange={(date: any) => setBidFundingDate(date)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box>
            <Button
              onClick={onSaveBid}
              color="primary"
              variant="outlined"
              autoFocus
              startIcon={
                loadingSaveBid ? (
                  <CircularProgress size={15} color="primary" />
                ) : (
                  <Icon name="Save" fontSize="small" />
                )
              }
              disabled={loadingSaveBid}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DTAccordion>
    )
  }
  return <></>
}

export default SaveBid
