import React, { useState } from 'react'
import {
  Chip,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { ListSelectedFilters, SelectedFilterTitle } from './styles'

interface DialogSalveFilersProps {
  onSave: (name: string, filterItems: any[]) => void
  onClose: () => void
  onRemoveFilter: (id: number | string) => void
  open: boolean
  filters: any[]
}

const DialogSaveFilter: React.FC<DialogSalveFilersProps> = ({
  onSave,
  onClose,
  onRemoveFilter,
  open,
  filters,
}: DialogSalveFilersProps) => {
  const [error, setError] = useState('')
  const [filterNameValue, setFilterNameValue] = useState<string>('')

  const handleSubmit = (): void => {
    if (!filterNameValue.length) {
      setError('Required')
    } else {
      setError('')
      onSave(filterNameValue, filters)
      setFilterNameValue('')
      onClose()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setFilterNameValue(value)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-filter"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-filter-title">Save filters</DialogTitle>
      <DialogContent>
        <DialogContentText>Create your custom filters</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="Name"
          label="Filter name"
          placeholder="My custom filter"
          fullWidth
          value={filterNameValue}
          onChange={handleChange}
          error={!!error}
          helperText={error}
          focused
        />
        {!!filters.length && (
          <ListSelectedFilters>
            {filters.map((filter) => (
              <div key={filter.displayName} className="selected-filter-item">
                <SelectedFilterTitle>{filter.displayName}</SelectedFilterTitle>
                <Chip
                  label={filter.filterItem.displayName}
                  onDelete={(): void => onRemoveFilter(filter.id)}
                  color="primary"
                  variant="outlined"
                />
              </div>
            ))}
          </ListSelectedFilters>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!filters.length}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogSaveFilter
