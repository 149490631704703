/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_BULK_RESPONSE_ACCOUNTS,
  GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID,
  GET_PORTFOLIO_BUYERS,
} from 'src/graphql/operations/queries/postSale'
import {
  BulkResponseQuery,
  PortfolioNumberResponse,
} from 'src/graphql/models/PostSale'
import { Cookies } from 'react-cookie'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import {
  useBulkResponseConfirm,
  useBulkResponseReject,
  useBulkCloseRequest,
} from 'src/graphql/operations/mutations/postSale'
import { formatDate, formatTime } from 'src/utils/date'
import { BusinessInfo, BusinessInfoResponse } from 'src/graphql/models/Business'

import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { capitalize } from 'src/utils/text'
import { TdNoEllipsis } from 'src/styles/layout'
import { ExportToExcel } from 'src/utils/exportToExcel'
import {
  GetPostSaleDownloadRequestFileColumns,
  GetPostSaleRespondFileHeaders,
} from 'src/utils/fileHeaders'
import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import {
  ColumnMetadata,
  DataValidation,
  FileParseResult,
  ParseXlsxFile,
} from 'src/utils/parseFile'
import { useDropzone } from 'react-dropzone'
import { Link, useHistory } from 'react-router-dom'
import {
  ACCOUNT_DETAIL,
  PORTFOLIO_DETAIL,
  POSTSALE,
  POST_SALE_DETAIL,
} from 'src/routes'
import { BulkRespondOperation } from 'src/utils/constants'
import { renderNumber } from 'src/utils/formatKendoColumns'
import { BulkUploadSection, Content, Header } from './styles'
import { RequestType, RequestTypeEnum, RequestTypeList } from '../RequestType'
import {
  getAccountClosedDataGridColumns,
  getBankruptGridColumns,
  getDeceasedGridColumns,
  getDirectPayGridColumns,
  getDismissedDischargedDataGridColumns,
  getInaccurateDataGridColumns,
  getInfoGridColumns,
  getLegalOtherAndFraudGridColumns,
  getLienLossGridColumns,
  getPaidPriorGridColumns,
  getPifSifDataGridColumns,
  getScraMilitaryDataGridColumns,
} from '../PostSaleCommomGridColumns'
import {
  DataTable,
  DataTableState,
  Icon,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getMyPortfoliosSellers } from 'src/data/features/get/portfolio/portfolio'
import { getStandardUri } from 'src/utils/common'
import { submitAccountsRequestedData } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const cookies = new Cookies()
const BulkResponseMain: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
  const [rejectReasonText, setRejectReasonText] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorDownload, setAnchorDownload] = useState<null | HTMLElement>(null)
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [accountsSelectedToDownload, setAccountsSelectedToDownload] = useState<
    any[]
  >([])
  const [totalDataSize, setTotalDataSize] = useState<number>(25)
  const [requestsData, setRequestsData] = useState<any[]>([])
  const [initialValues] = useState<any>({})
  const [error, setError] = useState<any>()
  const { userPermissions, profileBusiness } = useContext(AuthContext)
  const history = useHistory()

  const [file, setFile] = useState<any>(null)
  const [fileParseResult, SetFileParseResult] = useState<FileParseResult>()
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])
  const [submitAccountsRequest, setSubmitAccountsRequest] =
    useState<boolean>(false)

  const accept =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: disableUI,
    multiple: false,
  })

  const [action, setAction] = useState<string>('response')

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUkCountry = country.toUpperCase() === 'UK'
  const validate = (values: any) => {
    const errors: any = {}
    if (isBuyer && !values.buyerSelected) errors.buyerSelected = 'Required'
    if (isSeller && !values.sellerSelected) errors.sellerSelected = 'Required'
    if (!values.requestTypeSelected) errors.requestTypeSelected = 'Required'

    return errors
  }

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })
  useEffect(() => {
    const postsaleformCollection = cookies.get('postsaleformCollection')

    if (postsaleformCollection) {
      formCollection.setFieldValue(
        'requestTypeSelected',
        postsaleformCollection.values.requestTypeSelected
      )

      formCollection.setFieldValue(
        'sellerSelected',
        postsaleformCollection.values.sellerSelected
      )
      formCollection.setFieldValue(
        'buyerSelected',
        postsaleformCollection.values.buyerSelected
      )

      getRequests({
        variables: {
          requestTypeId: postsaleformCollection.values.requestTypeSelected?.Id,
          kendoPagination: JSON.stringify(gridState),
          sellerId: postsaleformCollection.values.sellerSelected?.id,
          buyerId: postsaleformCollection.values.buyerSelected?.id,
          batchAttachment: false,
        },
      })

      cookies.remove('postsaleformCollection')
    }
    formCollection.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])
  const isInternal = userPermissions.type === 'internal'

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const { data: sellerList, isFetching: loadingSellerList } = useCustomQuery<
    BusinessInfo[]
  >(['getMyPortfoliosSellers'], async () => getMyPortfoliosSellers(), {
    enabled: true,
    cacheTime: 0,
  })

  const [getBuyers, { data: buyersList, loading: loadingBuyerList }] =
    useLazyQuery<BusinessInfoResponse>(GET_PORTFOLIO_BUYERS)
  const [
    getPortfolios,
    { data: portfolioList, loading: loadingPortfolioList },
  ] = useLazyQuery<PortfolioNumberResponse>(
    GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID
  )

  const [
    getRequests,
    { data: requestsQuery, loading: loadingAccounts, refetch },
  ] = useLazyQuery<BulkResponseQuery>(GET_BULK_RESPONSE_ACCOUNTS, {
    variables: {
      requestTypeId: formCollection.values.requestTypeSelected?.Id,
      kendoPagination: JSON.stringify(gridState),
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [getDownloadRequests] = useLazyQuery<BulkResponseQuery>(
    GET_BULK_RESPONSE_ACCOUNTS,
    {
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
      },
    }
  )

  const {
    isFetching: submitAccountsRequestedDataLoading,
    isSuccess: submitAccountsRequestedSuccess,
    isError: submitAccountsRequestedError,
  } = useCustomQuery(
    ['submitAccountsRequestedData', accountsSelected, submitAccountsRequest],
    async () => submitAccountsRequestedData(accountsSelected),
    {
      cacheTime: 0,
      enabled: !!(accountsSelected.length > 0 && submitAccountsRequest),
    }
  )

  useEffect(() => {
    getBuyers({
      variables: {
        sellerId: '',
      },
    })
  }, [getBuyers])

  useEffect(() => {
    if (
      buyersList?.businesses &&
      buyersList?.businesses?.length > 0 &&
      isBuyer
    ) {
      const profile = buyersList.businesses.filter((x) =>
        profileBusiness.filter((y) => String(y.Id) === x.id)
      )
      if (profile.length > 0) handleBuyerSelected(profile[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyersList, formCollection.values.requestTypeSelected])

  useEffect(() => {
    if (sellerList && sellerList?.length > 0 && isSeller) {
      const profile = sellerList.filter((x) =>
        profileBusiness.filter((y) => String(y.Id) === x.id)
      )
      if (profile.length > 0) handleSellerSelected(profile[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerList, formCollection.values.requestTypeSelected])

  useEffect(() => {
    setRequestsData(requestsQuery?.requests.requestDetailsResponse || [])
  }, [requestsQuery])

  useEffect(() => {
    loadingAccounts
    setDisableUI(submitAccountsRequestedDataLoading || loadingAccounts)
    if (submitAccountsRequestedSuccess) {
      resetSelectedItems()
      enqueueSnackbar('Request(s) submitted', notifySuccess)
      setSubmitAccountsRequest(false)
      refetch()
    }
    if (submitAccountsRequestedError) {
      setSubmitAccountsRequest(false)
      enqueueSnackbar('Operation failed', notifyError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitAccountsRequestedSuccess,
    submitAccountsRequestedError,
    submitAccountsRequestedDataLoading,
    loadingAccounts,
  ])

  const { bulkResponseConfirm } = useBulkResponseConfirm({
    onCompleted: () => {
      resetSelectedItems()
      enqueueSnackbar('Request(s) confirmed', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })
  const { bulkCloseRequest } = useBulkCloseRequest({
    onCompleted: () => {
      resetSelectedItems()
      enqueueSnackbar('Request(s) closed', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })
  const { bulkResponseReject } = useBulkResponseReject({
    onCompleted: () => {
      resetSelectedItems()
      setShowRejectReasonModal(false)
      setRejectReasonText('')
      enqueueSnackbar('Request(s) rejected', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  useEffect(() => {
    setTotalDataSize(requestsQuery?.requests.total ?? 25)
  }, [requestsQuery])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDownload(event.currentTarget)
  }

  const handleFilterClick = () => {
    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        statuses: [action],
        accountIds: formCollection.values.accountIds,
      },
    })
    resetSelectedItems()
  }
  const handleDownloadRequestOption = (event: any) => {
    setAnchorDownload(null)
    handleDownloadRequests(event?.currentTarget?.textContent)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseDownload = () => {
    setAnchorDownload(null)
  }

  const resetSelectedItems = () => {
    setAccountsSelected([])
    setAccountsSelectedToDownload([])
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      const accountsId = requestsData
        .slice(gridState.skip, (gridState.take || 25) + (gridState.skip || 0))
        .map((x) => x.id)

      const accounts = requestsData.slice(
        gridState.skip,
        (gridState.take || 25) + (gridState.skip || 0)
      )

      if (accountsId && accountsId?.length > 0) {
        const allIds = accountsId.concat(accountsSelected)
        const dataSelected = [...new Set([...allIds, ...accountsSelected])]

        const allAccounts = accounts.concat(accountsSelectedToDownload)
        const newDataSelected = [
          ...new Set([...allAccounts, ...accountsSelectedToDownload]),
        ]

        if (dataSelected !== undefined && dataSelected.length > 0) {
          setAccountsSelected(dataSelected)
          setAccountsSelectedToDownload(newDataSelected)
        }
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const accountsId = requestsData
        .slice(gridState.skip, (gridState.take || 25) + (gridState.skip || 0))
        .map((x) => x.id)

      const accounts = requestsData.slice(
        gridState.skip,
        (gridState.take || 25) + (gridState.skip || 0)
      )

      const ids = accountsSelected.filter((x) => !accountsId?.includes(x))
      const newDataSelected = accountsSelectedToDownload.filter(
        (x) => !accounts?.includes(x)
      )

      if (ids !== undefined) {
        setAccountsSelected(ids)
        setAccountsSelectedToDownload(newDataSelected)
      }
    }
  }

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['id']) !== undefined
    )
      return true

    return false
  }

  const formatComments = (comments: any) => {
    if (!Array.isArray(comments) || comments.length === 0) {
      return ''
    }

    const formattedComments = comments
      .map((comment) => `• ${comment}`)
      .join('\n')
    return formattedComments
  }

  const GridColumns: any[] = [
    {
      title: '  #',
      width: 55,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              data-cy="bulk-request-checkbox"
              value={props.dataItem['id']}
              onChange={(event: any) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['id'],
                  ])
                  setAccountsSelectedToDownload([
                    ...accountsSelectedToDownload,
                    props.dataItem,
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter((x) => x !== props.dataItem['id'])
                  )
                  setAccountsSelectedToDownload(
                    accountsSelectedToDownload.filter(
                      (x) => x.id !== props.dataItem['id']
                    )
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'id',
      title: 'ID',
      filter: 'numeric',
      width: 80,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Link
              onClick={() => {
                cookies.set('postsaleformCollection', formCollection)
              }}
              to={getStandardUri(`${POST_SALE_DETAIL}/${props.dataItem['id']}`)}
            >
              {props.dataItem['id']}
            </Link>
          </td>
        )
      },
    },
    {
      field: 'lender',
      title: 'Lender',
      filter: 'text',
      show: true,
      width: 130,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      filter: 'text',
      show: true,
      width: 130,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'status',
      title: 'Status',
      filter: 'text',
      show: true,
      width: 165,
      render: (props: any) => {
        return (
          <td title={capitalize(props.dataItem['status'])}>
            {capitalize(props.dataItem['status'])}
          </td>
        )
      },
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      filter: 'date',
      show: true,
      width: 130,
      render: (props: any) => {
        return (
          <td>
            {formatDate(props.dataItem['lastUpdated'])}
            <br />
            {formatTime(props.dataItem['lastUpdated'])}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      filter: 'text',
      show: !isInternal,
      width: 120,
    },
    {
      field: 'firstName',
      title: 'First Name',
      filter: 'text',
      show: !isInternal,
      width: 120,
    },
    {
      field: 'numberOfFiles',
      title: 'Number Of Files',
      filter: 'numeric',
      show: true,
      render: renderNumber,
      width: 120,
    },
    {
      field: 'comments',
      title: 'Comments',
      show: true,
      width: 120,
      render: (props: any) => {
        const title = formatComments(props.dataItem['comments'])
        return (
          <td style={{ textAlign: 'center' }}>
            {title.length > 0 && (
              <Tooltip
                title={<div style={{ whiteSpace: 'pre-line' }}>{title}</div>}
              >
                <Icon name="Comment" />
              </Tooltip>
            )}
          </td>
        )
      },
    },
    {
      field: 'portfolioNumber',
      title: 'PID',
      filter: 'numeric',
      show: true,
      tooltipTitle: 'Portfolio Number',
      width: 100,
      render: (props: any) => {
        return (
          <td>
            <Link
              onClick={() => {
                cookies.set('postsaleformCollection', formCollection)
              }}
              to={getStandardUri(
                `${PORTFOLIO_DETAIL}/${props.dataItem['portfolioId']}`
              )}
            >
              {props.dataItem['portfolioNumber']}
            </Link>
          </td>
        )
      },
    },
  ]

  const getGridColumns = (): any[] => {
    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Bankrupt
    ) {
      return GridColumns.concat(getBankruptGridColumns(isUkCountry))
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Deceased
    ) {
      return GridColumns.concat(getDeceasedGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.DirectPay
    ) {
      return GridColumns.concat(getDirectPayGridColumns(isUkCountry))
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Info
    ) {
      return GridColumns.concat(getInfoGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Legal ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Other ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Fraud
    ) {
      return GridColumns.concat(getLegalOtherAndFraudGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.PaidPrior
    ) {
      return GridColumns.concat(getPaidPriorGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.LienLoss
    ) {
      return GridColumns.concat(getLienLossGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.InaccurateData
    ) {
      return GridColumns.concat(getInaccurateDataGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.ScraMilitary
    ) {
      return GridColumns.concat(getScraMilitaryDataGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.DismissedDischarged
    ) {
      return GridColumns.concat(getDismissedDischargedDataGridColumns())
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.PifSif
    ) {
      return GridColumns.concat(getPifSifDataGridColumns(isUkCountry))
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.AccountClosed
    ) {
      return GridColumns.concat(getAccountClosedDataGridColumns())
    }

    return GridColumns
  }

  const handleRequestSelected = (type: RequestType) => {
    resetSelectedItems()
    formCollection.setFieldValue('requestTypeSelected', type)
    getRequests({
      variables: {
        requestTypeId: type.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        statuses: [action],
        accountIds: formCollection.values.accountIds,
      },
    })
  }

  const clearCookies = () => {
    cookies.remove('postsaleformCollection')
  }

  const onSubmitRequestedData = () => {
    setDisableUI(true)
    setSubmitAccountsRequest(true)
  }

  const onAccept = () => {
    setDisableUI(true)
    bulkResponseConfirm({
      variables: {
        requestIds: accountsSelected,
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
      },
      refetchQueries: ['GetBulkResponseAccounts'],
    })
  }

  const onReject = () => {
    setShowRejectReasonModal(true)
  }
  const onClose = () => {
    setDisableUI(true)
    bulkCloseRequest({
      variables: {
        requestIds: accountsSelected,
      },
      refetchQueries: ['GetBulkResponseAccounts'],
    })
  }
  const onSave = () => {
    setDisableUI(true)
    const requestsInfo = requestsData
      .filter((request) => {
        return accountsSelected.indexOf(request.id) !== -1
      })
      .map((request) => ({
        id: request.id,
        rejectReason: rejectReasonText,
      }))

    bulkResponseReject({
      variables: {
        requestsInfo,
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
      },
      refetchQueries: ['GetBulkResponseAccounts'],
    })
  }

  const handleBuyerSelected = (business: BusinessInfo) => {
    formCollection.setFieldValue('buyerSelected', business)
    if (formCollection.values.sellerSelected) {
      getPortfolios({
        variables: {
          sellerId: formCollection.values.sellerSelected?.id,
          buyerId: business.id,
        },
      })
    }
    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: business?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        statuses: [action],
        accountIds: formCollection.values.accountIds,
      },
    })
  }

  const handleSellerSelected = (business: BusinessInfo) => {
    formCollection.setFieldValue('buyerSelected', undefined)
    formCollection.setFieldValue('pidSelected', undefined)
    formCollection.setFieldValue('loanIdsSelected', undefined)
    formCollection.setFieldValue('sellerSelected', business)
    resetSelectedItems()

    getPortfolios({
      variables: {
        sellerId: business.id,
      },
    })

    getBuyers({
      variables: {
        sellerId: business.id,
      },
    })

    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: business?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        statuses: [action],
        accountIds: formCollection.values.accountIds,
      },
    })
  }
  const handlePidSelected = (portfolioNumber: number) => {
    resetSelectedItems()
    formCollection.setFieldValue('pidSelected', portfolioNumber)
  }

  const getOperation = () => {
    if (
      [RequestTypeEnum.Info, RequestTypeEnum.InaccurateData].includes(
        formCollection.values.requestTypeSelected.Id
      )
    ) {
      if (action.toLowerCase() === 'response')
        return BulkRespondOperation.DataSubmission

      return BulkRespondOperation.AcceptReject
    }

    if (
      [RequestTypeEnum.PifSif, RequestTypeEnum.AccountClosed].includes(
        formCollection.values.requestTypeSelected.Id
      )
    )
      return BulkRespondOperation.Close

    return BulkRespondOperation.AcceptReject
  }

  const handleDownloadRequests = (option: string) => {
    const columns = GetPostSaleDownloadRequestFileColumns(
      formCollection.values.requestTypeSelected.Id,
      country
    ).map((x: ColumnMetadata) => x.name.toLowerCase())

    const operation = getOperation()

    columns.push('id')
    columns.push('status')

    if (option.toLowerCase() === 'all requests') {
      getDownloadRequests({
        variables: {
          requestTypeId: formCollection.values.requestTypeSelected?.Id,
          sellerId: formCollection.values.sellerSelected?.id,
          buyerId: formCollection.values.buyerSelected?.id,
          pId: formCollection.values.pidSelected,
          last4SSN: formCollection.values.lastSSN,
          statuses: [action],
          accountIds: formCollection.values.accountIds,
        },
        onCompleted: (data: any) => {
          if (data !== null) {
            const dataToDownload: any[] = data?.requests.requestDetailsResponse

            const columnsToDelete = Object.keys(dataToDownload[0]).filter(
              (key) => !columns.includes(key.toLowerCase())
            )

            const dataToExport = dataToDownload.reduce((acc, row) => {
              let newRow: any = {}
              Object.keys(row).forEach((key) => {
                newRow[key] = row[key]
              })
              newRow.ecaid = row.debtAccountId

              const formattedComments = row.comments
                ? row.comments
                    .map((comment: string) => `• ${comment}`)
                    .join('\n')
                : ''

              if (operation === BulkRespondOperation.DataSubmission)
                newRow = { ...newRow, comments: formattedComments }
              else if (operation === BulkRespondOperation.AcceptReject)
                newRow = {
                  'Accept (Y/N)': '',
                  'Reject Reason': '',
                  ...newRow,
                  comments: formattedComments,
                }

              columnsToDelete.forEach((columnName) => delete newRow[columnName])
              acc.push(newRow)

              return acc
            }, [])
            ExportToExcel(
              formCollection.values.requestTypeSelected?.Name,
              dataToExport
            )
          }
        },
      })
    } else if (option.toLowerCase() === 'template') {
      columns.push('id')
      columns.push('status')

      if (operation === BulkRespondOperation.AcceptReject) {
        columns.push('Accept (Y/N)')
        columns.push('Reject Reason')
      }

      const template: any = {}

      columns.forEach((key) => {
        template[key] = null
      })

      ExportToExcel(formCollection.values.requestTypeSelected?.Name, [template])
    } else {
      const columnsToDelete = Object.keys(accountsSelectedToDownload[0]).filter(
        (key) => !columns.includes(key.toLowerCase())
      )

      const dataToExport = accountsSelectedToDownload.reduce((acc, row) => {
        let newRow = row
        newRow.ecaid = row.debtAccountId

        const formattedComments = row.comments
          ? row.comments.map((comment: string) => `• ${comment}`).join('\n')
          : ''

        if (operation === BulkRespondOperation.DataSubmission)
          newRow = { ...newRow, comments: formattedComments }
        else if (operation === BulkRespondOperation.AcceptReject)
          newRow = {
            'Accept (Y/N)': '',
            'Reject Reason': '',
            ...newRow,
            comments: formattedComments,
          }

        columnsToDelete.forEach((columnName) => delete newRow[columnName])
        acc.push(newRow)

        return acc
      }, [])
      ExportToExcel(
        formCollection.values.requestTypeSelected?.Name,
        dataToExport
      )
    }
  }

  const OnHandleUploadFile = async () => {
    const columnsMetadata = GetPostSaleRespondFileHeaders(
      formCollection.values.requestTypeSelected.Id,
      getOperation()
    )
    const result = await ParseXlsxFile(file, columnsMetadata)

    if (!result.passedValidation) {
      SetFileParseResult(result)
      return
    }

    const { errors, dataConverted } = DataValidation(
      result.data,
      columnsMetadata,
      country
    )

    const validAccounts = dataConverted.filter(
      (x: any) => !errors.some((i) => i.rowIndex === x.rowIndex)
    )

    history.push({
      pathname: getStandardUri(`${POSTSALE}/respond/file-upload/errors`),
      state: {
        allData: result.data,
        allValidData: JSON.stringify(validAccounts),
        errors,
        isUkCountry,
        requestTypeId: formCollection.values.requestTypeSelected.Id,
        operation: getOperation(),
      },
    })
  }

  const getUploadFileType = () => {
    if (formCollection.values.requestTypeSelected?.Id === 7)
      return 'Upload Bulk Close File'

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Legal ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Other ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Fraud
    )
      return 'Upload Bulk Submit File'

    return 'Upload Bulk Respond File'
  }

  const handleResetSelections = () => {
    formCollection.resetForm()
    resetSelectedItems()
    setRequestsData([])
  }

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <BulkUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" style={{ color: '#656565' }}>
                Bulk Respond
                {formCollection.values.requestTypeSelected
                  ? ` - Type: ${formCollection.values.requestTypeSelected?.Name}`
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid item style={{ paddingTop: '5px', maxWidth: '100%' }}>
            {(loadingAccounts ||
              loadingSellerList ||
              loadingBuyerList ||
              loadingPortfolioList ||
              disableUI) &&
              loadingPanel}
            <Grid
              container
              direction="row"
              spacing={3}
              paddingX={2}
              paddingBottom={2}
            >
              <Grid item>
                <TextField
                  label="Request Type"
                  select
                  error={!!error?.requestTypeSelected}
                  helperText={error?.requestTypeSelected}
                  id="select-request-type"
                  value={formCollection.values.requestTypeSelected?.Name ?? ''}
                  style={{ minWidth: '150px' }}
                >
                  <MenuItem value="" onClick={handleResetSelections}>
                    <em>Select a request type</em>
                  </MenuItem>
                  {RequestTypeList(
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  ).map((type: RequestType) => {
                    if (
                      isBuyer &&
                      (type.Id === RequestTypeEnum.PifSif ||
                        type.Id === RequestTypeEnum.AccountClosed)
                    )
                      return undefined

                    if (isSeller && type.Id === RequestTypeEnum.DirectPay)
                      return undefined
                    if (isUkCountry && type.Id === RequestTypeEnum.ScraMilitary)
                      return undefined

                    if (type.Id === RequestTypeEnum.PifSif) {
                      if (type.Name === 'PIF') type.Name = 'PIF/SIF'
                      else if (type.Name === 'SIF') return undefined
                    }
                    return (
                      <MenuItem
                        key={type.Name}
                        value={type.Name}
                        onClick={() => {
                          if (type.Id !== 3 && type.Id !== 10) {
                            setAction('response')
                          }

                          handleRequestSelected(type)
                          formCollection.setFieldValue(
                            'buyerSelected',
                            undefined
                          )
                          formCollection.setFieldValue('pidSelected', undefined)
                          formCollection.setFieldValue(
                            'loanIdsSelected',
                            undefined
                          )
                          formCollection.setFieldValue(
                            'sellerSelected',
                            undefined
                          )
                          resetSelectedItems()
                          clearCookies()
                        }}
                      >
                        {type.Name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Seller"
                  select
                  id="select-seller"
                  error={!!error?.sellerSelected}
                  helperText={error?.sellerSelected}
                  value={formCollection.values.sellerSelected?.name ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('buyerSelected', undefined)
                      formCollection.setFieldValue('pidSelected', undefined)
                      formCollection.setFieldValue('loanIdsSelected', undefined)
                      formCollection.setFieldValue('sellerSelected', undefined)
                      resetSelectedItems()
                      clearCookies()
                    }}
                  >
                    <em>Select seller</em>
                  </MenuItem>
                  {sellerList?.map((business) => {
                    return (
                      <MenuItem
                        key={business.id}
                        value={business.name}
                        onClick={() => {
                          handleSellerSelected(business)
                        }}
                      >
                        {business.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Buyer"
                  select
                  id="select-buyer"
                  error={!!error?.buyerSelected}
                  helperText={error?.buyerSelected}
                  value={formCollection.values.buyerSelected?.name ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      resetSelectedItems()
                      clearCookies()
                    }}
                  >
                    <em>Select buyer</em>
                  </MenuItem>
                  {buyersList?.businesses.map((business: BusinessInfo) => {
                    return (
                      <MenuItem
                        key={business.id}
                        value={business.name}
                        onClick={() => {
                          handleBuyerSelected(business)
                        }}
                      >
                        {business.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="PID"
                  select
                  id="select-pid"
                  value={formCollection.values.pidSelected ?? ''}
                  fullWidth
                  style={{ minWidth: '90px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      resetSelectedItems()
                      formCollection.setFieldValue(
                        'requestTypeSelected',
                        undefined
                      )
                      formCollection.setFieldValue('loanIdsSelected', undefined)
                    }}
                  >
                    <em>Select PID</em>
                  </MenuItem>
                  {portfolioList?.portfoliosNumber.map(
                    (portfolioNumber: number) => {
                      return (
                        <MenuItem
                          key={portfolioNumber}
                          value={portfolioNumber}
                          onClick={() => {
                            handlePidSelected(portfolioNumber)
                            clearCookies()
                          }}
                        >
                          {portfolioNumber}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              {!isUkCountry && (
                <>
                  <Grid item>
                    <TextField
                      label="Last 4 SSN"
                      id="select-SSN"
                      value={formCollection.values.lastSSN ?? undefined}
                      fullWidth
                      style={{ minWidth: '100px' }}
                      disabled={
                        formCollection.values.pidSelected === undefined &&
                        formCollection.values.buyerSelected === undefined &&
                        formCollection.values.sellerSelected === undefined
                      }
                      onChange={(e) => {
                        const numbers = /^(\d+)$/
                        if (
                          e.target.value.match(numbers) ||
                          e.target.value === ''
                        ) {
                          formCollection.setFieldValue(
                            'lastSSN',
                            e.target.value
                          )
                        }
                      }}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                </>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  label="Account IDs"
                  id="select-LoanIds"
                  value={formCollection.values.accountIds ?? undefined}
                  fullWidth
                  style={{ minWidth: '300px' }}
                  onChange={(e) => {
                    formCollection.setFieldValue('accountIds', e.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  data-cy="filter-button"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    formCollection.values.requestTypeSelected === undefined
                  }
                >
                  Filter
                </Button>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={3}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: '10px',
                }}
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    Total of accounts selected: {accountsSelected.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-cy="bulk-selection-options-button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                    disabled={disableUI}
                  >
                    Bulk Selection Options{' '}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Select all rows (current page)
                    </MenuItem>
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Unselect all rows (current page)
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                {(formCollection.values.requestTypeSelected?.Id ===
                  RequestTypeEnum.InaccurateData ||
                  formCollection.values.requestTypeSelected?.Id ===
                    RequestTypeEnum.Info) && (
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Action"
                      select
                      data-cy="dropdown-action"
                      id="dropdown-action"
                      value={action}
                      fullWidth
                      style={{ minWidth: '120px' }}
                    >
                      <MenuItem
                        key="response"
                        value="response"
                        onClick={() => {
                          setAction('response')

                          getRequests({
                            variables: {
                              requestTypeId:
                                formCollection.values.requestTypeSelected?.Id,
                              kendoPagination: JSON.stringify(gridState),
                              sellerId:
                                formCollection.values.sellerSelected?.id,
                              buyerId: formCollection.values.buyerSelected?.id,
                              pId: formCollection.values.pidSelected,
                              last4SSN: formCollection.values.lastSSN,
                              statuses: ['response'],
                              accountIds: formCollection.values.accountIds,
                            },
                          })
                        }}
                      >
                        Response
                      </MenuItem>
                      <MenuItem
                        key="review"
                        value="review"
                        onClick={() => {
                          setAction('review')
                          getRequests({
                            variables: {
                              requestTypeId:
                                formCollection.values.requestTypeSelected?.Id,
                              kendoPagination: JSON.stringify(gridState),
                              sellerId:
                                formCollection.values.sellerSelected?.id,
                              buyerId: formCollection.values.buyerSelected?.id,
                              pId: formCollection.values.pidSelected,
                              last4SSN: formCollection.values.lastSSN,
                              statuses: ['review'],
                              accountIds: formCollection.values.accountIds,
                            },
                          })
                        }}
                      >
                        Review
                      </MenuItem>
                    </TextField>
                  </Grid>
                )}

                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid
                    container
                    style={{ display: 'flex', alignItems: 'center' }}
                    spacing={1}
                  >
                    {formCollection.values.requestTypeSelected?.Id !==
                      RequestTypeEnum.PifSif &&
                      formCollection.values.requestTypeSelected?.Id !==
                        RequestTypeEnum.AccountClosed && (
                        <>
                          {(formCollection.values.requestTypeSelected?.Id !==
                            RequestTypeEnum.InaccurateData &&
                            formCollection.values.requestTypeSelected?.Id !==
                              RequestTypeEnum.Info) ||
                          (action === 'review' &&
                            (formCollection.values.requestTypeSelected?.Id ===
                              RequestTypeEnum.InaccurateData ||
                              formCollection.values.requestTypeSelected?.Id ===
                                RequestTypeEnum.Info)) ? (
                            <>
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  data-cy="accept-post-sale-request-button"
                                  title="Accept"
                                  variant="contained"
                                  color="primary"
                                  onClick={onAccept}
                                  startIcon={<Icon name="Check" />}
                                  disabled={
                                    disableUI || accountsSelected.length === 0
                                  }
                                >
                                  Accept
                                </Button>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  data-cy="reject-post-sale-request-button"
                                  title="Reject"
                                  variant="contained"
                                  color="primary"
                                  onClick={onReject}
                                  startIcon={<Icon name="Clear" />}
                                  disabled={
                                    disableUI || accountsSelected.length === 0
                                  }
                                >
                                  Reject
                                </Button>
                              </Grid>
                            </>
                          ) : (
                            <Grid
                              item
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                data-cy="submit-request-information-button"
                                title="Submit Request Information"
                                variant="contained"
                                color="primary"
                                onClick={onSubmitRequestedData}
                                startIcon={<Icon name="ArrowForward" />}
                                disabled={
                                  disableUI || accountsSelected.length === 0
                                }
                              >
                                Submit Requested Information
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    {(formCollection.values.requestTypeSelected?.Id ===
                      RequestTypeEnum.PifSif ||
                      formCollection.values.requestTypeSelected?.Id ===
                        RequestTypeEnum.AccountClosed) && (
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          data-cy="close-post-sale-request-button"
                          title="Close"
                          variant="contained"
                          color="primary"
                          onClick={onClose}
                          startIcon={<Icon name="Check" />}
                          disabled={disableUI || accountsSelected.length === 0}
                        >
                          Close
                        </Button>
                      </Grid>
                    )}
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        data-cy="download-post-sale-request-button"
                        aria-controls="request-menu"
                        aria-haspopup="true"
                        title="Download"
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadClick}
                        startIcon={<Icon name="GetApp" />}
                        disabled={disableUI || requestsData.length === 0}
                      >
                        Download
                      </Button>
                      <Menu
                        id="request-menu"
                        anchorEl={anchorDownload}
                        keepMounted
                        open={Boolean(anchorDownload)}
                        onClose={handleCloseDownload}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        {accountsSelected.length > 0 && (
                          <MenuItem onClick={handleDownloadRequestOption}>
                            Selected requests
                          </MenuItem>
                        )}
                        <MenuItem onClick={handleDownloadRequestOption}>
                          All requests
                        </MenuItem>
                        <MenuItem onClick={handleDownloadRequestOption}>
                          Template
                        </MenuItem>
                      </Menu>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        data-cy="upload-bulk-file-button"
                        title="Upload Bulk File"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowUploadDialog(true)}
                        startIcon={<Icon name="Publish" />}
                        disabled={disableUI || requestsData.length === 0}
                      >
                        {getUploadFileType()}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DataTable
              style={{
                width: '100%',
              }}
              data={requestsData}
              sortable
              useFilterMenu
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
              total={totalDataSize}
              onDataStateChange={(e: any) => {
                setGridState(e.dataState)
                getRequests({
                  variables: {
                    requestTypeId:
                      formCollection.values.requestTypeSelected?.Id,
                    kendoPagination: JSON.stringify(e.dataState),
                    sellerId: formCollection.values.sellerSelected?.id,
                    buyerId: formCollection.values.buyerSelected?.id,
                    pId: formCollection.values.pidSelected,
                    last4SSN: formCollection.values.lastSSN,
                    statuses: [action],
                    accountIds: formCollection.values.accountIds,
                  },
                })
              }}
              gridColumns={getGridColumns()}
            />
          </Grid>
        </Grid>
        <Dialog
          open={showRejectReasonModal}
          onClose={() => {
            setShowRejectReasonModal(false)
            setRejectReasonText('')
          }}
          aria-labelledby="form-filter"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Reject Reason
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowRejectReasonModal(false)
                setRejectReasonText('')
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please, provide a reason for rejection:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="Reason"
              label="Reason"
              placeholder="Reason"
              fullWidth
              value={rejectReasonText}
              onChange={(e) => setRejectReasonText(e.currentTarget.value)}
              focused
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowRejectReasonModal(false)
                setRejectReasonText('')
              }}
              color="primary"
              disabled={disableUI}
            >
              Cancel
            </Button>
            <Button
              onClick={onSave}
              color="primary"
              variant="contained"
              disabled={disableUI || !rejectReasonText}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showUploadDialog}
          onClose={() => setShowUploadDialog(false)}
          aria-labelledby="form-filter"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Upload File</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowUploadDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box>
                {!file ? (
                  <DropUpload
                    isDragActive={isDragActive}
                    {...getRootProps()}
                    disabled={disableUI}
                  >
                    <input {...getInputProps()} />
                    <div className="upload-placeholder">
                      <Icon name="CloudUpload" className="upload-icon" />{' '}
                      <strong style={{ cursor: 'pointer' }}>Browse </strong> or
                      Drop file here to upload
                    </div>
                  </DropUpload>
                ) : (
                  <UploadItem>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      onClick={() => {
                        setFile(null)
                        SetFileParseResult(undefined)
                      }}
                      disabled={disableUI}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </UploadItem>
                )}
              </Box>
              {!fileParseResult?.passedValidation && (
                <Typography color="error">
                  {fileParseResult?.errorMessage}
                </Typography>
              )}
              <Box display="flex" justifyContent="end" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    disableUI && <CircularProgress size={15} color="primary" />
                  }
                  onClick={async () => {
                    OnHandleUploadFile()
                  }}
                  disabled={disableUI || !file}
                >
                  Upload File
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </BulkUploadSection>
    </form>
  )
}

export default BulkResponseMain
