import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

export interface IModalButton {
  text: string
  onClick: () => void
}

export interface IModalProps {
  open: boolean
  title: string
  body: string
  acceptButtonText: string
  cancelButtonText: string
  buttons?: IModalButton[]
}

export interface IModalContextProps {
  store: IModalProps
  setStore: Dispatch<
    SetStateAction<{
      acceptButtonText: string
      body: string
      cancelButtonText: string
      open: boolean
      title: string
    }>
  >
}

export const ModalContext = createContext<IModalContextProps>({
  store: {
    acceptButtonText: '',
    body: '',
    cancelButtonText: '',
    open: false,
    title: '',
    buttons: [],
  },
  setStore: () => {},
})

export const useGlobalModalContext = () => useContext(ModalContext)

interface ModalProviderProps {
  children: React.ReactNode
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [store, setStore] = useState({
    acceptButtonText: '',
    body: '',
    cancelButtonText: '',
    open: false,
    title: '',
  })

  return (
    <ModalContext.Provider value={{ store, setStore }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
